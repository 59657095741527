// React and Redux
import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

// PrimeReact Components
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import DataTableContainer from '../../../components/primeGrid/DataTableContainer';

// Template Components
import Header from '../../templates/Structures/Header';
import Options from '../../templates/Structures/Options';

// Common Components
import Flex from '../../../components/common/Flex';
import Text from '../../../components/common/Text';
import { pluralize } from '../../../components/common/Header';
import { EntityTools } from './components';

// Actions
import { showModal } from '../../../modules/modal/actions';
import {
  processArchiveConfigProfiles,
  processFetchAllConfigProfiles,
  processUnarchiveConfigProfiles,
} from '../../../entities/Synchronize/ConfigProfiles/actions';

// Selectors
import { selectIsShowingArchived } from '../../../modules/query/selectors';
import {
  selectSidebarIsFiltering,
  selectSynchronizeModulePermissionsAndState,
} from '../../Dashboard/selectors';
import {
  selectCurrentFilteredConfigProfiles,
  selectCurrentConfigProfileFilterIsAdvanced
} from './selectors';
import {
  selectConfigProfilesCounts,
  selectConfigProfileWithCounts
} from '../../../entities/Synchronize/ConfigProfiles/selectors';

// Paths and Styles
import { SynchronizeModuleConfigProfilesPath } from '../../../paths';
import './styles.css';

const mapStateToProps = createSelector(
  selectSynchronizeModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectCurrentConfigProfileFilterIsAdvanced(),
  selectIsShowingArchived(),
  selectConfigProfilesCounts(),
  selectCurrentFilteredConfigProfiles(),
  selectConfigProfileWithCounts(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isAdvancedFiltering,
    isShowingArchived,
    {unarchived, total},
    filteredData,
    profilesWithCounts
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!filteredData || filteredData.length === 0));

    // Create a map of profiles with counts for easy lookup
    const countsMap = profilesWithCounts.reduce((acc, profile) => {
      acc[profile.id] = {
        settingsCount: profile.settingsCount,
        mapkeysCount: profile.mapkeysCount
      };
      return acc;
    }, {});

    return {
      ...rest,
      isLoading,
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      data: (!isLoading && filteredData) || [],
      editable: canCollaborate && hasValidLicense,
      canCollaborate,
      hasValidLicense,
      isFiltering,
      isShowingArchived,
      unarchived,
      total,
      isAdvancedFiltering: !!isAdvancedFiltering,
      countsMap,
    };
  },
);

const baseFilterVal = {
  global: { value: '', matchMode: FilterMatchMode.CONTAINS },
  name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
};

function ConfigProfileGrid(props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const [filters, setFilters] = useState(baseFilterVal);

  const copyAction = (configProfile) => dispatch(showModal('CONFIG_PROFILE_EDITOR_MODAL', { configProfileId: configProfile.id, copyEntity: true }));
  const archiveAction = (configProfile) => dispatch(processArchiveConfigProfiles([configProfile.id]));
  const unarchiveAction = (configProfile) => dispatch(processUnarchiveConfigProfiles([configProfile.id]));
  const changesAction = (configProfile) => dispatch(showModal('CONFIG_PROFILE_MAPKEYS_MODAL', { configProfileId: configProfile.id }));
  const editAction = (configProfile) => dispatch(showModal('CONFIG_PROFILE_EDITOR_MODAL', { configProfileId: configProfile.id }));


  useEffect(() => {
    dispatch(processFetchAllConfigProfiles());
  }, []);

  return (
    <>
      <Flex flexDirection="row" mb={4}>
        <Header
          isLoading={reduxProps.isLoading}
          title={SynchronizeModuleConfigProfilesPath.defaultTitle}
          subtitle={reduxProps.isFiltering
            ? `Showing ${reduxProps.data.length}/${reduxProps.isShowingArchived ? reduxProps.total : reduxProps.unarchived
            } ${pluralize(
              'filtered Config Profile',
              reduxProps.isShowingArchived ? reduxProps.total : reduxProps.unarchived
            )} ${(reduxProps.isShowingArchived && '(including archived)') ||
            '(excluding archived)'
            }`
            : `Showing ${reduxProps.unarchived} ${pluralize('Config Profile', reduxProps.unarchived)} Total`
          }
        />
        <Options
          isLoading={reduxProps.isLoading}
          createEntity= {() => dispatch(showModal('CONFIG_PROFILE_EDITOR_MODAL'))}
          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}

          canFilter={true}
        />
      </Flex>

      <DataTableContainer>
        <DataTable
          reorderableColumns
          value={reduxProps.data}
          tableStyle={{ minWidth: '50rem' }}
          size='normal'

          scrollable
          scrollHeight='flex'

          filters={filters}
          globalFilterFields={['name']}
          filterDisplay='menu'
        >
          <Column
            field="name"
            header='Name'
            body={(rowData) => (
              <Text>
                {rowData.name}
              </Text>
            )}
            style={{ width: '25%' }}
            sortable
          />
          <Column
            field="settingsCount"
            header='# Settings'
            body={(rowData) => (
              <Text>
                {`${reduxProps.countsMap[rowData.id]?.settingsCount || 0} Settings`}
              </Text>
            )}
            style={{ width: '15%' }}
            sortable
          />
          <Column
            field="mapkeysCount"
            header='# Mapkeys'
            body={(rowData) => (
              <Text>
                {`${reduxProps.countsMap[rowData.id]?.mapkeysCount || 0} Mapkeys`}
              </Text>
            )}
            style={{ width: '15%' }}
            sortable
          />
          <Column
            field='name'
            header=''
            align='right'
            filter={reduxProps.isAdvancedFiltering}
            body={(rowdata) => EntityTools({
              isArchived: rowdata.isArchived,
              changesAction: () => changesAction(rowdata),
              editAction: () => editAction(rowdata),
              copyAction: () => copyAction(rowdata),
              archiveAction: () => archiveAction(rowdata),
              unarchiveAction: () => unarchiveAction(rowdata)
            })}
          />
        </DataTable>
      </DataTableContainer>
    </>
  );
}

export default withRouter(ConfigProfileGrid);
