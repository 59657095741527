import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

// primereact imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { push } from 'connected-react-router';
import DataTableContainer from '../../../components/primeGrid/DataTableContainer';

// Standard components
import Header from './Header';
import Options from '../../templates/Structures/Options';
import Flex from '../../../components/common/Flex/index';
import Text from '../../../components/common/Text';

// Custom Components
import {
  dialogFooter,
  deleteDialogFooter,
  nameBody,
  areFieldsValid,
  doRequiredFieldsExist
} from './components';

import RowEndEntityTools from '../../../components/common/EntityTools/RowEndTools';
import { selectIsShowingArchived } from '../../../modules/query/selectors';

import {
  processFetchAllNoteProfiles,
  processArchiveNoteProfiles,
  processUnarchiveNoteProfiles,
  processCreateNoteProfile,
  processCopyNoteProfile,
  processEditNoteProfile,
  processDeleteNoteProfile,
} from '../../../entities/Standardize/NoteProfiles/actions';

import {
  selectSidebarIsFiltering,
  selectStandardizeModulePermissionsAndState,
} from '../../Dashboard/selectors';


import { StandardizeModuleNoteProfilesEditPath } from '../../../paths';
import {
  selectCurrentFilteredNoteProfiles,
  selectCurrentNoteProfilesCount
} from '../../../entities/Standardize/NoteProfiles/selectors';

const mapStateToProps = createSelector(
  selectStandardizeModulePermissionsAndState(),
  selectCurrentFilteredNoteProfiles(),
  selectSidebarIsFiltering(),
  selectCurrentNoteProfilesCount(),
  selectIsShowingArchived(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    data,
    isFiltering,
    count,
    isShowingArchived
  ) => {
    const isLoading = isLoadingInitialData || (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      data: (!isLoading && data && data.toList().toArray()) || [],
      editable: canCollaborate && hasValidLicense,
      isLoading,
      count,
      isFiltering,
      showArchived: isFiltering && isShowingArchived,
      hasValidLicense,
      canCollaborate
    };
  }
);

const BLANK_PROFILE = {
  name: '',
};

const VALIDATION_FIELDS = {
  name: true,
};

export default function NoteProfilesBody(props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const [isDialogOpen, setIsDialogOpen] = useState(false); // controls if the create/edit/copy dialog is open
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false); // used to determine header text and select 'save' action
  const [isCopyDialogOpen, setIsCopyDialogOpen] = useState(false); // used to determine header text and select 'save' action
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false); // controls if the delete Dialog is open
  const [validation, setValidation] = useState(VALIDATION_FIELDS);

  const [currentEditingRow, setCurrentEditingRow] = useState(BLANK_PROFILE);

  const getDialogHeader = () => {
    if (isCreateDialogOpen) {
      return 'Create Note Profile';
    } else if (isCopyDialogOpen) {
      return `Create Copy of '${currentEditingRow.name}'`;
    } else {
      return `Edit '${currentEditingRow.name}'`;
    }
  };

  const openCreateDialog = () => {
    setCurrentEditingRow(BLANK_PROFILE);
    setIsCreateDialogOpen(true);
    setIsDialogOpen(true);
  };

  const cancelDialogs = () => {
    // used to reset the dialogs
    setCurrentEditingRow(BLANK_PROFILE);
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsCopyDialogOpen(false);
    setIsDeleteDialogOpen(false);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setCurrentEditingRow(BLANK_PROFILE);
  };

  const saveAction = () => {

    setValidation(areFieldsValid(currentEditingRow));
    if (!doRequiredFieldsExist(currentEditingRow)) {
      return;
    }

    const editedRow = {
      id: currentEditingRow.id,
      name: currentEditingRow.name,
    };

    if (isCreateDialogOpen) {
      dispatch(processCreateNoteProfile(editedRow));
    } else if (isCopyDialogOpen) {
      dispatch(processCopyNoteProfile(editedRow));
    } else {
      dispatch(processEditNoteProfile(editedRow.id, editedRow));
    }
    cancelDialogs();
  };

  const editRowAction = (rowdata) => {
    const currentNonEntityRow = {
      id: rowdata.id,
      name: rowdata.name,
    };
    setCurrentEditingRow(currentNonEntityRow);
    setIsDialogOpen(true);
  };

  const copyRowAction = (rowdata) => {
    const currentNonEntityRow = {
      id: rowdata.id,
      name: rowdata.name,
    };
    setCurrentEditingRow(currentNonEntityRow);
    setIsCopyDialogOpen(true);
    setIsDialogOpen(true);
  };

  const archiveAction = (rowdata) => {
    dispatch(processArchiveNoteProfiles([rowdata.id]));
  };

  const unarchiveAction = (rowdata) => {
    dispatch(processUnarchiveNoteProfiles([rowdata.id]));
  };

  const deleteAction = () => {
    // this action is PERMANENT, never call directly off of a button, always use the modal
    dispatch(processDeleteNoteProfile(currentEditingRow.id));
    setCurrentEditingRow(BLANK_PROFILE);
    setIsDeleteDialogOpen(false);
  };

  const openDeleteDialog = () => {
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsCopyDialogOpen(false);
    setIsDeleteDialogOpen(true);
  };

  const handleEditedRowChange = (newValue, field) => {
    // called on every edit
    let _editedRow = { ...currentEditingRow };
    _editedRow[`${field}`] = newValue;
    setCurrentEditingRow(_editedRow);
  };

  const manageNotesForNoteProfile = (noteProfileId) => {
    dispatch(push(StandardizeModuleNoteProfilesEditPath.generate({ id: noteProfileId })));
  };

  useEffect(() => {
    dispatch(processFetchAllNoteProfiles());
  }, []);

  return (
    <>
      <Flex flexDirection='row' mb={4}>
        <Header/>
        <Options
          canFilter={true}
          isLoading={reduxProps.isLoading}
          createEntity={openCreateDialog}
          shouldHaveLicense={false}
          canCollaborate={reduxProps.canCollaborate}
        />
      </Flex>

      <DataTableContainer>
        <DataTable
          value={reduxProps.data}
          tableStyle={{ minWidth: '50rem' }}
          size='normal'
          scrollable
          scrollHeight='flex'
        >
          <Column
            field='name'
            header='Name'
            style={{ fontSize: '14px', width: '20%' }}
            body={(rowData) => nameBody(rowData, manageNotesForNoteProfile)}
            sortable
          />
          <Column
            header=''
            bodyStyle={{ fontSize: '14px' }}
            style={{ textAlign: 'right', width: '20%' }}
            body={(rowData) =>
              <RowEndEntityTools
                rowdata={rowData}
                editAction={editRowAction}
                copyAction={copyRowAction}
                listAction={(data) => manageNotesForNoteProfile(data.id)}
                archiveAction={archiveAction}
                unarchiveAction={unarchiveAction}
              />
            }
          />
        </DataTable>
      </DataTableContainer>

      <Dialog
        visible={reduxProps.editable && isDialogOpen}
        style={{ width: '75%' }}
        header={()=>getDialogHeader()}
        footer={() => dialogFooter(isCreateDialogOpen, isCopyDialogOpen, openDeleteDialog, saveAction, cancelDialogs)}
        modal
        className='p-fluid'
        closable={false}
      >
        <div>
          <label>Name</label>
          <InputText
            value={currentEditingRow.name}
            onChange={(e) => handleEditedRowChange(e.target.value, 'name')}
            className={!validation.name && 'p-invalid'}
          />
        </div>
      </Dialog>

      <Dialog
        visible={reduxProps.editable && isDeleteDialogOpen}
        style={{ width: '32rem' }}
        header={`Delete Note Profile '${currentEditingRow.name}'`}
        footer={() => deleteDialogFooter(closeDeleteDialog, deleteAction)}
        closable={false}
      >
        <div>
          <Text>Are you sure you want to delete {currentEditingRow.name}?</Text>
          <Text style={{ color: 'red' }}>This action will be PERMANENT and CANNOT BE UNDONE.</Text>
          <Text><strong>Only delete this if you are certain that it needs to be removed from everywhere</strong></Text>
        </div>
      </Dialog>
    </>
  );
}