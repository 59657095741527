import React, { useCallback, useEffect, useRef, useState } from 'react';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { arrayUnshift, formValueSelector, submit } from 'redux-form/immutable';
import styled, { css } from 'styled-components';

import useBoundingclientrectRef from '@rooks/use-boundingclientrect-ref';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';
import Flex from '../../../../components/common/Flex';
import Text from '../../../../components/common/Text';

import BaseLabel from '../../../../components/form/BaseLabel';
import EmailParsingTextInput from '../../../../components/form/EmailParsingTextInput';

import OrganizationUserInvitesForm from '../../../form/templates/OrganizationUserInvitesForm';

import { selectIsPerformingInModal } from '../../../../modules/utility/selectors';

import { selectCurrentUser } from '../../../../entities/CurrentUser/selectors';

import { processCreateOrganizationUserInvites } from '../../../../entities/OrganizationUserInvites/actions';
import OrganizationUserInviteModel from '../../../../entities/OrganizationUserInvites/model';

import injectSaga from '../../../../utils/sagas/injectSaga';

import saga from './sagas';

const ModalContainer = styled(Flex)`
  width: 70%;
  height: 100%;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Modal = styled(Flex)`
  margin: auto;
  height: 100%;
  width: 100%;
  flex-direction: row;
  align-items: center;
  background: ${({ theme }) => theme.colors.gray[1]};
  border-radius: ${({ theme }) => theme.radii[2]};
  overflow: hidden;
`;

const Header = styled(Box)`
  margin-top: ${({ theme }) => theme.space[6]};
  padding: ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[6]};
  background: ${({ theme }) => theme.colors.gray[1]};
`;

const Content = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  height: 100%;
  flex: 1;
  min-height: 0;
  overflow: hidden;

  border-top-left-radius: ${({ theme }) => theme.radii[2]};
  background: ${({ theme }) => theme.colors.gray[0]};
`;

const ContentHeader = styled(Flex)`
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;

  padding: ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[6]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[0]};
`;

const ContentHeaderDescription = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

const ContentHeaderAction = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 30%;
`;

const InviteInputSection = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  padding: ${({ theme }) => theme.space[5]};
  flex-shrink: 0;

  transition: background-color 0.2s;
  border-top: ${({ theme }) => theme.borders[2]};
  border-bottom: ${({ theme }) => theme.borders[2]};
`;

const InviteHeader = styled(Flex)`
  flex-direction: row;
  align-items: left;
  background: ${({ theme }) => theme.colors.gray[2]};
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[4]};
  flex-shrink: 0;
`;

const InviteHeaderColumn = styled(Box)`
  ${({ $expand }) => $expand && css`
    width: calc( 100% - 45rem );
    min-width: fit-content;
  `}
  font-size: ${({ theme }) => theme.fontSizes[0]};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gray[6]};
  text-transform: uppercase;
  text-align: left;
`;

const InviteTypingContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  padding-left: ${({ theme }) => theme.space[5]};
  padding-right: ${({ theme }) => theme.space[5]};
  padding-top: ${({ theme }) => theme.space[3]};
  padding-bottom: ${({ theme }) => theme.space[3]};
`;

const InviteListContainer = styled(Box)`
  flex: 1;
  min-height: 0;
  overflow-y: auto;
  width: 100%;
`;

const InviteListEmptyContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const OrganizationUserInvitesModal = ({
  currentUser,
  isPerforming,
  invites,
}) => {
  const emailRef = useRef();
  const [isFocused, setIsFocused] = useState(false);
  const onFocus = useCallback(() => setIsFocused(true), [setIsFocused]);
  const onBlur = useCallback(() => setIsFocused(false), [setIsFocused]);
  const focusOnInput = useCallback(() => emailRef.current.focus(), [emailRef]);

  // after first render?
  useEffect(() => {
    focusOnInput();
  }, []);

  const dispatch = useDispatch();
  const [state, setState] = useState({
    typingText: '',
    isEmailValid: true,
  });

  const onTypingTextChange = useCallback(
    typingText => {
      setState(prev => ({
        ...prev,
        typingText,
        isEmailValid: typingText === '' || prev.isEmailValid
      }));
    },
    [setState]
  );

  const onVallidEmailParsed = useCallback(email => {
    dispatch(arrayUnshift(
      EK.ORGANIZATION_USER_INVITES.state,
      EK.ORGANIZATION_USER_INVITES.plural,
      new OrganizationUserInviteModel({ email })
    ));
  }, [dispatch]);
  const onEmailParseError = useCallback(() => { setState(prev => ({ ...prev, isEmailValid: false })); }, [setState]);
  const sendInvites = useCallback(() => {
    dispatch(submit(EK.ORGANIZATION_USER_INVITES.state));
  }, [dispatch]);
  const onSendInvites = useCallback(invites => {
    dispatch(processCreateOrganizationUserInvites(currentUser.organization.id, invites));
  }, [dispatch, currentUser]);

  const isInvitesEmpty = !invites || invites.size === 0;

  return (
    <ModalContainer style={{ width: '90%' }}>
      <Modal style={{ width: '100%' }} >
        <Flex flexDirection='column' width='100%' height='100%'>
          <Header>
            <Text color='gray.7' fontSize={5}>Invite Users</Text>
          </Header>
          <Content>
            <ContentHeader>
              <ContentHeaderDescription>
                <Text color='gray.7' fontSize={1}>
                  Get started by typing out users&apos; emails below.
                  You will then be able to decide permissions and modules before you send the invite.
                </Text>
              </ContentHeaderDescription>
              <ContentHeaderAction>
                <Button
                  primary
                  onClick={sendInvites}
                  disabled={isPerforming === true || isInvitesEmpty === true}
                >
                  Send Invites
                </Button>
              </ContentHeaderAction>
            </ContentHeader>

            <InviteInputSection bg={isFocused ? 'primary.0' : 'gray.1'}>
              <BaseLabel label='Add users by email' />
              <EmailParsingTextInput
                ref={emailRef}
                onValueChange={onTypingTextChange}
                onValidEmailParsed={onVallidEmailParsed}
                onEmailParseError={onEmailParseError}
                onFocus={onFocus}
                onBlur={onBlur}
              />
            </InviteInputSection>

            <InviteHeader>
              <InviteHeaderColumn $expand>E-mail</InviteHeaderColumn>
              <InviteHeaderColumn width='20rem'>Role</InviteHeaderColumn>
              <InviteHeaderColumn width='50rem'>Modules</InviteHeaderColumn>
              <InviteHeaderColumn width='5rem'></InviteHeaderColumn>
            </InviteHeader>

            <InviteListContainer>
              {
                state.typingText !== '' && state.typingText !== null && (

                  <InviteTypingContainer>
                    <Text color='gray.7' fontSize={2}>
                      { state.typingText }
                    </Text>
                    <Text color={ state.isEmailValid ? 'gray.6' : 'error.4' } fontSize={1}>
                      { state.isEmailValid ? 'Press enter, space, or comma to the email to the list' : 'Must be a valid email before it can be added' }
                    </Text>
                  </InviteTypingContainer>
                )
              }

              {
                isInvitesEmpty && state.typingText === '' && (
                  <InviteListEmptyContainer>
                    <Text color="gray.4" fontSize={3}>
                      Get started by adding emails
                    </Text>
                    {!isFocused && (
                      <Box m={4}>
                        <Button primary small onClick={focusOnInput}>
                          Get Started
                        </Button>
                      </Box>
                    )}
                  </InviteListEmptyContainer>
                )
              }

              {
                !isInvitesEmpty && (
                  <OrganizationUserInvitesForm
                    isPerforming={isPerforming}
                    allowedRoles={currentUser.allowedRoleAssignments}
                    onRDXSubmit={onSendInvites}
                  />
                )
              }
            </InviteListContainer>
          </Content>
        </Flex>
      </Modal>
    </ModalContainer>
  );
};

const organizationUserInvitesFormSelector = formValueSelector(EK.ORGANIZATION_USER_INVITES.state);

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser(),
  isPerforming: selectIsPerformingInModal(),
  invites: state => organizationUserInvitesFormSelector(state, EK.ORGANIZATION_USER_INVITES.plural),
});

const enhance = compose(
  injectSaga({ key: `${EK.ORGANIZATION_USER_INVITES.state}Modal`, saga }),
  connect(
    mapStateToProps,
  ),
);

export default enhance(OrganizationUserInvitesModal);
