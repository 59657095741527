// React and Redux
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { createSelector } from 'reselect';
import styled from 'styled-components';

// PrimeReact Components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';
import DataTableContainer from '../../../components/primeGrid/DataTableContainer';

// Common Components
import Flex from '../../../components/common/Flex';
import Text, { PlaceholderText } from '../../../components/common/Text';
import Header from '../../templates/Structures/Header';
import Skeleton from 'react-loading-skeleton';
import Options from '../../templates/Structures/Options';
import RowEndEntityTools from '../../../components/common/EntityTools/RowEndTools';
import { pluralize } from '../../../components/common/Header';

// Dialogs
import { DeleteNoteDialog, NotesDialog } from '../../../containers/dialog/templates/Standardize/NotesDialog';

// Actions and Models
import { areFieldsValid, doRequiredFieldsExist } from './actions';
import { VALIDATION_FIELDS, NOTE_TYPES_OPTIONS } from '../../../entities/Standardize/Notes/model';
import { processCreateNote, processFetchAllNotes, processSaveNotes } from '../../../entities/Standardize/Notes/actions';

// Paths
import { StandardizeModuleNotesPath } from '../../../paths';

// Selectors
import {
  selectSidebarIsFiltering,
  selectStandardizeModulePermissionsAndState,
} from '../../Dashboard/selectors';
import { selectIsShowingArchived } from '../../../modules/query/selectors';
import { selectCurrentFilteredNotes, selectNotesCount } from '../../../entities/Standardize/Notes/selectors';

const mapStateToProps = createSelector(
  selectStandardizeModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectCurrentFilteredNotes(),
  selectNotesCount(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isShowingArchived,
    data,
    total
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      showArchived: isFiltering && isShowingArchived,
      data: (!isLoading && data && data.toList().toArray()) || [],
      editable: canCollaborate && hasValidLicense,
      total,
      canCollaborate,
      hasValidLicense,
    };
  },
);

const BlankNote = {
  name: '',
  description: '',
  noteType: '',
  noteBody: '',
};

const NoteBodyText = styled(Text)`
  max-width: 50rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

function NotesGrid(props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  // dialog state
  const [isDialogOpen, setIsDialogOpen] = useState(false);                 // controls if the create/edit/copy dialog is open
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);     // used to determine header text and select "save" action
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);     // controls if the delete Dialog is open
  const [validation, setValidation] = useState(VALIDATION_FIELDS);

  // used to provide data for the modals
  const [currentEditingRow, setCurrentEditingRow] = useState(BlankNote);

  // cell Bodies
  const textValueBody = (curRow, field, placeholder = '') => {
    return curRow && curRow[`${field}`] ?
      <Text>{curRow[`${field}`]}</Text> :
      <PlaceholderText>{placeholder}</PlaceholderText>;
  };

  const NoteBodyCell = (curRow) => {
    return curRow && curRow['noteBody'] ? (
      <NoteBodyText>
        {curRow['noteBody']}
      </NoteBodyText>
    ) : (
      <PlaceholderText>
        No Note Body
      </PlaceholderText>
    );
  };

  const noteTypeBody = (rowData) => {
    if (!rowData.noteType) {
      return <PlaceholderText>Note Type</PlaceholderText>;
    }

    const option = NOTE_TYPES_OPTIONS.find(opt => opt.value === rowData.noteType);

    return <Text>
      {option ? option.label : rowData.noteType}
    </Text>;
  };

  // header actions
  const openCreateDialog = () => {
    setIsCreateDialogOpen(true);
    setIsDialogOpen(true);
  };

  // Dialog Actions
  const cancelDialogs = () => {
    // used to reset the dialogs
    setCurrentEditingRow(BlankNote);
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
  };

  const saveAction = () => {
    setValidation(areFieldsValid(currentEditingRow));
    if (!doRequiredFieldsExist(currentEditingRow)) {
      return;
    }
    // translated here to a format that can be sent to the api, replacing all object fields with their Id.
    const editedRow = {
      id: currentEditingRow.id,
      name: currentEditingRow.name,
      noteBody: currentEditingRow.noteBody,
      description: currentEditingRow.description,
      noteType: currentEditingRow.noteType,
    };

    if (isCreateDialogOpen) {
      dispatch(processCreateNote(editedRow));
    } else {
      dispatch(processSaveNotes([editedRow]));
    }
    cancelDialogs();
  };

  const deleteAction = () => {
    // this action is PERMANENT, never call directly off of a button, always use the modal
    dispatch(processSaveNotes([{id: currentEditingRow.id, _destroy: '1'}]));
    setCurrentEditingRow(BlankNote);
    setIsDeleteDialogOpen(false);
  };

  const openDeleteDialog = () => {
    // closes the other dialogs and opens the delete Dialog
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsDeleteDialogOpen(true);
  };

  //cell actions
  const handleEditedRowChange = (newValue, field) => {
    // called on every edit
    let _editedRow = { ...currentEditingRow };
    _editedRow[`${field}`] = newValue;
    setCurrentEditingRow(_editedRow);
  };

  const editRowAction = (rowdata) => {
    // Immutable.Entity's properties prevent the object from being used directly, so the object is converted here
    const currentNonEntityRow = {
      id: rowdata.id,
      name: rowdata.name,
      noteBody: rowdata.noteBody,
      description: rowdata.description,
      noteType: rowdata.noteType,
    };
    setCurrentEditingRow(currentNonEntityRow);
    setIsDialogOpen(true);
  };

  useEffect(() => {
    dispatch(processFetchAllNotes());
  }, []);

  return (
    <>
      {!reduxProps.isLoading && <Flex flexDirection="row" mb={4} >
        <Header
          isLoading={reduxProps.isLoading || (reduxProps.isFetching && reduxProps.total === 0)}
          title={StandardizeModuleNotesPath.defaultTitle}
          subtitle={reduxProps.isFiltering
            ? `Showing ${reduxProps.data.length}/${reduxProps.total} ${pluralize('filtered Note', reduxProps.data.length)}`
            : `Showing ${reduxProps.data.length} ${pluralize('Note', reduxProps.data.length)} Total`}
        />
        <Options
          canFilter={true}
          isLoading={reduxProps.isLoading}
          createEntity={openCreateDialog}
          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
        />
      </Flex> || <Skeleton style={{ height: '2rem', marginBottom: '1rem' }} />
      }

      <DataTableContainer>
        <DataTable
          value={reduxProps.data}
          tableStyle={{minWidth: '50rem'}}
          size='normal'

          scrollable
          scrollHeight='flex'
        >
          <Column
            field='name'
            header='Name'
            body={(rowData) => textValueBody(rowData, 'name', 'Name')}
            style={{ width: '15%' }}
            sortable
          />
          <Column
            field='noteType'
            header='Note Type'
            body={noteTypeBody}
            style={{ width: '10%' }}
            sortable
          />
          <Column
            field='noteBody'
            header='Note Body'
            body={NoteBodyCell}
            style={{ width: '40%' }}
            sortable
          />
          <Column
            field='description'
            header='Description'
            body={(rowdata) => textValueBody(rowdata, 'description', 'Description')}
            style={{ width: '25%' }}
            sortable
          />
          <Column header=''
            style={{ textAlign: 'right' }}
            body={(rowData) =>
              <RowEndEntityTools
                rowdata={rowData}
                editAction={editRowAction}
              />}
          ></Column>
        </DataTable>
      </DataTableContainer>

      <NotesDialog
        currentEditingRow={currentEditingRow}
        editable={reduxProps.editable}
        handleEditedRowChange={handleEditedRowChange}
        isCreateDialogOpen={isCreateDialogOpen}
        isDialogOpen={isDialogOpen}
        openDeleteDialog={openDeleteDialog}
        saveAction={saveAction}
        cancelDialogs={cancelDialogs}
        validation={validation}
      />

      <DeleteNoteDialog
        editable={reduxProps.editable}
        isDeleteDialogOpen={isDeleteDialogOpen}
        currentEditingRow={currentEditingRow}
        setIsDeleteDialogOpen={setIsDeleteDialogOpen}
        deleteAction={deleteAction}
      />
    </>
  );
}

export default withRouter(NotesGrid);
