import React from 'react';
import styled from 'styled-components';
import Box from '../../../../../components/common/Box';

const ExternalPageGraphicContainer = styled(Box)`
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${({ theme }) => theme.space[7]};
`;

const ExternalPageGraphic = ({ children }) => (
  <ExternalPageGraphicContainer>
    { children }
  </ExternalPageGraphicContainer>
);

export default ExternalPageGraphic;
