import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

// Grid Imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';
import DataTableContainer from '../../../components/primeGrid/DataTableContainer';

// Hooks
import { useOnCellEditComplete } from '../../../hooks/useOnCellEditComplete';

// cell bodies/editors
import EntityRowTools from '../../../components/common/EntityTools/RowStartTools';
import Text, { PlaceholderText } from '../../../components/common/Text';
import Button from '../../../components/common/Button';

// Header
import Flex from '../../../components/common/Flex';
import {
  PipingModuleSpecsPipeInstancesEditPath
} from '../../../paths';
import Header from '../../templates/Structures/Header';
import Options from '../SpecsEditSwitch/Options';
import Skeleton from 'react-loading-skeleton';
import { pluralize } from '../../../components/common/Header';

// dropdown constants and validation
import {
  BlankSpec,
  ALLOWANCES,
  VALIDATION_FIELDS,
} from '../../../entities/Piping/Specs/model';
import { rowStyles } from '../../../components/primeGrid/RowStyles';
import { areFieldsValid, doRequiredFieldsExist } from '../Specs/components';

// Actions
import { processEditPipeFamily, processFetchIndividualPipeFamily, processFetchPipeFamiliesForSpec } from '../../../entities/Piping/PipeFamilies/actions';
import { saveCurrentPipingSpecPipeinstanceChanges } from './actions';
import { convertToSpecPipeInstanceType } from '../SpecsPipesEdit/actions';
import { processEditSpec, processFetchIndividualSpec } from '../../../entities/Piping/Specs/actions';

// selectors
import {
  selectPipingModulePermissionsAndState,
} from '../../Dashboard/selectors';
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import { selectCurrentFilteredSpecPipeInstances } from '../../../entities/Piping/SpecPipeInstances/selectors';
import { selectCurrentSpec } from '../../../entities/Piping/Specs/selectors';
import { selectPipeInstanceCount } from '../../../entities/Piping/PipeInstances/selectors';
import { selectCurrentPipe } from '../PipesEditSwitch/selectors';
import { processSaveSpecPipeInstances } from '../../../entities/Piping/SpecPipeInstances/actions';

const mapStateToProps = (specId, PipeId) => createSelector(
  selectPipingModulePermissionsAndState(),
  selectCurrentSpec(specId),
  selectCurrentPipe(PipeId),
  selectCurrentFilteredSpecPipeInstances(specId, PipeId),
  selectPipeInstanceCount(PipeId),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    spec,
    pipe,
    data,
    instanceCount,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || instanceCount == 0));
    return {
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense && !pipe?.archivedFlag && !spec?.archivedFlag,
      canCollaborate,
      hasValidLicense,
      instanceCount,
      spec,
      pipe,
    };
  },
);

function SpecPipeInstancesEditGrid({ match }) {
  const reduxProps = useSelector(mapStateToProps(match.params.id, match.params.familyId));
  const dispatch = useDispatch();

  // table state
  const [
    editedRows,
    resetEditedRows,
    currentEditingRow,
    isEditing,
    onBeforeCellEditShow,
    onCellChange,
    onCellEditComplete,
    handleRowAction,
    onDropdownComplete
  ] = useOnCellEditComplete(
    reduxProps.data,
    convertToSpecPipeInstanceType,
    reduxProps.editable,
    true
  );

  //Cell Bodies
  const summaryBody = (rowdata) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToSpecPipeInstanceType(rowdata);
    return curRow?.pipeInstance ? (
      <ComplexCellRenderer
        value={{
          primary: `${curRow.pipeInstance?.sizeProperty?.name || 'No Size'} // ${curRow.pipeInstance?.schedule?.value || 'No Schedule'}`,
          secondary: `${curRow.pipeInstance?.endTypes?.reduce((endTypes, endType) =>
            endTypes ? `${endTypes}, ${endType.code}` : endType.code, '') || 'No End Types'}`
        }}
        isCapitalized={true}
        paddingLeft='0px'
      />
    ) : (
      <PlaceholderText>Summary Not Available</PlaceholderText>
    );
  };

  const sizeAndScheduleBody = (rowdata, field, placeholder) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToSpecPipeInstanceType(rowdata);

    if (!curRow?.pipeInstance) {
      return <PlaceholderText>{placeholder}</PlaceholderText>;
    }

    return (
      <ComplexCellRenderer
        value={curRow.pipeInstance[field]}
        isCapitalized={true}
        paddingLeft='0px'
      />
    );
  };

  const stockNoValueBody = (rowdata) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || rowdata;

    // If this is a direct instance (not yet in spec)
    if (!curRow.pipeInstance || !curRow.isSelectedForSpec) {
      return <PlaceholderText>{curRow.stockno ? `Catalog: ${curRow.stockno}` : 'Stock No'}</PlaceholderText>;
    }

    // For items in spec, check spec value first
    const stockNo = curRow.stockno;
    if (stockNo) {
      return <Text>{stockNo}</Text>;
    }

    // Then check if PipeInstance has a value to use as placeholder
    if (curRow?.pipeInstance?.stockno) {
      return <PlaceholderText>Catalog: {curRow.pipeInstance.stockno}</PlaceholderText>;
    }

    // Finally fall back to default placeholder
    return <PlaceholderText>Stock No</PlaceholderText>;
  };

  const mccsCodeValueBody = (rowdata) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || rowdata;

    // If this is a direct instance (not yet in spec)
    if (!curRow.pipeInstance || !curRow.isSelectedForSpec) {
      return <PlaceholderText>{curRow.mccsCode ? `Catalog: ${curRow.mccsCode}` : 'MCCS Code'}</PlaceholderText>;
    }

    // For items in spec, check spec value first
    const mccsCode = curRow.mccsCode;
    if (mccsCode) {
      return <Text>{mccsCode}</Text>;
    }

    // Then check if PipeInstance has a value to use as placeholder
    if (curRow?.pipeInstance?.mccsCode) {
      return <PlaceholderText>Catalog: {curRow.pipeInstance.mccsCode}</PlaceholderText>;
    }

    // Finally fall back to default placeholder
    return <PlaceholderText>MCCS Code</PlaceholderText>;
  };

  // Cell Editors
  const textCellEditor = (field) => (
    <InputText
      value={currentEditingRow && currentEditingRow[field] || ''}
      onChange={(e) => onCellChange(e.target.value, field)}
    />
  );

  const instanceSelector = (rowdata) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToSpecPipeInstanceType(rowdata);
    return (
      <Button
        icon={curRow.isSelectedForSpec ? 'checkbox' : 'checkbox-outline'}
        iconFillColor={curRow.isSelectedForSpec ? 'primary.4' : 'gray.6'}
        onClick={() => onDropdownComplete.current(!curRow.isSelectedForSpec, 'isSelectedForSpec', rowdata)}
      />
    );
  };

  const getRowTool = (rowdata) => {
    const data = editedRows && editedRows.find(row => row.id == rowdata.id) || reduxProps.data.find((item) => item.id == rowdata.id);
    return (
      <EntityRowTools
        rowdata={data}
        handleRowAction={handleRowAction}
      />
    );
  };

  useEffect(() => {
    dispatch(processFetchPipeFamiliesForSpec());
    dispatch(processFetchIndividualPipeFamily(match.params.familyId));
    dispatch(processFetchIndividualSpec(match.params.id));
  }, []);

  return (
    <>
      {!reduxProps.isLoading && <Flex flexDirection="row" mb={4} >
        <Header
          isLoading={reduxProps.isLoading}
          title={reduxProps.spec && reduxProps.pipe && `${reduxProps.spec.specName} // ${reduxProps.pipe.name}` || PipingModuleSpecsPipeInstancesEditPath.defaultTitle}
          subtitle={reduxProps.isFiltering ?
            `Showing ${reduxProps.data.length}/${reduxProps.instanceCount} ${pluralize('filtered Pipe Instance', reduxProps.instanceCount)}` :
            `Showing ${reduxProps.instanceCount} ${pluralize('Pipe Instance', reduxProps.instanceCount)} total (${reduxProps.spec?.specPipeInstances?.filter(inst => inst?.pipeFamilyId?.id == match.params.familyId).size || 0} selected)`}
        />
        <Options
          location={location}
          specId={(match.params.id)}
          updateEntities={() =>
            saveCurrentPipingSpecPipeinstanceChanges(
              editedRows,
              (rows) => {
                dispatch(processEditSpec(match.params.id, {
                  specPipeInstancesAttributes: rows
                }));
                resetEditedRows();
              }
            )
          }
          pendingValidChanges={editedRows.length > 0}
          isEditing={isEditing}
        />
      </Flex> || <Skeleton style={{ height: '2rem', marginBottom: '1rem' }} />
      }
      <DataTableContainer>
        <DataTable
          reorderableColumns
          value={reduxProps.data}
          tableStyle={{ minWidth: '55rem' }}
          size='normal'
          editMode='cell'
          rowClassName={(data) => data && rowStyles(data, editedRows)}

          scrollable
          scrollHeight='flex'

          removableSort
        >
          <Column
            header=""
            style={{ width: '2%' }}
            body={(rowdata) => getRowTool(rowdata)}
          />
          <Column
            header=""
            style={{ width: '2%' }}
            body={(rowdata) => instanceSelector(rowdata)}
          />
          <Column
            field="id"
            header="Summary"
            style={{ width: '19%' }}
            body={(rowdata) => summaryBody(rowdata)}
          />
          <Column
            field="size"
            header="Size"
            style={{ width: '19%' }}
            body={(rowdata) => sizeAndScheduleBody(rowdata, 'sizeProperty', 'No Size')}
            sortable
          />
          <Column
            field="schedule"
            header="Schedule"
            style={{ width: '19%' }}
            body={(rowdata) => sizeAndScheduleBody(rowdata, 'schedule', 'No Schedule')}
            sortable
          />
          <Column
            field="stockno"
            header="Stock No"
            style={{ width: '19%' }}
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={() => textCellEditor('stockno')}
            onCellEditComplete={(e) => onCellEditComplete.current(e)}
            body={(rowdata) => stockNoValueBody(rowdata)}
            sortable
          />
          <Column
            field="mccsCode"
            header="MCCS Code"
            style={{ width: '19%' }}
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={() => textCellEditor('mccsCode')}
            onCellEditComplete={(e) => onCellEditComplete.current(e)}
            body={(rowdata) => mccsCodeValueBody(rowdata)}
            sortable
          />
        </DataTable>
      </DataTableContainer>
    </>
  );
}

export default withRouter(SpecPipeInstancesEditGrid);