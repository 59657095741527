/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Initialize Sentry first
import * as Sentry from '@sentry/react';
import { initSentry } from './configuration/sentry';
initSentry();

// Initialize Mixpanel
import { initMixpanel } from './configuration/mixpanel';
initMixpanel();

// React and core dependencies
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { ThemeProvider } from './contexts/ThemeContext';

// App-specific imports
import history from './utils/routing/history';
import setup from './configuration/setup';
import configureStore from './store';
import App from './routes/App';
import InternalError from './routes/InternalError';

// CSS imports
import 'sanitize.css';

// Custom function to set up all the fun options shenanigans
// we'll be adding to this as we start testing more on staging
setup();

// Redux functions modified to configure the store
const store = configureStore();

// Render function
const render = () => {
  ReactDOM.render(
    <Sentry.ErrorBoundary fallback={<InternalError />}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ThemeProvider>
            <App />
          </ThemeProvider>
        </ConnectedRouter>
      </Provider>
    </Sentry.ErrorBoundary>,
    document.getElementById('app')
  );
};

// Hot module replacement handling
if (module.hot) {
  module.hot.accept();
}

// Render the app
render();

// Service worker cleanup
if (window?.navigator?.serviceWorker) {
  window.navigator.serviceWorker.getRegistrations()
    .then(registrations => {
      registrations.forEach(registration => registration.unregister());
    })
    .catch(error => console.warn('Service worker unregistration failed:', error));
}
