import React from 'react';
import styled from 'styled-components';

import Box from '../../common/Box';

const BaseTooltipBody = styled(Box)`
  background-color: transparent;
  border-radius: ${({ theme }) => theme.radii[1]};
  box-shadow: ${({ theme }) => theme.shadows[2]};

  overflow: hidden;

  & > :first-child {
    border-top-left-radius: ${({ theme }) => theme.radii[1]};
    border-top-right-radius: ${({ theme }) => theme.radii[1]};
  }

  & > :last-child {
    border-bottom-left-radius: ${({ theme }) => theme.radii[1]};
    border-bottom-right-radius: ${({ theme }) => theme.radii[1]};
  }
`;

const TooltipBody = React.forwardRef((props, ref) => (
  <BaseTooltipBody ref={ref} {...props} />
));

export default TooltipBody;
