import React from 'react';

import Box from '../../../../../components/common/Box';
import Text from '../../../../../components/common/Text';

const ExternalPageText = ({ children }) => (
  children ?
    <Box mb={6}>
      <Text as='p' color='gray.6' fontSize={2} style={{ textAlign: 'center' }} m={0} p={0}>
        { children }
      </Text>
    </Box> :
    null
);

export default ExternalPageText;
