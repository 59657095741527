import baseSchema from '../../../modules/entities/utils/schema';

import EK from '../../keys';

import NoteProfileSchema from '../NoteProfiles/schema';
import ParameterProfileSchema from '../ParameterProfiles/schema';
import OrientationSchema from '../Orientations/schema';
import LayerSchema from '../Layers/schema';
import SectionSchema from '../Sections/schema';
import SimplifiedRepSchema from '../SimplifiedReps/schema';
import CombinedStateSchema from '../CombinedStates/schema';
import AppearanceStateSchema from '../AppearanceStates/schema';
import ExplodeStateSchema from '../ExplodeStates/schema';
import LayerStateSchema from '../LayerStates/schema';
import StyleStateSchema from '../StyleStates/schema';

// prettier-ignore
const modelTemplateSchema = baseSchema(EK.MODEL_TEMPLATES.state, {
  // Model Metadata
  noteProfile: {
    schema: NoteProfileSchema,
    isId: true
  },
  modelParameterProfile: {
    schema: ParameterProfileSchema,
    isId: true
  },
  componentParameterProfile: {
    schema: ParameterProfileSchema,
    isId: true
  },
  // Model Views
  orientations: {
    schema: [OrientationSchema],
    isId: true,
    idField: 'orientationIds',
    isList: true
  },
  layers: {
    schema: [LayerSchema],
    isId: true,
    idField: 'layerIds',
    isList: true
  },
  sections: {
    schema: [SectionSchema],
    isId: true,
    idField: 'sectionIds',
    isList: true
  },
  simplifiedReps: {
    schema: [SimplifiedRepSchema],
    isId: true,
    idField: 'simplifiedRepIds',
    isList: true
  },
  combinedStates: {
    schema: [CombinedStateSchema],
    isId: true,
    idField: 'combinedStateIds',
    isList: true
  },
  appearanceStates: {
    schema: [AppearanceStateSchema],
    isId: true,
    idField: 'appearanceStateIds',
    isList: true
  },
  explodeStates: {
    schema: [ExplodeStateSchema],
    isId: true,
    idField: 'explodeStateIds',
    isList: true
  },
  layerStates: {
    schema: [LayerStateSchema],
    isId: true,
    idField: 'layerStateIds',
    isList: true
  },
  styleStates: {
    schema: [StyleStateSchema],
    isId: true,
    idField: 'styleStateIds',
    isList: true
  },
});

export default modelTemplateSchema;
