import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styled from 'styled-components';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';
import Flex from '../../../../components/common/Flex';
import Text from '../../../../components/common/Text';

import { showModal } from '../../../../modules/modal/actions';

import { selectIsLoadingInitialData } from '../../../Dashboard/selectors';
import { isInitialLoadComplete } from '../../../Dashboard/actions';

const desktopPluginDownloadUrl = 'https://go.cadactive.com/downloads/ptc-creo-installer';

const HomeItemContainer = styled(Flex)`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray[1]};
  border: 1px solid ${({ theme }) => theme.colors.gray[3]};
  border-radius: ${({ theme }) => theme.radii[2]};
  padding: ${({ theme }) => theme.space[3]};
  margin-bottom: ${({ theme }) => theme.space[4]};
  transition: background-color 0.2s ease, border-color 0.2s ease;

  flex-direction: row;
  align-items: center;
`;

const HomeItemTextContainer = styled(Flex)`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  margin-right: ${({ theme }) => theme.space[3]};
`;

const HomeItemTextTitle = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gray[7]};
  margin-bottom: ${({ theme }) => theme.space[2]};
`;

const HomeItemTextDescription = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  color: ${({ theme }) => theme.colors.gray[7]};
`;

const HomeItemButtonContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CenteredText = styled(Text)`
  text-align: center;
`;

const WelcomeHeader = styled(CenteredText)`
  font-size: ${({ theme }) => theme.fontSizes[5]};
  color: ${({ theme }) => theme.colors.gray[7]};
`;

const GettingStartedContainer = styled(Flex)`
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const GettingStartedContentContainer = styled(Box)`
  width: 50rem;
`;

const GettingStarted = ({
  isLoading,
  isInitialLoadComplete,
  openOrganizationUsersDirectory,
}) => {
  useEffect(() => {
    isInitialLoadComplete();
  }, []);
  return (
    <GettingStartedContainer>
      <GettingStartedContentContainer>
        <Box $mb={6}>
          <WelcomeHeader>
            {!isLoading ? (
              <React.Fragment>
                Welcome to <strong>CadActive</strong>
              </React.Fragment>
            ) : (
              <Skeleton />
            )}
          </WelcomeHeader>
        </Box>

        {desktopPluginDownloadUrl && (
          <HomeItemContainer>
            <HomeItemTextContainer>
              <HomeItemTextTitle>
                {!isLoading ? (
                  <React.Fragment>
                    <strong>Download</strong> Plugin
                  </React.Fragment>
                ) : (
                  <Skeleton />
                )}
              </HomeItemTextTitle>
              <HomeItemTextDescription>
                {!isLoading ? (
                  'Download the desktop plugin to get started with CadActive.'
                ) : (
                  <Skeleton />
                )}
              </HomeItemTextDescription>
            </HomeItemTextContainer>
            {!isLoading && (
              <HomeItemButtonContainer>
                <Button
                  $primary
                  as="a"
                  href={desktopPluginDownloadUrl}
                  target="_blank"
                >
                  Download
                </Button>
              </HomeItemButtonContainer>
            )}
          </HomeItemContainer>
        )}

        <HomeItemContainer>
          <HomeItemTextContainer>
            <HomeItemTextTitle>
              {!isLoading ? (
                <React.Fragment>
                  <strong>Manage</strong>{' '} Users
                </React.Fragment>
              ) : (
                <Skeleton />
              )}
            </HomeItemTextTitle>
            <HomeItemTextDescription>
              {!isLoading ? (
                'Add or Invite members to your organization, assign product licenses, and manage permissions.'
              ) : (
                <Skeleton />
              )}
            </HomeItemTextDescription>
          </HomeItemTextContainer>
          {!isLoading && (
            <HomeItemButtonContainer>
              <Button
                $primary
                onClick={openOrganizationUsersDirectory}
              >
                Manage Users
              </Button>
            </HomeItemButtonContainer>
          )}
        </HomeItemContainer>

        <HomeItemContainer>
          <HomeItemTextContainer>
            <HomeItemTextTitle>
              {!isLoading ? (
                <React.Fragment>
                  <strong>Questions</strong>?
                </React.Fragment>
              ) : (
                <Skeleton />
              )}
            </HomeItemTextTitle>
            <HomeItemTextDescription>
              {!isLoading ? (
                'Browse through support documentation, submit bugs or feedback, or contact our support team.'
              ) : (
                <Skeleton />
              )}
            </HomeItemTextDescription>
          </HomeItemTextContainer>
          {!isLoading && (
            <HomeItemButtonContainer>
              <Button
                $primary
                as="a"
                href="//go.cadactive.com/support/web"
                target="_blank"
              >
                Support
              </Button>
            </HomeItemButtonContainer>
          )}
        </HomeItemContainer>
      </GettingStartedContentContainer>
    </GettingStartedContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsLoadingInitialData(),
});

const mapDispatchToProps = dispatch => ({
  isInitialLoadComplete() { dispatch(isInitialLoadComplete()); },
  openOrganizationUsersDirectory() { dispatch(showModal('ORGANIZATION_MODAL')); },
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(GettingStarted);
