import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { List } from 'immutable';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable';
import styled from 'styled-components';

import EK from '../../../../../entities/keys';

import Box from '../../../../../components/common/Box';
import Flex from '../../../../../components/common/Flex';
import Button from '../../../../../components/common/Button';
import Text from '../../../../../components/common/Text';

import BaseFormSection from '../../../../../components/form/BaseFormSection';
import BaseFormRow from '../../../../../components/form/BaseFormRow';

import RDXTextInput from '../../../RDXTextInput';
import RDXRadioSelect from '../../../RDXRadioSelect';
import RDXSearchSelect from '../../../RDXSearchSelect';

import { selectGlobalCadSearchRulesAndCriteriaForStandardizeSimplifiedReps } from '../../../../../entities/GlobalCadSearchRules/selectors';

import SimplifiedRepRulesetRuleCriterionValueModel from '../../../../../entities/Standardize/SimplifiedRepRulesetRuleCriterionValues/model';

const RuleContainer = styled(Field)`
  background-color: ${({ theme }) => theme.colors.gray[1]};
  border-radius: ${({ theme }) => theme.radii[2]};
  border: ${({ theme }) => theme.borders[1]};
`;

const RuleSummary = styled(Box)`
  border-radius: ${({ theme }) => theme.radii[2]};
  border: ${({ theme }) => theme.borders[1]};

  background-color: ${({ theme }) => theme.colors.gray[1]};
  padding: ${({ theme }) => theme.space[3]};
  margin-bottom: ${({ theme }) => theme.space[2]};
`;

const NewRuleContainer = styled(BaseFormSection)`
  background-color: ${({ theme }) => theme.colors.gray[1]};
  border-radius: ${({ theme }) => theme.radii[2]};
  border: ${({ theme }) => theme.borders[1]};

  margin-bottom: ${({ theme }) => theme.space[3]};
`;

const RuleCriteriaContainer = styled(BaseFormSection)`
  background-color: ${({ theme }) => theme.colors.gray[1]};
  border-radius: ${({ theme }) => theme.radii[2]};
  border: ${({ theme }) => theme.borders[1]};

  flex-grow: 1;
  overflow: auto;
`;

const RuleCriteriaEmpty = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.gray[1]};

  border-radius: ${({ theme }) => theme.radii[2]};
  border: ${({ theme }) => theme.borders[1]};

  margin-bottom: ${({ theme }) => theme.space[3]};

  height: 15rem;

  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NewRuleContainerHeader = styled(Flex)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const formatComparisonValue = (value) => {
  if (!value) return value;

  // Split by underscore and capitalize each word
  return value
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const SimplifiedRepRulesetRuleForm = ({
  handleSubmit,
  isPerforming,
  valid,
  doesInitialRuleExist,
  expectedRule,
  expectedRuleCriteria,
}) => (
  <Flex
    as="form"
    onSubmit={handleSubmit}
    flexDirection="column"
    width="100%"
  >
    {(!doesInitialRuleExist && (
      <React.Fragment>
        <NewRuleContainerHeader>
          <Text color="gray.7" fontSize={4} mb={4}>
            <strong>Creating</strong> New Rule
          </Text>
        </NewRuleContainerHeader>

        <NewRuleContainer>
          <BaseFormRow>
            <RuleContainer
              component={RDXSearchSelect}
              name="globalCadSearchRule"
              label="Rule"
              ek={EK.GLOBAL_CAD_SEARCH_RULES}
              required
              clearable
            />
          </BaseFormRow>
        </NewRuleContainer>
      </React.Fragment>
    )) || (
      <Flex flexDirection="column">
        <RuleSummary>
          <Text color="gray.7" fontSize={1} fontWeight="600" mb={1}>
            {expectedRule.name}
          </Text>
          <Text color="gray.6" fontSize={1}>
            {expectedRule.description}
          </Text>
        </RuleSummary>
      </Flex>
    )}

    {expectedRuleCriteria.size > 0 ? (
      <RuleCriteriaContainer>
        {expectedRuleCriteria.map((criterion, index) => {

          // Transform the options if this is the Comparison criterion
          const options = criterion.name.toLowerCase() === 'comparison'
            ? criterion.listValues.map((value) => ({
              ...value,
              primary: formatComparisonValue(value.primary || value.value),
              value: value.value,
            }))
            : criterion.listValues;

          return (
            <BaseFormRow key={index}>
              <Field
                name={`simplifiedRepRulesetRuleCriterionValues[${index}].value`}
                label={criterion.name}
                description={criterion.description}
                required
                defaultValue={criterion.defaultValue}
                options={options}
                component={
                  criterion.valueType === 'list'
                    ? criterion.listValues.size <= 10
                      ? RDXRadioSelect
                      : RDXSearchSelect
                    : RDXTextInput
                }
                clearable
              />
            </BaseFormRow>
          );
        })}
      </RuleCriteriaContainer>
    ) : (
      <RuleCriteriaEmpty>
        <Text fontSize={3} color="gray.6" mb={4}>
          No rule template selected
        </Text>
        <Text fontSize={2} color="gray.6">
          Select a rule template from the dropdown above to get started
        </Text>
      </RuleCriteriaEmpty>
    )}

    <Button
      type="submit"
      $primary
      $large
      $full
      disabled={isPerforming || !valid}
      isPerformingAction={isPerforming}
    >
      Save
    </Button>
  </Flex>
);

const simplifiedRepRulesetRuleFormSelector = formValueSelector(
  EK.SIMPLIFIED_REP_RULESET_RULES.state
);

const mapStateToProps = createSelector(
  (state, props) => props.initialValues,
  (state) =>
    simplifiedRepRulesetRuleFormSelector(
      state,
      'globalCadSearchRule'
    ),
  selectGlobalCadSearchRulesAndCriteriaForStandardizeSimplifiedReps(),
  (initialValues, globalRuleId, rules) => {
    const globalCadSearchRule = initialValues.getIn(
      ['globalCadSearchRule', 'id'],
      globalRuleId
    );

    const expectedRuleCriteria = rules
      .getIn([globalCadSearchRule, 'globalCadSearchRuleCriteria'], List())
      .sortBy((criterion) => criterion.sortOrder);

    return {
      doesInitialRuleExist: !!initialValues.id,
      expectedRule: rules.get(globalCadSearchRule),
      expectedRuleCriteria,
      initialValues: initialValues
        .set('globalCadSearchRule', globalCadSearchRule)
        .set(
          'simplifiedRepRulesetRuleCriterionValues',
          initialValues.simplifiedRepRulesetRuleCriterionValues
            .size > 0
            ? initialValues
              .get('simplifiedRepRulesetRuleCriterionValues')
              .sortBy((value) =>
                value.getIn(['globalCadSearchRuleCriterion', 'sortOrder'])
              )
              .map((value) => {
                return value
                  .set(
                    'globalCadSearchRuleCriterion',
                    value.getIn(
                      ['globalCadSearchRuleCriterion', 'id'],
                      value.get('globalCadSearchRuleCriterion')
                    )
                  )
                  .set(
                    'value',
                    value.get(
                      'value',
                      value.getIn([
                        'globalCadSearchRuleCriterion',
                        'defaultValue',
                      ])
                    )
                  );
              })
            : expectedRuleCriteria.map(
              (criterion) =>
                new SimplifiedRepRulesetRuleCriterionValueModel({
                  globalCadSearchRuleCriterion: criterion.id,
                  value: criterion.defaultValue,
                })
            )
        ),
    };
  }
);

const enhance = compose(
  connect(mapStateToProps),
  reduxForm({
    form: EK.SIMPLIFIED_REP_RULESET_RULES.state,
    enableReinitialize: true, // every time initialValues changes, form resets
    onSubmit(model, dispatch, props) {
      props.onRDXSubmit(model.serialize());
    },
    onSubmitSuccess(_, dispatch, props) {
      props.reset(EK.SIMPLIFIED_REP_RULESET_RULES.state);
    },
  })
);

export default enhance(SimplifiedRepRulesetRuleForm);
