import { combineReducers } from 'redux-immutable';

import EK from '../../entities/keys';

import createEntitiesReducer from './utils/createEntitiesReducer';

import resetOnLogout from '../../utils/reducers/resetOnLogout';
import createFilteredReducer from '../../utils/reducers/createFilteredReducer';

// Organizations
import User from '../../entities/Users/model';
import Organization from '../../entities/Organizations/model';
import OrganizationUserInvite from '../../entities/OrganizationUserInvites/model';

import Setting from '../../entities/Settings/model';

import GlobalCadSearchRule from '../../entities/GlobalCadSearchRules/model';
import GlobalCadSearchRuleCriterion from '../../entities/GlobalCadSearchRuleCriteria/model';

// Synchronize
import Unit from '../../entities/Synchronize/Units/model';
import UnitSystem from '../../entities/Synchronize/UnitSystems/model';
import Appearance from '../../entities/Synchronize/Appearances/model';
import Mapkey from '../../entities/Synchronize/Mapkeys/model';
import ConfigProfile from '../../entities/Synchronize/ConfigProfiles/model';
import ConfigProfileMapkey from '../../entities/Synchronize/ConfigProfileMapkeys/model';
import ConfigProfileValue from '../../entities/Synchronize/ConfigProfileValues/model';
import ConfigSettingCategory from '../../entities/Synchronize/ConfigSettingCategories/model';
import ConfigSetting from '../../entities/Synchronize/ConfigSettings/model';

// Standardize
import Layer from '../../entities/Standardize/Layers/model';
import NoteProfile from '../../entities/Standardize/NoteProfiles/model';
import NoteBlock from '../../entities/Standardize/NoteBlocks/model';
import NoteBlockNote from '../../entities/Standardize/NoteBlockNotes/model';
import Note from '../../entities/Standardize/Notes/model';
import Orientation from '../../entities/Standardize/Orientations/model';
import ParameterProfile from '../../entities/Standardize/ParameterProfiles/model';
import ParameterProfileParameter from '../../entities/Standardize/ParameterProfileParameters/model';
import Parameter from '../../entities/Standardize/Parameters/model';
import Section from '../../entities/Standardize/Sections/model';
import SimplifiedRepRulesetRuleCriterionValue from '../../entities/Standardize/SimplifiedRepRulesetRuleCriterionValues/model';
import SimplifiedRepRulesetRule from '../../entities/Standardize/SimplifiedRepRulesetRules/model';
import SimplifiedRepRuleset from '../../entities/Standardize/SimplifiedRepRulesets/model';
import SimplifiedRep from '../../entities/Standardize/SimplifiedReps/model';
import ModelTemplate from '../../entities/Standardize/ModelTemplates/model';
import ExplodeState from '../../entities/Standardize/ExplodeStates/model';
import CombinedState from '../../entities/Standardize/CombinedStates/model';
import LayerState from '../../entities/Standardize/LayerStates/model';
import AppearanceState from '../../entities/Standardize/AppearanceStates/model';
import StyleState from '../../entities/Standardize/StyleStates/model';

// Automate
import RoutingProfile from '../../entities/Automate/RoutingProfiles/model';

// Cabling
import Spool from '../../entities/Cabling/Spools/model';

// ECAD
import Catalog from '../../entities/ECAD/Catalogs/model';

// Piping
import BendFamily from '../../entities/Piping/BendFamilies/model';
import BendInstance from '../../entities/Piping/BendInstances/model';
import BendMachineFamily from '../../entities/Piping/BendMachineFamilies/model';
import BendMachineInstance from '../../entities/Piping/BendMachineInstances/model';
import BendSpringbackFamily from '../../entities/Piping/BendSpringbackFamilies/model';
import BendSpringbackInstance from '../../entities/Piping/BendSpringbackInstances/model';
import BoltNutFamily from '../../entities/Piping/BoltNutFamilies/model';
import BoltNutInstance from '../../entities/Piping/BoltNutInstances/model';
import CurrentUser from '../../entities/CurrentUser/model';
import EndTypeCompatibility from '../../entities/Piping/EndTypeCompatibilities/model';
import EndType from '../../entities/Piping/EndTypes/model';
import FittingCategory from '../../entities/Piping/FittingCategories/model';
import FittingCode from '../../entities/Piping/FittingCodes/model';
import FittingFamily from '../../entities/Piping/FittingFamilies/model';
import FittingInstance from '../../entities/Piping/FittingInstances/model';
import InsulationFamily from '../../entities/Piping/InsulationFamilies/model';
import InsulationSpec from '../../entities/Piping/InsulationSpecs/model';
import InsulationInstance from '../../entities/Piping/InsulationInstances/model';
import Material from '../../entities/Piping/Materials/model';
import MiterFamily from '../../entities/Piping/MiterFamilies/model';
import MiterInstance from '../../entities/Piping/MiterInstances/model';
import PipingCatalog from '../../entities/Piping/Catalogs/model';
import PipeFamily from '../../entities/Piping/PipeFamilies/model';
import PipeInstance from '../../entities/Piping/PipeInstances/model';
import SpecFittingInstance from '../../entities/Piping/SpecFittingInstances/model';
import SpecPipeInstance from '../../entities/Piping/SpecPipeInstances/model';
import Spec from '../../entities/Piping/Specs/model';
import Schedule from '../../entities/Piping/Schedules/model';
import Size from '../../entities/Piping/Sizes/model';
import Project from '../../entities/Piping/Projects/model';

// Framework
import ProfileLibrary from '../../entities/Framework/ProfileLibraries/model';
import Profile from '../../entities/Framework/Profiles/model';
import ProfileDimension from '../../entities/Framework/ProfileDimensions/model';
import ProfileParameter from '../../entities/Framework/ProfileParameters/model';
import ProfileTables from '../../entities/Framework/ProfileTables/model';
import ProfileTableColumn from '../../entities/Framework/ProfileTableColumns/model';
import ProfileTableItem from '../../entities/Framework/ProfileTableItems/model';
import ProfileTableRow from '../../entities/Framework/ProfileTableRows/model';

// Reporting
import Template from '../../entities/Reporting/Templates/model';

// the 'createEntityReducer is used here to split up the create, edit, and delete actions for each of these reducers
const entitiesReducer = combineReducers({
  // Organization
  [EK.CURRENT_USER.state]: createEntitiesReducer(EK.CURRENT_USER.state, CurrentUser),
  [EK.ORGANIZATIONS.state]: createEntitiesReducer(EK.ORGANIZATIONS.state, Organization),
  [EK.ORGANIZATION_USER_INVITES.state]: createEntitiesReducer(EK.ORGANIZATION_USER_INVITES.state, OrganizationUserInvite),
  [EK.USERS.state]: createEntitiesReducer(EK.USERS.state, User),

  [EK.SETTINGS.state]: createEntitiesReducer(EK.SETTINGS.state, Setting),

  [EK.UNITS.state]: createEntitiesReducer(EK.UNITS.state, Unit),
  [EK.UNIT_SYSTEMS.state]: createEntitiesReducer(EK.UNIT_SYSTEMS.state, UnitSystem),

  [EK.GLOBAL_CAD_SEARCH_RULES.state]: createEntitiesReducer(EK.GLOBAL_CAD_SEARCH_RULES.state, GlobalCadSearchRule),
  [EK.GLOBAL_CAD_SEARCH_RULE_CRITERIA.state]: createEntitiesReducer(EK.GLOBAL_CAD_SEARCH_RULE_CRITERIA.state, GlobalCadSearchRuleCriterion),

  // Synchronize
  [EK.APPEARANCES.state]: createEntitiesReducer(EK.APPEARANCES.state, Appearance),
  [EK.CONFIG_PROFILES.state]: createEntitiesReducer(EK.CONFIG_PROFILES.state, ConfigProfile),
  [EK.CONFIG_PROFILE_MAPKEYS.state]: createEntitiesReducer(EK.CONFIG_PROFILE_MAPKEYS.state, ConfigProfileMapkey),
  [EK.CONFIG_PROFILE_VALUES.state]: createEntitiesReducer(EK.CONFIG_PROFILE_VALUES.state, ConfigProfileValue),
  [EK.CONFIG_SETTING_CATEGORIES.state]: createEntitiesReducer(EK.CONFIG_SETTING_CATEGORIES.state, ConfigSettingCategory),
  [EK.CONFIG_SETTINGS.state]: createEntitiesReducer(EK.CONFIG_SETTINGS.state, ConfigSetting),
  [EK.MAPKEYS.state]: createEntitiesReducer(EK.MAPKEYS.state, Mapkey),

  // Standardize
  [EK.PARAMETER_PROFILES.state]: createEntitiesReducer(EK.PARAMETER_PROFILES.state, ParameterProfile),
  [EK.PARAMETER_PROFILE_PARAMETERS.state]: createEntitiesReducer(EK.PARAMETER_PROFILE_PARAMETERS.state, ParameterProfileParameter),
  [EK.PARAMETERS.state]: createEntitiesReducer(EK.PARAMETERS.state, Parameter),
  [EK.EXPLODE_STATES.state]: createEntitiesReducer(EK.EXPLODE_STATES.state, ExplodeState),
  [EK.LAYER_STATES.state]: createEntitiesReducer(EK.LAYER_STATES.state, LayerState),
  [EK.COMBINED_STATES.state]: createEntitiesReducer(EK.COMBINED_STATES.state, CombinedState),
  [EK.APPEARANCE_STATES.state]: createEntitiesReducer(EK.APPEARANCE_STATES.state, AppearanceState),
  [EK.STYLE_STATES.state]: createEntitiesReducer(EK.STYLE_STATES.state, StyleState),
  [EK.SIMPLIFIED_REP_RULESET_RULE_CRITERION_VALUES.state]: createEntitiesReducer(EK.SIMPLIFIED_REP_RULESET_RULE_CRITERION_VALUES.state, SimplifiedRepRulesetRuleCriterionValue),
  [EK.SIMPLIFIED_REP_RULESET_RULES.state]: createEntitiesReducer(EK.SIMPLIFIED_REP_RULESET_RULES.state, SimplifiedRepRulesetRule),
  [EK.SIMPLIFIED_REP_RULESETS.state]: createEntitiesReducer(EK.SIMPLIFIED_REP_RULESETS.state, SimplifiedRepRuleset),
  [EK.SIMPLIFIED_REPS.state]: createEntitiesReducer(EK.SIMPLIFIED_REPS.state, SimplifiedRep),
  [EK.MODEL_TEMPLATES.state]: createEntitiesReducer(EK.MODEL_TEMPLATES.state, ModelTemplate),
  [EK.LAYERS.state]: createEntitiesReducer(EK.LAYERS.state, Layer),
  [EK.NOTES.state]: createEntitiesReducer(EK.NOTES.state, Note),
  [EK.NOTE_PROFILES.state]: createEntitiesReducer(EK.NOTE_PROFILES.state, NoteProfile),
  [EK.NOTE_BLOCKS.state]: createEntitiesReducer(EK.NOTE_BLOCKS.state, NoteBlock),
  [EK.NOTE_BLOCK_NOTES.state]: createEntitiesReducer(EK.NOTE_BLOCK_NOTES.state, NoteBlockNote),
  [EK.ORIENTATIONS.state]: createEntitiesReducer(EK.ORIENTATIONS.state, Orientation),
  [EK.SECTIONS.state]: createEntitiesReducer(EK.SECTIONS.state, Section),

  // Automate
  [EK.AUTOMATE_ROUTING_PROFILES.state]: createEntitiesReducer(EK.AUTOMATE_ROUTING_PROFILES.state, RoutingProfile),

  // Cabling
  [EK.CABLING_SPOOLS.state]: createEntitiesReducer(EK.CABLING_SPOOLS.state, Spool),

  // Piping
  // - Core
  [EK.PIPING_CATALOGS.state]: createEntitiesReducer(EK.PIPING_CATALOGS.state, PipingCatalog),
  [EK.SIZES.state]: createEntitiesReducer(EK.SIZES.state, Size),
  [EK.SCHEDULES.state]: createEntitiesReducer(EK.SCHEDULES.state, Schedule),
  [EK.END_TYPE_COMPATIBILITIES.state]: createEntitiesReducer(EK.END_TYPE_COMPATIBILITIES.state, EndTypeCompatibility),
  [EK.END_TYPES.state]: createEntitiesReducer(EK.END_TYPES.state, EndType),
  [EK.MATERIALS.state]: createEntitiesReducer(EK.MATERIALS.state, Material),
  [EK.FITTING_CATEGORIES.state]: createEntitiesReducer(EK.FITTING_CATEGORIES.state, FittingCategory),
  [EK.FITTING_CODES.state]: createEntitiesReducer(EK.FITTING_CODES.state, FittingCode),
  // - Catalog
  [EK.PIPE_FAMILIES.state]: createEntitiesReducer(EK.PIPE_FAMILIES.state, PipeFamily),
  [EK.PIPE_INSTANCES.state]: createEntitiesReducer(EK.PIPE_INSTANCES.state, PipeInstance),
  [EK.BENDS.state]: createEntitiesReducer(EK.BENDS.state, BendFamily),
  [EK.BEND_INSTANCES.state]: createEntitiesReducer(EK.BEND_INSTANCES.state, BendInstance),
  [EK.BEND_MACHINES.state]: createEntitiesReducer(EK.BEND_MACHINES.state, BendMachineFamily),
  [EK.BEND_MACHINE_INSTANCES.state]: createEntitiesReducer(EK.BEND_MACHINE_INSTANCES.state, BendMachineInstance),
  [EK.BEND_SPRINGBACKS.state]: createEntitiesReducer(EK.BEND_SPRINGBACKS.state, BendSpringbackFamily),
  [EK.BEND_SPRINGBACK_INSTANCES.state]: createEntitiesReducer(EK.BEND_SPRINGBACK_INSTANCES.state, BendSpringbackInstance),
  [EK.MITERS.state]: createEntitiesReducer(EK.MITERS.state, MiterFamily),
  [EK.MITER_INSTANCES.state]: createEntitiesReducer(EK.MITER_INSTANCES.state, MiterInstance),
  [EK.FITTING_FAMILIES.state]: createEntitiesReducer(EK.FITTING_FAMILIES.state, FittingFamily),
  [EK.FITTING_INSTANCES.state]: createEntitiesReducer(EK.FITTING_INSTANCES.state, FittingInstance),
  [EK.INSULATIONS.state]: createEntitiesReducer(EK.INSULATIONS.state, InsulationFamily),
  [EK.INSULATION_SPECS.state]: createEntitiesReducer(EK.INSULATION_SPECS.state, InsulationSpec),
  [EK.INSULATION_INSTANCES.state]: createEntitiesReducer(EK.INSULATION_INSTANCES.state, InsulationInstance),
  [EK.BOLT_NUTS.state]: createEntitiesReducer(EK.BOLT_NUTS.state, BoltNutFamily),
  [EK.BOLT_NUT_INSTANCES.state]: createEntitiesReducer(EK.BOLT_NUT_INSTANCES.state, BoltNutInstance),
  // - Specs
  [EK.SPECS.state]: createEntitiesReducer(EK.SPECS.state, Spec),
  [EK.SPEC_PIPE_INSTANCES.state]: createEntitiesReducer(EK.SPEC_PIPE_INSTANCES.state, SpecPipeInstance),
  [EK.SPEC_FITTING_INSTANCES.state]: createEntitiesReducer(EK.SPEC_FITTING_INSTANCES.state, SpecFittingInstance),
  [EK.PROJECTS.state]: createEntitiesReducer(EK.PROJECTS.state, Project),

  // ECAD
  [EK.ECAD_CATALOGS.state]: createEntitiesReducer(EK.ECAD_CATALOGS.state, Catalog),

  // Reporting
  [EK.REPORTING_TEMPLATES.state]: createEntitiesReducer(EK.REPORTING_TEMPLATES.state, Template),

  // Framework
  [EK.PROFILE_LIBRARIES.state]: createEntitiesReducer(EK.PROFILE_LIBRARIES.state, ProfileLibrary),
  [EK.PROFILES.state]: createEntitiesReducer(EK.PROFILES.state, Profile),
  [EK.DIMENSIONS.state]: createEntitiesReducer(EK.DIMENSIONS.state, ProfileDimension),
  [EK.PROFILE_PARAMETERS.state]: createEntitiesReducer(EK.PROFILE_PARAMETERS.state, ProfileParameter),
  [EK.PROFILE_TABLES.state]: createEntitiesReducer(EK.PROFILE_TABLES.state, ProfileTables),
  [EK.PROFILE_TABLE_COLUMNS.state]: createEntitiesReducer(EK.PROFILE_TABLE_COLUMNS.state, ProfileTableColumn),
  [EK.PROFILE_TABLE_ROWS.state]: createEntitiesReducer(EK.PROFILE_TABLE_ROWS.state, ProfileTableRow),
  [EK.PROFILE_TABLE_ITEMS.state]: createEntitiesReducer(EK.PROFILE_TABLE_ITEMS.state, ProfileTableItem),

});

export default resetOnLogout(
  createFilteredReducer(
    entitiesReducer,
    (action) => !!((action || {}).payload || {}).entities,
  ),
);
