import React, { createContext, useContext, useState, useEffect } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import lightTheme from '../assets/themes/theme';
import darkTheme from '../assets/themes/dark';

// Initial context value with noop function for toggleTheme
const initialContextValue = {
  isDarkMode: false,
  toggleTheme: function noop() {
    return undefined;
  }
};

const ThemeContext = createContext(initialContextValue);

export const useTheme = () => {
  const context = useContext(ThemeContext);
  return context;
};

export const ThemeProvider = ({ children }) => {
  // Get initial theme preference from localStorage or system preference
  const getInitialTheme = () => {
    const savedTheme = localStorage.getItem('theme');
    const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

    if (savedTheme) {
      return savedTheme === 'dark';
    }
    return systemPrefersDark;
  };

  const [isDarkMode, setIsDarkMode] = useState(getInitialTheme);

  useEffect(() => {
    localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
    // Update body background color
    document.body.style.backgroundColor = isDarkMode ? darkTheme.colors.gray[0] : lightTheme.colors.white;
  }, [isDarkMode]);

  const toggleTheme = () => {
    setIsDarkMode(prev => {
      return !prev;
    });
  };

  const contextValue = { isDarkMode, toggleTheme };

  return (
    <ThemeContext.Provider value={contextValue}>
      <StyledThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
        {children}
      </StyledThemeProvider>
    </ThemeContext.Provider>
  );
};