import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';

import BaseFormSection from '../../../../components/form/BaseFormSection';
import BaseFormRow from '../../../../components/form/BaseFormRow';

import RDXTextInput from '../../RDXTextInput';
import RDXPasswordInput from '../../RDXPasswordInput';

const LoginForm = ({ handleSubmit, isPerforming, valid }) => (
  <Box as='form' onSubmit={handleSubmit}>
    <BaseFormSection>
      <BaseFormRow>
        <Field
          component={RDXTextInput}
          name='email'
          label='Email'
          type='email'
          autoComplete='username'
        />
      </BaseFormRow>
      <BaseFormRow>
        <Field
          component={RDXPasswordInput}
          name='password'
          label='Password'
          autoComplete='current-password'
        />
      </BaseFormRow>
    </BaseFormSection>

    <Button
      type="submit"
      $primary
      $large
      $full
      disabled={isPerforming || !valid}
      isPerformingAction={isPerforming}
    >
      Log In
    </Button>
  </Box>
);

const enhance = compose(
  reduxForm({
    form: 'login',
    onSubmit(values, dispatch, props) {
      props.onRDXSubmit(values.toObject());
    },
  })
);

export default enhance(LoginForm);
