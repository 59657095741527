import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import { formValueSelector } from 'redux-form/immutable';

import EK from '../../keys';
import selectEntities from '../../../modules/entities/selectors';
import FittingFamilySchema from './schema';

import { selectIsShowingArchived } from '../../../modules/query/selectors';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';
import { selectNormalizedFittingCodes } from '../FittingCodes/selectors';
import { selectCurrentFittingId } from '../../../routes/Piping/SpecsEditSwitch/selectors';

export const selectNormalizedFittingFamilies = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.FITTING_FAMILIES.state)
);
export const selectFittingFamilies = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get(EK.FITTING_FAMILIES.state), [FittingFamilySchema], entities)
);
export const selectFittingFamiliesList = () => createSelector(
  selectFittingFamilies(),
  fittingFamilies => fittingFamilies.toList()
);

export const selectCurrentFittingCode = () =>
  createSelector(
    selectCurrentFittingId(),
    selectNormalizedFittingFamilies(),
    selectNormalizedFittingCodes(),
    (fittingId, fittings, fittingCodes) =>
      fittingCodes.get(fittings.getIn([fittingId, 'fittingCode']))
  );

export const selectFittingFamiliesCounts = () => createSelector(
  selectNormalizedFittingFamilies(),
  fittings => fittings.reduce(({ unarchived, total }, fitting) => ({
    unarchived: !fitting.archivedFlag ? unarchived + 1 : unarchived,
    total: total + 1,
  }), {
    unarchived: 0,
    total: 0,
  })
);

const fittingFilterForm = formValueSelector(`filters.${EK.FITTING_FAMILIES.state}`);

export const selectCurrentFilteredFittings = () => createSelector(
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectFittingFamilies(),
  state => fittingFilterForm(state,
    'name',
    'fittingCode',
    'fittingCategory',
    'material',
    'selectionName',
    'stockno',
    'mccsCode',
    'boltNutFamily'
  ),
  (isFiltering, isShowingArchived, fittings, query) => {
    if (!isFiltering) {
      return fittings.filter(fitting => !fitting.archivedFlag);
    }

    return fittings.filter(fitting => {
      // First check if we should include based on archived status
      if (!isShowingArchived && fitting.archivedFlag) {
        return false;
      }

      // Name filter
      if (query.name && fitting.name &&
        fitting.name.toLowerCase().indexOf(query.name.toLowerCase()) === -1) {
        return false;
      }

      // Selection Name filter
      if (query.selectionName && fitting.selectionName &&
        fitting.selectionName.toLowerCase().indexOf(query.selectionName.toLowerCase()) === -1) {
        return false;
      }

      // Stock Number filter
      if (query.stockno && fitting.stockno &&
        fitting.stockno.toLowerCase().indexOf(query.stockno.toLowerCase()) === -1) {
        return false;
      }

      // MCCS Code filter
      if (query.mccsCode && fitting.mccsCode &&
        fitting.mccsCode.toLowerCase().indexOf(query.mccsCode.toLowerCase()) === -1) {
        return false;
      }

      // Fitting Code filter (entity)
      if (query.fittingCode && typeof query.fittingCode === 'object' && query.fittingCode.id) {
        if (!fitting.fittingCode || fitting.fittingCode.id !== query.fittingCode.id) {
          return false;
        }
      } else if (query.fittingCode) {
        if (!fitting.fittingCode || fitting.fittingCode.id !== query.fittingCode) {
          return false;
        }
      }

      // Fitting Category filter (entity)
      if (query.fittingCategory && typeof query.fittingCategory === 'object' && query.fittingCategory.id) {
        if (!fitting.fittingCategory || fitting.fittingCategory.id !== query.fittingCategory.id) {
          return false;
        }
      } else if (query.fittingCategory) {
        if (!fitting.fittingCategory || fitting.fittingCategory.id !== query.fittingCategory) {
          return false;
        }
      }

      // Material filter (entity)
      if (query.material && typeof query.material === 'object' && query.material.id) {
        if (!fitting.material || fitting.material.id !== query.material.id) {
          return false;
        }
      } else if (query.material) {
        if (!fitting.material || fitting.material.id !== query.material) {
          return false;
        }
      }

      // Bolt Nut Family filter (entity)
      if (query.boltNutFamily && typeof query.boltNutFamily === 'object' && query.boltNutFamily.id) {
        if (!fitting.boltNutFamily || fitting.boltNutFamily.id !== query.boltNutFamily.id) {
          return false;
        }
      } else if (query.boltNutFamily) {
        if (!fitting.boltNutFamily || fitting.boltNutFamily.id !== query.boltNutFamily) {
          return false;
        }
      }

      return true;
    });
  }
);

export const selectCurrentFittingFamily = (familyId) => createSelector(
  selectEntities(),
  (entities) => (
    entities.hasIn([EK.FITTING_FAMILIES.state, familyId]) &&
    denormalize(
      entities.getIn([EK.FITTING_FAMILIES.state, familyId]),
      FittingFamilySchema,
      entities
    )
  )
);

export const selectCurrentFittingFamilyNumberOfPorts = (familyId) => createSelector(
  selectCurrentFittingFamily(familyId),
  (family) => family?.fittingCode?.endCount || 0
);