import React from 'react';

import Text, { PlaceholderText } from '../../../components/common/Text';
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import Button from '../../../components/common/Button';

import { CAD_MODEL_TYPES } from '../../../entities/Standardize/ModelTemplates/model';

export function areFieldsValid({
  name,
  modelTemplateModelName,
  cadModelType,
  cadModelAssemblySubtype,
  cadModelPartSubtype,
}) {
  // takes the currentEditedRow, which is similar to the FittingFamily type
  const _VALIDATION_FIELDS = {
    name: !!name,
    modelTemplateModelName: !!modelTemplateModelName,
    cadModelType: !!cadModelType,
    ...(cadModelType === CAD_MODEL_TYPES.ASSEMBLY ? {cadModelAssemblySubtype: !!cadModelAssemblySubtype} : {}),
    ...(cadModelType === CAD_MODEL_TYPES.PART ? {cadModelPartSubtype: !!cadModelPartSubtype} : {}),
  };
  return _VALIDATION_FIELDS;
}

export function doRequiredFieldsExist({
  name,
  modelTemplateModelName,
  cadModelType,
  cadModelAssemblySubtype,
  cadModelPartSubtype,
}) {
  // takes the VALIDATION_FIELDS type, so all values are booleans
  if (cadModelType === CAD_MODEL_TYPES.PART){
    return !!name && !!modelTemplateModelName && !!cadModelType && !!cadModelPartSubtype;
  } else if(cadModelType === CAD_MODEL_TYPES.ASSEMBLY){
    return !!name && !!modelTemplateModelName && !!cadModelType && !!cadModelAssemblySubtype;
  }
  return !!name && !!modelTemplateModelName && !!cadModelType;
}

// Cell Bodies
export const nameBody = (rowdata, onClickAction) => {
  return (
    <div
      onClick={() => onClickAction(rowdata.id)}
      style={{ cursor: 'pointer' }}
    >
      <ComplexCellRenderer
        showSecondary={false}
        value={{ ...rowdata.summary, to: true }}
        placeholder=""
        isCapitalized={true}
        paddingLeft="0px"
      />
    </div>
  );
};

export const descriptionBody = (rowdata) => {
  return rowdata.description ? (
    <Text>{rowdata.description}</Text>
  ) : (
    <PlaceholderText>No Description</PlaceholderText>
  );
};

// Misc. Dialog Components
export const dialogFooter = (
  isCreateDialog,
  isCopyDialog,
  deleteAction,
  saveAction,
  cancelAction
) => (
  <>
    {!isCreateDialog && !isCopyDialog && (
      <Button
        error={true}
        onClick={deleteAction}
        style={{ float: 'left' }}
      >
        Delete
      </Button>
    )}
    <Button
      secondary={true}
      onClick={cancelAction}
    >
      Cancel
    </Button>
    {!isCopyDialog ? (
      <Button
        primary={true}
        onClick={saveAction}
      >
        Save
      </Button>
    ) : (
      <Button
        primary={true}
        onClick={saveAction}
      >
        Copy
      </Button>
    )}
  </>
);

export const deleteDialogFooter = (cancelAction, deleteAction) => (
  <>
    <Button
      secondary={true}
      onClick={cancelAction}
    >
      Cancel
    </Button>
    <Button
      error={true}
      onClick={deleteAction}
    >
      Delete
    </Button>
  </>
);