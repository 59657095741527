import styled from 'styled-components';

import Text from '../../common/Text';

const BaseLabel = styled(Text).attrs(({ name, label, active, error }) => ({
  as: 'label',
  htmlFor: name,
  color: error ? 'error.4' : (active ? 'primary.4' : 'gray.6'),
  children: label,
  $error: error,
  $active: active
}))`
  flex-grow: 0;
  display: block;
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: 500;
  transition: color ${({ theme }) => theme.transitions.speed.fast} ${({ theme }) => theme.transitions.type.inOut};
  margin-right: ${({ theme }) => theme.space[2]};
  margin-left: 1px;
  padding-bottom: ${({ theme }) => theme.space[1]};
  color: ${({ $error, $active }) => $error ? ({ theme }) => theme.colors.error[4] : ($active ? ({ theme }) => theme.colors.primary[4] : ({ theme }) => theme.colors.gray[6])};
`;

export default BaseLabel;
