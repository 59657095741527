import React, { useEffect, useRef } from 'react';
import { VariableSizeList } from 'react-window';

const VariableItemSizeList = React.forwardRef(({ scrollToIndex, scrollAlign = 'auto', ...props }, ref) => (
  <VariableSizeList
    ref={ref}
    {...props}
  />
));

export default VariableItemSizeList;