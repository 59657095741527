import React from 'react';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';

import Notice from '../../../containers/common/Notice';

import { selectIsFetching } from '../../../modules/utility/selectors';
import { selectCurrentNormalizedSpecPipeInstances } from '../../../entities/Piping/SpecPipeInstances/selectors';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (specId) => createSelector(
  selectIsFetching(),
  selectCurrentNormalizedSpecPipeInstances(specId),
  (isFetching, data) => ({isFetching, data})
);

const SpecsPipeInstancesNotices = ({ match }) => {
  const {isFetching, data} = useSelector(mapStateToProps(match.params.id));

  if (isFetching && data && data.size > 0) {
    return (
      <Notice
        id='specsPipeInstances'
        message='Loading data in the background...'
      />
    );
  }
  return null;
};

export default withRouter(SpecsPipeInstancesNotices);
