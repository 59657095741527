import React from 'react';
import styled, { css } from 'styled-components';

import Flex from '../../common/Flex';

import BaseDataItem from '../BaseDataItem';

export const DefaultOptionTemplate = ({ searchWords, item }) => (
  <React.Fragment>
    <BaseDataItem
      primary
      disabled={item.disabled}
      searchWords={searchWords}
    >
      {item.primary || item.label}
    </BaseDataItem>
    {item.secondary && (
      <BaseDataItem
        secondary
        disabled={item.disabled}
        searchWords={searchWords}
      >
        {item.secondary}
      </BaseDataItem>
    )}
  </React.Fragment>
);

const BaseOptionContainer = styled(Flex).attrs(({ disabled, theme }) => ({
  flexDirection: 'column',
  justifyContent: 'center',
  cursor: disabled ? 'not-allowed' : 'pointer',
  style: {
    paddingRight: theme.space[3],
    paddingLeft: theme.space[3],
  }
}))`
  height: 100%;

  ${
  ({ $isSelected }) => $isSelected && css`
      font-weight: 600;
    `
}

  ${
  ({ $isHighlighted, theme }) => $isHighlighted && css`
      background: ${theme.colors.gray[1]};
    `
}
`;

const BaseOption = ({ getItemProps, item, renderItem, searchWords, isSelected, isHighlighted, ...props }) => (
  <BaseOptionContainer
    {...getItemProps({
      ...props,
      item,
      $isSelected: isSelected,
      $isHighlighted: isHighlighted,
      disabled: item.disabled,
    })}
  >
    {renderItem ? (
      renderItem({
        item,
        searchWords,
        isSelected,
        isHighlighted,
        ...props,
      })
    ) : (
      <DefaultOptionTemplate searchWords={searchWords} item={item} />
    )}
  </BaseOptionContainer>
);

export default BaseOption;