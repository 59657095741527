/* eslint-disable prefer-const */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

// Grid Imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import DataTableContainer from '../../../components/primeGrid/DataTableContainer';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

// Hooks
import { useOnCellEditComplete } from '../../../hooks/useOnCellEditComplete';

// cell bodies/editors
import EntityRowTools from '../../../components/common/EntityTools/RowStartTools';
import Text, { PlaceholderText } from '../../../components/common/Text';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';

// Header
import Flex from '../../../components/common/Flex';
import { PipingModuleSchedulesPath } from '../../../paths';
import Header from '../../templates/Structures/Header';
import Options from '../../templates/Structures/Options';
import { pluralize } from '../../../components/common/Header';
import { convertToSchedule, saveCurrentScheduleChanges } from './actions';

// validation
import { rowStyles } from '../../../components/primeGrid/RowStyles';

// actions
import { processFetchAllSchedules, processSaveSchedules } from '../../../entities/Piping/Schedules/actions';

// selectors
import { selectPipingModulePermissionsAndState, selectSidebarIsFiltering } from '../../Dashboard/selectors';
import { selectCurrentFilteredSchedules, selectUnfilteredSchedulesCount } from '../../../entities/Piping/Schedules/selectors';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectCurrentFilteredSchedules(),
  selectUnfilteredSchedulesCount(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    data,
    totalSizes,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.length == 0));
    return {
      ...rest,
      isFiltering,
      isLoading,
      canCollaborate,
      hasValidLicense,
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense,
      totalSizes,
    };
  },
);

function SchedulesGrid() {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();
  // editing state
  const [
    editedRows,
    resetEditedRows,
    currentEditingRow,
    isEditing,
    onBeforeCellEditShow,
    onCellChange,
    onCellEditComplete,
    handleRowAction
  ] = useOnCellEditComplete(
    reduxProps.data,
    convertToSchedule,
    reduxProps.editable
  );

  // Cell Editors
  const textCellEditor = (field) => (
    <InputText
      value={currentEditingRow && currentEditingRow[field] || ''}
      onChange={(e) => onCellChange(e.target.value, field)}
    />
  );

  //Cell displays
  const textValueBody = (rowdata, field) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || rowdata;

    if (curRow.id && curRow.id.includes('NEW_ROW_') && curRow[field] == '') {
      return <PlaceholderText>new row</PlaceholderText>;
    }

    return curRow && curRow[`${field}`] ?
      <Text>{curRow[`${field}`]}</Text> :
      <PlaceholderText>No {field}</PlaceholderText>;
  };

  const getRowTool = (rowdata) => {
    const data = editedRows && editedRows.find(row => row.id == rowdata.id) || reduxProps.data.find((item) => item.id == rowdata.id);
    return (
      <EntityRowTools rowdata={data} handleRowAction={handleRowAction} />
    );
  };

  useEffect(() => {
    dispatch(processFetchAllSchedules());
  }, []);

  return (
    <>
      {!reduxProps.isLoading && <Flex flexDirection="row" mb={4} >
        <Header
          isLoading={reduxProps.isLoading}
          title={PipingModuleSchedulesPath.defaultTitle}
          subtitle={reduxProps.isFiltering ?
            `Showing ${reduxProps.data.length}/${reduxProps.totalSizes} ${pluralize('filtered Schedule', reduxProps.data.length)}` :
            `Showing ${reduxProps.data.length} ${pluralize('Schedule', reduxProps.data.length)} Total`}
        />
        <Options
          updateEntities={() => saveCurrentScheduleChanges(
            editedRows,
            (data) => { dispatch(processSaveSchedules(data)); resetEditedRows(); },
          )}
          isEditingGrid={isEditing}
          isLoading={reduxProps.isLoading}
          pendingValidChanges={editedRows.length > 0}

          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
          canFilter={true}
        />
      </Flex> || <Skeleton style={{ height: '2rem', marginBottom: '1rem' }} />
      }

      <DataTableContainer>
        <DataTable
          reorderableColumns
          value={reduxProps.data}
          tableStyle={{ minWidth: '50rem' }}
          size='normal'
          editMode='cell'
          rowClassName={(data) => rowStyles(data, editedRows)}

          scrollable
          scrollHeight='flex'

          removableSort
        >
          <Column
            header=''
            style={{ width: '2%' }}
            body={(rowdata) => getRowTool(rowdata)}
          />
          <Column
            field='value'
            header='Value'
            style={{ width: '40%' }}
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={() => textCellEditor('value')}
            onCellEditComplete={(e) => onCellEditComplete.current(e)}
            body={(rowdata) => textValueBody(rowdata, 'value')}
            sortable
            sortField='value'
          />
          <Column
            field='description'
            header='Description'
            style={{ width: '40%' }}
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={() => textCellEditor('description')}
            onCellEditComplete={(e) => onCellEditComplete.current(e)}
            body={(rowdata) => textValueBody(rowdata, 'description')}
            sortable
            sortField='description'
          />
        </DataTable>
      </DataTableContainer>
    </>
  );
}

export default withRouter(SchedulesGrid);