import React from 'react';
import Button from '../../../components/common/Button';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Text from '../../../components/common/Text';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

import { push } from 'connected-react-router';

import { FrameworkModuleProfilePath, FrameworkModuleProfileTablesPath } from '../../../paths';

//popup styling
const contentStyle = { width: 'fit-content' };
export const addProfileButton = (action, libraryName='') => (
  <>
    <Popup
      trigger={() => (
        <Button
          icon='add'
          secondary={true}
          onClick={action}
        />
      )}
      position='bottom center'
      on={['hover']}
      {...{contentStyle}}
    >
      Add new Profile to library {libraryName != '' && `'${libraryName}'`}
    </Popup>
  </>
);

const profileLinkBody = (rowData, dispatch, libraryId) => {
  const profileLink = FrameworkModuleProfilePath.generate({ id: rowData.id, libraryId: libraryId });
  return (
    <>
      <Text
        onClick={() => dispatch(push(profileLink))}
        style={{
          cursor: 'pointer',
          padding: '8px 0px'
        }}
      >
        {rowData.name}
      </Text>
    </>
  );
};

const profileActionBodyTemplate = (rowdata, dispatch, editAction, deleteAction, libraryId) => {
  const profileLink = FrameworkModuleProfilePath.generate({ id: rowdata.id, libraryId: libraryId });
  const tablesLink = FrameworkModuleProfileTablesPath.generate({ id: rowdata.id, libraryId: libraryId });
  return (
    <>
      <Button
        icon="edit"
        onClick={() => editAction(rowdata)}
      />
      <Popup
        trigger={() => (
          <Button
            icon="list"
            onClick={() => dispatch(push(profileLink))}
          />
        )}
        position='bottom center'
        on={['hover']}
        {...{contentStyle}}
      >
        See {rowdata.name}&apos;s dimensions/parameters
      </Popup>
      <Popup
        trigger={() => (
          <Button
            icon="dynamic-feed"
            onClick={() => dispatch(push(tablesLink))}
          />
        )}
        position='bottom center'
        on={['hover']}
        {...{contentStyle}}
      >
        See {rowdata.name}&apos;s tables
      </Popup>
      <Button
        icon="delete"
        onClick={() => deleteAction(rowdata)}
      />
    </>
  );
};

export const rowExpansionTemplate = (data, dispatch, newItemAction, editAction, deleteAction) => {
  const action = () => newItemAction(data.id);

  return (
    <div style={{ marginLeft: '5rem', marginBottom: '2rem' }}>
      <DataTable value={data.profiles} removableSort>
        <Column
          field="name"
          header="Profile Name"
          sortable
          body={(rowdata) => profileLinkBody(rowdata, dispatch, data.id)}
          style={{ width: '20%' }}
        />
        <Column
          field="fileName"
          header="File Name"
          sortable
          style={{ width: '20%' }}
        />
        <Column
          field="description"
          header="Description"
          style={{ width: '40%' }}
        />
        <Column
          header={addProfileButton(action, data.name)}
          body={(rowdata) => profileActionBodyTemplate(rowdata, dispatch, editAction, deleteAction, data.id)}
          style={{ width: '20%', textAlign: 'right' }}
          alignHeader='right'
        />
      </DataTable>
    </div>
  );
};