import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { removeToken } from '../../../modules/auth/utils/token';
import { deauthenticateClient } from '../../../modules/api/client';

import Flex from '../../common/Flex';
import Text from '../../common/Text';

import LogOutGraphic from '../../common/graphics/LogOutGraphic';

const ProfileMenuItem = styled(Flex)`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 5rem;
  padding: ${({ theme }) => theme.space[2]} 1rem;
  width: 100%;
  text-decoration: none;

  flex-grow: 1;
  flex-shrink: 0;

  background-color: transparent;
  transition: background-color 0.2s;
  cursor: pointer;

  border: none;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[1]};
  }
`;

const ProfileMenuItemLogo = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 2.5rem;
  width: 2.5rem;
`;

const ProfileMenuItemText = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

const ProfileMenuItemTitle = styled(Text)`
  text-align: end;
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray[7]};
`;

function SignOutButton ({ history, ...props}) {

  const signOut = () => {
    removeToken();
    deauthenticateClient();
    window.location.href ='/auth/sessions/sign_out';
  };

  return (
    <ProfileMenuItem
      onClick={ () => signOut()}
    >
      <ProfileMenuItemLogo>
        <LogOutGraphic />
      </ProfileMenuItemLogo>
      <ProfileMenuItemText>
        <ProfileMenuItemTitle>
          Log Out
        </ProfileMenuItemTitle>
      </ProfileMenuItemText>
    </ProfileMenuItem>
  );
}

export default withRouter(SignOutButton);