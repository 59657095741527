import React, { useCallback, useRef } from 'react';

import Flex from '../../../components/common/Flex';

import BaseFormCell from '../../../components/form/BaseFormCell';
import BaseLabel from '../../../components/form/BaseLabel';
import BaseInput from '../../../components/form/BaseInput';
import BaseAppend from '../../../components/form/BaseAppend';
import BaseDebounceInput from '../../../components/form/BaseDebounceInput';
import BaseRequiredStatus from '../../../components/form/BaseRequiredStatus';

export const FORMATS = {
  DIMENSION: unitId => value => {
    const foundUnit = unitId && value && value.find(dimension => `${dimension['id']}` === unitId) || null;
    return foundUnit && foundUnit['value'] || null;
  },
};

export const NORMALIZATIONS = {
  DIMENSION: unitId => (value, previousValue, ...args) => {
    if (unitId) {
      if (previousValue.size > 0) {
        return previousValue.update(
          previousValue.findIndex(d => `${d['id']}` === unitId),
          listItem => ({ ...listItem, value })
        );
      } else {
        return previousValue.push({ id: unitId, value });
      }
    } else {
      return null;
    }
  },
};

const RDXTextInput = ({
  input,
  meta,
  type = 'text',
  width = '100%',
  height,
  bg,
  label,
  required,
  append,
  onAppendClick,
  tabIndex,
  autoComplete,
  wait,
  ...rest
}) => {
  const inputRef = useRef();
  const onCellClick = useCallback(() => inputRef.current.focus(), []);

  const InputComponent = wait ? BaseDebounceInput : BaseInput;

  const inputProps = { ...input };
  delete inputProps.defaultValue;

  return (
    type !== 'hidden' && (
      <BaseFormCell
        $width={width}
        $height={height}
        $bg={bg}
        $style={{ justifyContent: 'center' }}
        onClick={onCellClick}
      >
        {
          label &&
          <BaseLabel
            name={input.name}
            label={label}
            required={required}
            $active={!!meta.active}
            $error={!!meta.submitFailed}
          /> || null
        }
        {
          required &&
          <BaseRequiredStatus
            $error={!!meta.submitFailed}
          />
        }
        <Flex
          height='100%'
          $flexDirection='row'
          $alignItems='center'
        >
          <InputComponent
            ref={inputRef}
            type={type}
            tabIndex={tabIndex}
            $autofilled={!!meta.autofilled}
            autoComplete={autoComplete}
            wait={wait}
            { ...inputProps }
            { ...rest }
          />
          { append && <BaseAppend onClick={onAppendClick}>{ append }</BaseAppend>}
        </Flex>
      </BaseFormCell>
    ) || (
      <InputComponent
        ref={inputRef}
        type={type}
        tabIndex={tabIndex}
        $autofilled={!!meta.autofilled}
        autoComplete={autoComplete}
        wait={wait}
        { ...inputProps }
        { ...rest }
      />
    )
  );
};

export default RDXTextInput;
