import React from 'react';
import styled, { css } from 'styled-components';

import Box from '../../common/Box';

import { REACTDOM_CONTAINERS } from '../../../routes/App/constants';

export const LAYER_PRIORITY = {
  'DEFAULT': 400,
  'MODAL_DROPDOWN': 950,
};

export const CONTAINERS = {
  TOOLTIP: `#${REACTDOM_CONTAINERS.TOOLTIP}`,
  DROPDOWN: `#${REACTDOM_CONTAINERS.DROPDOWN}`,
};

const TooltipContainer = styled(Box)`
  z-index: ${ ({ priority }) => priority || LAYER_PRIORITY.DEFAULT };

  ${
  ({ size }) => size === 'small' && css`
      width: 14rem;
    ` || size === 'large' && css`
      width: 20rem;
    ` || size === 'fluid' && css`
      width: auto;
    ` || css`
      width: 18rem;
    `
}

  & > :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.space[2]};
  }
`;

const Tooltip = React.forwardRef((props, ref) => (
  <TooltipContainer ref={ref} {...props} />
));

export default Tooltip;