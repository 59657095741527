import React from 'react';
import styled, { css } from 'styled-components';

import Box from '../../../../components/common/Box';
import Flex from '../../../../components/common/Flex';

const BaseContainer = styled(Box)`
  position: absolute;
  z-index: 1;
  height: 100vh;
  width: 20rem;
  top: 0;
  bottom: 0;
  left: -20rem;

  transition: left ${({ theme }) => theme.transitions.speed.fast} ${({ theme }) => theme.transitions.type.inOut};

  ${({ $isFiltering }) => $isFiltering && css`
    left: 0;
  `}
`;

const Inner = styled(Flex)`
  flex-direction: row;
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;

  transition: padding-top ${({ theme }) => theme.transitions.speed.fast} ${({ theme }) => theme.transitions.type.inOut},
    padding-bottom ${({ theme }) => theme.transitions.speed.fast} ${({ theme }) => theme.transitions.type.inOut},
    height ${({ theme }) => theme.transitions.speed.fast} ${({ theme }) => theme.transitions.type.inOut};

  ${({ $isMinimized }) => $isMinimized && css`
    padding-top: ${({ theme }) => theme.space[4]};
    padding-bottom: ${({ theme }) => theme.space[4]};
  `}
`;

const FormWrapper = styled(Flex)`
  display: flex;
  flex-direction: column;
  width: 20rem;
  background-color: ${({ theme }) => theme.colors.gray[2]};

  transition: border-top ${({ theme }) => theme.transitions.speed.fast} ${({ theme }) => theme.transitions.type.inOut},
    border-top-right-radius ${({ theme }) => theme.transitions.speed.fast} ${({ theme }) => theme.transitions.type.inOut},
    border-bottom ${({ theme }) => theme.transitions.speed.fast} ${({ theme }) => theme.transitions.type.inOut},
    border-bottom-right-radius ${({ theme }) => theme.transitions.speed.fast} ${({ theme }) => theme.transitions.type.inOut},
    background-color 0.2s ease,
    border-color 0.2s ease;

  border-right: 1px solid ${({ theme }) => theme.colors.gray[4]};

  ${({ $isMinimized, theme }) => $isMinimized && css`
    overflow: hidden;
    border-top: 1px solid ${theme.colors.gray[4]};
    border-bottom: 1px solid ${theme.colors.gray[4]};
    border-top-right-radius: ${theme.radii[2]};
    border-bottom-right-radius: ${theme.radii[2]};
  `}
`;

const Container = React.forwardRef((props, ref) => <BaseContainer ref={ref} { ...props } />);

const FilterSidebar = {
  Container,
  Inner,
  FormWrapper,
};

export default FilterSidebar;
