import { takeLatest, put, fork, all } from 'redux-saga/effects';

import { reloadModal } from '../../../../../modules/modal/actions';

import { updatePipingCatalogTypes } from '../../../../../entities/Piping/Catalogs/constants';

import {
  SETTING_PRODUCTS,
  SETTING_MODULES,
} from '../../../../../entities/Settings/model';

import {
  ORGANIZATION_SECTIONS,
} from '../../OrganizationModal';

export function* handleClosePipingCatalogUnitSystemModal() {
  yield put(reloadModal('ORGANIZATION_MODAL', { section: ORGANIZATION_SECTIONS.SETTINGS, productTier: SETTING_MODULES.PIPING }, true));
}

export function* watchClosePipingCatalogUnitSystemModalSaga() {
  yield takeLatest(updatePipingCatalogTypes.SUCCESS, handleClosePipingCatalogUnitSystemModal);
}

// final output saga
export default function* main() {
  yield all([
    fork(watchClosePipingCatalogUnitSystemModalSaga),
  ]);
}
