import React from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';

const Light = React.forwardRef(({ theme, children, ...props }, ref) => (
  <SkeletonTheme
    ref={ref}
    baseColor={theme.colors.gray[4]}
    highlightColor={theme.colors.gray[3]}
    {...props}
  >
    {children}
  </SkeletonTheme>
));

const StaticLight = React.forwardRef(({ theme, children, ...props }, ref) => (
  <SkeletonTheme
    ref={ref}
    baseColor={theme.colors.gray[4]}
    highlightColor={theme.colors.gray[4]}
    {...props}
  >
    {children}
  </SkeletonTheme>
));

const Dark = React.forwardRef(({ theme, children, ...props }, ref) => (
  <SkeletonTheme
    ref={ref}
    baseColor={theme.colors.gray[7]}
    highlightColor={theme.colors.gray[6]}
    {...props}
  >
    {children}
  </SkeletonTheme>
));

export default {
  Light,
  StaticLight,
  Dark,
};
