import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { formValueSelector } from 'redux-form/immutable';
import { List } from 'immutable';

import EK from '../../keys';
import selectEntities from '../../../modules/entities/selectors';
import SpecFittingInstanceSchema from './schema';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';
import { selectCurrentFittingInstances } from '../FittingInstances/selectors';
import { convertToSpecFittingInstanceType } from '../../../routes/Piping/SpecsFittingsEdit/actions';
import { selectCurrentSpec } from '../Specs/selectors';

export const selectNormalizedSpecFittingInstances = () => createSelector(
  selectEntities(),
  entities => entities.get('specFittingInstances')
);

export const selectCurrentNormalizedSpecFittingInstances = (specId) =>
  createSelector(
    selectNormalizedSpecFittingInstances(),
    instances => instances.toList().filter(item => item.spec == specId)
  );

const selectCurrentSpecFittingInstancesFromSpec = (specId) => createSelector(
  selectCurrentSpec(specId),
  (spec) => spec?.specFittingInstances || List()
);

const specFilterForm = formValueSelector(`filters.${EK.SPEC_FITTING_INSTANCES.state}`);

function removeDuplicateFromSpecFittingInstancesArray(arr) {
  // borrowed this from https://www.geeksforgeeks.org/how-to-get-all-unique-values-remove-duplicates-in-a-javascript-array/, it's pretty slick.
  let outputArray = arr.filter(function (v, i, self) {
    if (v.fittingInstance) {
      return v?.fittingInstance.id && i == self.findIndex((item) => item?.fittingInstance?.id ? item.fittingInstance.id == v.fittingInstance.id : item.id == v.fittingInstance.id);
    }
    return v?.id && i == self.findIndex((item) => item?.fittingInstance?.id ? item.fittingInstance.id == v.id : item.id == v.id);
  });

  return outputArray;
}

// Helper function to get values from either spec instance or direct instance
const getInstanceValues = (item) => {
  // If it's a spec instance
  if (item.fittingInstance) {
    const fittingInstance = item.fittingInstance;
    return {
      cadModelName: fittingInstance.cadModelName,
      sizeProperty: fittingInstance.sizeProperty,
      schedule: fittingInstance.schedule,
      stockno: item.stockno || fittingInstance.stockno || '',
      mccsCode: item.mccsCode || fittingInstance.mccsCode || '',
      selectionName: item.selectionName || fittingInstance.selectionName || ''
    };
  }

  // If it's a direct instance
  return {
    cadModelName: item.cadModelName,
    sizeProperty: item.sizeProperty,
    schedule: item.schedule,
    stockno: item.stockno || '',
    mccsCode: item.mccsCode || '',
    selectionName: item.selectionName || ''
  };
};

// gets all of the instances for a fitting family, concatenates that list with the list of specfittinginstances, and then removes the duplicates
export const selectCurrentFilteredSpecFittingInstances = (specId, fittingId) => createSelector(
  selectSidebarIsFiltering(),
  selectCurrentSpecFittingInstancesFromSpec(specId),
  selectCurrentFittingInstances(fittingId),
  state => specFilterForm(state,
    'schedule',
    'cadModelName',
    'port0Size',
    'port0EndType',
    'port1Size',
    'port1EndType',
    'port2Size',
    'port2EndType',
    'selectionName',
    'stockno',
    'mccsCode',
    'boltNutFamily'),
  (isFiltering, instances, fittingInstances, query) => {
    const specFittingInstances = instances && instances.filter(item => typeof (item.fittingFamilyId) == 'string' ? item.fittingFamilyId == fittingId : item.fittingFamilyId?.id == fittingId);

    // merge the specfittinginstances with the fittinginstances
    const mergedInstances = specFittingInstances.concat(fittingInstances);

    // remove duplicates
    const uniqueInstances = removeDuplicateFromSpecFittingInstancesArray(mergedInstances);

    // If not filtering, return all instances
    if (!isFiltering) {
      return uniqueInstances.toArray().map(item => convertToSpecFittingInstanceType(item));
    }

    // Filter instances
    const filteredInstances = uniqueInstances.filter(item => {
      const values = getInstanceValues(item);

      // Check CAD Model Name
      const queryCadModelName = query.cadModelName;
      const cadModelNameMatch = !queryCadModelName || (values.cadModelName && values.cadModelName.toLowerCase().includes(queryCadModelName.toLowerCase()));

      // Check Size
      const querySizeProperty = query.sizeProperty;
      const sizeMatch = !querySizeProperty || (values.sizeProperty && values.sizeProperty.id === (querySizeProperty.id || querySizeProperty));

      // Check Schedule
      const querySchedule = query.schedule;
      const scheduleMatch = !querySchedule || (values.schedule && values.schedule.id === (querySchedule.id || querySchedule));

      // Check stockno
      const queryStockno = query.stockno || '';
      const stocknoMatch = !queryStockno || values.stockno.toLowerCase().includes(queryStockno.toLowerCase());

      // Check mccsCode
      const queryMccsCode = query.mccsCode || '';
      const mccsCodeMatch = !queryMccsCode || values.mccsCode.toLowerCase().includes(queryMccsCode.toLowerCase());

      // Check selectionName
      const querySelectionName = query.selectionName || '';
      const selectionNameMatch = !querySelectionName || values.selectionName.toLowerCase().includes(querySelectionName.toLowerCase());

      return cadModelNameMatch && sizeMatch && scheduleMatch && stocknoMatch && mccsCodeMatch && selectionNameMatch;
    });

    return filteredInstances.toArray().map(item => convertToSpecFittingInstanceType(item));
  }
);
