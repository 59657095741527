import React, { useState } from 'react';
import Icon from '../../../components/common/Icon';

import './styles.css';

export const ColorTypeOptions = [
  {name: 'Creo Color', subtitle: 'this color is managed by Creo', value: true},
  {name: 'Custom Color', subtitle: 'this color is managed by CadActive', value: false}
];

export const DropdownItemTemplate = (option) => (
  <div>
    <div style={{ fontSize: '1rem' }} >{option.name}</div>
    <div style={{ fontSize: '.75rem'}} >{option.subtitle}</div>
  </div>
);

export const EntityTools = ({rowdata, handleRowAction}) => {
  let [iconName, iconColor] = rowdata.isDeleted || rowdata.isCreated || rowdata.isEdited ? ['undo', 'gray.6'] : ['delete', 'error.4'];
  let clickStyle = { cursor: 'pointer' };
  if (!rowdata.canSafelyDelete && !rowdata.isEdited) {
    [iconName, iconColor] = ['lock', 'gray.6'];
    clickStyle = {};
  }
  if (rowdata.id.includes('NEW_ROW_') && !rowdata.isCreated && !rowdata.isEdited) {
    iconColor = 'gray.2';
  }
  return (
    <div style={clickStyle} onClick={() => handleRowAction(rowdata, iconName)}>
      <Icon name={iconName} color={iconColor} />
    </div>
  );
};
