import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { Route, matchPath } from 'react-router-dom';
import { push } from 'connected-react-router';
import { List } from 'immutable';

import Button from '../../../components/common/Button';

import Options from '../../templates/Structures/Options';

import { convertListToString } from '../../../entities/Piping/Specs/model';
import {
  processArchiveSpecs,
  processUnarchiveSpecs,
  processEditSpec,
} from '../../../entities/Piping/Specs/actions';

import { selectPipingModulePermissionsAndState } from '../../Dashboard/selectors';

import {
  PipingModuleSpecsFittingsEditPath,
  PipingModuleSpecsFittingInstancesEditPath,
  PipingModuleSpecsPipesEditPath,
} from '../../../paths';

import { showModal } from '../../../modules/modal/actions';

import {
  selectCurrentSpec,
  selectisPrioritizedFamilySortingEnabled,
} from './selectors';

import {
  updateFamilySorting,
} from './actions';

const ConstructedOptions = ({
  isPerforming,
  managePipes,
  manageFittings,
  togglePipeFamilySorting,
  toggleFittingFamilySorting,
  isPrioritizedFamilySortingEnabled,
  toggleHiddenGridColumns,
}) => (
  <>

    {/* List Button */}
    <Route
      key="piping-specs-list-button"
      path={PipingModuleSpecsFittingInstancesEditPath.url}
      render={() =>
        React.createElement(Button, {
          secondary: true,
          icon: 'list',
          disabled: isPerforming,
          onClick: toggleHiddenGridColumns,
          mr: 2,
        })
      }
    />

    {/* Fittings */}
    <Route
      key="piping-specs-fittings-edit"
      path={PipingModuleSpecsFittingsEditPath.url}
      render={() =>
        React.createElement(
          Button,
          {
            secondary: true,
            disabled: isPerforming,
            onClick: managePipes,
            mr: 2,
          },
          ['Manage Pipes']
        )
      }
    />
    <Route
      key="piping-specs-fitting-instances-edit"
      path={PipingModuleSpecsFittingsEditPath.url}
      exact
      render={() =>
        React.createElement(Button, {
          icon: 'star',
          secondary: !isPrioritizedFamilySortingEnabled,
          primary: isPrioritizedFamilySortingEnabled,
          iconFillColor: isPrioritizedFamilySortingEnabled ? 'gray.5' : 'gray.6',
          disabled: isPerforming,
          onClick: toggleFittingFamilySorting,
          mr: 2,
        })
      }
    />

    {/* Pipes */}
    <Route
      key="piping-specs-pipes-edit"
      path={PipingModuleSpecsPipesEditPath.url}
      render={() =>
        React.createElement(
          Button,
          {
            secondary: true,
            disabled: isPerforming,
            onClick: manageFittings,
            mr: 2,
          },
          ['Manage Fittings']
        )
      }
    />
    <Route
      key="piping-specs-pipe-instances-edit"
      path={PipingModuleSpecsPipesEditPath.url}
      exact
      render={() =>
        React.createElement(Button, {
          icon: 'star',
          secondary: !isPrioritizedFamilySortingEnabled,
          primary: isPrioritizedFamilySortingEnabled,
          iconFillColor: isPrioritizedFamilySortingEnabled ? 'gray.5' : 'gray.6',
          disabled: isPerforming,
          onClick: togglePipeFamilySorting,
          mr: 2,
        })
      }
    />
  </>
);

const mapStateToProps = (specId) => createSelector(
  selectPipingModulePermissionsAndState(),
  selectCurrentSpec(specId),
  selectisPrioritizedFamilySortingEnabled(),
  (
    { isLoadingInitialData, isFetching, ...rest },
    spec,
    isPrioritizedFamilySortingEnabled,
  ) => ({
    ...rest,
    canFilter: true,
    isLoading: isLoadingInitialData || isFetching,
    isPrioritizedFamilySortingEnabled,
    specId: (spec && spec.id) || null,
    isArchived: spec && spec.archivedFlag,
    ConstructedOptions,
  })
);

function SpecsEditSwitchOptions({ location, specId, pendingValidChanges, updateEntities, isEditing=false, editSpec}) {

  const reduxProps = useSelector(mapStateToProps(specId));
  const dispatch = useDispatch();

  return (
    <Options
      {...reduxProps}

      isEditing={isEditing}

      updateEntities={updateEntities}
      pendingValidChanges={pendingValidChanges}
      editEntity={editSpec}
      archiveEntity = {() => dispatch(processArchiveSpecs([reduxProps.specId]))}
      unarchiveEntity = {() => dispatch(processUnarchiveSpecs([reduxProps.specId]))}
      managePipes = {() => dispatch(push(PipingModuleSpecsPipesEditPath.generate({ id: reduxProps.specId })))}
      manageFittings = {() => dispatch(push(PipingModuleSpecsFittingsEditPath.generate({ id: reduxProps.specId })))}
      togglePipeFamilySorting = {() => dispatch(updateFamilySorting())}
      toggleFittingFamilySorting = {() => dispatch(updateFamilySorting())}
    />
  );
}

export default SpecsEditSwitchOptions;