import React from 'react';
import styled from 'styled-components';
import Box from '../Box';

const BaseFlex = styled(Box)`
  // Flex container properties
  align-items: ${props => props.$alignItems};
  align-content: ${props => props.$alignContent};
  justify-content: ${props => props.$justifyContent};
  flex-wrap: ${props => props.$flexWrap};
  flex-direction: ${props => props.$flexDirection};

  // Flex item properties
  flex: ${props => props.$flex};
  flex-basis: ${props => typeof props.$flexBasis === 'number' ? `${props.$flexBasis}px` : props.$flexBasis};
  justify-self: ${props => props.$justifySelf};
  align-self: ${props => props.$alignSelf};
`;

const Flex = React.forwardRef((props, ref) => {
  const {
    alignItems,
    flexDirection,
    justifyContent,
    alignContent,
    flexWrap,
    flex,
    flexBasis,
    justifySelf,
    alignSelf,
    ...rest
  } = props;
  return (
    <BaseFlex
      ref={ref}
      display="flex"
      $alignItems={alignItems}
      $flexDirection={flexDirection}
      $justifyContent={justifyContent}
      $alignContent={alignContent}
      $flexWrap={flexWrap}
      $flex={flex}
      $flexBasis={flexBasis}
      $justifySelf={justifySelf}
      $alignSelf={alignSelf}
      {...rest}
    />
  );
});

export default Flex;
