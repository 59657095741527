import styled, { css } from 'styled-components';

const StatusRequired = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.space[3]};
  right: ${({ theme }) => theme.space[3]};

  height: ${({ theme }) => theme.space[2]};
  width: ${({ theme }) => theme.space[2]};
  background-color: ${({ theme }) => theme.colors.primary[3]};

  ${
  ({ error }) => error && css`
      background-color: ${({ theme }) => theme.colors.error[3]};
    `
}

  border-radius: 100%;

  transition: transform ${({ theme }) => theme.transitions.speed.fast} ${({ theme }) => theme.transitions.type.out};

  &:hover {
    cursor: pointer;
    transform: scale(1.4);
  }
`;

export default StatusRequired;