import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';
import styled from 'styled-components';

import EK from '../../../../../entities/keys';

import Box from '../../../../../components/common/Box';
import Flex from '../../../../../components/common/Flex';
import Button from '../../../../../components/common/Button';
import Icon from '../../../../../components/common/Icon';

import RDXTextInput from '../../../RDXTextInput';

const FlexibleInputContainer = styled(Box)`
  flex-grow: 1;
  height: 100%;
  height: 4rem;
`;

const BoxForm = styled(Box)`
  flex-grow: 1;
`;

const ConfigProfileNameForm = ({ handleSubmit, isPerforming, valid, showSubmitButton }) => (
  <BoxForm as='form' onSubmit={handleSubmit}>
    <Flex flexDirection='row' alignItems='center'>
      <FlexibleInputContainer>
        <Field
          component={RDXTextInput}
          name='name'
          type='text'
          height='100%'
          placeholder='Enter profile name...'
          fontSize={5}
          bg='transparent'
          autoFocus
        />
      </FlexibleInputContainer>
      {
        showSubmitButton &&
        <Button
          primary
          subtle
          type="submit"
          small
          disabled={isPerforming || !valid}
          isPerformingAction={isPerforming}
          style={{
            marginLeft: theme.space[2],
            marginRight: theme.space[3],
          }}
        >Update Name</Button> || null
      }
    </Flex>
  </BoxForm>
);

const enhance = compose(
  reduxForm({
    form: EK.CONFIG_PROFILES.state,
    onSubmit(model, dispatch, props) {
      props.onRDXSubmit(model.serialize());
    },
  })
);

export default enhance(ConfigProfileNameForm);
