import React from 'react';

import Button from '../../../components/common/Button';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import { FrameworkModuleProfilePath } from '../../../paths';

import { push } from 'connected-react-router';

//popup styling
const contentStyle = { width: 'fit-content' };
export const ConstructedOptions = (profileId, libraryId, dispatch) => {
  const profileLink = FrameworkModuleProfilePath.generate({ id: profileId, libraryId: libraryId });
  return (
    <>
      <Popup
        trigger={() => (
          <Button
            icon="list"
            secondary={true}
            onClick={() => dispatch(push(profileLink))}
            style={{ marginRight: '10px' }}
          />
        )}
        position="bottom center"
        on={['hover']}
        {...{ contentStyle }}
      >
        To Parameters/Dimensions
      </Popup>
    </>
  );
};
