import React from 'react';
import { compose } from 'redux';
import { FormSection, FieldArray, reduxForm } from 'redux-form/immutable';
import styled from 'styled-components';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';
import Flex from '../../../../components/common/Flex';

import {
  InviteFieldEmailFormSection,
  InviteFieldPermissionsFormSection,
} from '../OrganizationUserInviteForm';

const InviteFieldRemoveFormSection = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 5rem;
`;

const Invite = styled(Flex)`
  flex-direction: row;
  align-items: center;
  height: 4rem;
  padding-left: ${({ theme }) => theme.space[4]};
  padding-right: ${({ theme }) => theme.space[4]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[0]};

  &:last-child {
    border-bottom: none;
  }
`;

const renderInvites = ({ fields, isPerforming, allowedRoles }) => {
  return (fields.map((invite, index) => (
    <Invite key={index}>
      <InviteFieldEmailFormSection
        invite={invite}
        isPerforming={isPerforming}
      />

      <InviteFieldPermissionsFormSection
        invite={invite}
        isPerforming={isPerforming}
        allowedRoles={allowedRoles}
      />

      <InviteFieldRemoveFormSection>
        <Button
          transparent
          subtle
          icon='close'
          type='button'
          error
          onClick={() => fields.remove(index)}
        />
      </InviteFieldRemoveFormSection>
    </Invite>
  )));
};

const InviteListBox = styled(Box)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const OrganizationUserInvitesForm = ({ handleSubmit, isPerforming, allowedRoles }) => {
  return (
    <InviteListBox
      as="form"
      onSubmit={handleSubmit}
    >
      <FieldArray
        name={EK.ORGANIZATION_USER_INVITES.plural}
        component={renderInvites}
        allowedRoles={allowedRoles}
        isPerforming={isPerforming}
      />
    </InviteListBox>
  );
};

const enhance = compose(
  reduxForm({
    form: `${EK.ORGANIZATION_USER_INVITES.state}`,
    onSubmit(model, dispatch, props) {
      const invites = model.get(EK.ORGANIZATION_USER_INVITES.state);
      // Add null check before mapping
      if (!invites) {
        return;
      }
      props.onRDXSubmit(invites.map(invite => invite.serialize()).toArray());
    },
  })
);

export default enhance(OrganizationUserInvitesForm);
