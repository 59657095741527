/* eslint-disable */

import React, { useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import styled, { css } from "styled-components";

import Box from "../../components/common/Box";
import Flex from "../../components/common/Flex";

import Breadcrumbs from "../templates/Structures/Breadcrumbs";
import Headers from "../templates/Structures/Headers";

import GettingStarted from "../templates/Structures/GettingStarted";

import PageNotFoundRedirect from "../../containers/common/PageNotFoundRedirect";

import Sidebar from "./Sidebar";
import FilterSidebar from "./FilterSidebar";

import {
  UserIsAuthenticated,
  UserEmailVerified,
  UserHasOrganization,
  UserIsActive,
  UserHasNoRequiredPasswordResetPending,
} from "../../modules/auth/utils/routes";

import { processFetchAllUnits } from "../../entities/Synchronize/Units/actions";
import { processFetchAllUnitSystems } from "../../entities/Synchronize/UnitSystems/actions";
import { processFetchAllAppearances } from "../../entities/Synchronize/Appearances/actions";
import { processFetchAllSettings } from "../../entities/Settings/actions";

import { handleLogout } from "../../modules/auth/actions";

import { selectCurrentUser } from "../../entities/CurrentUser/selectors";

import { showModal } from "../../modules/modal/actions";
import { MODAL_SIZES } from "../../containers/modal/Modal";

import {
  DashboardPath,
  OrganizationPath,
  // Product Tiers
  // - Synchronize
  SynchronizeModulePath,
  SynchronizeModuleConfigProfilesPath,
  SynchronizeModuleMapkeysPath,
  SynchronizeModuleAppearancesPath,
  // - Standardize
  StandardizeModulePath,
  StandardizeModuleLayersPath,
  StandardizeModuleNoteProfilesPath,
  StandardizeModuleNoteProfilesEditPath,
  StandardizeModuleNotesPath,
  StandardizeModuleOrientationsPath,
  StandardizeModuleParameterProfilesPath,
  StandardizeModuleParameterProfilesEditPath,
  StandardizeModuleParametersPath,
  StandardizeModuleSectionsPath,
  StandardizeModuleSimplifiedRepsPath,
  StandardizeModuleModelTemplatesPath,
  StandardizeModuleExplodeStatesPath,
  StandardizeModuleCombinedStatesPath,
  StandardizeModuleLayerStatesPath,
  StandardizeModuleAppearanceStatesPath,
  StandardizeModuleStyleStatesPath,
  // - Automate
  AutomateModulePath,
  AutomateModuleRoutingProfilesPath,
  // Product Modules
  // - Cabling
  CablingModulePath,
  CablingModuleSpoolsPath,
  // - Piping
  PipingModulePath,
  PipingModuleSchedulesPath,
  PipingModuleSizesPath,
  PipingModuleEndTypesPath,
  PipingModuleFittingCategoriesPath,
  PipingModuleBoltNutsPath,
  PipingModuleBoltNutsEditPath,
  PipingModuleFittingsPath,
  PipingModuleFittingsEditPath,
  PipingModuleMaterialsPath,
  PipingModuleInsulationSpecsPath,
  PipingModuleInsulationsPath,
  PipingModuleInsulationsEditPath,
  PipingModulePipesPath,
  PipingModulePipesEditPath,
  PipingModulePipesBendsPath,
  PipingModulePipesBendsEditPath,
  PipingModulePipesBendMachinesPath,
  PipingModulePipesBendMachinesEditPath,
  PipingModulePipesBendSpringbacksPath,
  PipingModulePipesBendSpringbacksEditPath,
  PipingModulePipesMitersPath,
  PipingModulePipesMitersEditPath,
  PipingModuleSpecsPath,
  PipingModuleSpecsEditPath,
  PipingModuleSpecsFittingsEditPath,
  PipingModuleSpecsFittingInstancesEditPath,
  PipingModuleSpecsPipesEditPath,
  PipingModuleSpecsPipeInstancesEditPath,
  PipingModuleProjectsPath,
  PipingModuleProjectsEditPath,
  // - ECAD
  EcadModulePath,
  EcadModuleCatalogsPath,
  // - Framework
  FrameworkModulePath,
  FrameworkModuleProfileLibrariesPath,
  FrameworkModuleProfileLibrariesBasePath,
  FrameworkModuleProfilePath,
  FrameworkModuleProfileTablesPath,
  FrameworkModuleTablePath,
  // Reporting
  ReportingModulePath,
  ReportingModuleTemplatesPath,
} from "../../paths";

import injectReducer from "../../utils/reducers/injectReducer";

import dashboardReducer from "./reducer";

import {
  selectIsLoadingInitialData,
  selectSidebarIsMinimized,
  selectSidebarIsFiltering,
} from "./selectors";

import breadcrumbs from "./breadcrumbs";

// Synchronize Components
import SynchronizeModuleBody from "../Synchronize/Base/Body";
import AppearancesBody from "../Synchronize/Appearances/Body";
import MapkeysBody from "../Synchronize/Mapkeys/Body";
import ConfigProfilesBody from "../Synchronize/ConfigProfiles/Body";

// Standardize Components
import StandardizeModuleBody from "../Standardize/Base/Body";

import ExplodeStatesBody from '../Standardize/ExplodeStates/Body';

import CombinedStateBody from '../Standardize/CombinedStates/Body';

import LayerStateBody from '../Standardize/LayerStates/Body';

import AppearanceStateBody from '../Standardize/AppearanceStates/Body';

import StyleStatesBody from '../Standardize/StyleStates/Body';

import LayersBody from "../Standardize/Layers/Body";

import NoteProfilesBody from "../Standardize/NoteProfiles/Body";
import NoteProfilesNotices from "../Standardize/NoteProfiles/Notices";

import NoteProfilesEditBack from "../Standardize/NoteProfilesEdit/Back";
import NoteProfilesEditHeader from "../Standardize/NoteProfilesEdit/Header";
import NoteProfilesEditOptions from "../Standardize/NoteProfilesEdit/Options";
import NoteProfilesEditBody from "../Standardize/NoteProfilesEdit/Body";
import NoteProfilesEditNotices from "../Standardize/NoteProfilesEdit/Notices";

import NotesBody from "../Standardize/Notes/Body";

import OrientationsBody from "../Standardize/Orientations/Body";

import ParameterProfilesBody from "../Standardize/ParameterProfiles/Body";
import ParameterProfilesNotices from "../Standardize/ParameterProfiles/Notices";

import ParameterProfilesEditBack from "../Standardize/ParameterProfilesEdit/Back";
import ParameterProfilesEditBody from "../Standardize/ParameterProfilesEdit/Body";

import ParametersBody from "../Standardize/Parameters/Body";

import SectionsBody from "../Standardize/Sections/Body";

import SimplifiedRepsBody from "../Standardize/SimplifiedReps/Body";
import SimplifiedRepsNotices from "../Standardize/SimplifiedReps/Notices";

import ModelTemplatesBody from "../Standardize/ModelTemplates/Body";
import ModelTemplatesNotices from "../Standardize/ModelTemplates/Notices";

// Automate Components
import AutomateModuleSwitch from "../Automate/Base/Switch";
import AutomateRoutingProfilesBody from "../Automate/RoutingProfiles/Body";

// Modules
// - Cabling
import CablingModuleSwitch from '../Cabling/Base/Switch';
import SpoolsBody from '../Cabling/Spools/Body';

// - Piping
import PipingModuleBody from "../Piping/Base/Body";

import SchedulesBody from "../Piping/Schedules/Body";

import SizesBody from "../Piping/Sizes/Body";

import EndTypesBody from "../Piping/EndTypes/Body";

import FittingCategoriesBody from "../Piping/FittingCategories/Body";

import MaterialsBody from "../Piping/Materials/Body";

import BoltNutsBody from "../Piping/BoltNuts/Body";

import BoltNutsEditBack from "../Piping/BoltNutsEdit/Back";
import BoltNutsEditBody from "../Piping/BoltNutsEdit/Body";

import InsulationsBody from "../Piping/Insulations/Body";
import InsulationsNotices from "../Piping/Insulations/Notices";

import InsulationsEditBack from "../Piping/InsulationsEdit/Back";
import InsulationsEditBody from "../Piping/InsulationsEdit/Body";

import FittingsBody from "../Piping/Fittings/Body";
import FittingsNotices from "../Piping/Fittings/Notices";

import FittingsEditBack from "../Piping/FittingsEdit/Back";
import FittingsEditBody from "../Piping/FittingsEdit/Body";

import PipesBody from "../Piping/Pipes/Body";
import PipesNotices from "../Piping/Pipes/Notices";

import PipesEditSwitch from "../Piping/PipesEditSwitch/Body";
import PipesEditBack from "../Piping/PipesEdit/Back";
import PipesEditBody from "../Piping/PipesEdit/Body";

import PipesBendsBack from "../Piping/PipesBends/Back";
import PipesBendsBody from "../Piping/PipesBends/Body";

import PipesBendsEditBack from "../Piping/PipesBendsEdit/Back";
import PipesBendsEditBody from "../Piping/PipesBendsEdit/Body";

import PipesBendMachinesBack from "../Piping/PipesBendMachines/Back";
import PipesBendMachinesBody from "../Piping/PipesBendMachines/Body";

import PipesBendMachinesEditBack from "../Piping/PipesBendMachinesEdit/Back";
import PipesBendMachinesEditBody from "../Piping/PipesBendMachinesEdit/Body";

import PipesBendSpringbacksBack from "../Piping/PipesBendSpringbacks/Back";
import PipesBendSpringbacksBody from "../Piping/PipesBendSpringbacks/Body";

import PipesBendSpringbacksEditBack from "../Piping/PipesBendSpringbacksEdit/Back";
import PipesBendSpringbacksEditBody from "../Piping/PipesBendSpringbacksEdit/Body";

import PipesMitersBack from "../Piping/PipesMiters/Back";
import PipesMitersBody from "../Piping/PipesMiters/Body";

import PipesMitersEditBack from "../Piping/PipesMitersEdit/Back";
import PipesMitersEditBody from "../Piping/PipesMitersEdit/Body";

import SpecsSwitch from "../Piping/SpecsSwitch/Body";
import SpecsBody from "../Piping/Specs/Body";
import SpecsNotices from "../Piping/Specs/Notices";

import SpecsEditSwitch from "../Piping/SpecsEditSwitch/Body";

import SpecsFittingsEditBack from "../Piping/SpecsFittingsEdit/Back";
import SpecsFittingsEditBody from "../Piping/SpecsFittingsEdit/Body";
import SpecsFittingsEditNotices from "../Piping/SpecsFittingsEdit/Notices";

import SpecsFittingInstancesEditBack from "../Piping/SpecsFittingInstancesEdit/Back";
import SpecsFittingInstancesEditBody from "../Piping/SpecsFittingInstancesEdit/Body";
import SpecsFittingInstancesEditNotices from "../Piping/SpecsFittingInstancesEdit/Notices";

import SpecsPipesEditBack from "../Piping/SpecsPipesEdit/Back";
import SpecsPipesEditBody from "../Piping/SpecsPipesEdit/Body";
import SpecsPipesEditNotices from "../Piping/SpecsPipesEdit/Notices";

import SpecsPipeInstancesEditBack from "../Piping/SpecsPipeInstancesEdit/Back";
import SpecsPipeInstancesEditBody from "../Piping/SpecsPipeInstancesEdit/Body";
import SpecsPipeInstancesEditNotices from "../Piping/SpecsPipeInstancesEdit/Notices";

import ProjectsBody from "../Piping/Projects/Body";
import ProjectsNotices from "../Piping/Projects/Notices";

import ProjectsEditBack from "../Piping/ProjectsEdit/Back";
import ProjectsEditBody from "../Piping/ProjectsEdit/Body";

import InsulationSpecsBody from "../Piping/InsulationSpecs/Body";

// - ECAD
import EcadModuleSwitch from '../ECAD/Base/Switch';
import EcadCatalogsBody from '../ECAD/Catalogs/Body';

// - Framework
import FrameworkModuleSwitch from '../Framework/Base/Switch';

import FrameworkProfileLibrariesBody from '../Framework/ProfileLibraries/Body';
import FrameworkProfileBody from '../Framework/Profile/Body';
import FrameworkProfileTablesBody from '../Framework/ProfileTables/Body';
import FrameworkTableBody from '../Framework/Table/Body';

// Reporting
import ReportingModuleSwitch from '../Reporting/Base/Switch';
import TemplatesBody from '../Reporting/Templates/Body';

// <span className={cx('raptorInstructions')}>Up, Up, Down, Down, Left, Right, Left, Right, B, A</span>

const DashboardPageContainer = styled(Flex)`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
`;

const DashboardPage = styled(Box)`
  display: flex;
  flex-direction: column;

  height: 100vh;
  position: absolute;

  transition: left ${({ theme }) => theme.transitions.speed.fast}
      ${({ theme }) => theme.transitions.type.inOut},
    right ${({ theme }) => theme.transitions.speed.fast}
      ${({ theme }) => theme.transitions.type.inOut};

  will-change: left, right;

  left: 20rem;
  right: 0;

  ${({ $isFullPage }) =>
    $isFullPage &&
    css`
      left: 2rem;
      right: 1rem;
    `}
`;

const DashboardSuperHeader = styled(Flex)`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: ${({ theme }) => theme.space[5]};
  margin-right: ${({ theme }) => theme.space[6]};
  margin-left: ${({ theme }) => theme.space[6]};
  margin-bottom: ${({ theme }) => theme.space[2]};
`;

const DashboardBody = styled(Flex)`
  flex: 1;
  margin-bottom: ${({ theme }) => theme.space[4]};

  min-height: 0;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`;

const Options = styled(Flex)`
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 0;
`;

const CadActiveBreadcrumbs = withBreadcrumbs(breadcrumbs, {
  excludePaths: ["/"],
})(Breadcrumbs);

const Dashboard = ({
  currentUser,
  isLoading,
  isMinimized,
  isFiltering,
  showCurrentUserModal,
  showPrivacyPolicyModal,
  logout,

  fetchUnits,
  fetchUnitSystems,
  fetchAppearances,
  fetchSettings,
  showLegalTermsUpdatedModal,
}) => {
  useEffect(() => {
    fetchUnits();
    fetchUnitSystems();
    fetchAppearances();
    fetchSettings();

    if (!currentUser.hasAcceptedEula) {
      showLegalTermsUpdatedModal();
    }
  }, []);

  return (
    <DashboardPageContainer>
      <Sidebar
        currentUser={currentUser}
        isLoading={isLoading}
        isMinimized={isMinimized}
        isFiltering={isFiltering}
        showCurrentUserModal={showCurrentUserModal}
        showPrivacyPolicyModal={showPrivacyPolicyModal}
        logout={logout}
      />

      <FilterSidebar
        isLoading={isLoading}
        isMinimized={isMinimized}
        isFiltering={isFiltering}
      />

      <DashboardPage $isFullPage={isMinimized && !isFiltering}>
        <DashboardSuperHeader>
          <Switch>
            {/* Standardize */}
            <Route
              key="standardize-note-profiles-edit"
              exact
              path={StandardizeModuleNoteProfilesEditPath.url}
              component={NoteProfilesEditBack}
            />
            <Route
              key="standardize-parameter-profiles-edit"
              exact
              path={StandardizeModuleParameterProfilesEditPath.url}
              component={ParameterProfilesEditBack}
            />

            {/* Piping */}
            <Route
              key="piping-bolt-nuts-edit"
              exact
              path={PipingModuleBoltNutsEditPath.url}
              component={BoltNutsEditBack}
            />
            <Route
              key="piping-insulations-edit"
              exact
              path={PipingModuleInsulationsEditPath.url}
              component={InsulationsEditBack}
            />
            <Route
              key="piping-fittings-edit"
              exact
              path={PipingModuleFittingsEditPath.url}
              component={FittingsEditBack}
            />
            <Route
              key="piping-pipes-edit"
              exact
              path={PipingModulePipesEditPath.url}
              component={PipesEditBack}
            />
            <Route
              key="piping-pipes-bends"
              exact
              path={PipingModulePipesBendsPath.url}
              component={PipesBendsBack}
            />
            <Route
              key="piping-pipes-bends-edit"
              exact
              path={PipingModulePipesBendsEditPath.url}
              component={PipesBendsEditBack}
            />
            <Route
              key="piping-pipes-bend-machines"
              exact
              path={PipingModulePipesBendMachinesPath.url}
              component={PipesBendMachinesBack}
            />
            <Route
              key="piping-pipes-bend-machines-edit"
              exact
              path={PipingModulePipesBendMachinesEditPath.url}
              component={PipesBendMachinesEditBack}
            />
            <Route
              key="piping-pipes-bend-springbacks"
              exact
              path={PipingModulePipesBendSpringbacksPath.url}
              component={PipesBendSpringbacksBack}
            />
            <Route
              key="piping-pipes-bend-springbacks-edit"
              exact
              path={PipingModulePipesBendSpringbacksEditPath.url}
              component={PipesBendSpringbacksEditBack}
            />
            <Route
              key="piping-pipes-miters"
              exact
              path={PipingModulePipesMitersPath.url}
              component={PipesMitersBack}
            />
            <Route
              key="piping-pipes-miters-edit"
              exact
              path={PipingModulePipesMitersEditPath.url}
              component={PipesMitersEditBack}
            />
            <Route
              key="piping-specs-fittings-edit"
              exact
              path={PipingModuleSpecsFittingsEditPath.url}
              component={SpecsFittingsEditBack}
            />
            <Route
              key="piping-specs-fitting-instances-edit"
              exact
              path={PipingModuleSpecsFittingInstancesEditPath.url}
              component={SpecsFittingInstancesEditBack}
            />
            <Route
              key="piping-specs-pipes-edit"
              exact
              path={PipingModuleSpecsPipesEditPath.url}
              component={SpecsPipesEditBack}
            />
            <Route
              key="piping-specs-pipe-instances-edit"
              exact
              path={PipingModuleSpecsPipeInstancesEditPath.url}
              component={SpecsPipeInstancesEditBack}
            />
            <Route
              key="piping-projects-edit"
              exact
              path={PipingModuleProjectsEditPath.url}
              component={ProjectsEditBack}
            />
          </Switch>
          <CadActiveBreadcrumbs isLoading={isLoading} />
        </DashboardSuperHeader>

        <DashboardBody flexDirection="column" mr={6} ml={6}>
          <Flex flexDirection="row" mb={4}>
            <Headers>
              <Switch>
                <Route
                  key="standardize-note-profiles-edit-header"
                  exact
                  path={StandardizeModuleNoteProfilesEditPath.url}
                  component={NoteProfilesEditHeader}
                />
              </Switch>
            </Headers>

            <Options>
              <Switch>
                <Route
                  key="standardize-note-profiles-edit-options"
                  exact
                  path={StandardizeModuleNoteProfilesEditPath.url}
                  component={NoteProfilesEditOptions}
                />
              </Switch>
            </Options>
          </Flex>

          <Switch>
            <Route
              key="dashboard"
              exact
              path={DashboardPath.url}
              component={GettingStarted}
            />

            {/* Synchronize */}
            <Route
              key="synchronize-module"
              path={SynchronizeModulePath.url}
              render={() => (
                <SynchronizeModuleBody>
                  <Route
                    key="synchronize-module-appearances"
                    exact
                    path={SynchronizeModuleAppearancesPath.url}
                    component={AppearancesBody}
                  />
                  <Route
                    key="synchronize-module-config-profiles"
                    exact
                    path={SynchronizeModuleConfigProfilesPath.url}
                    component={ConfigProfilesBody}
                  />
                  <Route
                    key="synchronize-module-mapkeys"
                    exact
                    path={SynchronizeModuleMapkeysPath.url}
                    component={MapkeysBody}
                  />
                  <Redirect
                    exact
                    from={SynchronizeModulePath.url}
                    to={DashboardPath.url}
                  />
                  <PageNotFoundRedirect
                    key="synchronize-module-redirect"
                  />
                </SynchronizeModuleBody>
              )}
            />

            {/* Standardize */}
            <Route
              key="standardize-module"
              path={StandardizeModulePath.url}
              render={() => (
                <StandardizeModuleBody>
                  <Route
                    key="standardize-module-layers"
                    exact
                    path={StandardizeModuleLayersPath.url}
                    component={LayersBody}
                  />
                  <Route
                    key="standardize-module-note-profiles"
                    exact
                    path={StandardizeModuleNoteProfilesPath.url}
                    component={NoteProfilesBody}
                  />
                  <Route
                    key="standardize-module-note-profiles-edit"
                    exact
                    path={StandardizeModuleNoteProfilesEditPath.url}
                    component={NoteProfilesEditBody}
                  />
                  <Route
                    key="standardize-module-notes"
                    exact
                    path={StandardizeModuleNotesPath.url}
                    component={NotesBody}
                  />
                  <Route
                    key="standardize-module-orientations"
                    exact
                    path={StandardizeModuleOrientationsPath.url}
                    component={OrientationsBody}
                  />
                  <Route
                    key="standardize-module-parameter-profiles"
                    exact
                    path={StandardizeModuleParameterProfilesPath.url}
                    component={ParameterProfilesBody}
                  />
                  <Route
                    key="standardize-module-parameter-profiles-edit"
                    exact
                    path={StandardizeModuleParameterProfilesEditPath.url}
                    component={ParameterProfilesEditBody}
                  />
                  <Route
                    key="standardize-module-parameters"
                    exact
                    path={StandardizeModuleParametersPath.url}
                    component={ParametersBody}
                  />
                  <Route
                    key="standardize-module-sections"
                    exact
                    path={StandardizeModuleSectionsPath.url}
                    component={SectionsBody}
                  />
                  <Route
                    key="standardize-module-simplified-reps"
                    exact
                    path={StandardizeModuleSimplifiedRepsPath.url}
                    component={SimplifiedRepsBody}
                  />
                  <Route
                    key="standardize-module-model-templates"
                    exact
                    path={StandardizeModuleModelTemplatesPath.url}
                    component={ModelTemplatesBody}
                  />
                  <Route
                    key="standardize-module-explode-states"
                    exact
                    path={StandardizeModuleExplodeStatesPath.url}
                    component={ExplodeStatesBody}
                  />
                  <Route
                    key="standardize-module-combined-states"
                    exact
                    path={StandardizeModuleCombinedStatesPath.url}
                    component={CombinedStateBody}
                  />
                  <Route
                    key="standardize-module-layer-states"
                    exact
                    path={StandardizeModuleLayerStatesPath.url}
                    component={LayerStateBody}
                  />
                  <Route
                    key="standardize-module-appearance-states"
                    exact
                    path={StandardizeModuleAppearanceStatesPath.url}
                    component={AppearanceStateBody}
                  />
                  <Route
                    key="standardize-module-style-states"
                    exact
                    path={StandardizeModuleStyleStatesPath.url}
                    component={StyleStatesBody}
                  />
                  <Redirect
                    key="standardize-module-redirect"
                    exact
                    from={StandardizeModulePath.url}
                    to={DashboardPath.url}
                  />
                  <PageNotFoundRedirect
                    key="standardize-module-switch-redirect"
                  />
                </StandardizeModuleBody>
              )}
            />

            {/* Automate */}
            <Route
              key="automate-module"
              path={AutomateModulePath.url}
              render={() => (
                <AutomateModuleSwitch>
                  <Route
                    key="automate-module-routing-profiles"
                    exact
                    path={AutomateModuleRoutingProfilesPath.url}
                    component={AutomateRoutingProfilesBody}
                  />
                  <Redirect
                    key="automate-module-redirect"
                    exact
                    from={AutomateModulePath.url}
                    to={DashboardPath.url}
                  />
                  <PageNotFoundRedirect
                    key="automate-module-switch-redirect"
                  />
                </AutomateModuleSwitch>
              )}
            />

            {/* Cabling */}
            <Route
              key="cabling-module"
              path={CablingModulePath.url}
              render={() => (
                <CablingModuleSwitch>
                  <Route
                    key="cabling-module-spools"
                    exact
                    path={CablingModuleSpoolsPath.url}
                    component={SpoolsBody}
                  />
                  <Redirect
                    key="cabling-module-redirect"
                    exact
                    from={CablingModulePath.url}
                    to={DashboardPath.url}
                  />
                  <PageNotFoundRedirect
                    key="cabling-module-switch-redirect"
                  />
                </CablingModuleSwitch>
              )}
            />

            {/* Piping */}
            <Route
              key="piping-module"
              path={PipingModulePath.url}
              render={() => (
                <PipingModuleBody>
                  <Route
                    key="piping-module-schedules"
                    exact
                    path={PipingModuleSchedulesPath.url}
                    component={SchedulesBody}
                  />
                  <Route
                    key="piping-module-sizes"
                    exact
                    path={PipingModuleSizesPath.url}
                    component={SizesBody}
                  />
                  <Route
                    key="piping-module-end-types"
                    exact
                    path={PipingModuleEndTypesPath.url}
                    component={EndTypesBody}
                  />
                  <Route
                    key="piping-module-fitting-categories"
                    exact
                    path={PipingModuleFittingCategoriesPath.url}
                    component={FittingCategoriesBody}
                  />
                  <Route
                    key="piping-module-materials"
                    exact
                    path={PipingModuleMaterialsPath.url}
                    component={MaterialsBody}
                  />
                  <Route
                    key="piping-module-bolt-nuts"
                    exact
                    path={PipingModuleBoltNutsPath.url}
                    component={BoltNutsBody}
                  />
                  <Route
                    key="piping-module-bolt-nuts-edit"
                    exact
                    path={PipingModuleBoltNutsEditPath.url}
                    component={BoltNutsEditBody}
                  />
                  <Route
                    key="piping-module-insulations"
                    exact
                    path={PipingModuleInsulationsPath.url}
                    component={InsulationsBody}
                  />
                  <Route
                    key="piping-module-insulations-edit"
                    exact
                    path={PipingModuleInsulationsEditPath.url}
                    component={InsulationsEditBody}
                  />
                  <Route
                    key="piping-module-fittings"
                    exact
                    path={PipingModuleFittingsPath.url}
                    component={FittingsBody}
                  />
                  <Route
                    key="piping-module-fittings-edit"
                    exact
                    path={PipingModuleFittingsEditPath.url}
                    component={FittingsEditBody}
                  />
                  <Route
                    key="piping-module-pipes"
                    exact
                    path={PipingModulePipesPath.url}
                    component={PipesBody}
                  />
                  <Route
                    key="piping-module-pipes-edit"
                    path={PipingModulePipesEditPath.url}
                    render={() => (
                      <PipesEditSwitch>
                        <Route
                          key="piping-module-pipes-edit"
                          exact
                          path={PipingModulePipesEditPath.url}
                          component={PipesEditBody}
                        />
                        <Route
                          key="piping-module-pipes-bends"
                          exact
                          path={PipingModulePipesBendsPath.url}
                          component={PipesBendsBody}
                        />
                        <Route
                          key="piping-module-pipes-bends-edit"
                          exact
                          path={PipingModulePipesBendsEditPath.url}
                          component={PipesBendsEditBody}
                        />
                        <Route
                          key="piping-module-pipes-bend-machines"
                          exact
                          path={PipingModulePipesBendMachinesPath.url}
                          component={PipesBendMachinesBody}
                        />
                        <Route
                          key="piping-module-pipes-bend-machines-edit"
                          exact
                          path={PipingModulePipesBendMachinesEditPath.url}
                          component={PipesBendMachinesEditBody}
                        />
                        <Route
                          key="piping-module-pipes-bend-springbacks"
                          exact
                          path={PipingModulePipesBendSpringbacksPath.url}
                          component={PipesBendSpringbacksBody}
                        />
                        <Route
                          key="piping-module-pipes-bend-springbacks-edit"
                          exact
                          path={PipingModulePipesBendSpringbacksEditPath.url}
                          component={PipesBendSpringbacksEditBody}
                        />
                        <Route
                          key="piping-module-pipes-miters"
                          exact
                          path={PipingModulePipesMitersPath.url}
                          component={PipesMitersBody}
                        />
                        <Route
                          key="piping-module-pipes-miters-edit"
                          exact
                          path={PipingModulePipesMitersEditPath.url}
                          component={PipesMitersEditBody}
                        />
                        <PageNotFoundRedirect
                          key="piping-module-pipes-edit-redirect"
                        />
                      </PipesEditSwitch>
                    )}
                  />
                  <Route
                    key="piping-module-insulation-specs"
                    exact
                    path={PipingModuleInsulationSpecsPath.url}
                    component={InsulationSpecsBody}
                  />
                  <Route
                    key="piping-module-specs"
                    path={PipingModuleSpecsPath.url}
                    render={() => (
                      <SpecsSwitch>
                        <Route
                          key="piping-module-specs"
                          exact
                          path={PipingModuleSpecsPath.url}
                          component={SpecsBody}
                        />
                        <Route
                          key="piping-module-specs-edit"
                          path={PipingModuleSpecsEditPath.url}
                          render={() => (
                            <SpecsEditSwitch>
                              <Redirect
                                key="piping-module-specs-edit-redirect"
                                exact
                                from={PipingModuleSpecsEditPath.url}
                                to={PipingModuleSpecsPipesEditPath.url}
                              />
                              <Route
                                key="piping-module-specs-fittings-edit"
                                exact
                                path={
                                  PipingModuleSpecsFittingsEditPath.url
                                }
                                component={SpecsFittingsEditBody}
                              />
                              <Route
                                key="piping-module-specs-fitting-instances-edit"
                                exact
                                path={
                                  PipingModuleSpecsFittingInstancesEditPath.url
                                }
                                component={SpecsFittingInstancesEditBody}
                              />
                              <Route
                                key="piping-module-specs-pipes-edit"
                                exact
                                path={PipingModuleSpecsPipesEditPath.url}
                                component={SpecsPipesEditBody}
                              />
                              <Route
                                key="piping-module-specs-pipe-instances-edit"
                                exact
                                path={
                                  PipingModuleSpecsPipeInstancesEditPath.url
                                }
                                component={SpecsPipeInstancesEditBody}
                              />
                              <PageNotFoundRedirect
                                key="piping-module-specs-edit-redirect"
                              />
                            </SpecsEditSwitch>
                          )}
                        />
                        <PageNotFoundRedirect
                          key="piping-module-specs-redirect"
                        />
                      </SpecsSwitch>
                    )}
                  />
                  <Route
                    key="piping-module-projects"
                    exact
                    path={PipingModuleProjectsPath.url}
                    component={ProjectsBody}
                  />
                  <Route
                    key="piping-module-projects-edit"
                    exact
                    path={PipingModuleProjectsEditPath.url}
                    component={ProjectsEditBody}
                  />
                  <Redirect
                    key="piping-module-redirect"
                    exact
                    from={PipingModulePath.url}
                    to={DashboardPath.url}
                  />
                  <PageNotFoundRedirect
                    key="piping-module-switch-redirect"
                  />
                </PipingModuleBody>
              )}
            />

            {/* ECAD */}
            <Route
              key="ecad-module"
              path={EcadModulePath.url}
              render={() => (
                <EcadModuleSwitch>
                  <Route
                    key="ecad-module-catalogs"
                    exact
                    path={EcadModuleCatalogsPath.url}
                    component={EcadCatalogsBody}
                  />
                  <Redirect
                    key="ecad-module-redirect"
                    exact
                    from={EcadModulePath.url}
                    to={DashboardPath.url}
                  />
                  <PageNotFoundRedirect
                    key="ecad-module-switch-redirect"
                  />
                </EcadModuleSwitch>
              )}
            />

            {/* Reporting */}
            <Route
              key="reporting-module"
              path={ReportingModulePath.url}
              render={() => (
                <ReportingModuleSwitch>
                  <Route
                    key="reporting-module-templates"
                    exact
                    path={ReportingModuleTemplatesPath.url}
                    component={TemplatesBody}
                  />
                  <Redirect
                    key="reporting-module-redirect"
                    exact
                    from={ReportingModulePath.url}
                    to={DashboardPath.url}
                  />
                  <PageNotFoundRedirect
                    key="reporting-module-switch-redirect"
                  />
                </ReportingModuleSwitch>
              )}
            />

            {/* Framework */}
            <Route
              key="framework-module"
              path={FrameworkModulePath.url}
              render={ () => (
                <FrameworkModuleSwitch>
                  <Route
                    key="framework-module-profile-libraries"
                    exact
                    path={FrameworkModuleProfileLibrariesPath.url}
                    component={FrameworkProfileLibrariesBody}
                  />
                  <Route
                    key="framework-module-profile"
                    exact
                    path={FrameworkModuleProfilePath.url}
                    component={FrameworkProfileBody}
                  />
                  <Route
                    key="framework-module-profile-tables"
                    exact
                    path={FrameworkModuleProfileTablesPath.url}
                    component={FrameworkProfileTablesBody}
                  />
                  <Route
                    key="framework-module-table"
                    exact
                    path={FrameworkModuleTablePath.url}
                    component={FrameworkTableBody}
                  />
                  <Redirect
                    key="framework-module-profile-libraries-redirect"
                    exact
                    from={FrameworkModuleProfileLibrariesBasePath.url}
                    to={FrameworkModuleProfileLibrariesPath.url}
                  />
                  <Redirect
                    key="framework-module-redirect"
                    exact
                    from={FrameworkModulePath.url}
                    to={DashboardPath.url}
                  />
                  <PageNotFoundRedirect
                    key="framework-module-switch-redirect"
                  />
                </FrameworkModuleSwitch>
              )}
            />
            <PageNotFoundRedirect
              key="dashboard-redirect"
            />
          </Switch>

          <Switch>
            {/* Synchronize */}

            {/* Standardize */}
            <Route
              key="standardize-module-note-profiles"
              exact
              path={StandardizeModuleNoteProfilesPath.url}
              component={NoteProfilesNotices}
            />
            <Route
              key="standardize-module-note-profiles-edit"
              exact
              path={StandardizeModuleNoteProfilesEditPath.url}
              component={NoteProfilesEditNotices}
            />
            <Route
              key="standardize-module-parameter-profiles"
              exact
              path={StandardizeModuleParameterProfilesPath.url}
              component={ParameterProfilesNotices}
            />
            <Route
              key="standardize-module-simplified-reps"
              exact
              path={StandardizeModuleSimplifiedRepsPath.url}
              component={SimplifiedRepsNotices}
            />

            {/* ECAD */}

            {/* Piping */}
            <Route
              key="piping-module-insulations"
              exact
              path={PipingModuleInsulationsPath.url}
              component={InsulationsNotices}
            />
            <Route
              key="piping-module-fittings"
              exact
              path={PipingModuleFittingsPath.url}
              component={FittingsNotices}
            />
            <Route
              key="piping-module-pipes"
              exact
              path={PipingModulePipesPath.url}
              component={PipesNotices}
            />
            <Route
              key="piping-module-specs"
              exact
              path={PipingModuleSpecsPath.url}
              component={SpecsNotices}
            />
            <Route
              key="piping-module-specs-fittings-edit"
              exact
              path={PipingModuleSpecsFittingsEditPath.url}
              component={SpecsFittingsEditNotices}
            />
            <Route
              key="piping-module-specs-fitting-instances-edit"
              exact
              path={PipingModuleSpecsFittingInstancesEditPath.url}
              component={SpecsFittingInstancesEditNotices}
            />
            <Route
              key="piping-module-specs-pipes-edit"
              exact
              path={PipingModuleSpecsPipesEditPath.url}
              component={SpecsPipesEditNotices}
            />
            <Route
              key="piping-module-specs-pipe-instances-edit"
              exact
              path={PipingModuleSpecsPipeInstancesEditPath.url}
              component={SpecsPipeInstancesEditNotices}
            />
            <Route
              key="piping-module-projects"
              exact
              path={PipingModuleProjectsPath.url}
              component={ProjectsNotices}
            />
          </Switch>
        </DashboardBody>
      </DashboardPage>
    </DashboardPageContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser(),
  isLoading: selectIsLoadingInitialData(),
  isMinimized: selectSidebarIsMinimized(),
  isFiltering: selectSidebarIsFiltering(),
});

const mapDispatchToProps = (dispatch) => ({
  fetchUnits() {
    dispatch(processFetchAllUnits());
  },
  fetchUnitSystems() {
    dispatch(processFetchAllUnitSystems());
  },
  fetchAppearances() {
    dispatch(processFetchAllAppearances());
  },
  fetchSettings() {
    dispatch(processFetchAllSettings());
  },
  showCurrentUserModal() {
    dispatch(showModal("CURRENT_USER_MODAL"));
  },
  showPrivacyPolicyModal() {
    dispatch(
      showModal("PRIVACY_POLICY_MODAL", { modalSize: MODAL_SIZES.XLARGE })
    );
  },
  showLegalTermsUpdatedModal() {
    dispatch(
      showModal("LEGAL_TERMS_UPDATED_MODAL", { modalSize: MODAL_SIZES.SMALL })
    );
  },
  logout() {
    dispatch(handleLogout());
  },
});

const enhance = compose(
  withRouter,
  injectReducer({ key: "dashboard", reducer: dashboardReducer }),
  connect(mapStateToProps, mapDispatchToProps),
  UserIsAuthenticated,
  UserEmailVerified,
  UserHasOrganization,
  UserIsActive,
  UserHasNoRequiredPasswordResetPending
);

export default enhance(Dashboard);
