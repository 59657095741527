import React from 'react';
import { Helmet } from 'react-helmet';

const CadActiveHelmet = React.memo(({ title }) => (
  <Helmet>
    <title>
      { `CadActive ${ title && `| ${ title }` || '' }` }
    </title>
  </Helmet>
));

export default CadActiveHelmet;
