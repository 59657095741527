import React from 'react';
import styled, { css } from 'styled-components';

import Box from '../../../../components/common/Box';
import Flex from '../../../../components/common/Flex';
import Icon from '../../../../components/common/Icon';

const BaseContainer = styled(Box)`
  position: absolute;
  z-index: 1;
  height: 100vh;
  width: 20rem;
  top: 0;
  bottom: 0;
  left: 0;

  transition: left ${({ theme }) => theme.transitions.speed.fast} ${({ theme }) => theme.transitions.type.inOut};
  will-change: left;

  ${({ $isMinimized, $isFiltering }) =>
    $isMinimized &&
    !$isFiltering &&
    css`
      left: -19rem;
    `}

  ${({ $isMinimized, $isHovering }) =>
    $isMinimized &&
    $isHovering &&
    css`
      left: 0;
    `}
`;

const Inner = styled(Flex)`
  flex-direction: row;
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.gray[1]};

  transition: padding-top ${({ theme }) => theme.transitions.speed.fast} ${({ theme }) => theme.transitions.type.inOut},
              padding-bottom ${({ theme }) => theme.transitions.speed.fast} ${({ theme }) => theme.transitions.type.inOut},
              background-color 0.2s ease;

  ${({ isMinimized }) =>
    isMinimized &&
    css`
      padding-top: ${({ theme }) => theme.space[4]};
      padding-bottom: ${({ theme }) => theme.space[4]};
    `}
`;

const Header = styled(Box)`
  margin-bottom: ${({ theme }) => theme.space[3]};
  padding: ${({ theme }) => theme.space[5]} ${({ theme }) => theme.space[3]};

  flex-grow: 0;
  flex-shrink: 0;
`;

const Body = styled(Box)`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;

  overflow-x: hidden;
  overflow-y: auto;
`;

const Footer = styled(Box)`
  padding: ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[4]};
  flex-grow: 0;
  flex-shrink: 0;
`;

const ToggleWatcherContainer = styled(Flex)`
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes[3]};
  top: 0;
  bottom: 0;
  left: 100%;
  width: 4rem;
  margin-left: -2rem;

  transition: opacity 0.1s 0.1s ease;
  opacity: 0;

  &:hover,
  &:focus,
  &:active {
    opacity: 1;
  }
`;

const ToggleWatcher = ({ isMinimized, toggle }) => (
  <ToggleWatcherContainer>
    <Flex
      onClick={toggle}
      $flexDirection='column'
      $justifyContent='center'
      $alignItems='center'
      $cursor='pointer'
      $bg='white'
      $border={2}
      $borderRadius={1}
      $height='2rem'
      $width='2rem'
    >
      <Icon
        color='primary.4'
        name={isMinimized ? 'chevron-right' : 'chevron-left'}
      />
    </Flex>
  </ToggleWatcherContainer>
);

const BaseHoverWatcher = styled(Box)`
  position: absolute;
  height: 100vh;
  width: 0;
  top: 0;
  bottom: 0;
  left: 0;

  transition: width ${({ theme }) => theme.transitions.speed.fast} ${({ theme }) => theme.transitions.type.inOut};

  ${
  ({ isActive }) => isActive && css`
      width: 23rem;
    `
}
`;

const Container = React.forwardRef((props, ref) => (
  <BaseContainer
    ref={ref}
    $isMinimized={props.isMinimized}
    $isFiltering={props.isFiltering}
    $isHovering={props.isHovering}
    {...props}
  />
));

const HoverWatcher = React.forwardRef((props, ref) => (
  <BaseHoverWatcher
    ref={ref}
    {...props}
  />
));

const Sidebar = {
  Container,
  Inner,
  Header,
  Body,
  Footer,
  ToggleWatcher,
  HoverWatcher,
};

export default Sidebar;
