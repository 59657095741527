import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import {
  NavLink,
  matchPath,
  withRouter,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { List } from 'immutable';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styled, { css } from 'styled-components';
import { useTheme } from '../../contexts/ThemeContext';

import useEventListener from '../../hooks/useEventListener';
import useFloatingState from '../../hooks/useFloatingState';
import useLatest from '../../hooks/useLatest';
import useToggle from '../../hooks/useToggle';
import useOnClickOutside from '../../hooks/useOnClickOutside';

import Icon from '../../components/common/Icon';
import Box from '../../components/common/Box';
import Flex from '../../components/common/Flex';
import Text from '../../components/common/Text';

import Tooltip, { CONTAINERS } from '../../components/tooltip/Tooltip';
import TooltipBody from '../../components/tooltip/TooltipBody';

import Sidebar from '../templates/Structures/Sidebar';

import { showModal } from '../../modules/modal/actions';

import {
  DashboardPath,
  // Synchronize
  SynchronizeModulePath,
  SynchronizeModuleAppearancesPath,
  SynchronizeModuleMapkeysPath,
  SynchronizeModuleConfigProfilesPath,
  // Standardize
  StandardizeModulePath,
  StandardizeModuleLayersPath,
  StandardizeModuleOrientationsPath,
  StandardizeModuleSectionsPath,
  StandardizeModuleSimplifiedRepsPath,
  StandardizeModuleExplodeStatesPath,
  StandardizeModuleCombinedStatesPath,
  StandardizeModuleParametersPath,
  StandardizeModuleParameterProfilesPath,
  StandardizeModuleModelTemplatesPath,
  StandardizeModuleNotesPath,
  StandardizeModuleNoteProfilesPath,
  StandardizeModuleLayerStatesPath,
  StandardizeModuleAppearanceStatesPath,
  StandardizeModuleStyleStatesPath,
  // Automate
  AutomateModulePath,
  AutomateModuleRoutingProfilesPath,
  // Cabling
  CablingModulePath,
  CablingModuleSpoolsPath,
  // Piping
  PipingModulePath,
  PipingModuleSizesPath,
  PipingModuleSchedulesPath,
  PipingModuleFittingCategoriesPath,
  PipingModuleEndTypesPath,
  PipingModuleMaterialsPath,
  PipingModuleBoltNutsPath,
  PipingModulePipesPath,
  PipingModuleFittingsPath,
  PipingModuleInsulationsPath,
  PipingModuleInsulationSpecsPath,
  PipingModuleSpecsPath,
  PipingModuleProjectsPath,
  // Framework
  FrameworkModulePath,
  FrameworkModuleProfileLibrariesPath,
  // ECAD
  EcadModulePath,
  EcadModuleCatalogsPath,
  // Reporting
  ReportingModulePath,
  ReportingModuleTemplatesPath,
} from '../../paths';

import CadActiveLogoGraphic from '../../components/common/graphics/CadActiveLogoGraphic';

import SynchronizeGraphic from '../../components/common/graphics/SynchronizeGraphic';
import StandardizeGraphic from '../../components/common/graphics/StandardizeGraphic';
import AutomateGraphic from '../../components/common/graphics/AutomateGraphic';

import CableGraphic from '../../components/common/graphics/CableGraphic';
import PipeGraphic from '../../components/common/graphics/PipeGraphic';
import EcadGraphic from '../../components/common/graphics/EcadGraphic';
import FrameworkGraphic from '../../components/common/graphics/FrameworkGraphic';
import ReportingGraphic from '../../components/common/graphics/ReportingGraphic';

import SettingsGraphic from '../../components/common/graphics/SettingsGraphic';
import AstronautGraphic from '../../components/common/graphics/AstronautGraphic';
import DocumentationGraphic from '../../components/common/graphics/DocumentationGraphic';
import MegaphoneGraphic from '../../components/common/graphics/MegaphoneGraphic';
import AboutUsGraphic from '../../components/common/graphics/AboutUsGraphic';
import LegalGraphic from '../../components/common/graphics/LegalGraphic';
import SecurityGraphic from '../../components/common/graphics/SecurityGraphic';
import ThemeToggle from '../../components/common/ThemeToggle';

import { handleLogout } from '../../modules/auth/actions';

import {
  SETTING_PRODUCTS,
} from '../../entities/Settings/model';

import {
  ORGANIZATION_SECTIONS,
} from '../../containers/modal/templates/OrganizationModal';
import SignOutButton from '../../components/management/SignOutButton';

import {
  // Product
  selectCurrentUserHasSynchronizeModule,
  selectCurrentUserHasStandardizeModule,
  selectCurrentUserHasAutomateModule,
  // Modules
  selectCurrentUserHasCablingModule,
  selectCurrentUserHasEcadModule,
  selectCurrentUserHasPipingModule,
  selectCurrentUserHasFrameworkModule,
} from '../../modules/auth/utils/_selectors';

const StyledFlex = styled(Flex)`
  display: flex;
  flex-direction: ${({ $flexDirection }) => $flexDirection || 'row'};
  align-items: ${({ $alignItems }) => $alignItems || 'stretch'};
  justify-content: ${({ $justifyContent }) => $justifyContent || 'flex-start'};
`;

const CadActiveHeader = styled(Box)`
  padding: ${({ theme }) => theme.space[5]} 1rem ${({ theme }) => theme.space[5]} 0;
  flex-grow: 0;
  flex-shrink: 0;
`;

const CadActiveHeaderLogoContainer = styled(Flex)`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.space[2]} 1rem;
  text-decoration: none;
  border-radius: ${({ theme }) => theme.radii[2]};
  background-color: transparent;
  border: 1px solid transparent;
  margin-left: 0rem;

  transition-property: background-color, border-color, margin-left;
  transition-duration: 0.2s, 0.2s, 0.2s;
  transition-timing-function: ease, ease, ease;
  transition-delay: 0s, 0s, 0s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[0]};
    border-color: ${({ theme }) => theme.colors.gray[4]};
  }

  &.active:hover {
    background-color: transparent;
    border-color: transparent;
    cursor: default;
  }

  ${({ $minimized }) =>
    $minimized &&
    css`
      margin-left: 2rem;
  `};
`;

const CadActiveSidebarMenu = styled(Flex)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  border-top: 1px solid transparent;
`;

const SidebarMenuSectionWrapper = styled(Flex)`
  display: flex;
  flex-direction: ${({ $flexDirection }) => $flexDirection || 'column'};
  position: absolute;
  top: 0;
  left: 0;
  flex-grow: 1;
  width: 100%;
  height: 4rem;
  margin-bottom: 2rem;

  opacity: ${({ $isEnabled }) => $isEnabled ? 1 : 0.5};
  transform: translateY(${({ $index }) => $index * 6}rem);
  transition: opacity 0.2s ease, transform 0.2s ease;
  will-change: opacity, transform;
  transition-delay: 0.2s;
  z-index: 1;

  ${({ $hidden }) =>
    $hidden &&
    css`
      opacity: 0;
      transform: translateY(0);
      transition-delay: 0s;
      z-index: 0;
    `}

  ${({ $active }) =>
    $active &&
    css`
      transform: translateY(0);
      transition-delay: 0s;
    `}
`;

const SidebarMenuSectionHeader = styled(Flex)`
  display: flex;
  flex-direction: ${props => props.$flexDirection || 'row'};
  align-items: ${props => props.$alignItems || 'center'};

  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
  height: 5rem;
  padding: ${({ theme }) => theme.space[3]} 1rem ${({ theme }) => theme.space[3]} 4.5rem;
  border-radius: ${({ theme }) => theme.radii[2]};
  border: 1px solid transparent;

  flex-grow: 0;
  flex-shrink: 0;

  background-color: transparent;
  transition: background-color 0.2s, border-color 0.2s;

  ${({ $active }) =>
    !$active &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.colors.gray[0]};
        border-color: ${({ theme }) => theme.colors.gray[3]};
        cursor: pointer;
      }
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      &:hover {
        background-color: transparent;
        border-color: transparent;
      }
    `}
`;

const SidebarMenuSectionHeaderLogoContainer = styled(Box)`
  height: 2rem;
  width: 2rem;
  position: absolute;
  left: 1.625rem;
  z-index: 100;

  transform: scale(1.5) translateX(0.5rem);
  transition: transform 0.2s;
  will-change: transform;

  ${({ $active }) =>
    $active &&
    css`
      transform: scale(1) translateX(-0.875rem);
      transition-delay: 0.2s;
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      &:hover {
        background-color: transparent;
        border-color: transparent;
      }
    `}
`;

const SidebarMenuSectionHeaderTextContainer = styled(Flex)`
  display: flex;
  flex-direction: ${props => props.$flexDirection || 'column'};
  justify-content: ${props => props.$justifyContent || 'center'};
  flex-grow: 1;
`;

const SidebarMenuSectionText = styled(Text)`
  display: ${({ $ellipsis }) => ($ellipsis ? 'block' : 'inline-block')};
  transition: transform 0.2s;
  will-change: transform;

  ${({ $active }) =>
    $active &&
    css`
      transition-delay: 0.2s;
    `}
`;

const SidebarSlideOutBackground = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.gray[0]};
  border-right: 1px solid ${({ theme }) => theme.colors.gray[4]};
  width: 3.625rem;
  z-index: 1;

  transform: translateX(-3.75rem);
  transition: transform 0.2s;
  will-change: transform;

  ${({ $active }) =>
    $active &&
    css`
      transform: translateX(0);
      transition-delay: 0.2s;
    `}
`;

const SidebarMenuUtilityMenu = styled(Flex)`
  display: flex;
  flex-direction: ${props => props.$flexDirection || 'column'};
  justify-content: ${props => props.$justifyContent || 'center'};
  align-items: ${props => props.$alignItems || 'center'};

  border-top-right-radius: ${({ theme }) => theme.radii[2]};
  border-bottom-right-radius: ${({ theme }) => theme.radii[2]};

  position: absolute;
  width: 3.625rem;
  top: 0.5rem;
  left: 0;
  padding-top: 5.5rem;
  padding-bottom: 0.875rem;
  z-index: 99;

  transform: translateX(-3.75rem);
  transition: transform 0.2s;
  will-change: transform;

  ${({ $active }) =>
    $active &&
    css`
      transform: translateX(0);
      transition-delay: 0.2s;
    `}
`;

const SidebarMenuUtilityMenuItems = styled(Box)`
  width: 100%;
`;

const SidebarMenuUtilityMenuItem = styled(Flex)`
  display: flex;
  flex-direction: ${props => props.$flexDirection || 'column'};
  justify-content: ${props => props.$justifyContent || 'center'};
  align-items: ${props => props.$alignItems || 'center'};
  width: 100%;
  padding-top: 0.875rem;
  text-decoration: none;
`;

const SidebarMenuUtilityMenuIconContainer = styled(Box)`
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  line-height: 2rem;
  text-align: center;
  text-decoration: none;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[2]};
    cursor: pointer;
  }
`;

const SidebarMenuUtilityMenuIcon = styled(Icon).attrs(props => ({
  color: 'gray.6'
}))``;

const SidebarMenuSubSections = styled(Box)`
  position: absolute;
  top: 5rem;
  left: 0;
  bottom: 0;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  transform: translateX(-100%);
  transition: transform 0.2s;
  will-change: transform;

  ${({ $active }) =>
    $active &&
    css`
      transform: translateX(0);
      transition-delay: 0.2s;
    `}
`;

const SidebarMenuSubSection = styled(Box)`
  margin-bottom: ${({ theme }) => theme.space[4]};
  padding-bottom: ${({ theme }) => theme.space[4]};
  padding-left: 3.5rem;
`;

const SidebarMenuSubSectionHeader = styled(Text)`
  display: block;
  font-size: ${({ theme }) => theme.fontSizes[0]};
  color: ${({ theme }) => theme.colors.gray[6]};
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.space[2]};
  padding-left: 2rem;
  padding-top: ${({ theme }) => theme.space[1]};
  padding-bottom: ${({ theme }) => theme.space[1]};
  background-color: ${({ theme }) => theme.colors.gray[2]};
  border-right: ${({ theme }) => theme.borders[2]};
`;

const SidebarMenuSubSectionLink = styled(Text).attrs((_) => ({
  as: NavLink,
}))`
  display: block;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  color: ${({ theme }) => theme.colors.gray[7]};
  font-weight: 500;

  margin-bottom: ${({ theme }) => theme.space[1]};
  margin-left: 1rem;
  margin-right: 1rem;
  border-radius: ${({ theme }) => theme.radii[2]};
  padding-top: ${({ theme }) => theme.space[2]};
  padding-bottom: ${({ theme }) => theme.space[2]};
  padding-left: 1rem;
  border: 1px solid transparent;
  text-decoration: none;

  background-color: transparent;
  transition: color 0.2s, background-color 0.2s, border-color 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[2]};
    cursor: pointer;
  }

  &.active {
    color: ${({ theme }) => theme.colors.primary[4]};
    background-color: ${({ theme }) => theme.colors.gray[2]};
    border-color: ${({ theme }) => theme.colors.primary[4]};

    &:hover {
      border-color: ${({ theme }) => theme.colors.primary[4]};
    }
  }
`;

const MenuBackButtonContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  position: absolute;
  top: -0.25rem;
  left: 0;
  z-index: 98;

  transform: translateX(-100%);
  transition: transform 0.2s, opacity 0.2s;
  will-change: transform, opacity;

  ${({ $active }) =>
    $active &&
    css`
      transition-delay: 0.2s;
      transform: translateX(0);
    `}
`;

const MenuBackButton = styled(Box)`
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.125rem;
  text-align: center;
  border-radius: ${({ theme }) => theme.radii[2]};
  color: ${({ theme }) => theme.colors.gray[7]};
  font-size: ${({ theme }) => theme.fontSizes[5]};

  background-color: transparent;
  transition: background-color 0.2s;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.gray[2]};
  }
`;

const CadActiveLogoText = styled(Text)`
  line-height: ${props => props.$lineHeight || 'inherit'};
`;

const InteractiveSidebarMenuSectionText = ({
  active,
  fontSize,
  fontWeight,
  color,
  ellipsis,
  children,
}) => {
  const parentRef = useRef();
  const childRef = useRef();
  const [childWidth, setChildWidth] = useState(null);

  const handleFontLoadedCallback = useCallback(() => {
    if (childRef && childRef.current && parentRef && parentRef.current) {
      if (parentRef.current.clientWidth <= childRef.current.clientWidth) {
        setChildWidth(0);
      } else {
        setChildWidth(
          parentRef.current.clientWidth - childRef.current.clientWidth
        );
      }
    }
  }, [children, setChildWidth]);
  const handleFontLoadedCallbackRef = useLatest(handleFontLoadedCallback);

  useEffect(() => {
    document.fonts.ready.then(handleFontLoadedCallbackRef.current);
  }, []);

  return (
    <Box ref={parentRef}>
      <SidebarMenuSectionText
        ref={childRef}
        $active={active}
        fontSize={fontSize}
        fontWeight={fontWeight}
        color={color}
        $ellipsis={ellipsis && childWidth === 0}
      >
        {children}
      </SidebarMenuSectionText>
    </Box>
  );
};

const SidebarMenuSection = ({
  index,
  graphic,
  section,
  title,
  subtitle,
  active,
  hidden,
  isEnabled = true,
  onSelect,
  onShowModal,
  isLoading,
  utilityMenu,
  children,
}) => {

  const onSectionSelected = useCallback(() => onSelect(section), [
    onSelect,
    section,
  ]);

  return (
    <React.Fragment>
      <SidebarMenuSectionWrapper
        $active={active}
        $hidden={hidden}
        $index={index}
        $isEnabled={isEnabled}
      >
        <SidebarMenuSectionHeader
          $active={active}
          $disabled={!isEnabled}
          onClick={(!active && !hidden && isEnabled && onSectionSelected) || null}
          style={{ cursor: isEnabled ? 'pointer' : 'not-allowed' }}
        >
          <SidebarMenuSectionHeaderLogoContainer
            $active={active}
          >
            {graphic}
          </SidebarMenuSectionHeaderLogoContainer>

          {utilityMenu && (
            <SidebarMenuUtilityMenu
              $active={active}
            >
              <SidebarMenuUtilityMenuItems>
                {utilityMenu.map(
                  ({ name, to, href, target = '_blank', tooltipText, showModal, modalType, modalProps }) => (
                    <SidebarMenuUtilityMenuItem key={name}>
                      <SidebarMenuUtilityMenuIconContainer
                        as={to ? NavLink : href ? 'a' : null}
                        to={to}
                        href={href}
                        target={href ? target : null}
                        onClick={showModal ? () => { onShowModal(modalType, modalProps); } : null}
                      >
                        <SidebarMenuUtilityMenuIcon name={name} />
                      </SidebarMenuUtilityMenuIconContainer>
                    </SidebarMenuUtilityMenuItem>
                  )
                )}
              </SidebarMenuUtilityMenuItems>
            </SidebarMenuUtilityMenu>
          )}

          <SidebarMenuSectionHeaderTextContainer>
            {isLoading ? (
              <Text fontSize={4} fontWeight='400' color='gray.7'>
                <Skeleton />
              </Text>
            ) : (
              <InteractiveSidebarMenuSectionText
                $active={active}
                fontSize={3}
                fontWeight='500'
                color='gray.7'
              >
                {title}
              </InteractiveSidebarMenuSectionText>
            )}
          </SidebarMenuSectionHeaderTextContainer>
        </SidebarMenuSectionHeader>
      </SidebarMenuSectionWrapper>
      <SidebarMenuSubSections $active={active}>
        {children}
      </SidebarMenuSubSections>
    </React.Fragment>
  );
};

const SIDEBAR_SECTIONS = {
  // Product Tiers
  SYNCHRONIZE: SynchronizeModulePath.url,
  STANDARDIZE: StandardizeModulePath.url,
  AUTOMATE: AutomateModulePath.url,
  // Modules
  CABLING: CablingModulePath.url,
  ECAD: EcadModuleCatalogsPath.url,
  PIPING: PipingModulePath.url,
  FRAMEWORK: FrameworkModulePath.url,
  REPORTING: ReportingModuleTemplatesPath.url,
};

const getMatchingSidebarSection = (pathname) =>
  Object.values(SIDEBAR_SECTIONS).find((path) =>
    matchPath(pathname, { path })
  ) || null;

const NAVIGATION = List([
  // PRODUCT TIERS
  {
    graphic: SynchronizeGraphic,
    section: SIDEBAR_SECTIONS.SYNCHRONIZE,
    title: 'Synchronize',
    subtitle: 'Configuration management',
    isVisible: true,
    isEnabled: true,
    utility: List([
      {
        name: 'settings',
        showModal: true,
        modalType: 'ORGANIZATION_MODAL',
        modalProps: { section: ORGANIZATION_SECTIONS.SETTINGS, productTier: SETTING_PRODUCTS.SYNCHRONIZE },
        tooltipText: 'Synchronize Settings',
      },
      {
        name: 'help',
        href: 'https://go.cadactive.com/support/synchronize',
        tooltipText: 'Synchronize Documentation',
      },
    ]),
    sections: List([
      {
        title: 'Entities',
        links: List([
          { title: 'Appearances', to: SynchronizeModuleAppearancesPath.url },
          { title: 'Mapkeys', to: SynchronizeModuleMapkeysPath.url },
          { title: 'Config Profiles', to: SynchronizeModuleConfigProfilesPath.url },
        ]),
      },
    ]),
  },
  {
    graphic: StandardizeGraphic,
    section: SIDEBAR_SECTIONS.STANDARDIZE,
    title: 'Standardize',
    subtitle: 'Model reliability governance',
    isVisible: true,
    isEnabled: true,
    utility: List([
      {
        name: 'settings',
        showModal: true,
        modalType: 'ORGANIZATION_MODAL',
        modalProps: { section: ORGANIZATION_SECTIONS.SETTINGS, productTier: SETTING_PRODUCTS.STANDARDIZE },
        tooltipText: 'Standardize Settings',
      },
      {
        name: 'help',
        href: 'https://go.cadactive.com/support/standardize',
        tooltipText: 'Standardize Documentation',
      },
    ]),
    sections: List([
      {
        title: 'Model Views',
        links: List([
          { title: 'Layers', to: StandardizeModuleLayersPath.url },
          { title: 'Orientations', to: StandardizeModuleOrientationsPath.url },
          {
            title: 'Sections',
            to: StandardizeModuleSectionsPath.url,
          },
          {
            title: 'Simplified Reps',
            to: StandardizeModuleSimplifiedRepsPath.url,
          },
          {
            title: 'Explode States',
            to: StandardizeModuleExplodeStatesPath.url,
          },
          {
            title: 'Layer States',
            to: StandardizeModuleLayerStatesPath.url,
          },
          {
            title: 'Appearance States',
            to: StandardizeModuleAppearanceStatesPath.url
          },
          {
            title: 'Style States',
            to: StandardizeModuleStyleStatesPath.url
          },
          {
            title: 'Combined States',
            to: StandardizeModuleCombinedStatesPath.url,
          },
        ]),
      },
      {
        title: 'Model Metadata',
        links: List([
          { title: 'Parameters', to: StandardizeModuleParametersPath.url },
          {
            title: 'Parameter Profiles',
            to: StandardizeModuleParameterProfilesPath.url,
          },
        ]),
      },
      {
        title: 'Model Details',
        links: List([
          { title: 'Notes', to: StandardizeModuleNotesPath.url },
          {
            title: 'Note Profiles',
            to: StandardizeModuleNoteProfilesPath.url,
          },
          { title: 'Templates', to: StandardizeModuleModelTemplatesPath.url },
        ]),
      },
    ]),
  },
  {
    graphic: AutomateGraphic,
    section: SIDEBAR_SECTIONS.AUTOMATE,
    title: 'Automate',
    subtitle: 'Process automation tools',
    isVisible: true,
    isEnabled: true,
    utility: List([
      {
        name: 'settings',
        showModal: true,
        modalType: 'ORGANIZATION_MODAL',
        modalProps: { section: ORGANIZATION_SECTIONS.SETTINGS, productTier: SETTING_PRODUCTS.AUTOMATE },
        tooltipText: 'Automate Settings',
      },
      {
        name: 'help',
        href: 'https://go.cadactive.com/support/automate',
        tooltipText: 'Automate Documentation',
      },
    ]),
    sections: List([
      {
        title: 'Configuration',
        links: List([
          { title: 'Routing Profiles', to: AutomateModuleRoutingProfilesPath.url },
        ]),
      },
    ]),
  },
  // MODULES
  {
    graphic: CableGraphic,
    section: SIDEBAR_SECTIONS.CABLING,
    title: 'Cabling',
    subtitle: 'Cabling and Harness',
    isVisible: false,
    isEnabled: true,
    utility: List([
      {
        name: 'settings',
        showModal: true,
        modalType: 'ORGANIZATION_MODAL',
        modalProps: { section: ORGANIZATION_SECTIONS.SETTINGS, productTier: SETTING_PRODUCTS.MODULE },
        tooltipText: 'Cabling Settings',
      },
      {
        name: 'help',
        href: 'https://go.cadactive.com/support/cabling',
        tooltipText: 'Cabling Documentation',
      },
    ]),
    sections: List([
      {
        title: 'Configuration',
        links: List([
          { title: 'Spools', to: CablingModuleSpoolsPath.url },
        ]),
      },
    ]),
  },
  {
    graphic: PipeGraphic,
    section: SIDEBAR_SECTIONS.PIPING,
    title: 'Piping',
    subtitle: 'Piping and Hosing',
    isVisible: true,
    isEnabled: true,
    utility: List([
      {
        name: 'settings',
        showModal: true,
        modalType: 'ORGANIZATION_MODAL',
        modalProps: { section: ORGANIZATION_SECTIONS.SETTINGS, productTier: SETTING_PRODUCTS.MODULE },
        tooltipText: 'Piping Settings',
      },
      {
        name: 'help',
        href: 'https://go.cadactive.com/support/piping',
        tooltipText: 'Piping Documentation',
      },
    ]),
    sections: List([
      {
        title: 'Core Elements',
        links: List([
          { title: 'Sizes', to: PipingModuleSizesPath.url },
          { title: 'Schedules', to: PipingModuleSchedulesPath.url },
          {
            title: 'Fitting Categories',
            to: PipingModuleFittingCategoriesPath.url,
          },
          { title: 'End Types', to: PipingModuleEndTypesPath.url },
          { title: 'Materials', to: PipingModuleMaterialsPath.url },
        ]),
      },
      {
        title: 'Piping Catalog',
        links: List([
          { title: 'Pipes', to: PipingModulePipesPath.url },
          { title: 'Fittings', to: PipingModuleFittingsPath.url },
          { title: 'Insulations', to: PipingModuleInsulationsPath.url },
          { title: 'Bolt Nuts', to: PipingModuleBoltNutsPath.url },
        ]),
      },
      {
        title: 'Specs',
        links: List([
          {
            title: 'Insulation Specs',
            to: PipingModuleInsulationSpecsPath.url,
          },
          { title: 'Piping Specs', to: PipingModuleSpecsPath.url },
          { title: 'Projects', to: PipingModuleProjectsPath.url },
        ]),
      },
    ]),
  },
  {
    graphic: EcadGraphic,
    section: SIDEBAR_SECTIONS.ECAD,
    title: 'ECAD',
    subtitle: 'Electronic Design Automation',
    isVisible: false,
    isEnabled: true,
    utility: List([
      {
        name: 'settings',
        showModal: true,
        modalType: 'ORGANIZATION_MODAL',
        modalProps: { section: ORGANIZATION_SECTIONS.SETTINGS, productTier: SETTING_PRODUCTS.MODULE },
        tooltipText: 'ECAD Settings',
      },
      {
        name: 'help',
        href: 'https://go.cadactive.com/support/ecad',
        tooltipText: 'ECAD Documentation',
      },
    ]),
    sections: List([
      {
        title: 'Configuration',
        links: List([
          { title: 'Catalogs', to: EcadModuleCatalogsPath.url },
        ]),
      },
    ]),
  },
  {
    graphic: FrameworkGraphic,
    section: SIDEBAR_SECTIONS.FRAMEWORK,
    title: 'Framework',
    subtitle: 'AFX Framework Management',
    isVisible: true,
    isEnabled: true,
    utility: List([
      {
        name: 'settings',
        showModal: true,
        modalType: 'ORGANIZATION_MODAL',
        modalProps: { section: ORGANIZATION_SECTIONS.SETTINGS, productTier: SETTING_PRODUCTS.STANDARDIZE },
        tooltipText: 'Framework Settings',
      },
      {
        name: 'help',
        href: 'https://go.cadactive.com/support/framework',
        tooltipText: 'Framework Documentation',
      },
    ]),
    sections: List([
      {
        title: 'Libraries',
        links: List([
          { title: 'Profile Libraries', to: FrameworkModuleProfileLibrariesPath.url },
        ]),
      },
    ]),
  },
  {
    graphic: ReportingGraphic,
    section: SIDEBAR_SECTIONS.REPORTING,
    title: 'Reporting',
    subtitle: 'Report Generation',
    isVisible: false,
    isEnabled: true,
    utility: List([
      {
        name: 'settings',
        showModal: true,
        modalType: 'ORGANIZATION_MODAL',
        modalProps: { section: ORGANIZATION_SECTIONS.SETTINGS, productTier: SETTING_PRODUCTS.MODULE },
        tooltipText: 'Reporting Settings',
      },
      {
        name: 'help',
        href: 'https://go.cadactive.com/support/reporting',
        tooltipText: 'Reporting Documentation',
      },
    ]),
    sections: List([
      {
        title: 'Configuration',
        links: List([
          { title: 'Templates', to: ReportingModuleTemplatesPath.url },
        ]),
      },
    ]),
  },
]).filter(item => item.isVisible);

const SidebarOrganizationContainer = styled(Box)`
  position: absolute;
  bottom: -10rem;
  left: 0;
  width: 20rem;
  height: 7rem;
`;

const SidebarOrganization = styled(Flex)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  bottom: 0.75rem;
  left: 1rem;
  width: 18rem;
  height: 4.5rem;
  flex-grow: 1;
  border: 1px solid transparent;
  border-radius: ${({ theme }) => theme.radii[2]};

  transform: translateY(-18rem);
  transition: transform 0.2s, border-color 0.2s, background-color 0.2s;
  will-change: transform;
  z-index: 1;

  ${({ $hidden }) =>
    $hidden &&
    css`
      transform: translateY(8rem);
    `}

  ${({ $hidden }) =>
    !$hidden &&
    css`
      &:hover {
        border-color: ${({ theme }) => theme.colors.gray[3]};
        background-color: ${({ theme }) => theme.colors.gray[0]};
        cursor: pointer;
      }
    `}
`;

const SidebarOrganizationDetails = styled(Flex)`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-left: 1rem;
  height: 6rem;
  padding: ${({ theme }) => theme.space[3]} 1rem ${({ theme }) => theme.space[3]} 4.5rem;

  flex-grow: 0;
  flex-shrink: 0;
`;

const SidebarOrganizationLogoContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 2.5rem;
  position: absolute;
  top: -5.25rem;
  left: 1.5rem;
  z-index: 2;
  border-radius: 50%;
  cursor: pointer;
  padding: ${({ theme }) => theme.space[1]};
  background-color: transparent;

  transform: scale(1.5) translateX(0.5rem) translateY(-6.625rem);
  transition: transform 0.2s, background-color 0.2s;
  will-change: transform;
  ${({ $minimized }) =>
    $minimized &&
    css`
      transform: scale(1) translateX(-1rem) translateY(-5.625rem);
      transition: transform 0.2s 0.2s, background-color 0.2s 0s;

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray[2]};
      }
    `};
`;

const SidebarOrganizationDetailsTextContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

const SidebarUserMenuContainer = styled(Box)`
  position: absolute;
  bottom: -10rem;
  left: 0;
  width: 20rem;
  height: 7rem;
`;

const SidebarUserMenu = styled(Flex)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  bottom: 0.75rem;
  left: 1rem;
  width: 18rem;
  height: 4.5rem;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.colors.gray[1]};
  border: ${({ theme }) => theme.borders[2]};
  border-color: ${({ theme }) => theme.colors.gray[3]};
  border-radius: ${({ theme }) => theme.radii[2]};

  transform: translateY(-11rem);
  transition: transform 0.2s, border-color 0.2s;
  will-change: transform;
  z-index: 1;

  ${({ hidden }) =>
    hidden &&
    css`
      transform: translateY(0rem);
    `}

  ${({ hidden }) =>
    !hidden &&
    css`
      &:hover {
        border-color: ${({ theme }) => theme.colors.primary[4]};
        cursor: pointer;
      }
    `}
`;

const SidebarUserMenuDetails = styled(Flex)`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-left: 1rem;
  height: 6rem;
  padding: ${({ theme }) => theme.space[3]} 1rem ${({ theme }) => theme.space[3]} 4.5rem;

  flex-grow: 0;
  flex-shrink: 0;
`;

const SidebarUserMenuLogoContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 2.5rem;
  position: absolute;
  top: -5.25rem;
  left: 1.5rem;
  z-index: 2;
  border-radius: 50%;
  cursor: pointer;
  padding: ${({ theme }) => theme.space[1]};
  background-color: transparent;

  transform: scale(1.5) translateX(0.5rem) translateY(-2rem);
  transition: transform 0.2s, background-color 0.2s;
  will-change: transform;
  ${({ $minimized }) =>
    $minimized &&
    css`
      transform: scale(1) translateX(-1rem) translateY(-2rem);
      transition: transform 0.2s 0.2s, background-color 0.2s 0s;

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray[2]};
      }
    `};
`;

const SidebarUserMenuDetailsTextContainer = styled(Flex)`
  display: flex;
  flex-direction: ${props => props.$flexDirection || 'column'};
  justify-content: ${props => props.$justifyContent || 'center'};
  flex-grow: 1;
`;

const SidebarUserMenuDetailsIconContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 2rem;
  color: ${({ theme }) => theme.colors.gray[6]};
`;

const ProfileMenuItems = styled(Box)`
  background-color: ${({ theme }) => theme.colors.gray[0]};
  border: 1px solid ${({ theme }) => theme.colors.gray[4]};
  transition: background-color 0.2s ease, border-color 0.2s ease;
`;

const ProfileMenuAccount = styled(Flex)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ theme }) => theme.space[4]} 1rem;
`;

const ProfileMenuItem = styled(Flex)`
  display: flex;
  flex-direction: ${props => props.$flexDirection || 'row'};
  align-items: ${props => props.$alignItems || 'center'};
  justify-content: ${props => props.$justifyContent || 'flex-start'};

  height: 5rem;
  padding: ${({ theme }) => theme.space[2]} 1rem;
  width: 100%;
  text-decoration: none;

  flex-grow: 1;
  flex-shrink: 0;

  background-color: transparent;
  transition: background-color 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[1]};
  }
`;

const SmallProfileMenuItem = styled(Flex)`
  display: flex;
  flex-direction: ${props => props.$flexDirection || 'column'};
  justify-content: ${props => props.$justifyContent || 'center'};
  align-items: ${props => props.$alignItems || 'center'};

  height: 6rem;
  text-decoration: none;
  width: 33.333333%;
  padding-top: ${({ theme }) => theme.space[3]};
  padding-bottom: ${({ theme }) => theme.space[3]};

  background-color: transparent;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[2]};
  }
`;

const ProfileMenuHeader = styled(Box)`
  width: 100%;
  padding: ${({ theme }) => theme.space[2]} 1rem;
  background-color: ${({ theme }) => theme.colors.gray[2]};
  transition: background-color 0.2s ease;
`;

const ProfileMenuHeaderText = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[6]};
`;

const ProfileMenuAccountName = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes[4]};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray[7]};
`;

const ProfileMenuAccountOrg = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray[6]};
`;

const ProfileMenuSmallText = styled(Text)`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray[6]};
`;

const ProfileMenuItemLogo = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 2.5rem;
  color: ${({ theme }) => theme.colors.gray[6]};
  transition: color 0.2s ease;
`;

const ProfileMenuItemText = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

const ProfileMenuItemTitle = styled(Text)`
  text-align: end;
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray[7]};
`;

const ProfileMenuItemSubtitle = styled(Text)`
  text-align: end;
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray[6]};
`;

const ProfileTooltipMenu = React.memo(
  ({ currentUser, closeMenu }) => {
    const bodyRef = useRef();
    const { toggleTheme } = useTheme();

    useOnClickOutside(bodyRef, closeMenu);

    const handleThemeToggle = () => {
      toggleTheme();
    };

    return (
      <TooltipBody ref={bodyRef}>
        <ProfileMenuItems>
          <ProfileMenuAccount>
            <ProfileMenuAccountName>
              {currentUser.fullName}
            </ProfileMenuAccountName>
            <ProfileMenuAccountOrg>
              {currentUser.organization.name}
            </ProfileMenuAccountOrg>
          </ProfileMenuAccount>
          <ProfileMenuHeader>
            <ProfileMenuHeaderText>
              Product
            </ProfileMenuHeaderText>
          </ProfileMenuHeader>
          <ProfileMenuItem
            as='a'
            target='_blank'
            href='//go.cadactive.com/support/web'
          >
            <ProfileMenuItemLogo>
              <DocumentationGraphic />
            </ProfileMenuItemLogo>
            <ProfileMenuItemText>
              <ProfileMenuItemTitle>
                Documentation
              </ProfileMenuItemTitle>
              <ProfileMenuItemSubtitle>
                Need help? Start here
              </ProfileMenuItemSubtitle>
            </ProfileMenuItemText>
          </ProfileMenuItem>

          <ProfileMenuItem
            as='a'
            target='_blank'
            href='//go.cadactive.com/support/release-notes'
          >
            <ProfileMenuItemLogo>
              <MegaphoneGraphic />
            </ProfileMenuItemLogo>
            <ProfileMenuItemText>
              <ProfileMenuItemTitle>
                What&apos;s New?
              </ProfileMenuItemTitle>
              <ProfileMenuItemSubtitle>
                Our latest updates and features
              </ProfileMenuItemSubtitle>
            </ProfileMenuItemText>
          </ProfileMenuItem>

          <ProfileMenuItem
            onClick={handleThemeToggle}
          >
            <ProfileMenuItemLogo>
              <ThemeToggle />
            </ProfileMenuItemLogo>
            <ProfileMenuItemText>
              <ProfileMenuItemTitle>
                Theme
              </ProfileMenuItemTitle>
              <ProfileMenuItemSubtitle>
                Toggle light/dark mode
              </ProfileMenuItemSubtitle>
            </ProfileMenuItemText>
          </ProfileMenuItem>

          <ProfileMenuHeader>
            <ProfileMenuHeaderText>
              Company
            </ProfileMenuHeaderText>
          </ProfileMenuHeader>

          <Flex flexDirection='row'>
            <SmallProfileMenuItem
              as='a'
              target='_blank'
              href='//cadactive.com/press'
            >
              <ProfileMenuItemLogo>
                <AboutUsGraphic />
              </ProfileMenuItemLogo>
              <ProfileMenuItemText>
                <ProfileMenuSmallText>
                  About Us
                </ProfileMenuSmallText>
              </ProfileMenuItemText>
            </SmallProfileMenuItem>

            <SmallProfileMenuItem
              as='a'
              target='_blank'
              href='//cadactive.com/legal'
            >
              <ProfileMenuItemLogo>
                <LegalGraphic />
              </ProfileMenuItemLogo>
              <ProfileMenuItemText>
                <ProfileMenuSmallText>
                  Legal
                </ProfileMenuSmallText>
              </ProfileMenuItemText>
            </SmallProfileMenuItem>

            <SmallProfileMenuItem
              as='a'
              target='_blank'
              href='//cadactive.com/legal'
            >
              <ProfileMenuItemLogo>
                <SecurityGraphic />
              </ProfileMenuItemLogo>
              <ProfileMenuItemText>
                <ProfileMenuSmallText>
                  Privacy
                </ProfileMenuSmallText>
              </ProfileMenuItemText>
            </SmallProfileMenuItem>
          </Flex>

          <ProfileMenuHeader>
            <ProfileMenuHeaderText>
              Profile
            </ProfileMenuHeaderText>
          </ProfileMenuHeader>
          <SignOutButton />
        </ProfileMenuItems>
      </TooltipBody>
    );
  }
);

const CadActiveMenuContainer = styled(Flex)`
  display: flex;
  flex-direction: column;

  position: absolute;
  z-index: 10;
  height: 100vh;
  width: 20rem;
  top: 0;
  bottom: 0;
  left: 0;
`;

const CadActiveMenuContainerBackground = styled(Box)`
  position: absolute;
  z-index: 1;
  height: calc(100% + 1rem);
  width: 20rem;
  top: 0;
  left: 0;

  background-color: ${({ theme }) => theme.colors.gray[1]};
  border-right: ${({ theme }) => theme.borders[2]};
  border-bottom: ${({ theme }) => theme.borders[2]};
  border-bottom-right-radius: ${({ theme }) => theme.radii[2]};

  transform: translateY(-9rem);
  transition: transform 0.2s;
  will-change: transform;

  ${({ $active }) =>
    $active &&
    css`
      transform: translateY(0);
    `}
`;

const DashboardSidebar = ({
  currentUser,
  isLoading,
  isMinimized,
  isFiltering,
  location: { pathname = null },
}) => {
  const hoverWatcherRef = useRef();
  const containerRef = useRef();
  const [isHoveringWhileMinimized, setIsHoveringWhileMinimized] = useState(
    false
  );
  const beginHovering = useCallback(
    () => setIsHoveringWhileMinimized(true),
    []
  );
  useEventListener(hoverWatcherRef, 'mouseenter', beginHovering);

  const stopHovering = useCallback(
    () => setIsHoveringWhileMinimized(false),
    []
  );
  useEventListener(containerRef, 'mouseleave', stopHovering);

  const [currentActiveSection, setCurrentActiveSection] = useState(
    getMatchingSidebarSection(pathname)
  );
  const clearActiveSection = useCallback(() => setCurrentActiveSection(null), [
    setCurrentActiveSection,
  ]);

  useEffect(() => {
    setCurrentActiveSection(getMatchingSidebarSection(pathname));
  }, [pathname, setCurrentActiveSection]);

  const [isProfileMenuVisible, toggleProfileMenuVisible] = useToggle();
  const [profileMenuReference, profileMenuFloating, profileMenuStyle] = useFloatingState({
    placement: 'top',
  });

  const dispatch = useDispatch();

  const onShowModal = useCallback((...args) => {
    dispatch(showModal(...args));
  }, [dispatch]);

  const onShowOrganizationModal = useCallback(() => dispatch(showModal('ORGANIZATION_MODAL')), [dispatch]);

  const logout = useCallback(() => dispatch(handleLogout()), [dispatch]);

  return (
    <Sidebar.Container
      ref={containerRef}
      $isMinimized={isMinimized}
      $isFiltering={isFiltering}
      $isHovering={isHoveringWhileMinimized || isProfileMenuVisible}
    >
      <CadActiveMenuContainer $isMinimized={isMinimized}>
        <CadActiveHeader>
          <StyledFlex
            $flexDirection='row'
            $alignItems='center'
            $justifyContent='center'
            height='2rem'
            position='relative'
          >
            <MenuBackButtonContainer
              $active={currentActiveSection !== null}
              onClick={clearActiveSection}
            >
              <MenuBackButton>
                <Icon name='chevron-left' />
              </MenuBackButton>
            </MenuBackButtonContainer>
            <CadActiveHeaderLogoContainer
              as={NavLink}
              to={DashboardPath.url}
              $minimized={currentActiveSection !== null}
              exact
            >
              <Box
                height='2.5rem'
                width='2.5rem'
                mr={2}
              >
                <CadActiveLogoGraphic />
              </Box>
              <CadActiveLogoText
                fontSize='1.4em'
                fontWeight='500'
                color='gray.7'
              >
                {isLoading ? <Skeleton /> : 'CADACTIVE'}
              </CadActiveLogoText>
            </CadActiveHeaderLogoContainer>
          </StyledFlex>
        </CadActiveHeader>

        <SidebarSlideOutBackground $active={currentActiveSection !== null} />
        <CadActiveSidebarMenu $active={currentActiveSection !== null}>
          {NAVIGATION.map(({
            graphic: GraphicComponent,
            section,
            title,
            subtitle,
            utility,
            sections,
            isEnabled,
          }, index) => (
            <SidebarMenuSection
              key={title}
              index={index}
              graphic={<GraphicComponent />}
              section={section}
              title={title}
              subtitle={subtitle}
              active={currentActiveSection === section}
              hidden={currentActiveSection && currentActiveSection !== section}
              isEnabled={isEnabled}
              onSelect={setCurrentActiveSection}
              onShowModal={onShowModal}
              isLoading={isLoading}
              utilityMenu={utility}
            >
              {sections.map(({ title, links }) => (
                <SidebarMenuSubSection key={title}>
                  <SidebarMenuSubSectionHeader>
                    {title}
                  </SidebarMenuSubSectionHeader>
                  {links.map(({ title, to }) => (
                    <SidebarMenuSubSectionLink key={title} to={to}>
                      {title}
                    </SidebarMenuSubSectionLink>
                  ))}
                </SidebarMenuSubSection>
              ))}
            </SidebarMenuSection>
          ))}
        </CadActiveSidebarMenu>

        {
          currentUser.isAdmin && (
            <SidebarOrganizationContainer>
              <SidebarOrganizationLogoContainer
                $minimized={currentActiveSection !== null}
                onClick={
                  (currentActiveSection !== null && onShowOrganizationModal) ||
                  null
                }
              >
                <SettingsGraphic />
              </SidebarOrganizationLogoContainer>
              <SidebarOrganization
                $hidden={currentActiveSection !== null}
                onClick={
                  (currentActiveSection === null && onShowOrganizationModal) ||
                  null
                }
              >
                <SidebarOrganizationDetails>
                  <SidebarOrganizationDetailsTextContainer>
                    {isLoading ? (
                      <Text fontSize={4} fontWeight='400' color='gray.7'>
                        <Skeleton />
                      </Text>
                    ) : (
                      <InteractiveSidebarMenuSectionText
                        active={currentActiveSection !== null}
                        fontSize={2}
                        fontWeight='500'
                        color='gray.7'
                      >
                        Manage Organization
                      </InteractiveSidebarMenuSectionText>
                    )}
                  </SidebarOrganizationDetailsTextContainer>
                </SidebarOrganizationDetails>
              </SidebarOrganization>
            </SidebarOrganizationContainer>
          )
        }

        <SidebarUserMenuContainer>
          <SidebarUserMenuLogoContainer
            ref={currentActiveSection !== null && profileMenuReference || null}
            $minimized={currentActiveSection !== null}
            onClick={
              (currentActiveSection !== null && toggleProfileMenuVisible) ||
              null
            }
          >
            <AstronautGraphic />
          </SidebarUserMenuLogoContainer>
          <SidebarUserMenu
            ref={currentActiveSection === null && profileMenuReference || null}
            hidden={currentActiveSection !== null}
            onClick={
              (currentActiveSection === null && toggleProfileMenuVisible) ||
              null
            }
          >
            <SidebarUserMenuDetails>
              <SidebarUserMenuDetailsTextContainer>
                {isLoading ? (
                  <Text fontSize={4} fontWeight='400' color='gray.7'>
                    <Skeleton />
                  </Text>
                ) : (
                  <InteractiveSidebarMenuSectionText
                    active={currentActiveSection !== null}
                    fontSize={2}
                    fontWeight='500'
                    color='gray.7'
                  >
                    {currentUser.fullName}
                  </InteractiveSidebarMenuSectionText>
                )}

                {isLoading ? (
                  <Text fontSize={1} fontWeight='400' color='gray.6'>
                    <Skeleton />
                  </Text>
                ) : (
                  <InteractiveSidebarMenuSectionText
                    active={currentActiveSection !== null}
                    fontSize={1}
                    fontWeight='400'
                    color='gray.6'
                    ellipsis
                  >
                    {currentUser.organization.name}
                  </InteractiveSidebarMenuSectionText>
                )}
              </SidebarUserMenuDetailsTextContainer>
              <SidebarUserMenuDetailsIconContainer>
                <Icon name='chevron-right' />
              </SidebarUserMenuDetailsIconContainer>
            </SidebarUserMenuDetails>
          </SidebarUserMenu>
          {isProfileMenuVisible &&
            ReactDOM.createPortal(
              <Tooltip
                ref={profileMenuFloating}
                style={profileMenuStyle}
              >
                <ProfileTooltipMenu
                  currentUser={currentUser}
                  closeMenu={toggleProfileMenuVisible}
                  logout={logout}
                />
              </Tooltip>,
              document.querySelector(CONTAINERS.TOOLTIP)
            )}
        </SidebarUserMenuContainer>
      </CadActiveMenuContainer>

      <Sidebar.HoverWatcher
        ref={hoverWatcherRef}
        $isActive={isMinimized && !isFiltering && !isHoveringWhileMinimized}
      />

      <CadActiveMenuContainerBackground
        $active={currentActiveSection !== null}
      />
    </Sidebar.Container>
  );
};

export default withRouter(DashboardSidebar);
