import React, { useState, useEffect } from 'react';
import { withRouter, useLocation, matchPath } from 'react-router-dom';

// Header imports
import Header from '../../templates/Structures/Header';
import Flex from '../../../components/common/Flex/index';
import Options from '../../templates/Structures/Options';
import {
  ConstructedOptions
} from'./components';

import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

// redux selectors
import {
  selectCurrentTableData,
  selectCurrentParameterOrDimensionColumns,
  selectNormalizedProfileTablesList,
} from '../../../entities/Framework/ProfileTables/selectors';
import {
  selectSidebarIsFiltering,
  selectFrameworkModulePermissionsAndState,
} from '../../Dashboard/selectors';
import { selectIsShowingArchived } from '../../../modules/query/selectors';
import { selectNormalizedProfileParametersListById } from '../../../entities/Framework/ProfileParameters/selectors';
import { selectNormalizedProfileDimensionsListById } from '../../../entities/Framework/ProfileDimensions/selectors';
import { selectCurrentTableRowsById } from '../../../entities/Framework/ProfileTableRows/selectors';
import { selectCurrentTableItems } from '../../../entities/Framework/ProfileTableItems/selectors';


// datatable imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

// Dialog imports
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import Button from '../../../components/common/Button';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { toast } from 'react-toastify';

// fetch processes
import { processFetchTables } from '../../../entities/Framework/ProfileTables/actions';
import { processFetchParameters } from '../../../entities/Framework/ProfileParameters/actions';
import { processFetchDimensions } from '../../../entities/Framework/ProfileDimensions/actions';
import { FrameworkModuleTablePath } from '../../../paths';
import {
  processFetchTableRows,
  processCreateTableRows,
  processEditTableRows,
  processDeleteTableRows
} from '../../../entities/Framework/ProfileTableRows/actions';
import {
  processCreateTableItems,
  processFetchTableItems,
  tableItemCollection,
  processDeleteTableItem
} from '../../../entities/Framework/ProfileTableItems/actions';
import {
  processCreateTableColumn,
  processEditTableColumn,
  processDeleteTableColumn,
  processFetchTableColumns
} from '../../../entities/Framework/ProfileTableColumns/actions';

// general components
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputNumber } from 'primereact/inputnumber';
import Text from '../../../components/common/Text';
import { push } from 'connected-react-router';

const mapStateToProps = createSelector(
  selectFrameworkModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectCurrentTableItems(),
  selectCurrentTableData(),
  selectNormalizedProfileTablesList(),
  selectCurrentParameterOrDimensionColumns(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isShowingArchived,
    items,
    data,
    tables,
    columns,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      data: (!isLoading && data) || null,
      editable: canCollaborate && hasValidLicense,
      items: !isLoading && items || [],
      tables: tables && tables.toArray() || [],
      columns: columns || [{ header: 'No Columns Found', field: 'nocolumns', id: '', columnId: '', columnType: 'subtable' }],
      hasValidLicense,
      canCollaborate,
    };
  },
);

function FrameworkTableBody (props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  // gets info in case of reload
  const location = useLocation();
  const profileTableId = matchPath(location.pathname, { path: FrameworkModuleTablePath.url});

  const profileParameterOptions = useSelector(selectNormalizedProfileParametersListById(props.match.params.id));
  const profileDimensionOptions = useSelector(selectNormalizedProfileDimensionsListById(props.match.params.id));

  const originalRows = useSelector(selectCurrentTableRowsById());
  const useableRows = useSelector(selectCurrentTableRowsById());

  const [rows, setRows] = useState([]);

  const displayValue = (rowdata, field) => {
    let rowVal = rows && rows.find(item => item.rowId == rowdata.rowId) || originalRows.find( item => item.rowId == rowdata.rowId);
    return (<Text>{rowVal && rowVal[`${field}`] || '-'}</Text>);
  };

  const subtableDisplayValue = (rowdata, field) => {
    let rowVal = rows && rows.find(item => item.rowId == rowdata.rowId) || originalRows.find(item => item.rowId == rowdata.rowId);
    let tabledata = reduxProps.tables && reduxProps.tables.find(table => table.id == rowVal[field] ) || {tableName: '-'};
    let subtableLink = FrameworkModuleTablePath.generate({ id: profileTableId.params.id, tableId: `${rowVal[field]}`, libraryId: profileTableId.params.libraryId });

    return (
      <div
        style={{
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <Text style={{ float: 'left', marginRight: '10px', cursor: 'pointer' }} >{tabledata.tableName}</Text>
        {rowVal[field] && <Button icon='edit' style={{ float: 'right' }} onClick={() => tabledata.tableName && dispatch(push(subtableLink))} />}
      </div>
    );
  };

  const selectionStringBody = (rowdata) => {
    let rowVal = rows && rows.find(item => item.rowId == rowdata.rowId) || originalRows.find(item => item.rowId == rowdata.rowId);
    return <div className={rowVal.isInvalid ? 'bg-invalid' : undefined} >{rowVal.selectionString}</div>;
  };

  // CRUD States/vars
  const [ currentChanges, setCurrentChanges ] = useState({
    items: [],
    selectionStrings: [],
    deleteRows: []
  });

  const [ isCreateDialogOpen, setIsCreateDialogueOpen ] = useState(false);
  const [ isColumnDialogOpen, setIsColumnDialogOpen ] = useState(false);
  const [ isDeleteDialogOpen, setIsDeleteDialogOpen ] = useState(false);

  const emptyColumn = {
    id: '',
    columnType: '',
    profileDimensionId: null,
    profileParameterId: null,
    profileSubtableColumnName: null
  };

  const [ currentColumn, setCurrentColumn ] = useState(emptyColumn);

  const addNewColumn = () => {
    setCurrentColumn(emptyColumn);
    setIsCreateDialogueOpen(true);
    setIsColumnDialogOpen(true);
  };

  const editColumn = (columnId) => {
    const _curCol = reduxProps.columns && reduxProps.columns.find(col => col.columnId == columnId) || emptyColumn;
    setCurrentColumn(_curCol);
    setIsCreateDialogueOpen(false);
    setIsColumnDialogOpen(true);
  };

  const rowStyles = (data) => {
    const currentData = rows && rows.find(row => row.rowId == data.rowId) || data;
    return {
      'bg-deleted': currentData.isDeleted,
      'bg-created': (currentData.isCreated || currentData.isEdited ),
    };
  };

  const cancelDialog = () => {
    setIsColumnDialogOpen(false);
    setIsCreateDialogueOpen(false);
  };

  const cancelDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const dialogFooter = () => (
    <>
      {!isCreateDialogOpen && (
        <Button
          error={true}
          onClick={() => {
            cancelDialog();
            setIsDeleteDialogOpen(true);
          }}
          style={{ float: 'left' }}
        >
          Delete
        </Button>
      )}
      <Button
        secondary={true}
        onClick={cancelDialog}
      >
        Cancel
      </Button>
      {isCreateDialogOpen && (
        <Button
          primary={true}
          onClick={() => {
            dispatch(processCreateTableColumn(profileTableId.params.tableId, [currentColumn]));
            cancelDialog();
          }}
        >
          Save
        </Button>
      )}
    </>
  );

  const deleteDialogFooter = () => {
    return (
      <>
        <Button
          error={true}
          onClick={() => {
            dispatch(processDeleteTableColumn(reduxProps.data.id, currentColumn.columnId));
            cancelDeleteDialog();
          }}
        >
          Delete
        </Button>
        <Button
          secondary={true}
          onClick={cancelDeleteDialog}
        >
          Cancel
        </Button>
      </>
    );
  };

  const submitEdits = () => {
    if(!reduxProps.editable) {
      return;
    }

    let createItems = [];
    let collectionItems = [];

    currentChanges.items.forEach(item => {
      const originalItem = reduxProps.items.find(cell => cell.row == item.rowId && cell.column == item.columnId);
      if(originalItem) {
        collectionItems = item.value && item.value != null ?
          [ ...collectionItems,
            {
              id: originalItem.id,
              _destroy: false,
              profile_table_column_id: item.columnId,
              profile_table_row_id: item.rowId,
              value: item.value,
              profile_subtable_id: item.profileSubtableId
            }
          ] :
          dispatch(processDeleteTableItem(profileTableId.params.tableId, originalItem.id) );
      } else {
        createItems = [ ...createItems, {
          profile_table_column_id: item.columnId,
          profile_table_row_id: item.rowId,
          value: item.value,
          profile_subtable_id: item.profileSubtableId
        }];
      }
    });
    createItems && createItems.length > 0 && dispatch(processCreateTableItems(profileTableId.params.tableId, createItems));
    collectionItems && collectionItems.length > 0 && dispatch(tableItemCollection(profileTableId.params.tableId, collectionItems));

    currentChanges.selectionStrings.forEach( row => {
      dispatch(processEditTableRows(profileTableId.params.tableId, { rowId: row.rowId, selection_string: row.value }));
    });

    // tableId, rowId
    currentChanges.deleteRows.forEach( row => {
      dispatch(processDeleteTableRows(profileTableId.params.tableId, row.rowId));
    });

    setCurrentChanges({
      items: [],
      selectionStrings: [],
      deleteRows: []
    });
    setRows([]);

  };

  const handleRowAction = (rowdata, icon) => {
    let _updates = { ...currentChanges };
    let _rows = [ ...rows ];
    if ( icon == 'undo' ) {
      let item = rows && rows.find(item => item.rowId == rowdata.rowId) || originalRows.find(item => item.rowId == rowdata.rowId);
      if (item.isDeleted) {
        _updates.deleteRows = _updates.deleteRows.filter(row => row.rowId != rowdata.rowId);
        setCurrentChanges(_updates);
        _rows.splice(_rows.findIndex(item => item.rowId == rowdata.rowId), 1);
        setRows(_rows);
        return;
      }
      if (item.isEdited) {
        _rows.splice(_rows.findIndex(row => row.rowId == item.rowId), 1);
        setRows(_rows);
        _updates.items = _updates.items.filter(item => item.rowId != rowdata.rowId);
        _updates.selectionStrings = _updates.selectionStrings.filter(item => item.rowId != rowdata.rowId);
        setCurrentChanges(_updates);
        setCurVal(null);
        return;
      }
    }else if(rowdata.rowId != 'NEW_ROW') {
      let rowToDelete = _rows.find(item => item.rowId == rowdata.rowId);
      if(rowToDelete) {
        rowToDelete['isDeleted'] = true;
      } else {
        rowToDelete = { ...originalRows.find(item => item.rowId == rowdata.rowId), isDeleted: true };
        _rows.push(rowToDelete);
      }
      setRows(_rows);
      _updates.deleteRows.push(rowToDelete);
      setCurrentChanges(_updates);
    }
  };

  const rowOptions = (rowdata) => {
    const data = rows && rows.find(row => row.rowId == rowdata.rowId) || rowdata;
    let icon = data.isDeleted || data.isCreated || data.isEdited ? 'undo' : 'delete';
    return (
      <>
        <Button icon={icon} onClick={() => handleRowAction(rowdata, icon)} />
      </>
    );
  };

  // Tooltip Styling
  const contentStyle = { width: 'fit-content' };

  const addColumnButton = () => (
    <>
      <Popup
        trigger={() => (
          <Button
            icon="add"
            secondary={true}
            onClick={addNewColumn}
          />
        )}
        position="bottom center"
        on={['hover']}
        {...{ contentStyle }}
      >
        Create New Column
      </Popup>
    </>
  );

  const editColumnButton = (headerText, columnId, valueType) => {
    if (headerText == 'No Columns Found') {
      return <Text >{headerText}</Text>;
    }
    // I know this isn't pretty, but it won't cause problems and there are other issues to clean up so we will be back
    return (
      <>
        <Popup
          trigger={() => (
            <Text
              onClick={() => editColumn(columnId)}
              style={{ cursor: 'pointer', width: '100%' }}
            >
              {headerText}
            </Text>
          )}
          position='bottom center'
          on={['hover']}
          {...{contentStyle}}
        >
          Click to Edit Column<br />
          Details:<br />
          &nbsp;&nbsp;name:&nbsp;&apos;{headerText}&apos;<br />
          &nbsp;&nbsp;Value Type:&nbsp;&apos;{valueType == 'double' ? 'number' : valueType}&apos;
        </Popup>
      </>
    );
  };

  const onCellEditComplete = (e) => {
    if(!reduxProps.editable) {
      return;
    }

    let _rows = [...rows];
    let newItem = { ...curVal };
    setCurVal(null);

    let _updates = { ...currentChanges };

    if (newItem[`${e.field}`] == originalRows[e.rowIndex][e.field]) {
      if(_rows.find(item => item.rowId == originalRows[e.rowIndex].rowId)){
        _rows.splice(_rows.indexOf(item => item.rowId == originalRows[e.rowIndex].rowId), 1);
      }
      if (_updates.items.find(item => item.rowId == originalRows[e.rowIndex].rowId)){
        _updates.items.splice(_rows.indexOf(item => item.rowId == originalRows[e.rowIndex].rowId), 1);
      } else if (_updates.selectionStrings.find(item => item.rowId == originalRows[e.rowIndex].rowId)) {
        _updates.selectionStrings.splice(_rows.indexOf(item => item.rowId == originalRows[e.rowIndex].rowId), 1);
      }
      setCurrentChanges(_updates);
      return;
    }

    if (e.field == 'selectionString' && originalRows[e.rowIndex].rowId == 'NEW_ROW' && newItem.selectionString != ''){
      dispatch(processCreateTableRows(profileTableId.params.tableId, [{selectionString: newItem.selectionString}]));
    } else if (e.field == 'selectionString' && originalRows[e.rowIndex].rowId == 'NEW_ROW' && newItem.selectionString == '') {
      _rows.push({...newItem, isInvalid: true});
    } else if (originalRows[e.rowIndex].rowId == 'NEW_ROW') {
      toast.error('Enter Selection String to create the row.', { position: toast.POSITION.BOTTOM_CENTER });
    } else {
      let itemToEdit = _rows.find(item => item.rowId == newItem.rowId);
      if(itemToEdit){
        itemToEdit[`${e.field}`] = newItem[`${e.field}`];
        itemToEdit.isEdited = true;
      }else {
        newItem['isEdited'] = true;
        _rows.push(newItem);
      }

      if(e.field == 'selectionString') {
        if (_updates.selectionStrings.find( item => item.rowId == newItem.rowId)){
          _updates.selectionStrings.find(item => item.rowId == newItem.rowId).value = newItem[`${e.field}`];
        } else {
          _updates.selectionStrings.push({ rowId: newItem.rowId, value: newItem[`${e.field}`] });
        }
      } else {
        if (_updates.items.find(item => item.rowId == newItem.rowId && item.columnId == e.field)) {
          _updates.items.find(item => item.rowId == newItem.rowId && item.columnId == e.field).value = newItem[`${e.field}`];
        } else {
          _updates.items.push({ rowId: newItem.rowId, columnId: e.field, value: newItem[`${e.field}`] });
        }
      }
      setCurrentChanges(_updates);
    }
    setRows(_rows);
  };

  const onSubtableSelect = (e, rowId, rowIndex, field) => {
    let _rows = [...rows];
    let _updates = {...currentChanges};

    let itemToEdit = _rows.find(item => item.rowId == rowId);

    if (e.target.value && e.target.value == originalRows[rowIndex][e.field]) {
      if (_rows.find(item => item.rowId == originalRows[rowIndex].rowId)) {
        _rows.splice(_rows.indexOf(item => item.rowId == originalRows[rowIndex].rowId), 1);
      }
      if (_updates.items.find(item => item.rowId == originalRows[rowIndex].rowId)) {
        _updates.items.splice(_rows.indexOf(item => item.rowId == originalRows[rowIndex].rowId), 1);
      } else if (_updates.selectionStrings.find(item => item.rowId == originalRows[rowIndex].rowId)) {
        _updates.selectionStrings.splice(_rows.indexOf(item => item.rowId == originalRows[rowIndex].rowId), 1);
      }
      setCurrentChanges(_updates);
      return;
    }

    if (itemToEdit) {
      itemToEdit[`${field}`] = e.target.value;
      itemToEdit.isEdited = true;
    } else {
      let newItem = {...originalRows[rowIndex]};
      newItem[field] = e.target.value;
      newItem.isEdited = true;
      _rows.push(newItem);
    }

    let itemToUpdate = _updates.items.find(item => item.rowId == rowId && item.columnId == field);
    if (itemToUpdate) {
      itemToUpdate.profileSubtableId = e.target.value;
    } else if (e.target.value == null) {
      _updates.items.push({ rowId: rowId, columnId: field, value: null });
    } else {
      _updates.items.push({ rowId: rowId, columnId: field, profileSubtableId: e.target.value });
    }
    setCurrentChanges(_updates);
    setRows(_rows);
  };

  const handleColumnChange = (e) => {
    let _curCol = {...currentColumn};
    _curCol[e.target.id] = e.value || e.target && e.target.value;
    _curCol.id = e.value || e.target && e.target.value;
    if (_curCol.columnType != currentColumn.columnType) {
      _curCol.profileDimensionId = null;
      _curCol.profileParameterId = null;
      _curCol.profileSubtableColumnName = null;
    }
    setCurrentColumn(_curCol);
  };

  const parameterEditor = () => {
    const currentParameterOptions = reduxProps.columns && profileParameterOptions.filter(option => !reduxProps.columns.find(col => col.id == option.id));
    return (
      <>
        <div className="field">
          <label htmlFor="id" className="font-bold">Select Parameter</label>
          <Dropdown
            id="profileParameterId"
            value={currentColumn.id}
            options={isCreateDialogOpen ? currentParameterOptions.toArray() : profileParameterOptions.toArray()}
            optionLabel='parameterName'
            optionValue='id'
            onChange={(e) => handleColumnChange(e)}
            disabled={!isCreateDialogOpen}
            required
          />
        </div>
      </>
    );
  };

  const dimensionEditor = () => {
    const currentDimensionOptions = reduxProps.columns && profileDimensionOptions.filter(option => !reduxProps.columns.find(col => col.id == option.id));
    return (
      <>
        <div className="field">
          <label htmlFor="id" className="font-bold">Select Dimension</label>
          <Dropdown
            id="profileDimensionId"
            value={currentColumn.id}
            options={isCreateDialogOpen ? currentDimensionOptions.toArray() : profileDimensionOptions.toArray()}
            optionLabel='dimensionName'
            optionValue='id'
            onChange={(e) => handleColumnChange(e)}
            disabled={!isCreateDialogOpen}
            required
          />
        </div>
      </>
    );
  };

  const subtableEditor = () => (
    <div className="field">
      <label htmlFor="id" className="font-bold">Subtable Column Name</label>
      <InputText
        id="profileSubtableColumnName"
        value={currentColumn.profileSubtableColumnName}
        onChange={(e) => handleColumnChange(e)}
        disabled={!isCreateDialogOpen}
        required
      />
    </div>
  );

  const [curVal, setCurVal] = useState();

  const handleCellChange = (value, field) => {
    let _curVal = {...curVal};
    _curVal[`${field}`] = value;
    setCurVal(_curVal);
  };

  const subtableCellEditor = (e, field) => {
    const curRow = { ...(rows && rows.find(item => item.rowId == originalRows[e.rowIndex].rowId && item[field]) || originalRows[e.rowIndex]) };
    let value = curRow && curRow[field];
    const currentSubTable = reduxProps && reduxProps.tables.find(table => table.id == value);
    const currentTable = reduxProps && reduxProps.tables.find(table => table.id == profileTableId.params.tableId);

    let tableOptions = reduxProps && reduxProps.tables.filter(table =>
      !originalRows.find(row => row[field] && row[field] === table.id) &&
      !(rows && rows.find(row => row[field] && row[field] === table.id)) &&
      table.id !== profileTableId.params.tableId &&
      table.id !== currentTable.parentTable &&
      !table.parentTable
    );
    currentSubTable && tableOptions.push(currentSubTable);
    currentSubTable && tableOptions.push({id: null, tableName: 'Remove Subtable'});

    return (
      <Dropdown
        id="table"
        value={value}
        options={tableOptions || []}
        optionLabel='tableName'
        optionValue='id'
        onChange={(ent) => onSubtableSelect(ent, originalRows[e.rowIndex].rowId, e.rowIndex, field)}
        style={{width: '100%'}}
      />
    );
  };

  const selectStringCellEditor = (e) => {
    if (!curVal || (curVal && curVal.rowId != originalRows[e.rowIndex].rowId)) {
      setCurVal({ ...(rows && rows.find(item => item.rowId == originalRows[e.rowIndex].rowId && item['selectionString']) || originalRows[e.rowIndex]) });
    }
    let value = curVal && curVal['selectionString'];

    return <InputText value={value} onChange={(ent) => handleCellChange(ent.target.value, 'selectionString')} style={{ direction: 'ltr' }} />;
  };

  const getColumnEditorByColumnType = (columnType) => {
    switch (columnType) {
    case 'parameter':
      return parameterEditor();
    case 'dimension':
      return dimensionEditor();
    case 'subtable':
      return subtableEditor();
    }
  };

  const getFieldEditor = (e, field, valueType) => {

    if (originalRows[e.rowIndex].rowId == 'NEW_ROW') {
      return (
        <div className='field' >
          <InputText id='defaultValue' placeholder='Selection String Cannot be Blank' disabled />
        </div>
      );
    }

    if (!curVal || (curVal && curVal.rowId != originalRows[e.rowIndex].rowId)){
      setCurVal({...(rows && rows.find(item => item.rowId == originalRows[e.rowIndex].rowId && item[`${field}`]) || originalRows[e.rowIndex])});
    }

    // let value = curVal && curVal[field];

    switch (valueType) {
    case 'integer':
      return (
        <div className='field' >
          <InputNumber
            id='defaultValue'
            value={curVal && curVal[field]}
            onChange={(ent) => handleCellChange(ent.value, field)}
            maxFractionDigits={0}
          />
        </div>
      );
    case 'bool':
      return (
        <div className='field' style={{ paddingBottom: '3px', marginTop: '20px' }} >
          <InputSwitch
            id='defaultValue'
            checked={curVal && curVal[field]}
            onChange={(ent) => handleCellChange(ent.value, field)}
            trueValue={'true'}
            falseValue={'false'}
            style={{ marginLeft: '20px' }}
          />
        </div>
      );
    case 'string':
      return (
        <div className='field' >
          <InputText
            id='defaultValue'
            value={curVal && curVal[field] || ''}
            onChange={(ent) => handleCellChange(ent.target.value, field)}
          />
        </div>
      );
    case 'double':
      return (
        <div className='field' >
          <InputNumber
            id='defaultValue'
            value={curVal && curVal[field]}
            onChange={(ent) => handleCellChange(ent.value, field)}
            minFractionDigits={2}
            maxFractionDigits={5}
          />
        </div>
      );
    }
  };

  useEffect(() => {

    if (!reduxProps.data || !reduxProps.columns) {
      dispatch(processFetchDimensions(profileTableId.params.id));
      dispatch(processFetchParameters(profileTableId.params.id));
      dispatch(processFetchTables(profileTableId.params.id));
    }

    dispatch(processFetchTableColumns(profileTableId.params.tableId));
    dispatch(processFetchTableRows(profileTableId.params.tableId));
    dispatch(processFetchTableItems(profileTableId.params.tableId));

    const twoTables = document.querySelectorAll('div.p-datatable-wrapper');

    twoTables[0].addEventListener('scroll', () => {
      twoTables[1].scrollTop = twoTables[0].scrollTop;
    });
    twoTables[1].addEventListener('scroll', () => {
      twoTables[0].scrollTop = twoTables[1].scrollTop;
    });

    return () => {
      twoTables[0].removeEventListener('scroll', () => {
        twoTables[1].scrollTop = twoTables[0].scrollTop;
      });
      twoTables[1].removeEventListener('scroll', () => {
        twoTables[0].scrollTop = twoTables[1].scrollTop;
      });
    };

  }, [location]);

  return (
    <>
      <Flex flexDirection="row" mb={4}>
        <Header
          title={reduxProps.data && reduxProps.data.tableName || 'Table'}
          isLoading={reduxProps.isLoading}
          subtitle={`${originalRows.length && originalRows.length - 1 || 0} ${(originalRows.length && (originalRows.length - 1) > 1) || originalRows.length-1 == 0 ? 'Rows' : 'Row'}, ${reduxProps.columns.length || 0} ${(!!reduxProps.columns.length && reduxProps.columns.length > 1) || !reduxProps.columns.length ? 'Columns' : 'Column'}`}
        />
        <Options
          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
          pendingValidChanges={ currentChanges.deleteRows.length > 0 || currentChanges.selectionStrings.length > 0 || currentChanges.items.length > 0 }
          updateEntities={submitEdits}
          ConstructedOptions={() => ConstructedOptions(profileTableId.params.id, profileTableId.params.libraryId, dispatch)}
          isLoading={reduxProps.isLoading}
        />
      </Flex>
      <div className='gridBox'>
        <div className='CreoTable' >
          <DataTable
            value={useableRows}
            dataKey='rowId'
            size='normal'

            metaKeySelection={false}

            scrollable
            scrollHeight='flex'

            editMode='cell'

            rowClassName={rowStyles}
          >
            <Column
              field='selectionString'
              header='Selection String'
              alignHeader='right'
              align='left'
              headerStyle={{ paddingLeft: '0px', marginLeft: '0px' }}
              body={(rowdata) => selectionStringBody(rowdata)}
              editor={(e) => selectStringCellEditor(e)}
              onCellEditComplete={onCellEditComplete}
            />
            <Column
              field='id'
              header=''
              alignHeader='right'
              align='right'
              style={{ width: '50px' }}
              body={(rowdata) => rowOptions(rowdata)}
            />
          </DataTable>
        </div>
        <div className='tableEditTable' >
          <DataTable
            value={useableRows}
            size='normal'

            scrollable
            scrollHeight='flex'

            editMode='cell'

            rowClassName={rowStyles}
          >
            {reduxProps.columns.map(column =>
              column.columnType == 'subtable' ?
                <Column
                  key={column.id}
                  field={column.columnId}
                  header={() => editColumnButton(column.header, column.columnId, 'subtable')}
                  body={(rowdata) => subtableDisplayValue(rowdata, column.columnId)}
                  style={{ width: `${100 / reduxProps.columns.length}%`, minWidth: '170px' }}
                  editor={(e) => subtableCellEditor(e, column.columnId)}
                />
                :
                <Column
                  key={column.id}
                  field={column.columnId}
                  header={() => editColumnButton(column.header, column.columnId, column.valueType)}
                  body={(rowdata) => displayValue(rowdata, column.columnId)}
                  style={{ width: `${100 / reduxProps.columns.length}%`, minWidth: '170px' }}
                  editor={(e) => getFieldEditor(e, column.columnId, column.valueType)}
                  onCellEditComplete={onCellEditComplete}
                />
            )}
            <Column field='rowId' header={() => addColumnButton()} style={{ width: '50px' }} body={() => <></>} />
          </DataTable>
        </div>
      </div>

      <Dialog
        visible={reduxProps.editable && isColumnDialogOpen}
        style={{ width: '32rem' }}
        breakpoints={{ '960px': '75vw', '641px': '90vw' }}
        header={isCreateDialogOpen ? 'Create New Column' : 'Edit Column'}
        footer={dialogFooter}
        modal
        className="p-fluid"
        onHide={cancelDialog}
        closable={false}
      >
        <div className="field">
          <label htmlFor="columnType" className="font-bold">
            Column Type
          </label>
          <Dropdown
            id="columnType"
            value={currentColumn.columnType}
            options={[
              { name: 'Parameter', value: 'parameter' },
              { name: 'Dimension', value: 'dimension' },
              { name: 'Subtable', value: 'subtable' }
            ]}
            optionLabel="name"
            onChange={(e) => handleColumnChange(e)}
            disabled={!isCreateDialogOpen}
          />
        </div>
        {currentColumn.columnType && getColumnEditorByColumnType(currentColumn.columnType)}
      </Dialog>

      <Dialog
        visible={reduxProps.editable && isDeleteDialogOpen}
        style={{ width: '32rem' }}
        header={'Delete Column'}
        footer={deleteDialogFooter}
        modal
        className='p-fluid'
        onHide={cancelDeleteDialog}
        closable={false}
      >
        <div>
          <Text>
            Are you sure you want to delete {currentColumn.header}?
          </Text>
          <Text style={{ color: 'red' }}>
            This action will be PERMANENT and CANNOT BE UNDONE.
          </Text>
        </div>
      </Dialog>
    </>
  );
}

export default withRouter(FrameworkTableBody);