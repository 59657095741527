import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Notice from '../../../containers/common/Notice';

import { selectIsFetching } from '../../../modules/utility/selectors';

import { selectPipeFamiliesList } from '../../../entities/Piping/PipeFamilies/selectors';

const mapStateToProps = createStructuredSelector({
  isFetching: selectIsFetching(),
  data: selectPipeFamiliesList(),
});

const enhance = compose(
  connect(mapStateToProps)
);

const PipesNotices = ({ isFetching, data }) => {
  if (isFetching && data && data.size > 0) {
    return (
      <Notice
        id='pipes'
        message='Loading data in the background...'
      />
    );
  }
  return null;
};

export default enhance(PipesNotices);
