import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Link, withRouter } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import Box from '../../components/common/Box';
import Flex from '../../components/common/Flex';
import Text from '../../components/common/Text';

import RegisterForm from '../../containers/form/templates/RegisterForm';

import CadActiveTextLogoGraphic from '../../components/common/graphics/CadActiveTextLogoGraphic';

import ExternalPage from '../templates/ExternalPage';

import { UserIsNotAuthenticated } from '../../modules/auth/utils/routes';

import {
  selectIsFetching,
  selectIsPerforming,
} from '../../modules/utility/selectors';

import {
  selectIsAuthError,
  selectIsAuthSuccess,
} from '../../modules/auth/selectors';

import {
  selectInviteFromToken,
  selectIsInviteValid,
} from './selectors';

import {
  processRegister,
  processAcceptInvite,
} from '../../modules/auth/actions';

import { processFetchOrganizationUserInviteByToken } from '../../entities/OrganizationUserInvites/actions';

import theme from '../../assets/themes/theme';

import {
  LoginPath,
  RegisterPath,
} from '../../paths';

const mapStateToProps = createStructuredSelector({
  isFetching: selectIsFetching(),
  isPerforming: selectIsPerforming(),
  isError: selectIsAuthError(),
  isSuccess: selectIsAuthSuccess(),
});

const enhance = compose(
  withRouter,
  UserIsNotAuthenticated,
);

function Register(props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const invite = useSelector(selectInviteFromToken(props.location.query.token));
  const isInviteValid = useSelector(selectIsInviteValid(props.location.query.token));

  const register = (user) => dispatch(isInviteValid ? processAcceptInvite(user, props.location.query.token) : processRegister(user));

  useEffect( () => {
    props.location.query.token && dispatch(processFetchOrganizationUserInviteByToken(props.location.query.token));
  }, []);

  return (
    <ExternalPage title={RegisterPath.title}>
      <Box width="20rem" ml="auto" mr="auto" mb={4}>
        <CadActiveTextLogoGraphic />
      </Box>

      {
        reduxProps.isSuccess ?
          <React.Fragment>
            <Flex flexDirection='row' justifyContent='center' ml='auto' mr='auto' mb={7}>
              <Text as='span' fontSize={5} color='gray.7'>
                <strong>Email </strong> Sent
              </Text>
            </Flex>

            <Box ml='auto' mr='auto' mb={6}>
              <Text as='p' color='gray.6' fontSize={2} style={{ textAlign: 'center' }} m={0} p={0}>
                You&apos;re almost ready to go! We just sent you a confirmation email - click on the link in the email to verify your account and get started.
              </Text>
            </Box>

            <Flex flexDirection='row' justifyContent='center' ml='auto' mr='auto' mb={8}>
              <Text as={Link} $link to={LoginPath.url}>
                Back to Log In
              </Text>
            </Flex>
          </React.Fragment> :
          <React.Fragment>
            <Flex flexDirection='row' justifyContent='center' ml='auto' mr='auto' mb={7}>
              <Text as='span' fontSize={5} color='gray.7'>
                {
                  reduxProps.isFetching ?
                    <Skeleton /> :
                    <React.Fragment><strong>Create</strong> Account</React.Fragment>
                }
              </Text>
            </Flex>

            <Box ml='auto' mr='auto' mb={5}>
              {
                reduxProps.isFetching ?
                  <Skeleton count={5} /> :
                  <RegisterForm
                    isPerforming={reduxProps.isPerforming}
                    isError={reduxProps.isError}
                    onRDXSubmit={(user) => register(user)}
                    email={isInviteValid && invite.email}
                  />
              }
            </Box>

            <Box ml='auto' mr='auto'>
              <Text as='p' color='gray.7' fontSize={2} style={{ textAlign: 'center' }} m={0} p={0}>
                {
                  reduxProps.isFetching ?
                    <Skeleton /> :
                    <React.Fragment>Already have an account? <Text primary as={reduxProps.isPerforming ? 'span' : Link} $link to={LoginPath.url}>Log in</Text></React.Fragment>
                }
              </Text>
            </Box>
          </React.Fragment>
      }
    </ExternalPage>
  );
}

export default enhance(Register);
