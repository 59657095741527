// : id,
// : pipe_instance_id,
// : stockno,
// : mccs_code,
// : _destroy
export const saveCurrentPipingSpecPipeinstanceChanges = (
  changes,
  saveAction,
) => {
  const collectionsdata = [];
  changes.forEach((item) => {
    if (item.isSelectedForSpec != item.originalRow.isSelectedForSpec && item.isSelectedForSpec) {
      // When adding to spec, only include the pipeInstanceId
      collectionsdata.push({
        pipeInstanceId: item.id,
        // Don't copy over stockno and mccsCode - let them start empty
        // stockno: item.stockno,
        // mccsCode: item.mccsCode,
      });
    } else if (item.isSelectedForSpec != item.originalRow.isSelectedForSpec && !item.isSelectedForSpec) {
      collectionsdata.push({ id: item.id, _destroy: '1' });
    } else if (item.isEdited) {
      // For edits, include all fields
      collectionsdata.push({
        id: item.id,
        pipeInstanceId: item.pipeInstanceId?.id || item.pipeInstanceId,
        stockno: item.stockno,
        mccsCode: item.mccsCode,
      });
    }
  });

  collectionsdata.length > 0 && saveAction(collectionsdata);
};