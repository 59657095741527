import React from 'react';

import Button from '../../Button';

//actions for each row
const RowEndEntityTools = React.forwardRef(({
  rowdata,
  editAction,
  listAction,
  deleteAction,
  copyAction,
  archiveAction,
  unarchiveAction,
  ...props
}, ref) => {
  const hasArchiveButton = archiveAction && unarchiveAction;

  return (
    <div ref={ref} {...props}>
      {editAction && !rowdata.archivedFlag && (
        <Button
          icon="edit"
          onClick={() => editAction(rowdata)}
        />
      )}
      {copyAction && (
        <Button
          icon="copy"
          onClick={() => copyAction(rowdata)}
        />
      )}
      {listAction && (
        <Button
          icon="list"
          onClick={() => listAction(rowdata)}
        />
      )}
      {hasArchiveButton && (
        rowdata.archivedFlag ? (
          <Button
            icon="unarchive"
            $iconFillColor="error.4"
            onClick={() => unarchiveAction(rowdata)}
          />
        ) : (
          <Button
            icon="archive"
            onClick={() => archiveAction(rowdata)}
          />
        )
      )}
      {deleteAction && (
        <Button
          icon="delete"
          onClick={() => deleteAction(rowdata)}
        />
      )}
    </div>
  );
});

export default RowEndEntityTools;