import React from 'react';
import styled from 'styled-components';
import Text from '../../common/Text';

import { getUnitLength } from '../../../entities/Synchronize/UnitSystems/model';

const StyledValueText = styled(Text)`
  float: left;
  width: 75%;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledUnitText = styled.span`
  color: ${({ theme }) => theme.colors.gray[5]};
  float: right;
`;

export default function ({data, unitSystem}) {
  return (
    <div style={{
      minWidth: '5rem',
      maxWidth: '10rem',
      width: '100%'
    }}>
      <StyledValueText>
        {data && getUnitLength(data, unitSystem) || ''}
      </StyledValueText>
      <StyledUnitText>
        {unitSystem?.unitLength?.abbreviation || ''}
      </StyledUnitText>
    </div>
  );
}