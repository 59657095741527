import styled from 'styled-components';

const Key = styled.kbd`
  display: inline-block;
  border: 1px solid ${({ theme }) => theme.colors.gray[4]};
  border-bottom: 3px solid ${({ theme }) => theme.colors.gray[5]};
  border-radius: ${({ theme }) => theme.radii[1]};
  background-color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  color: ${({ theme }) => theme.colors.gray[5]};
  padding: ${({ theme }) => `${theme.space[1]} ${theme.space[2]}`};
  margin: ${({ theme }) => `0 ${theme.space[1]}`};
`;

export default Key;