import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';
import styled from 'styled-components';
import { useTheme } from 'styled-components';

import EK from '../../../../../entities/keys';

import Box from '../../../../../components/common/Box';
import Flex from '../../../../../components/common/Flex';
import Button from '../../../../../components/common/Button';
import Icon from '../../../../../components/common/Icon';

import RDXTextInput from '../../../RDXTextInput';

const SearchIconContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SearchFormInputContainer = styled(Flex)`
  flex-grow: 1;
  height: 4rem;
`;

const SearchFormInputField = styled(Field)`
  height: 100%;
  padding-left: ${({ theme }) => theme.space[4]};
  padding-right: ${({ theme }) => theme.space[4]};
  padding-top: 0 !important;
  padding-bottom: 0 !important;
`;

const SearchFormContainer = styled(Box)`
  flex-grow: 1;
`;

const SearchFormInnerContainer = styled(Flex)`
  flex-direction: row;
  align-items: center;
  padding-right: ${({ theme }) => theme.space[4]};
  padding-left: ${({ theme }) => theme.space[4]};
`;

const SearchForm = ({ handleSubmit, isPerforming, valid }) => {
  const theme = useTheme();

  return (
    <SearchFormContainer as='form' onSubmit={handleSubmit}>
      <SearchFormInnerContainer>
        <SearchIconContainer>
          <Icon
            name="search"
            fontSize={3}
            color="gray.4"
          />
        </SearchIconContainer>
        <SearchFormInputContainer>
          <SearchFormInputField
            component={RDXTextInput}
            name='searchTerm'
            type='text'
            placeholder='Search for...'
            $p='0'
          />
        </SearchFormInputContainer>
        <Button
          type="submit"
          icon='enter'
          $primary
          $transparent
          $subtle
          $large
          $disabled={isPerforming || !valid}
          isPerformingAction={isPerforming}
          style={{
            marginLeft: theme.space[2],
          }}
        />
      </SearchFormInnerContainer>
    </SearchFormContainer>
  );
};

const enhance = compose(
  reduxForm({
    form: `search.${EK.CONFIG_SETTINGS.state}`,
    onSubmit(model, dispatch, props) {
      props.onRDXSubmit(model.get('searchTerm'));
    },
  })
);

export default enhance(SearchForm);
