import React, { useState, useEffect } from 'react';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// custom hooks
import { useOnCellEditComplete } from '../../../hooks/useOnCellEditComplete';

//Grid
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { rowStyles } from '../../../components/primeGrid/RowStyles';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';
import DataTableContainer from '../../../components/primeGrid/DataTableContainer';

// cell editors/bodies
import EntityRowTools from '../../../components/common/EntityTools/RowStartTools';
import Text, { PlaceholderText } from '../../../components/common/Text';
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import UnitValueCellRenderer from '../../../components/grid/UnitValueCellRenderer';

// Header imports
import Options from '../../templates/Structures/Options';
import Header from '../../templates/Structures/Header';
import { pluralize } from '../../../components/common/Header';
import Skeleton from 'react-loading-skeleton';
import Flex from '../../../components/common/Flex';
import { PipingModulePipesBendSpringbacksEditPath } from '../../../paths';

//actions
import {
  processEditBendSpringbackFamilyForPipeFamily,
  processArchiveBendSpringbackFamiliesForPipeFamily,
  processUnarchiveBendSpringbackFamiliesForPipeFamily,
  processFetchIndividualBendSpringbackFamilyForPipeFamily,
  processDeleteBendSpringbackFamilyForPipeFamily
} from '../../../entities/Piping/BendSpringbackFamilies/actions';
import { processFetchIndividualPipeFamily } from '../../../entities/Piping/PipeFamilies/actions';
import { processFetchAllSizes } from '../../../entities/Piping/Sizes/actions';
import { processSaveBendSpringbackInstances } from '../../../entities/Piping/BendSpringbackInstances/actions';

// Bend SpringBackFamily Dialog
import { doRequiredFieldsExist, areFieldsValid } from '../PipesBends/components';
import { VALIDATION_FIELDS } from '../PipesBends/Body';
import { BendDialog, DeleteBendDialog } from '../../../containers/dialog/templates/Piping/BendDialog';

// misc.
import { getUnitLength } from '../../../entities/Synchronize/UnitSystems/model';
import { convertToBendSpringbackInstanceType, saveCurrentBendSpringbackInstanceChanges } from './actions';

// Selectors
import { selectCurrentPipeUnitSystem } from '../PipesEditSwitch/selectors';
import { selectPipingModulePermissionsAndState } from '../../Dashboard/selectors';
import { selectNormalizedSizes } from '../../../entities/Piping/Sizes/selectors';
import { selectSidebarIsFiltering } from '../../Dashboard/selectors';
import {
  selectBendSpringbackInstanceCount,
  selectCurrentFilteredBendSpringbackInstances
} from '../../../entities/Piping/BendSpringbackInstances/selectors';
import { selectCurrentBendSpringBack } from '../../../entities/Piping/BendSpringbackFamilies/selectors';

const mapStateToProps = (springbackFamilyId, pipeFamilyId) => createSelector(
  selectPipingModulePermissionsAndState(),
  selectCurrentBendSpringBack(springbackFamilyId),
  selectCurrentFilteredBendSpringbackInstances(springbackFamilyId),
  selectNormalizedSizes(),
  selectCurrentPipeUnitSystem(pipeFamilyId),
  selectBendSpringbackInstanceCount(springbackFamilyId),
  selectSidebarIsFiltering(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    bend,
    data,
    sizes,
    defaultUnitSystem,
    instanceCount,
    isFiltering,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || instanceCount == 0));
    return {
      ...rest,
      isLoading,
      data: !isLoading && data || [],
      editable: canCollaborate && hasValidLicense && bend && !bend.archivedFlag,
      bend,
      bendId: bend && bend.id || null,
      defaultUnitSystem,
      instanceCount,
      canCollaborate,
      hasValidLicense,
      sizes: sizes && sizes.toList().toArray() || [],
      isFiltering,
    };
  },
);

function BendsEditGrid({ match }) {
  const reduxProps = useSelector(mapStateToProps(match.params.familyId, match.params.id));
  const dispatch = useDispatch();

  // dialog state
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [validation, setValidation] = useState(VALIDATION_FIELDS);
  const [editedBendFamily, setEditedBendFamily] = useState(reduxProps.bend);

  // table state
  const [
    editedRows,
    resetEditedRows,
    currentEditingRow,
    isEditing,
    onBeforeCellEditShow,
    onCellChange,
    onCellEditComplete,
    handleRowAction,
    onDropdownComplete
  ] = useOnCellEditComplete(
    reduxProps.data,
    convertToBendSpringbackInstanceType,
    reduxProps.editable,
    true
  );

  //dialog methods
  const handleEditedDialogChange = (newValue, field) => {
    // called on every edit, needs to be made into a custom hook of some kind
    let _editedRow = { ...editedBendFamily };
    _editedRow[`${field}`] = newValue;
    setEditedBendFamily(_editedRow);
  };

  const saveBendAction = () => {
    // translated here to a format that can be sent to the api, replacing all object fields with their Id.
    setValidation(areFieldsValid(editedBendFamily));
    if (!doRequiredFieldsExist(editedBendFamily)) {
      return;
    }

    const editedRow = {
      id: editedBendFamily.id,
      name: editedBendFamily.name,
    };

    dispatch(processEditBendSpringbackFamilyForPipeFamily(match.params.id, reduxProps.bend.id, editedRow));
    setIsDialogOpen(false);
  };

  //Cell Bodies
  const textValueBody = (rowdata, field, placeholder = '') => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToBendSpringbackInstanceType(rowdata);

    return curRow && curRow[`${field}`] ?
      <Text style={{ minWidth: '100%' }}>{curRow[`${field}`]}</Text> :
      <PlaceholderText style={{ width: '100%' }}>{placeholder}</PlaceholderText>;
  };

  const unitValueBody = (rowdata, field) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToBendSpringbackInstanceType(rowdata);

    return (
      <UnitValueCellRenderer data={curRow[field] || ''} unitSystem={reduxProps.defaultUnitSystem} />
    );
  };

  const sBody = (rowdata, field, placeholder) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToBendSpringbackInstanceType(rowdata);

    return curRow && curRow[field] ?
      <ComplexCellRenderer value={curRow[field]} isCapitalized={true} paddingLeft='0px' /> :
      <PlaceholderText>{placeholder}</PlaceholderText>;
  };

  // Cell Editors
  const unitNumberCellEditor = (field) => (
    <span className='p-input-icon-right' style={{ minWidth: '10rem', width: '100%' }}>
      <Text as='i'>
        {reduxProps.defaultUnitSystem.unitLength.abbreviation || ''}
      </Text>
      <InputNumber
        value={currentEditingRow && getUnitLength(currentEditingRow[field], reduxProps.defaultUnitSystem)}
        onChange={(e) => onCellChange(e.value, field)}
        maxFractionDigits={8}
      />
    </span>
  );

  const numberCellEditor = (field) => (
    <InputNumber
      value={currentEditingRow && currentEditingRow[field]}
      onChange={(e) => onCellChange(e.value, field)}
      maxFractionDigits={8}
    />
  );

  const sizeDropdown = () => {
    return <Dropdown
      value={currentEditingRow && currentEditingRow.sizeProperty}
      options={reduxProps.sizes}
      optionLabel='name'
      filter={true}

      itemTemplate={(item) => ComplexCellRenderer({ value: item, isCapitalized: true })}
      valueTemplate={(item) => ComplexCellRenderer({ value: item, isCapitalized: true })}

      onChange={(e) => onDropdownComplete.current(e.value, 'sizeProperty', currentEditingRow)}
      style={{ width: '100%' }}
    />;
  };

  const getRowTool = (rowdata) => {
    const data = editedRows && editedRows.find(row => row.id == rowdata.id) || reduxProps.data.find((item) => item.id == rowdata.id);
    return (
      <EntityRowTools rowdata={data} handleRowAction={handleRowAction} />
    );
  };

  useEffect(() => {
    dispatch(processFetchIndividualBendSpringbackFamilyForPipeFamily(match.params.id, match.params.familyId));
    dispatch(processFetchIndividualPipeFamily(match.params.id));
    dispatch(processFetchAllSizes());
  }, []);

  return (
    <>
      {!reduxProps.isLoading && <Flex flexDirection="row" mb={4} >
        <Header
          isLoading={reduxProps.isLoading}
          title={(reduxProps.bend && reduxProps.bend.name) || PipingModulePipesBendSpringbacksEditPath.defaultTitle}
          subtitle={reduxProps.isFiltering ?
            `Showing ${reduxProps.data.length}/${reduxProps.instanceCount} ${pluralize('filtered Bend Instance', reduxProps.instanceCount)}` :
            `Showing ${reduxProps.instanceCount} ${pluralize('Bend Instance', reduxProps.instanceCount)} Total`}
        />
        <Options
          updateEntities={() => saveCurrentBendSpringbackInstanceChanges(
            editedRows,
            (data) => { dispatch(processSaveBendSpringbackInstances(reduxProps.bendId, data)); resetEditedRows(); },
            reduxProps.defaultUnitSystem
          )}
          isEditingGrid={isEditing}
          isLoading={reduxProps.isLoading}
          pendingValidChanges={editedRows.length > 0}

          editEntity={() => setIsDialogOpen(true)}
          isArchived={reduxProps.bend?.archivedFlag}
          archiveEntity={() => dispatch(processArchiveBendSpringbackFamiliesForPipeFamily(match.params.id, [reduxProps.bendId]))}
          unarchiveEntity={() => dispatch(processUnarchiveBendSpringbackFamiliesForPipeFamily(match.params.id, [reduxProps.bendId]))}

          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
          canFilter={true}
        />
      </Flex> || <Skeleton style={{ height: '2rem', marginBottom: '1rem' }} />
      }
      <DataTableContainer>
        <DataTable
          reorderableColumns
          value={reduxProps.data}
          tableStyle={{ minWidth: '55rem' }}
          size='normal'
          editMode='cell'
          rowClassName={(data) => data && rowStyles(data, editedRows)}

          scrollable
          scrollHeight='flex'

          removableSort
        >
          <Column
            header=''
            style={{ width: '2%' }}
            body={(rowdata) => getRowTool(rowdata)}
          />
          <Column
            field='sizeProperty'
            header='Size'
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={() => sizeDropdown()}
            body={(rowdata) => sBody(rowdata, 'sizeProperty', 'Select Size')}
            style={{ width: '19%' }}
          />
          <Column
            field='angle'
            header='Angle'
            style={{ width: '19%' }}
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={() => numberCellEditor('angle')}
            onCellEditComplete={(e) => onCellEditComplete.current(e)}
            body={(rowdata) => textValueBody(rowdata, 'angle', 'Angle')}
            sortable
          />
          <Column
            field='radius'
            style={{ width: '19%' }}
            header='Radius'
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={() => unitNumberCellEditor('radius')}
            onCellEditComplete={(e) => onCellEditComplete.current(e)}
            body={(rowdata) => unitValueBody(rowdata, 'radius', '')}
          />
          <Column
            field='springBackLength'
            style={{ width: '19%' }}
            header='SpringBack Length'
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={() => unitNumberCellEditor('springBackLength')}
            onCellEditComplete={(e) => onCellEditComplete.current(e)}
            body={(rowdata) => unitValueBody(rowdata, 'springBackLength', '')}
          />
          <Column
            field='elongationLength'
            style={{ width: '19%' }}
            header='Elongation Length'
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={() => unitNumberCellEditor('elongationLength')}
            onCellEditComplete={(e) => onCellEditComplete.current(e)}
            body={(rowdata) => unitValueBody(rowdata, 'elongationLength', '')}
          />
        </DataTable>
      </DataTableContainer>
      <BendDialog
        currentEditingRow={editedBendFamily || reduxProps.bend}
        editable={reduxProps.editable}
        handleEditedRowChange={handleEditedDialogChange}
        isDialogOpen={isDialogOpen}
        saveAction={saveBendAction}
        cancelDialogs={() => { setIsDialogOpen(false); setEditedBendFamily(reduxProps.bend); }}
        validation={validation}
      />
    </>);
}

export default withRouter(BendsEditGrid);