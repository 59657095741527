import React from 'react';
import styled, { css } from 'styled-components';

import Flex from '../../common/Flex';

const StyledOptionsFilterCount = styled(Flex).attrs(_ => ({
  flexDirection: 'column',
  justifyContent: 'center',
  color: 'gray.6',
  pl: 3,
}))`
  font-size: 0.75rem;
  height: 2rem;
  ${
  ({ $isEmpty }) => !$isEmpty && css`
      border-top: 1px solid ${({ theme }) => theme.colors.gray[4]};
    `
}
`;

const BaseOptionsFilterCount = React.memo(({ width, filteredCount, totalCount }) => (
  <StyledOptionsFilterCount
    style={{ width }}
    $isEmpty={totalCount === 0 || filteredCount === 0}
  >
    {
      totalCount === 0 ?
        'No options available' :
        (
          filteredCount === 0 ?
            `No matches found in ${totalCount} options` : (
              filteredCount === totalCount ?
                `Showing all ${totalCount} options` :
                `Showing ${filteredCount} / ${totalCount} filtered options`
            )
        )
    }
  </StyledOptionsFilterCount>
));

export default BaseOptionsFilterCount;