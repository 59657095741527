import React from 'react';
import styled from 'styled-components';

const ParentBox = styled.div`
  position: relative;
  overflow: hidden;
  ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth};`}
`;

const OverlayBox = styled.div`
  position: absolute;
  background-image: linear-gradient(
    to right,
    ${({ theme }) => `rgba(0, 0, 0, 0), ${theme.colors.gray[0]}`}
  );
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  opacity: 1;
  max-width: 200px;
`;

/**
 * Puts a 0 - 1 opacity component over the content to soften the end of an overflow: hidden
 */
const FadeOverflow = React.forwardRef(({ content, maxWidth, ...props }, ref) => (
  <ParentBox ref={ref} $maxWidth={maxWidth} {...props}>
    {content}
    <OverlayBox />
  </ParentBox>
));

export default FadeOverflow;