import React from 'react';

import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const TinySpinnerBox = styled.svg`
  animation: ${spin} 2s linear infinite;
  width: ${({ theme }) => `calc(2*${theme.space[3]})`};
  height: ${({ theme }) => `calc(2*${theme.space[3]})`};
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -13;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -49;
  }
`;

const TinySpinnerCircle = styled.circle.attrs(({ $color, theme }) => ({
  fill: 'none',
  stroke: $color || theme.colors.gray[4],
}))`
  cx: ${({ theme }) => theme.space[3]};
  cy: ${({ theme }) => theme.space[3]};
  r: ${({ theme }) => theme.space[2]};
  strokeWidth: ${({ theme }) => theme.space[1]};
  stroke-linecap: round;
  animation: ${dash} 1.5s ease-in-out infinite;
`;

const TinySpinner = ({ color, ...props }) => (
  <TinySpinnerBox>
    <TinySpinnerCircle $color={color} {...props} />
  </TinySpinnerBox>
);

export default TinySpinner;
