/**
 *
 * App.react.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a neccessity for you then you can refactor it and remove
 * the linting exception.
 */

import React, { useEffect } from 'react';
import { compose } from 'redux';
import { detect } from 'detect-browser';
import Typekit from 'react-typekit';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import styled, { createGlobalStyle } from 'styled-components';

import theme, { baseHtmlFontSize } from '../../assets/themes/theme';

import { ConfigProvider } from 'react-avatar';

import { ThemeProvider, useTheme } from '../../contexts/ThemeContext';

import ModalRoot from '../../containers/modal/Modal';

import Box from '../../components/common/Box';
import Flex from '../../components/common/Flex';
import Icon from '../../components/common/Icon';
import RouteTracker from '../../components/RouteTracker';

import CadActiveHelmet from '../../components/common/CadActiveHelmet';
import CadActiveSkeletonTheme from '../../components/common/CadActiveSkeletonTheme';
import BrowserNotSupported from '../../components/common/BrowserNotSupported';

import PageNotFoundRedirect from '../../containers/common/PageNotFoundRedirect';

import injectReducer from '../../utils/reducers/injectReducer';
import injectSaga from '../../utils/sagas/injectSaga';

// global reducers
import { reducer as formReducer } from 'redux-form/immutable';
import authReducer from '../../modules/auth/reducer';
import entitiesReducer from '../../modules/entities/reducer';
import draftsReducer from '../../modules/drafts/reducer';
import cacheReducer from '../../modules/cache/reducer';
import modalReducer from '../../modules/modal/reducer';
import statusReducer from '../../modules/status/reducer';
import noticeReducer from '../../modules/notices/reducer';
import gridReducer from '../../modules/grid/reducer';
import queryReducer from '../../modules/query/reducer';
import buttonsReducer from '../../modules/buttons/reducer';

// global sagas
import apiSaga from '../../modules/api/sagas';
import errorSaga from '../../modules/errors/sagas';
import authSaga from '../../modules/auth/sagas';
import entitiesSaga from '../../modules/entities/sagas';
import buttonsSaga from '../../modules/buttons/sagas';

import {
  // SupportRedirectPath,
  LoginPath,
  RegisterPath,
  ForgotPasswordRequestPath,
  ResetPasswordPath,
  AccountDeactivatedPath,
  NoLicensePath,
  EmailNotVerifiedPath,
  VerifyEmailPath,
  AccountSetupPath,
  ServerErrorPath,
  PageNotFoundPath,
  DashboardPath,
} from '../../paths';

import {
  // SUPPORT_REDIRECT_ROUTE_CONTAINER,
  LOGIN_ROUTE_CONTAINER,
  REGISTER_ROUTE_CONTAINER,
  FORGOT_PASSWORD_REQUEST_ROUTE_CONTAINER,
  RESET_PASSWORD_ROUTE_CONTAINER,
  ACCOUNT_DEACTIVATED_ROUTE_CONTAINER,
  NO_LICENSE_ROUTE_CONTAINER,
  EMAIL_NOT_VERIFIED_ROUTE_CONTAINER,
  VERIFY_EMAIL_ROUTE_CONTAINER,
  ACCOUNT_SETUP_ROUTE_CONTAINER,
  SERVER_ERROR_ROUTE_CONTAINER,
  PAGE_NOT_FOUND_ROUTE_CONTAINER,
  DASHBOARD_ROUTE_CONTAINER,
} from './children/routes';

import 'react-toastify/dist/ReactToastify.min.css';

import {
  SELECT_HIGHLIGHTER_CLASS,
  DISABLED_SELECT_HIGHLIGHTER_CLASS,
  REACTDOM_CONTAINERS,
} from './constants';

const toastClassName = 'cadactive-toast';
const bodyClassName = 'cadactive-toast-body';
const progressClassName = 'cadactive-toast-progress';

/**
 * To add any aditional font weights or italics, modify the project in Typekit
 */
const AppStyle = createGlobalStyle`
  html {
    font-size: ${baseHtmlFontSize}px;
    font-weight: 400;
    overflow: hidden;
  }

  body {
    transition: background-color 0.2s ease;
    color: ${({ theme }) => theme.colors.gray[7]};
    background-color: ${({ theme }) => theme.colors.gray[0]};
  }

  body > iframe {
    display: none;
  }

  .wf-inactive {
    font-family: 'arial', sans-serif;
  }

  .wf-active {
    font-family: 'proxima-soft', sans-serif;
  }

  .${DISABLED_SELECT_HIGHLIGHTER_CLASS},
  .${SELECT_HIGHLIGHTER_CLASS} {
    background-color: transparent !important;
    font-weight: bold;
  }

  .${DISABLED_SELECT_HIGHLIGHTER_CLASS} {
    color: ${({ theme }) => theme.colors.gray[6]};
  }

  .${SELECT_HIGHLIGHTER_CLASS} {
    color: ${({ theme }) => theme.colors.primary[4]};
  }

  /* Toastify */
  .Toastify__toast.${toastClassName} {
    padding: 0 ${({ theme }) => theme.space[3]};
    min-height: auto;
    border-radius: ${({ theme }) => theme.radii[1]};
    background: ${({ theme }) => theme.colors.gray[7]};

    & .Toastify__toast-body.${bodyClassName} {
      font-family: 'proxima-soft', sans-serif;
      font-size: ${({ theme }) => theme.fontSizes[1]};

      margin: ${({ theme }) => theme.space[3]} 0 ${({ theme }) => theme.space[3]} 0;
    }
  }
  [data-theme="dark"] {
    .Toastify__toast.${toastClassName} {
      background: ${({ theme }) => theme.colors.gray[3]};
    }
  }

  .Toastify__toast--default.${toastClassName} {
    color: ${({ theme }) => theme.colors.white};

    & .Toastify__progress-bar--default.${progressClassName} {
      background: ${({ theme }) => theme.colors.white};
      height: 4px;
    }
  }

  .Toastify__toast--success.${toastClassName} {
    color: ${({ theme }) => theme.colors.white};

    & .Toastify__progress-bar--success.${progressClassName} {
      background: ${({ theme }) => theme.colors.primary[4]};
      height: 4px;
    }
  }

  .Toastify__toast--error.${toastClassName} {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.error[4]};

    & .Toastify__progress-bar--error.${progressClassName} {
      background: ${({ theme }) => theme.colors.white};
      height: 4px;
    }
  }

  /* reactjs-popup */
  .popup-content {
    background-color: ${({ theme }) => theme.colors.gray[0]} !important;
    color: ${({ theme }) => theme.colors.gray[7]} !important;
    border-radius: ${({ theme }) => theme.radii[1]} !important;
    box-shadow: ${({ theme }) => theme.shadows[2]} !important;
    padding: ${({ theme }) => theme.space[2]} !important;
  }

  .popup-arrow {
    color: ${({ theme }) => theme.colors.gray[0]} !important;
  }

  [data-theme="dark"] {
    .popup-content {
      background-color: ${({ theme }) => theme.colors.gray[2]} !important;
      color: ${({ theme }) => theme.colors.gray[7]} !important;
    }

    .popup-arrow {
      color: ${({ theme }) => theme.colors.gray[2]} !important;
    }
  }
`;

const AppWrapper = styled.div`
  height: 100vh;
  overflow: hidden;
  display: flex;
  background-color: ${({ theme }) => theme.colors.gray[0]};
  color: ${({ theme }) => theme.colors.gray[7]};
  transition: background-color 0.2s ease, color 0.2s ease;
`;

const browser = detect();

const canRenderApp =
  browser &&
  (browser.name === 'chrome' ||
    browser.name === 'firefox' ||
    browser.name === 'safari' ||
    browser.name === 'edge' ||
    browser.name === 'edge-chromium');

const CloseToastButton = ({ closeToast }) => (
  <Flex
    flexDirection="column"
    justifyContent="center"
    ml={2}
    onClick={closeToast}
  >
    <Icon name="close" />
  </Flex>
);

const App = () => {
  const { isDarkMode } = useTheme();
  const SkeletonThemeComponent = CadActiveSkeletonTheme[isDarkMode ? 'Dark' : 'Light'];

  useEffect(() => {
    document.body.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  return (
    <SkeletonThemeComponent theme={theme}>
      <ConfigProvider colors={[theme.colors.primary[4], theme.colors.gray[7]]}>
        <AppWrapper>
          <AppStyle />
          <CadActiveHelmet />
          <RouteTracker />
          <Typekit kitId="gyk0exu" />
          <Box id={REACTDOM_CONTAINERS.TOOLTIP} />
          <Box id={REACTDOM_CONTAINERS.DROPDOWN} />

          {canRenderApp ? (
            <Switch>
              <Redirect from="/" exact to={DashboardPath.url} />
              {/* <Route path={SupportRedirectPath.url} exact component={SUPPORT_REDIRECT_ROUTE_CONTAINER} /> */}
              <Route
                key="login"
                path={LoginPath.url}
                exact
                component={LOGIN_ROUTE_CONTAINER}
              />
              <Route
                key="register"
                path={RegisterPath.url}
                exact
                component={REGISTER_ROUTE_CONTAINER}
              />
              <Route
                key="forgot-password-request"
                path={ForgotPasswordRequestPath.url}
                exact
                component={FORGOT_PASSWORD_REQUEST_ROUTE_CONTAINER}
              />
              <Route
                key="reset-password"
                path={ResetPasswordPath.url}
                exact
                component={RESET_PASSWORD_ROUTE_CONTAINER}
              />
              <Route
                key="account-deactivated"
                path={AccountDeactivatedPath.url}
                exact
                component={ACCOUNT_DEACTIVATED_ROUTE_CONTAINER}
              />
              <Route
                key="no-license"
                path={NoLicensePath.url}
                exact
                component={NO_LICENSE_ROUTE_CONTAINER}
              />
              <Route
                key="email-not-verified"
                path={EmailNotVerifiedPath.url}
                exact
                component={EMAIL_NOT_VERIFIED_ROUTE_CONTAINER}
              />
              <Route
                key="verify-email"
                path={VerifyEmailPath.url}
                exact
                component={VERIFY_EMAIL_ROUTE_CONTAINER}
              />
              <Route
                key="account-setup"
                path={AccountSetupPath.url}
                exact
                component={ACCOUNT_SETUP_ROUTE_CONTAINER}
              />
              <Route
                key="server-error"
                path={ServerErrorPath.url}
                exact
                component={SERVER_ERROR_ROUTE_CONTAINER}
              />
              <Route
                key="page-not-found"
                path={PageNotFoundPath.url}
                exact
                component={PAGE_NOT_FOUND_ROUTE_CONTAINER}
              />

              <Route
                key="dashboard"
                path={DashboardPath.url}
                component={DASHBOARD_ROUTE_CONTAINER}
              />

              <PageNotFoundRedirect
                key="dashboard-redirect"
              />
            </Switch>
          ) : (
            <BrowserNotSupported />
          )}

          <ToastContainer
            autoClose={5000}
            toastClassName={toastClassName}
            bodyClassName={bodyClassName}
            progressClassName={progressClassName}
            closeButton={<CloseToastButton />}
          />

          <ModalRoot />
        </AppWrapper>
      </ConfigProvider>
    </SkeletonThemeComponent>
  );
};

const enhance = compose(
  withRouter,
  injectSaga({ key: 'api', saga: apiSaga }),

  injectReducer({ key: 'modal', reducer: modalReducer }),
  injectReducer({ key: 'cache', reducer: cacheReducer }),
  injectReducer({ key: 'status', reducer: statusReducer }),

  injectSaga({ key: 'error', saga: errorSaga }),

  injectReducer({ key: 'form', reducer: formReducer }),

  injectReducer({ key: 'entities', reducer: entitiesReducer }),
  injectSaga({ key: 'entities', saga: entitiesSaga }),

  injectReducer({ key: 'drafts', reducer: draftsReducer }),

  injectReducer({ key: 'auth', reducer: authReducer }),
  injectSaga({ key: 'auth', saga: authSaga }),

  // make sure to update the middleware if this key ever changes
  injectReducer({ key: 'notices', reducer: noticeReducer }),

  injectReducer({ key: 'grid', reducer: gridReducer }),
  injectReducer({ key: 'query', reducer: queryReducer }),

  injectReducer({ key: 'buttons', reducer: buttonsReducer }),
  injectSaga({ key: 'buttons', saga: buttonsSaga })
);

export default enhance(App);
