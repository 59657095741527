import React, {useState, useEffect, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Checkbox } from 'primereact/checkbox';
import DataTableContainer from '../../../components/primeGrid/DataTableContainer';

// Standard Components
import Options from '../../templates/Structures/Options';

import Flex from '../../../components/common/Flex/index';
import Text from '../../../components/common/Text';
import Box from '../../../components/common/Box/index';
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import TextCell from '../../../components/grid/TextCell';
import RowEndEntityTools from '../../../components/common/EntityTools/RowEndTools';
import { pluralize } from '../../../components/common/Header';

// Custom Components
import {
  dialogFooter,
  deleteDialogFooter,
  nameBody,
  areFieldsValid,
  doRequiredFieldsExist
} from './components';

import Header from './Header';

// Selectors
import {
  selectSidebarIsFiltering,
  selectStandardizeModulePermissionsAndState,
} from '../../Dashboard/selectors';

import { selectIsShowingArchived } from '../../../modules/query/selectors';
import { selectCurrentFilteredModelTemplates, selectCurrentModelTemplatesCount } from '../../../entities/Standardize/ModelTemplates/selectors';

// - Model Metadata
import { selectParameterProfiles } from '../../../entities/Standardize/ParameterProfiles/selectors';
import { selectNoteProfiles } from '../../../entities/Standardize/NoteProfiles/selectors';

// - Model Views
import { selectDenormalizedOrientationsLists } from '../../../entities/Standardize/Orientations/selectors.js';
import { selectDenormalizedLayersLists } from '../../../entities/Standardize/Layers/selectors';
import { selectDenormalizedSectionsLists } from '../../../entities/Standardize/Sections/selectors';
import { selectDenormalizedSimplifiedRepsLists } from '../../../entities/Standardize/SimplifiedReps/selectors';
import { selectDenormalizedCombinedStatesLists } from '../../../entities/Standardize/CombinedStates/selectors';
import { selectDenormalizedAppearanceStatesLists } from '../../../entities/Standardize/AppearanceStates/selectors';
import { selectDenormalizedExplodeStatesLists } from '../../../entities/Standardize/ExplodeStates/selectors';
import { selectDenormalizedLayerStatesLists } from '../../../entities/Standardize/LayerStates/selectors';
import { selectDenormalizedStyleStatesLists } from '../../../entities/Standardize/StyleStates/selectors';

// Actions
import {
  processFetchAllModelTemplates,
  processCreateModelTemplate,
  processCopyModelTemplate,
  processEditModelTemplate,
  processDeleteModelTemplate,
  processArchiveModelTemplates,
  processUnarchiveModelTemplates,
} from '../../../entities/Standardize/ModelTemplates/actions';

// - Model Metadata
import { processFetchAllParameterProfiles } from '../../../entities/Standardize/ParameterProfiles/actions.js';
import { processFetchAllNoteProfiles } from '../../../entities/Standardize/NoteProfiles/actions';

// - Model Views
import { processFetchAllOrientations } from '../../../entities/Standardize/Orientations/actions';
import { processFetchAllLayers } from '../../../entities/Standardize/Layers/actions';
import { processFetchAllSections } from '../../../entities/Standardize/Sections/actions';
import { processFetchAllSimplifiedReps } from '../../../entities/Standardize/SimplifiedReps/actions';
import { processFetchAllCombinedStates } from '../../../entities/Standardize/CombinedStates/actions';
import { processFetchAllAppearanceStates } from '../../../entities/Standardize/AppearanceStates/actions';
import { processFetchAllExplodeStates } from '../../../entities/Standardize/ExplodeStates/actions';
import { processFetchAllLayerStates } from '../../../entities/Standardize/LayerStates/actions';
import { processFetchAllStyleStates } from '../../../entities/Standardize/StyleStates/actions';

// Constants
import {
  CAD_MODEL_TYPES,
  CAD_MODEL_TYPE_OPTIONS,
  CAD_MODEL_ASSEMBLY_SUBTYPE_OPTIONS,
  CAD_MODEL_PART_SUBTYPE_OPTIONS,
  CAD_MODEL_DRAWING_SUBTYPE_OPTIONS,
} from '../../../entities/Standardize/ModelTemplates/model';
import { StandardizeModuleModelTemplatesPath } from '../../../paths';

const CheckboxText = styled(Text)`
  margin-left: ${({ theme }) => theme.space[1]};
`;

const ModelViewLabel = styled(Text)`
  margin-bottom: 0;
  margin-top: ${({ theme }) => theme.space[2]};
  font-weight: 500;
`;

const mapStateToProps = createSelector(
  selectStandardizeModulePermissionsAndState(),
  selectCurrentFilteredModelTemplates(),
  selectSidebarIsFiltering(),
  selectCurrentModelTemplatesCount(),
  selectIsShowingArchived(),
  // - Model Metadata
  selectNoteProfiles(),
  selectParameterProfiles(),
  // - Model Views
  selectDenormalizedOrientationsLists(),
  selectDenormalizedLayersLists(),
  selectDenormalizedSectionsLists(),
  selectDenormalizedSimplifiedRepsLists(),
  selectDenormalizedCombinedStatesLists(),
  selectDenormalizedAppearanceStatesLists(),
  selectDenormalizedExplodeStatesLists(),
  selectDenormalizedLayerStatesLists(),
  selectDenormalizedStyleStatesLists(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    data,
    isFiltering,
    count,
    isShowingArchived,
    // Model Metadata
    noteProfiles,
    parameterProfiles,
    // Model Views
    orientations,
    layers,
    sections,
    simplifiedReps,
    combinedStates,
    appearanceStates,
    explodeStates,
    layerStates,
    styleStates,
  ) => {
    const isLoading = isLoadingInitialData || (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      data: (!isLoading && data && data.toList().toArray()) || [],
      editable: canCollaborate && hasValidLicense,
      isList: true,
      showArchived: isFiltering && isShowingArchived,

      // Model Metadata
      noteProfiles: (!isLoading && noteProfiles && noteProfiles.toList().toArray()) || [],
      parameterProfiles: (!isLoading && parameterProfiles && parameterProfiles.toList().toArray()) || [],

      // Model Views
      orientations: (!isLoading && orientations && orientations.toList().toArray()) || [],
      layers: (!isLoading && layers && layers.toList().toArray()) || [],
      sections: (!isLoading && sections && sections.toList().toArray()) || [],
      simplifiedReps: (!isLoading && simplifiedReps && simplifiedReps.toList().toArray()) || [],
      combinedStates: (!isLoading && combinedStates && combinedStates.toList().toArray()) || [],
      appearanceStates: (!isLoading && appearanceStates && appearanceStates.toList().toArray()) || [],
      explodeStates: (!isLoading && explodeStates && explodeStates.toList().toArray()) || [],
      layerStates: (!isLoading && layerStates && layerStates.toList().toArray()) || [],
      styleStates: (!isLoading && styleStates && styleStates.toList().toArray()) || [],

      isLoading,
      count,
      isFiltering,
      hasValidLicense,
      canCollaborate,
    };
  }
);

const BLANK_TEMPLATE = {
  name: '',
  modelTemplateModelName: '',
  cadModelType: '',
  cadModelAssemblySubtype: '',
  cadModelPartSubtype: '',
  cadModelDrawingSubtype: '',

  // Model Metadata
  modelParameterProfile: '',
  componentParameterProfile: '',
  noteProfile: '',

  // Model Views
  // - Orientations
  orientations: [],
  instructionDeleteNonstandardOrientations: false,
  // - Layers
  layers: [],
  instructionDeleteNonstandardLayers: false,
  instructionCopyLayersFromTemplate: false,
  // - Sections
  sections: [],
  instructionDeleteNonstandardSections: false,
  // - Simplified Reps
  simplifiedReps: [],
  instructionDeleteNonstandardSimplifiedReps: false,
  // - Combined States
  combinedStates: [],
  instructionDeleteNonstandardCombinedStates: false,
  // - Appearance States
  appearanceStates: [],
  instructionDeleteNonstandardAppearanceStates: false,
  // - Explode States
  explodeStates: [],
  instructionDeleteNonstandardExplodeStates: false,
  // - Layer States
  layerStates: [],
  instructionDeleteNonstandardLayerStates: false,
  // - Style States
  styleStates: [],
  instructionDeleteNonstandardStyleStates: false,

  // Model Instructions
  instructionCreateSkeletonComponent: false,
  // Drawing Instructions
  instructionDrawingCopyWithModel: false,
  instructionDrawingReplaceSolid: false,
  instructionDrawingCreateBySolidCombinedStates: false,
};

const SET_VALIDATION_FIELDS = ({cadModelType}) => {
  return {
    name: true,
    modelTemplateModelName: true,
    cadModelType: true,
    ...(cadModelType === CAD_MODEL_TYPES.ASSEMBLY ? {cadModelAssemblySubtype: true} : {}),
    ...(cadModelType === CAD_MODEL_TYPES.PART ? {cadModelPartSubtype: true} : {}),
    ...(cadModelType === CAD_MODEL_TYPES.DRAWING ? {cadModelDrawingSubtype: true} : {}),
  };
};

const safeToArray = (collection) => {
  if (!collection) {
    return [];
  }

  if (Array.isArray(collection)) {
    return collection.map(item => typeof item === 'string' ? item : item.get('id'));
  }

  if (typeof collection.toArray === 'function') {
    const array = collection.toArray();
    // Handle both string IDs and Immutable objects with 'id' property
    return array.map(item => {
      if (typeof item === 'string') return item;
      return item.get ? item.get('id') : item.id;
    });
  }

  return [];
};

export default function ModelTemplatesBody(props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const [isDialogOpen, setIsDialogOpen] = useState(false); // controls if the create/edit/copy dialog is open
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false); // used to determine header text and select 'save' action
  const [isCopyDialogOpen, setIsCopyDialogOpen] = useState(false); // used to determine header text and select 'save' action
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false); // controls if the delete Dialog is open
  const [currentEditingRow, setCurrentEditingRow] = useState(BLANK_TEMPLATE);

  const [validation, setValidation] = useState(SET_VALIDATION_FIELDS(currentEditingRow));

  const getDialogHeader = () => {
    if (isCreateDialogOpen) {
      return 'Create Template';
    } else if (isCopyDialogOpen) {
      return `Create Copy of '${currentEditingRow.name}'`;
    } else {
      return `Edit '${currentEditingRow.name}'`;
    }
  };

  const openCreateDialog = () => {
    setCurrentEditingRow(BLANK_TEMPLATE);
    setIsCreateDialogOpen(true);
    setIsDialogOpen(true);
  };

  const cancelDialogs = () => {
    // used to reset the dialogs
    setCurrentEditingRow(BLANK_TEMPLATE);
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsCopyDialogOpen(false);
    setIsDeleteDialogOpen(false);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setCurrentEditingRow(BLANK_TEMPLATE);
  };

  const saveAction = () => {

    setValidation(areFieldsValid(currentEditingRow));
    if (!doRequiredFieldsExist(currentEditingRow)) {
      return;
    }

    const editedRow = {
      id: currentEditingRow?.id,
      name: currentEditingRow?.name,
      modelTemplateModelName: currentEditingRow?.modelTemplateModelName,
      cadModelType: currentEditingRow?.cadModelType,
      cadModelAssemblySubtype: currentEditingRow?.cadModelAssemblySubtype,
      cadModelPartSubtype: currentEditingRow?.cadModelPartSubtype,
      cadModelDrawingSubtype: currentEditingRow?.cadModelDrawingSubtype,

      // Model Metadata
      // - Parameters
      modelParameterProfileId: currentEditingRow?.modelParameterProfile?.id,
      componentParameterProfileId: currentEditingRow?.componentParameterProfile?.id,

      // - Note Profile
      noteProfileId: currentEditingRow?.noteProfile?.id,

      // Model Views
      // - Orientations
      orientationIds: safeToArray(currentEditingRow?.orientations).filter(id => id != null),
      instructionDeleteNonstandardOrientations: currentEditingRow?.instructionDeleteNonstandardOrientations,

      // - Layers
      layerIds: safeToArray(currentEditingRow?.layers).filter(id => id != null),
      instructionDeleteNonstandardLayers: currentEditingRow?.instructionDeleteNonstandardLayers,
      instructionCopyLayersFromTemplate: currentEditingRow?.instructionCopyLayersFromTemplate,

      // - Sections
      sectionIds: safeToArray(currentEditingRow?.sections).filter(id => id != null),
      instructionDeleteNonstandardSections: currentEditingRow?.instructionDeleteNonstandardSections,

      // - Simplified Reps
      simplifiedRepIds: safeToArray(currentEditingRow?.simplifiedReps).filter(id => id != null),
      instructionDeleteNonstandardSimplifiedReps: currentEditingRow?.instructionDeleteNonstandardSimplifiedReps,

      // - Combined States
      combinedStateIds: safeToArray(currentEditingRow?.combinedStates).filter(id => id != null),
      instructionDeleteNonstandardCombinedStates: currentEditingRow?.instructionDeleteNonstandardCombinedStates,

      // - Appearance States
      appearanceStateIds: safeToArray(currentEditingRow?.appearanceStates).filter(id => id != null),
      instructionDeleteNonstandardAppearanceStates: currentEditingRow?.instructionDeleteNonstandardAppearanceStates,

      // - Explode States
      explodeStateIds: safeToArray(currentEditingRow?.explodeStates).filter(id => id != null),
      instructionDeleteNonstandardExplodeStates: currentEditingRow?.instructionDeleteNonstandardExplodeStates,

      // - Layer States
      layerStateIds: safeToArray(currentEditingRow?.layerStates).filter(id => id != null),
      instructionDeleteNonstandardLayerStates: currentEditingRow?.instructionDeleteNonstandardLayerStates,

      // - Style States
      styleStateIds: safeToArray(currentEditingRow?.styleStates).filter(id => id != null),
      instructionDeleteNonstandardStyleStates: currentEditingRow?.instructionDeleteNonstandardStyleStates,

      // Model Instructions
      instructionCreateSkeletonComponent: currentEditingRow?.instructionCreateSkeletonComponent,

      // Drawing Instructions
      instructionDrawingCopyWithModel: currentEditingRow?.instructionDrawingCopyWithModel,
      instructionDrawingReplaceSolid: currentEditingRow?.instructionDrawingReplaceSolid,
      instructionDrawingCreateBySolidCombinedStates: currentEditingRow?.instructionDrawingCreateBySolidCombinedStates,
    };

    if (isCreateDialogOpen) {
      const { id, ...rest } = editedRow;
      dispatch(processCreateModelTemplate(rest));
    } else {
      dispatch(processEditModelTemplate(currentEditingRow.id, editedRow));
    }
    cancelDialogs();
  };

  const editRowAction = (rowdata) => {

    const currentNonEntityRow = {
      id: rowdata.id,
      name: rowdata.name,
      modelTemplateModelName: rowdata.modelTemplateModelName,
      cadModelType: rowdata.cadModelType,
      cadModelAssemblySubtype: rowdata.cadModelAssemblySubtype,
      cadModelPartSubtype: rowdata.cadModelPartSubtype,
      cadModelDrawingSubtype: rowdata.cadModelDrawingSubtype,

      // Model Metadata
      modelParameterProfile: rowdata.modelParameterProfile,
      componentParameterProfile: rowdata.componentParameterProfile,
      noteProfile: rowdata.noteProfile,

      // Model Views
      orientations: safeToArray(rowdata.orientations),
      instructionDeleteNonstandardOrientations: rowdata.instructionDeleteNonstandardOrientations,

      // - Layers
      layers: safeToArray(rowdata.layers),
      instructionDeleteNonstandardLayers: rowdata.instructionDeleteNonstandardLayers,
      instructionCopyLayersFromTemplate: rowdata.instructionCopyLayersFromTemplate,

      // - Sections
      sections: safeToArray(rowdata.sections),
      instructionDeleteNonstandardSections: rowdata.instructionDeleteNonstandardSections,

      // - Simplified Reps
      simplifiedReps: safeToArray(rowdata.simplifiedReps),
      instructionDeleteNonstandardSimplifiedReps: rowdata.instructionDeleteNonstandardSimplifiedReps,

      // - Combined States
      combinedStates: safeToArray(rowdata.combinedStates),
      instructionDeleteNonstandardCombinedStates: rowdata.instructionDeleteNonstandardCombinedStates,

      // - Appearance States
      appearanceStates: safeToArray(rowdata.appearanceStates),
      instructionDeleteNonstandardAppearanceStates: rowdata.instructionDeleteNonstandardAppearanceStates,

      // - Explode States
      explodeStates: safeToArray(rowdata.explodeStates),
      instructionDeleteNonstandardExplodeStates: rowdata.instructionDeleteNonstandardExplodeStates,

      // - Layer States
      layerStates: safeToArray(rowdata.layerStates),
      instructionDeleteNonstandardLayerStates: rowdata.instructionDeleteNonstandardLayerStates,

      // - Style States
      styleStates: safeToArray(rowdata.styleStates),
      instructionDeleteNonstandardStyleStates: rowdata.instructionDeleteNonstandardStyleStates,

      // Model Instructions
      instructionCreateSkeletonComponent: rowdata.instructionCreateSkeletonComponent,

      // Drawing Instructions
      instructionDrawingCopyWithModel: rowdata.instructionDrawingCopyWithModel,
      instructionDrawingReplaceSolid: rowdata.instructionDrawingReplaceSolid,
      instructionDrawingCreateBySolidCombinedStates: rowdata.instructionDrawingCreateBySolidCombinedStates,
    };

    setCurrentEditingRow(currentNonEntityRow);
    setIsDialogOpen(true);
  };

  const copyRowAction = (rowdata) => {
    const currentNonEntityRow = {
      id: rowdata.id,
      name: rowdata.name,
      modelTemplateModelName: rowdata.modelTemplateModelName,
      cadModelType: rowdata.cadModelType,
      cadModelAssemblySubtype: rowdata.cadModelAssemblySubtype,
      cadModelPartSubtype: rowdata.cadModelPartSubtype,
      cadModelDrawingSubtype: rowdata.cadModelDrawingSubtype,
      modelParameterProfile: rowdata.modelParameterProfile,
      componentParameterProfile: rowdata.componentParameterProfile,
      // Model Metadata
      noteProfile: rowdata.noteProfile,
      // Model Views
      orientations: rowdata.orientations.toArray(),
      layers: rowdata.layers.toArray(),
      sections: rowdata.sections.toArray(),
      simplifiedReps: rowdata.simplifiedReps.toArray(),
      combinedStates: rowdata.combinedStates.toArray(),
      appearanceStates: rowdata.appearanceStates.toArray(),
      explodeStates: rowdata.explodeStates.toArray(),
      layerStates: rowdata.layerStates.toArray(),
      styleStates: rowdata.styleStates.toArray(),
    };
    setCurrentEditingRow(currentNonEntityRow);
    setIsCopyDialogOpen(true);
    setIsDialogOpen(true);
  };

  const archiveAction = (rowdata) => {
    dispatch(processArchiveModelTemplates([rowdata.id]));
  };

  const unarchiveAction = (rowdata) => {
    dispatch(processUnarchiveModelTemplates([rowdata.id]));
  };

  const deleteAction = () => {
    // this action is PERMANENT, never call directly off of a button, always use the modal
    dispatch(processDeleteModelTemplate(currentEditingRow.id));
    setCurrentEditingRow(BLANK_TEMPLATE);
    setIsDeleteDialogOpen(false);
  };

  const openDeleteDialog = () => {
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsCopyDialogOpen(false);
    setIsDeleteDialogOpen(true);
  };

  const handleEditedRowChange = (newValue, field) => {
    let _editedRow = { ...currentEditingRow };
    _editedRow[`${field}`] = newValue;

    setCurrentEditingRow(_editedRow);
  };

  const getModelTypeDisplay = (rowData) => {
    const option = CAD_MODEL_TYPE_OPTIONS.find(opt => opt.value === rowData.cadModelType);
    return option?.primary || '';
  };

  const getModelSubtypeDisplay = (rowData) => {
    switch (rowData.cadModelType) {
    case CAD_MODEL_TYPES.ASSEMBLY:
      return CAD_MODEL_ASSEMBLY_SUBTYPE_OPTIONS.find(opt => opt.value === rowData.cadModelAssemblySubtype)?.primary || '';
    case CAD_MODEL_TYPES.PART:
      return CAD_MODEL_PART_SUBTYPE_OPTIONS.find(opt => opt.value === rowData.cadModelPartSubtype)?.primary || '';
    case CAD_MODEL_TYPES.DRAWING:
      return CAD_MODEL_DRAWING_SUBTYPE_OPTIONS.find(opt => opt.value === rowData.cadModelDrawingSubtype)?.primary || '';
    default:
      return '';
    }
  };

  useEffect(() => {
    dispatch(processFetchAllModelTemplates());

    // - Model Metadata
    dispatch(processFetchAllParameterProfiles());
    dispatch(processFetchAllNoteProfiles());

    // - Model Views
    dispatch(processFetchAllOrientations());
    dispatch(processFetchAllLayers());
    dispatch(processFetchAllSections());
    dispatch(processFetchAllSimplifiedReps());
    dispatch(processFetchAllCombinedStates());
    dispatch(processFetchAllAppearanceStates());
    dispatch(processFetchAllExplodeStates());
    dispatch(processFetchAllLayerStates());
    dispatch(processFetchAllStyleStates());
  }, []);

  const columns = [
    {
      field: 'name',
      header: 'Name',
      style: { width: '20%' },
      body: (rowData) => nameBody(rowData, (rowData) => editRowAction(reduxProps.data.find(template => template.id === rowData))),
      sortable: true,
    },
    {
      field: 'cadModelType',
      header: 'Model Type',
      body: (rowData) => <ComplexCellRenderer value={{ primary: getModelTypeDisplay(rowData) }} />,
      style: { width: '20%' },
      sortable: true,
    },
    {
      field: 'cadModelSubtype', // this is a computed field, not a real field in the database
      header: 'Model SubType',
      body: (rowData) => <ComplexCellRenderer value={{ primary: getModelSubtypeDisplay(rowData) }} />,
      sortable: true,
      sortField: 'cadModelSubtype',
      sortFunction: (event) => {
        const { data, order } = event;
        data.sort((a, b) => {
          const valueA = getModelSubtypeDisplay(a).toLowerCase();
          const valueB = getModelSubtypeDisplay(b).toLowerCase();
          return order * valueA.localeCompare(valueB);
        });
        return data;
      }
    },
    {
      field: 'modelTemplateModelName',
      header: 'Model Name',
      body: (rowData) => TextCell({ value: rowData.modelTemplateModelName, placeholder: 'No Model Name' }),
      style: { width: '20%' },
      sortable: true,
    },
    {
      header: '',
      style: { textAlign: 'right', width: '20%' },
      body: (rowData) =>
        <RowEndEntityTools
          rowdata={rowData}
          editAction={editRowAction}
          copyAction={copyRowAction}
          archiveAction={archiveAction}
          unarchiveAction={unarchiveAction}
        />
    }
  ];

  return (
    <>
      <Flex flexDirection='row' mb={4}>
        <Header
          isLoading={reduxProps.isLoading}
          title={StandardizeModuleModelTemplatesPath.defaultTitle}
          subtitle={reduxProps.isFiltering ?
            `Showing ${reduxProps.data.length}/${reduxProps.count} ${pluralize('filtered Model Template', reduxProps.data.length)}` :
            `Showing ${reduxProps.count} ${pluralize('Model Template', reduxProps.count)} Total`}
        />
        <Options
          canFilter={true}
          isLoading={reduxProps.isLoading}
          createEntity={openCreateDialog}
          shouldHaveLicense={false}
          canCollaborate={reduxProps.canCollaborate}
        />
      </Flex>

      <DataTableContainer>
        <DataTable value={reduxProps.data} tableStyle={{ minWidth: '50rem' }} size='normal' scrollable scrollHeight='flex' >
          {columns.map((column, index) => (
            <Column key={index} {...column} />
          ))}
        </DataTable>
      </DataTableContainer>

      <Dialog
        visible={reduxProps.editable && isDialogOpen}
        style={{ width: '75%' }}
        header={()=>getDialogHeader()}
        footer={() => dialogFooter(isCreateDialogOpen, isCopyDialogOpen, openDeleteDialog, saveAction, cancelDialogs)}
        modal
        className='p-fluid'
        closable={false}
      >
        <h3>Template Details</h3>
        <label>Name</label>
        <InputText
          value={currentEditingRow.name}
          onChange={(e) => handleEditedRowChange(e.target.value, 'name')}
          className={!validation.name && 'p-invalid'}
        />

        <label>Model Template Model Name</label>
        <InputText
          value={currentEditingRow.modelTemplateModelName}
          onChange={(e) => handleEditedRowChange(e.target.value, 'modelTemplateModelName')}
          className={!validation.modelTemplateModelName && 'p-invalid'}
        />

        {!isCopyDialogOpen &&
          <>
            <label>CAD Model Type</label>
            <Dropdown
              value={currentEditingRow.cadModelType}
              options={CAD_MODEL_TYPE_OPTIONS}
              optionLabel='label'
              optionValue='value'
              onChange={(e) => handleEditedRowChange(e.value, 'cadModelType')}
              className={!validation.cadModelType && 'p-invalid'}
              filter
              placeholder='select'
            />
          </>
        }

        {currentEditingRow.cadModelType === CAD_MODEL_TYPES.ASSEMBLY && !isCopyDialogOpen &&
          <>
            <label>CAD Model Assembly Sub-Type</label>
            <Dropdown
              value={currentEditingRow.cadModelAssemblySubtype}
              options={CAD_MODEL_ASSEMBLY_SUBTYPE_OPTIONS}
              onChange={(e) => handleEditedRowChange(e.value, 'cadModelAssemblySubtype')}
              className={!validation.cadModelType && 'p-invalid'}
              filter
              placeholder='select'
            />
          </>
        }

        {currentEditingRow.cadModelType === CAD_MODEL_TYPES.PART && !isCopyDialogOpen &&
          <>
            <label>CAD Model Part Sub-Type</label>
            <Dropdown
              value={currentEditingRow.cadModelPartSubtype}
              options={CAD_MODEL_PART_SUBTYPE_OPTIONS}
              onChange={(e) => handleEditedRowChange(e.value, 'cadModelPartSubtype')}
              className={!validation.name && 'p-invalid'}
              filter
              placeholder='select'
            />
          </>
        }

        <h3>Model Details</h3>
        <Flex justifyContent='space-between' >
          <Box width={'48%'} >
            <label>Model Parameter Profile</label>
            <Dropdown
              value={currentEditingRow.modelParameterProfile}
              options={reduxProps.parameterProfiles}
              filter
              optionLabel='name'
              onChange={(e) => handleEditedRowChange(e.value, 'modelParameterProfile')}
              itemTemplate={(item) => (
                <Flex flexDirection='row'>
                  <ComplexCellRenderer
                    value={{ primary: item?.primary }}
                    isCapitalized={false}
                    placeholder='--'
                  />
                </Flex>
              )}
              placeholder='select'
            />
          </Box>

          <Box width={'48%'}>
            {currentEditingRow.cadModelType !== CAD_MODEL_TYPES.DRAWING &&
              <>
                <label>Component Parameter Profile</label>
                <Dropdown
                  value={currentEditingRow.componentParameterProfile}
                  options={reduxProps.parameterProfiles}
                  filter
                  optionLabel='name'
                  onChange={(e) => handleEditedRowChange(e.value, 'componentParameterProfile')}
                  itemTemplate={(item) => (
                    <Flex flexDirection='row'>
                      <ComplexCellRenderer
                        value={{ primary: item?.primary }}
                        isCapitalized={false}
                        placeholder='--'
                      />
                    </Flex>
                  )}
                  placeholder='select'
                />
              </>
            }
          </Box>
        </Flex>

        <label>Note Profile</label>
        <Dropdown
          placeholder='select'
          value={currentEditingRow.noteProfile}
          options={reduxProps.noteProfiles}
          optionDisabled={'archivedFlag'}
          filter
          optionLabel='name'
          onChange={(e) => handleEditedRowChange(e.value, 'noteProfile')}
          itemTemplate={(item) => (
            <Flex flexDirection='row'>
              <ComplexCellRenderer
                value={{ primary: item?.primary }}
                isCapitalized={false}
                placeholder='--'
              />
            </Flex>
          )}
        />

        <h3>Model Views</h3>
        <Flex justifyContent='space-between' >
          <Box width={'48%'} >
            {currentEditingRow.cadModelType !== CAD_MODEL_TYPES.DRAWING &&
              <>
                <ModelViewLabel>Layers</ModelViewLabel>
                <MultiSelect
                  placeholder='select'
                  optionLabel='name'
                  optionValue='id'
                  value={currentEditingRow.layers || []}
                  options={reduxProps.layers}
                  display='chip'
                  onChange={(e) => handleEditedRowChange(e.value, 'layers')}
                  filter
                />

                <Flex alignItems='center'>
                  <Checkbox
                    checked={currentEditingRow.instructionDeleteNonstandardLayers}
                    onChange={(e) => handleEditedRowChange(e.checked, 'instructionDeleteNonstandardLayers')}
                  />
                  <CheckboxText>Delete Non-Standard Layers</CheckboxText>
                </Flex>

                <Flex alignItems='center'>
                  <Checkbox
                    checked={currentEditingRow.instructionCopyLayersFromTemplate}
                    onChange={(e) => handleEditedRowChange(e.checked, 'instructionCopyLayersFromTemplate')}
                  />
                  <CheckboxText>Copy Layers from Template</CheckboxText>
                </Flex>
              </>
            }

            {currentEditingRow.cadModelType !== CAD_MODEL_TYPES.DRAWING &&
              <>
                <ModelViewLabel>Orientations</ModelViewLabel>
                <MultiSelect
                  placeholder='select'
                  optionLabel='name'
                  optionValue='id'
                  value={(() => {
                    return currentEditingRow.orientations || [];
                  })()}
                  options={reduxProps.orientations}
                  display='chip'
                  onChange={(e) => {
                    handleEditedRowChange(e.value, 'orientations');
                  }}
                  filter
                />

                <Flex alignItems='center'>
                  <Checkbox
                    checked={currentEditingRow.instructionDeleteNonstandardOrientations}
                    onChange={(e) => handleEditedRowChange(e.checked, 'instructionDeleteNonstandardOrientations')}
                  />
                  <CheckboxText>Delete Non-Standard Orientations</CheckboxText>
                </Flex>
              </>
            }

            {currentEditingRow.cadModelType !== CAD_MODEL_TYPES.DRAWING &&
              <>
                <ModelViewLabel>Sections</ModelViewLabel>
                <MultiSelect
                  placeholder='select'
                  optionLabel='name'
                  optionValue='id'
                  value={currentEditingRow.sections || []}
                  options={reduxProps.sections}
                  display='chip'
                  onChange={(e) => handleEditedRowChange(e.value, 'sections')}
                  filter
                />

                <Flex alignItems='center'>
                  <Checkbox
                    checked={currentEditingRow.instructionDeleteNonstandardSections}
                    onChange={(e) => handleEditedRowChange(e.checked, 'instructionDeleteNonstandardSections')}
                  />
                  <CheckboxText>Delete Non-Standard Sections</CheckboxText>
                </Flex>
              </>
            }

            {currentEditingRow.cadModelType === CAD_MODEL_TYPES.ASSEMBLY &&
              <>
                <ModelViewLabel>Simplified Reps</ModelViewLabel>
                <MultiSelect
                  placeholder='select'
                  optionLabel='name'
                  optionValue='id'
                  value={currentEditingRow.simplifiedReps || []}
                  options={reduxProps.simplifiedReps}
                  display='chip'
                  onChange={(e) => handleEditedRowChange(e.value, 'simplifiedReps')}
                  filter
                />

                <Flex alignItems='center'>
                  <Checkbox
                    checked={currentEditingRow.instructionDeleteNonstandardSimplifiedReps}
                    onChange={(e) => handleEditedRowChange(e.checked, 'instructionDeleteNonstandardSimplifiedReps')}
                  />
                  <CheckboxText>Delete Non-Standard Simplified Reps</CheckboxText>
                </Flex>
              </>
            }
          </Box>

          <Box width={'48%'} >
            {currentEditingRow.cadModelType !== CAD_MODEL_TYPES.DRAWING &&
              <>
                <ModelViewLabel>Combined States</ModelViewLabel>
                <MultiSelect
                  placeholder='select'
                  optionLabel='name'
                  optionValue='id'
                  value={currentEditingRow.combinedStates || []}
                  options={reduxProps.combinedStates}
                  display='chip'
                  onChange={(e) => handleEditedRowChange(e.value, 'combinedStates')}
                  filter
                />

                <Flex alignItems='center'>
                  <Checkbox
                    checked={currentEditingRow.instructionDeleteNonstandardCombinedStates}
                    onChange={(e) => handleEditedRowChange(e.checked, 'instructionDeleteNonstandardCombinedStates')}
                  />
                  <CheckboxText>Delete Non-Standard Combined States</CheckboxText>
                </Flex>
              </>
            }

            {currentEditingRow.cadModelType !== CAD_MODEL_TYPES.DRAWING &&
              <>
                <ModelViewLabel>Layer States</ModelViewLabel>
                <MultiSelect
                  placeholder='select'
                  optionLabel='name'
                  optionValue='id'
                  value={currentEditingRow.layerStates || []}
                  options={reduxProps.layerStates}
                  display='chip'
                  onChange={(e) => handleEditedRowChange(e.value, 'layerStates')}
                  filter
                />

                <Flex alignItems='center'>
                  <Checkbox
                    checked={currentEditingRow.instructionDeleteNonstandardLayerStates}
                    onChange={(e) => handleEditedRowChange(e.checked, 'instructionDeleteNonstandardLayerStates')}
                  />
                  <CheckboxText>Delete Non-Standard Layer States</CheckboxText>
                </Flex>
              </>
            }

            {currentEditingRow.cadModelType !== CAD_MODEL_TYPES.DRAWING &&
              <>
                <ModelViewLabel>Appearance States</ModelViewLabel>
                <MultiSelect
                  placeholder='select'
                  optionLabel='name'
                  optionValue='id'
                  value={currentEditingRow.appearanceStates || []}
                  options={reduxProps.appearanceStates}
                  display='chip'
                  onChange={(e) => handleEditedRowChange(e.value, 'appearanceStates')}
                  filter
                />

                <Flex alignItems='center'>
                  <Checkbox
                    checked={currentEditingRow.instructionDeleteNonstandardAppearanceStates}
                    onChange={(e) => handleEditedRowChange(e.checked, 'instructionDeleteNonstandardAppearanceStates')}
                  />
                  <CheckboxText>Delete Non-Standard Appearance States</CheckboxText>
                </Flex>
              </>
            }

            {currentEditingRow.cadModelType === CAD_MODEL_TYPES.ASSEMBLY &&
              <>
                <ModelViewLabel>Explode States</ModelViewLabel>
                <MultiSelect
                  placeholder='select'
                  optionLabel='name'
                  optionValue='id'
                  value={currentEditingRow.explodeStates || []}
                  options={reduxProps.explodeStates}
                  display='chip'
                  onChange={(e) => handleEditedRowChange(e.value, 'explodeStates')}
                  filter
                />

                <Flex alignItems='center'>
                  <Checkbox
                    checked={currentEditingRow.instructionDeleteNonstandardExplodeStates}
                    onChange={(e) => handleEditedRowChange(e.checked, 'instructionDeleteNonstandardExplodeStates')}
                  />
                  <CheckboxText>Delete Non-Standard Explode States</CheckboxText>
                </Flex>
              </>
            }

            {currentEditingRow.cadModelType === CAD_MODEL_TYPES.ASSEMBLY &&
              <>
                <ModelViewLabel>Style States</ModelViewLabel>
                <MultiSelect
                  placeholder='select'
                  optionLabel='name'
                  optionValue='id'
                  value={currentEditingRow.styleStates || []}
                  options={reduxProps.styleStates}
                  display='chip'
                  onChange={(e) => handleEditedRowChange(e.value, 'styleStates')}
                  filter
                />

                <Flex alignItems='center'>
                  <Checkbox
                    checked={currentEditingRow.instructionDeleteNonstandardStyleStates}
                    onChange={(e) => handleEditedRowChange(e.checked, 'instructionDeleteNonstandardStyleStates')}
                  />
                  <CheckboxText>Delete Non-Standard Style States</CheckboxText>
                </Flex>
              </>
            }
          </Box>
        </Flex>

        {currentEditingRow.cadModelType === CAD_MODEL_TYPES.ASSEMBLY &&
          <>
            <Box mt={4}>
              <h3>Model Instructions</h3>

              <Flex flexDirection="column">
                <Flex alignItems='center' mt={2}>
                  <Checkbox
                    checked={currentEditingRow.instructionCreateSkeletonComponent}
                    onChange={(e) => handleEditedRowChange(e.checked, 'instructionCreateSkeletonComponent')}
                  />
                  <CheckboxText>Create Skeleton</CheckboxText>
                </Flex>
              </Flex>
            </Box>
          </>
        }

        {currentEditingRow.cadModelType === CAD_MODEL_TYPES.DRAWING && (
          <>
            <Box mt={4}>
              <h3>Drawing Instructions</h3>
              <Flex alignItems='center'>
                <Checkbox
                  checked={currentEditingRow.instructionDrawingCopyWithModel}
                  onChange={(e) => handleEditedRowChange(e.checked, 'instructionDrawingCopyWithModel')}
                />
                <CheckboxText>Copy Drawing with Model</CheckboxText>
              </Flex>

              <Flex alignItems='center'>
                <Checkbox
                  checked={currentEditingRow.instructionDrawingReplaceSolid}
                  onChange={(e) => handleEditedRowChange(e.checked, 'instructionDrawingReplaceSolid')}
                />
                <CheckboxText>Replace Solid in Drawing</CheckboxText>
              </Flex>

              <Flex alignItems='center'>
                <Checkbox
                  checked={currentEditingRow.instructionDrawingCreateBySolidCombinedStates}
                  onChange={(e) => handleEditedRowChange(e.checked, 'instructionDrawingCreateBySolidCombinedStates')}
                />
                <CheckboxText>Create Drawing by Solid Combined States</CheckboxText>
              </Flex>
            </Box>
          </>
        )}
      </Dialog>

      <Dialog
        visible={reduxProps.editable && isDeleteDialogOpen}
        style={{ width: '32rem' }}
        header={`Delete Model Template '${currentEditingRow.name}'`}
        footer={() => deleteDialogFooter(closeDeleteDialog, deleteAction)}
        closable={false}
      >
        <div>
          <Text>Are you sure you want to delete {currentEditingRow.name}?</Text>
          <Text style={{ color: 'red' }}>This action will be PERMANENT and CANNOT BE UNDONE.</Text>
          <Text><strong>Only delete this if you are certain that it needs to be removed from everywhere</strong></Text>
        </div>
      </Dialog>
    </>
  );
}