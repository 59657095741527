import { toast } from 'react-toastify';

import EK from '../../keys';

import api from '../../../modules/api/actions';
import { REQUEST_TYPES } from '../../../modules/api/constants';

import {
  createNoteBlocks,
  editNoteBlocks,
  deleteNoteBlocks,
  sortNoteBlockNotes,
} from './constants';
import schema from './schema';

const client = api(schema, EK.NOTE_BLOCKS);

export const processCreateNoteBlock = (profileId, data) => client(
  REQUEST_TYPES.POST,
  'plural',
  createNoteBlocks,
  `/standardize/note_profiles/${profileId}/note_blocks`,
  {
    data: {
      noteBlocks: [data],
    },
    mutateResponse: ({ noteBlocks = [] }) => ({
      noteBlocks: noteBlocks.map(block => ({
        ...block,
        noteProfile: `${profileId}`,
        noteProfileId: `${profileId}`
      }))
    }),
    errorNotification: {
      type: toast.TYPE.ERROR,
      message: 'Unable to create block',
    },
  }
);

export const processSortNoteBlockNote = (profileId, blockId, noteBlockNoteId, sortOrder) => client(
  REQUEST_TYPES.PATCH,
  'single',
  sortNoteBlockNotes,
  `/standardize/note_profiles/${profileId}/note_blocks/${blockId}/sort?with_full_detail=true`,
  {
    data: {
      noteBlockNoteId: `${noteBlockNoteId}`,
      sortOrder,
    },
    mutateResponse: ({ noteBlock = {} }) => ({
      noteBlock: {
        ...noteBlock,
        noteProfile: `${profileId}`,
        noteProfileId: `${profileId}`,
        noteBlockNotes: (noteBlock.noteBlockNotes || []).map(note => ({
          ...note,
          noteBlock: `${blockId}`,
          noteBlockId: `${blockId}`,
        })),
      },
    }),
  }
);

export const processEditNoteBlock = (profileId, blockId, data) => client(
  REQUEST_TYPES.PATCH,
  'single',
  editNoteBlocks,
  `/standardize/note_profiles/${profileId}/note_blocks/${blockId}`,
  {
    data,
    mutateResponse: ({ noteBlock = {} }) => ({
      noteBlock: {
        ...noteBlock,
        noteProfile: `${profileId}`,
        noteProfileId: `${profileId}`,
      },
    }),
    errorNotification: {
      type: toast.TYPE.ERROR,
      message: 'Unable to save changes',
    },
  }
);

export const processDeleteNoteBlock = (profileId, blockId) => client(
  REQUEST_TYPES.DELETE,
  'single',
  deleteNoteBlocks,
  `/standardize/note_profiles/${profileId}/note_blocks/${blockId}?with_full_detail=true`,
  {
    deleteKey: EK.NOTE_BLOCKS.state,
    dependentDeletionKeys: [EK.NOTE_BLOCK_NOTES],
    mutateResponse: ({ noteBlock: { id, ...other } = {}, noteBlockNotes = [] }) => ({
      noteBlock: {
        ...other,
        id,
        noteBlockNotes: noteBlockNotes.map(note => ({
          ...note,
          noteBlock: `${blockId}`,
          noteBlockId: `${blockId}`,
        })),
      },
    }),
    errorNotification: {
      type: toast.TYPE.ERROR,
      message: 'Unable to delete block',
    },
  }
);