import React from 'react';
import styled from 'styled-components';

import Box from '../Box';

const Swatch = styled(Box).attrs(({ $mt, $mr, $mb, $ml }) => ({
  mt: $mt !== undefined ? $mt : 2,
  mr: $mr !== undefined ? $mr : 0,
  mb: $mb !== undefined ? $mb : 2,
  ml: $ml !== undefined ? $ml : 0,
}))`
  width: ${({ theme }) => theme.space[8]};
  height: ${({ theme }) => theme.space[5]};
  padding: ${({ theme }) => theme.space[1]};
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.radii[1]};
  box-shadow: ${({ theme }) => theme.shadows[1]};
  display: block;
  cursor: ${({ $onClick }) => $onClick ? 'pointer' : 'default'};
`;

const Color = styled(Box)`
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.radii[2]};
  background: ${({ $color }) => $color && `rgb(${$color.r}, ${$color.g}, ${$color.b})` || 'transparent'};
`;

const ColorSwatch = React.forwardRef(({ color, onClick, mt, mr, mb, ml, ...props }, ref) => (
  <Swatch
    $onClick={onClick}
    $mt={mt}
    $mr={mr}
    $mb={mb}
    $ml={ml}
    onClick={onClick}
    ref={ref}
    {...props}
  >
    <Color $color={color} />
  </Swatch>
));

export default ColorSwatch;

