import React from 'react';
import styled from 'styled-components';

import Flex from '../../common/Flex';
import Text from '../../common/Text';

const StyledCreateOption = styled(Flex).attrs(({disabled}) => ({
  flexDirection: 'column',
  justifyContent: 'center',
  cursor: disabled ? 'not-allowed' : 'pointer',
  style: {
    paddingRight: theme.space[3],
    paddingLeft: theme.space[3],
  }
}))`
  background-color: ${({ theme }) => theme.colors.gray[1]};

  &:hover,
  &:focus,
  &:active {
    background-color: ${({ theme }) => theme.colors.gray[2]};
  }
`;

const BaseCreateOption = React.memo(({ width, height, currentValue, disabled, onClick }) => (
  <StyledCreateOption
    style={{ width, height }}
    disabled={disabled}
    onClick={!disabled && onClick || null}
  >
    <Text color='gray.7' fontWeight={600}>
      Create new item <strong>{currentValue}</strong>
    </Text>
  </StyledCreateOption>
));

export default BaseCreateOption;