import React from 'react';
import styled from 'styled-components';

import Box from '../../../../components/common/Box';

import ModalTitle from '../../../../components/modal/templates/ModalTitle';

const canUseDOM = !!(
  (typeof window !== 'undefined' &&
  window.document && window.document.createElement)
);

const PrivacyContainer = styled(Box)`
  height: 75%;
  height: 45rem;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;
  overflow-x: auto;

  border: ${({ theme }) => theme.borders[1]};
  border-radius: ${({ theme }) => theme.radii[1]};
  box-shadow: ${({ theme }) => theme.shadows[4]};
`;

class PrivacyPolicyModal extends React.Component {
  componentDidMount = () => {
    if (canUseDOM) {
      (function (w,d) {
        var loader = function () {
          var s = d.createElement('script'), tag = d.getElementsByTagName('script')[0];
          s.src='https://cdn.iubenda.com/iubenda.js';
          tag.parentNode.insertBefore(s,tag);
        };

        if (d.readyState === 'complete') {
          loader();
        } else {
          if (w.addEventListener) {
            w.addEventListener('load', loader, false);
          } else if (w.attachEvent) {
            w.attachEvent('onload', loader);
          } else {
            w.onload = loader;
          }
        }
      })(window, document);
    }
  }

  render() {
    return (
      <Box>
        <ModalTitle><strong>Privacy</strong> Policy</ModalTitle>

        <PrivacyContainer>
          <a
            href="https://www.iubenda.com/privacy-policy/97899315"
            className="iubenda-white no-brand iubenda-embed iub-body-embed"
            title="Privacy Policy"
          >
            Privacy Policy
          </a>
        </PrivacyContainer>
      </Box>
    );
  }
}

export default PrivacyPolicyModal;