import { List } from 'immutable';
import isArray from 'lodash/isArray';

import EntityRecord, { COMPARATORS, VALIDATORS, UTILS } from '../../modules/entities/model';

export const ALLOWANCES = {
  INTEGER: /^([0-9]*)$/,
  DOUBLE: /^([0-9]*(\.)?[0-9]*)$/,
};

export const SETTING_PRODUCTS = {
  SYNCHRONIZE: 'synchronize',
  STANDARDIZE: 'standardize',
  AUTOMATE: 'automate'
};

export const SETTING_PRODUCTS_LIST = List([
  {
    id: SETTING_PRODUCTS.SYNCHRONIZE,
    value: SETTING_PRODUCTS.SYNCHRONIZE,
    primary: 'Synchronize',
    label: 'Synchronize',
  },
  {
    id: SETTING_PRODUCTS.STANDARDIZE,
    value: SETTING_PRODUCTS.STANDARDIZE,
    primary: 'Standardize',
    label: 'Standardize',
  },
  {
    id: SETTING_PRODUCTS.AUTOMATE,
    value: SETTING_PRODUCTS.AUTOMATE,
    primary: 'Automate',
    label: 'Automate',
  }
]);

export const SETTING_MODULES = {
  PIPING: 'piping',
  CABLING: 'cabling',
  FRAMEWORK: 'framework',
  ECAD: 'ecad',
  REPORTING: 'reporting'
};

export const SETTING_MODULES_LIST = List([
  {
    id: SETTING_MODULES.PIPING,
    value: SETTING_MODULES.PIPING,
    primary: 'Piping',
    label: 'Piping',
  },
  {
    id: SETTING_MODULES.CABLING,
    value: SETTING_MODULES.CABLING,
    primary: 'Cabling',
    label: 'Cabling',
  },
  {
    id: SETTING_MODULES.FRAMEWORK,
    value: SETTING_MODULES.FRAMEWORK,
    primary: 'Framework',
    label: 'Framework',
  },
  {
    id: SETTING_MODULES.ECAD,
    value: SETTING_MODULES.ECAD,
    primary: 'ECAD',
    label: 'ECAD',
  },
  {
    id: SETTING_MODULES.REPORTING,
    value: SETTING_MODULES.REPORTING,
    primary: 'Reporting',
    label: 'Reporting',
  }
]);

class Setting extends EntityRecord({
  value: {
    value: null,
    handleCompare: true,
    handleSerialize: entity => {
      const value = entity.value;
      if (value === null || value === undefined) { return null; }

      switch(entity.valueType) {
      case 'string':
      case 'list':
      case 'integer':
      case 'double': {
        return entity.value;
      }

      case 'bool': {
        return `${entity.value}`;
      }

      case 'color': {
        return UTILS.STRINGIFY_COLOR(entity.value);
      }
      }
    },
    validate: entity => {
      const value = entity.value;
      // because all settings are optional, null and undefined are acceptable values across the board
      // either of these values means that we will default to the setting's defaultValue
      if (value === null || value === undefined) { return true; }

      switch (entity.valueType) {
      case 'string': {
        return VALIDATORS.IS_REQUIRED_STRING(value);
      }
      case 'list': {
        return VALIDATORS.IS_REQUIRED_STRING(value) && entity.listValues.find(lv => lv.value === value);
      }
      case 'integer': {
        return VALIDATORS.IS_POSITIVE_INTEGER(value);
      }
      case 'double': {
        return VALIDATORS.IS_NUMERIC(value);
      }
      case 'bool': {
        return value === true || value === false;
      }
      case 'color': {
        try {
          return value && VALIDATORS.IS_RGB_COLOR_OBJECT(value);
        } catch (e) {
          return false;
        }
      }
      }
    },
    validateError: entity => {
      switch (entity.valueType) {
      case 'string': {
        return 'A valid non-empty string must be present';
      }
      case 'list': {
        return 'Only one of the options from the list is allowed';
      }
      case 'integer': {
        return 'A non-negative integer must be present';
      }
      case 'double': {
        return 'A valid number must be present';
      }
      case 'bool': {
        return 'This value may only be either true or false';
      }
      case 'color': {
        return 'A valid color must be present';
      }
      }
    },
  },
  name: {
    value: null,
  },
  productTier: {
    value: null,
  },
  productModule: {
    value: null,
  },
  category: {
    value: null,
  },
  description: {
    value: null,
  },
  defaultValue: {
    value: null,
  },
  valueType: {
    value: null,
  },
  listValues: {
    value: List(),
    isList: true,
  },
}) {
  constructor({ productTier, productModule, valueType, listValues, value, ...other } = {}) {
    super({
      ...other,
      valueType,
      productTier: productTier || null,
      productModule: productModule || null,
      listValues: valueType === 'list' && isArray(listValues)
        ? List(listValues.map(value => (
          {
            value,
            label: value,
            primary: value,
          }
        ))) : List(),
      value: valueType === 'bool'
        ? value === 'true'
        : valueType === 'color'
          ? UTILS.OBJECTIZE_COLOR(value)
          : value
    });
  }

  get summary() {
    return {
      primary: this.name,
      secondary: this.description,
    };
  }

  hasSameValueAs(incomingValue) {
    return this.valueType === 'color' ? COMPARATORS.COLOR(this.value, incomingValue) : this.value === incomingValue;
  }
}

export default Setting;