import React from 'react';
import styled, { css } from 'styled-components';

const BaseBox = styled.div`
  // Display
  display: ${({ $display }) => $display};

  // Space
  margin: ${({ $m, theme }) => {
    if (typeof $m === 'number') {
      return theme.space[$m] || `${$m}rem`;
    }
    return $m;
  }};
  margin-top: ${({ $mt, theme }) => {
    if (typeof $mt === 'number') {
      return theme.space[$mt] || `${$mt}rem`;
    }
    return $mt;
  }};
  margin-right: ${({ $mr, theme }) => {
    if (typeof $mr === 'number') {
      return theme.space[$mr] || `${$mr}rem`;
    }
    return $mr;
  }};
  margin-bottom: ${({ $mb, theme }) => {
    if (typeof $mb === 'number') {
      return theme.space[$mb] || `${$mb}rem`;
    }
    return $mb;
  }};
  margin-left: ${({ $ml, theme }) => {
    if (typeof $ml === 'number') {
      return theme.space[$ml] || `${$ml}rem`;
    }
    return $ml;
  }};
  padding: ${({ $p, theme }) => {
    if (typeof $p === 'number') {
      return theme.space[$p] || `${$p}rem`;
    }
    return $p;
  }};
  padding-top: ${({ $pt, theme }) => {
    if (typeof $pt === 'number') {
      return theme.space[$pt] || `${$pt}rem`;
    }
    return $pt;
  }};
  padding-right: ${({ $pr, theme }) => {
    if (typeof $pr === 'number') {
      return theme.space[$pr] || `${$pr}rem`;
    }
    return $pr;
  }};
  padding-bottom: ${({ $pb, theme }) => {
    if (typeof $pb === 'number') {
      return theme.space[$pb] || `${$pb}rem`;
    }
    return $pb;
  }};
  padding-left: ${({ $pl, theme }) => {
    if (typeof $pl === 'number') {
      return theme.space[$pl] || `${$pl}rem`;
    }
    return $pl;
  }};

  // Colors
  background-color: ${({ $bg, theme }) => {
    if (!$bg) return 'transparent';

    if (typeof $bg === 'string' && $bg.includes('.')) {
      const [colorName, shade] = $bg.split('.');
      return theme.colors[colorName]?.[shade] || $bg;
    }

    return theme.colors[$bg] || $bg;
  }};

  color: ${({ $color, theme }) => {
    if (!$color) return 'inherit';
    if (typeof $color === 'string' && $color.includes('.')) {
      const [colorName, shade] = $color.split('.');
      return theme.colors[colorName]?.[shade] || $color;
    }
    return theme.colors[$color] || $color;
  }};

  // Dimensions
  width: ${({ $width }) => typeof $width === 'number' ? `${$width}px` : $width};
  height: ${({ $height }) => typeof $height === 'number' ? `${$height}px` : $height};
  min-width: ${({ $minWidth }) => typeof $minWidth === 'number' ? `${$minWidth}px` : $minWidth};

  // Position
  position: ${({ $position }) => $position};
  top: ${({ $top }) => $top};
  right: ${({ $right }) => $right};
  bottom: ${({ $bottom }) => $bottom};
  left: ${({ $left }) => $left};

  // Typography
  line-height: ${({ $lineHeight }) => $lineHeight};
  text-align: ${({ $textAlign }) => $textAlign};

  // Borders
  border: ${({ $border, theme }) => {
    if (!$border) return theme.borders[0];

    if (typeof $border === 'number') {
      return theme.borders[$border];
    }
    return $border;
  }};
  border-color: ${({ $borderColor, theme }) => {
    if (!$borderColor) return theme.colors.gray[3];

    if (typeof $borderColor === 'string' && $borderColor.includes('.')) {
      const [colorName, shade] = $borderColor.split('.');
      return theme.colors[colorName]?.[shade] || $borderColor;
    }
    return theme.colors[$borderColor] || $borderColor;
  }};
  border-radius: ${({ $borderRadius, theme }) => $borderRadius && theme.radii[$borderRadius]};
  border-width: ${({ $borderWidth }) => typeof $borderWidth === 'number' ? `${$borderWidth}px` : $borderWidth};
  border-style: ${({ $borderStyle }) => $borderStyle};

  box-shadow: ${({ $boxShadow, theme }) => $boxShadow && theme.shadows[$boxShadow] || $boxShadow};

  // Custom props
  cursor: ${({ $cursor }) => $cursor};

  ${({ $borderRadii, $borderRadius, theme }) => $borderRadii && Array.isArray($borderRadii) ?
    css`
      border-top-left-radius: ${theme.radii[$borderRadii[0]]};
      border-top-right-radius: ${theme.radii[$borderRadii[1]]};
      border-bottom-right-radius: ${theme.radii[$borderRadii[2]]};
      border-bottom-left-radius: ${theme.radii[$borderRadii[3]]};
    ` :
    !$borderRadius && css`
      border-radius: ${theme.radii[$borderRadii || 0]};
    `}

  ${({ $ellipsis }) => $ellipsis && css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    word-break: break-all;
  `}
`;

const Box = React.forwardRef((props, ref) => {
  const {
    display,
    m,
    mt,
    mr,
    mb,
    ml,
    p,
    pt,
    pr,
    pb,
    pl,
    bg,
    color,
    width,
    height,
    minWidth,
    position,
    top,
    right,
    bottom,
    left,
    lineHeight,
    textAlign,
    border,
    borderColor,
    borderRadius,
    borderWidth,
    borderStyle,
    boxShadow,
    cursor,
    borderRadii,
    ellipsis,
    ...rest
  } = props;

  return (
    <BaseBox
      ref={ref}
      $display={display}
      $m={m}
      $mt={mt}
      $mr={mr}
      $mb={mb}
      $ml={ml}
      $p={p}
      $pt={pt}
      $pr={pr}
      $pb={pb}
      $pl={pl}
      $bg={bg}
      $color={color}
      $width={width}
      $height={height}
      $minWidth={minWidth}
      $position={position}
      $top={top}
      $right={right}
      $bottom={bottom}
      $left={left}
      $lineHeight={lineHeight}
      $textAlign={textAlign}
      $border={border}
      $borderColor={borderColor}
      $borderRadius={borderRadius}
      $borderWidth={borderWidth}
      $borderStyle={borderStyle}
      $boxShadow={boxShadow}
      $cursor={cursor}
      $borderRadii={borderRadii}
      $ellipsis={ellipsis}
      {...rest}
    />
  );
});

export default Box;