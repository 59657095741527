import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import Flex from '../Flex';
import Text from '../Text';

const TabContainer = styled(Flex).attrs(_ => ({
  as: 'ul',
}))`
  display: flex;
  position: relative;
  list-style: none;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: center;
  backface-visibility: hidden;
  padding: 0;
  margin: 0;
  width: 100%;
`;

const BaseTab = styled(Flex).attrs(({ as = 'li', $active }) => ({
  as,
  className: $active ? 'active' : undefined,
}))`
  position: relative;
  z-index: 1;
  width: 8rem;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 4rem;
  color: ${({ theme }) => theme.colors.gray[6]};
  padding: 0;

  transition: color ${({ theme }) => theme.transitions.speed.fast} ${({ theme }) => theme.transitions.type.inOut};

  ${({ $active }) => $active ? css`
    color: ${({ theme }) => theme.colors.primary[4]};
  ` : css`
    &:hover {
      color: ${({ theme }) => theme.colors.gray[7]};
    }
  `}
`;

const TabText = styled(Text).attrs(({ $active }) => ({
  $ellipsis: true,
  fontSize: 2,
  fontWeight: '600'
}))`
  display: inline-block;
  text-decoration: none;
  outline: none;
  vertical-align: middle;
  color: inherit;
  padding: ${({ theme }) => `${theme.space[1]} ${theme.space[2]}`};

  ${({ $active }) => $active && css`
    background: ${({ theme }) => theme.colors.primary[1]};
    border-radius: ${({ theme }) => theme.radii[2]};
  `}
`;

const Tab = ({ id, onSelect, active, ...rest }) => {
  const onClick = useCallback(() => onSelect(id), [id, onSelect]);

  return <BaseTab $active={active} onClick={onClick} {...rest} />;
};

const Tabs = ({ tabs, activeTabId, onChange }) => (
  <TabContainer>
    {tabs.map(({ id, label, ...rest }) => (
      <Tab
        key={id}
        id={id}
        active={id === activeTabId}
        onSelect={onChange}
        {...rest}
      >
        <TabText $active={id === activeTabId}>
          {label}
        </TabText>
      </Tab>
    ))}
  </TabContainer>
);

export default Tabs;