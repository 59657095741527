export default {
  // General
  CURRENT_USER: {
    single: 'user',
    plural: 'users',
    state: 'currentUser',
  },
  ORGANIZATIONS: {
    single: 'organization',
    plural: 'organizations',
    state: 'organizations',
  },
  ORGANIZATION_USER_INVITES: {
    single: 'organizationUserInvite',
    plural: 'organizationUserInvites',
    state: 'organizationUserInvites',
  },
  SETTINGS: {
    single: 'setting',
    plural: 'settings',
    state: 'settings',
  },
  GLOBAL_CAD_SEARCH_RULE_CRITERIA: {
    single: 'globalCadSearchRuleCriterion',
    plural: 'globalCadSearchRuleCriteria',
    state: 'globalCadSearchRuleCriteria',
  },
  GLOBAL_CAD_SEARCH_RULES: {
    single: 'globalCadSearchRule',
    plural: 'globalCadSearchRules',
    state: 'globalCadSearchRules',
  },
  GLOBAL_CAD_SEARCH_RULESET_RULE_CRITERION_VALUES: {
    single: 'globalCadSearchRulesetRuleCriterionValue',
    plural: 'globalCadSearchRulesetRuleCriterionValues',
    state: 'globalCadSearchRulesetRuleCriterionValues',
  },
  UNITS: {
    single: 'unit',
    plural: 'units',
    state: 'units',
  },
  UNIT_SYSTEMS: {
    single: 'unitSystem',
    plural: 'unitSystems',
    state: 'unitSystems',
  },
  USERS: {
    single: 'user',
    plural: 'users',
    state: 'users',
  },
  // Synchronize
  APPEARANCES: {
    single: 'appearance',
    plural: 'appearances',
    collection: 'appearancesAttributes',
    state: 'appearances',
  },
  CONFIG_SETTING_CATEGORIES: {
    single: 'configSettingCategory',
    plural: 'configSettingCategories',
    state: 'configSettingCategories',
  },
  CONFIG_SETTINGS: {
    single: 'configSetting',
    plural: 'configSettings',
    state: 'configSettings',
  },
  CONFIG_PROFILES: {
    single: 'configProfile',
    plural: 'configProfiles',
    state: 'configProfiles',
  },
  CONFIG_PROFILE_MAPKEYS: {
    single: 'configProfileMapkey',
    plural: 'configProfileMapkeys',
    collection: 'configProfileMapkeysAttributes',
    state: 'configProfileMapkeys',
  },
  CONFIG_PROFILE_VALUES: {
    single: 'configProfileValue',
    plural: 'configProfileValues',
    collection: 'configProfileValuesAttributes',
    state: 'configProfileValues',
  },
  MAPKEYS: {
    single: 'mapkey',
    plural: 'mapkeys',
    state: 'mapkeys',
    collection: 'mapkeysAttributes',
  },
  // Standardize
  APPEARANCE_STATES: {
    single: 'appearanceState',
    plural: 'appearanceStates',
    state: 'appearanceStates'
  },
  COMBINED_STATES: {
    single: 'combinedState',
    plural: 'combinedStates',
    state: 'combinedStates'
  },
  EXPLODE_STATES: {
    single: 'explodeState',
    plural: 'explodeStates',
    state: 'explodeStates'
  },
  LAYERS: {
    single: 'layer',
    plural: 'layers',
    state: 'layers',
  },
  LAYER_STATES: {
    single: 'layerState',
    plural: 'layerStates',
    state: 'layerStates'
  },
  NOTES: {
    single: 'note',
    plural: 'notes',
    state: 'notes',
  },
  ORIENTATIONS: {
    single: 'orientation',
    plural: 'orientations',
    state: 'orientations',
  },
  NOTE_PROFILES: {
    single: 'noteProfile',
    plural: 'noteProfiles',
    state: 'noteProfiles',
  },
  NOTE_BLOCKS: {
    single: 'noteBlock',
    plural: 'noteBlocks',
    state: 'noteBlocks',
  },
  NOTE_BLOCK_NOTES: {
    single: 'noteBlockNote',
    plural: 'noteBlockNotes',
    state: 'noteBlockNotes',
  },
  PARAMETER_PROFILES: {
    single: 'parameterProfile',
    plural: 'parameterProfiles',
    state: 'parameterProfiles',
  },
  PARAMETER_PROFILE_PARAMETERS: {
    single: 'parameterProfileParameter',
    plural: 'parameterProfileParameters',
    collection: 'parameterProfileParametersAttributes',
    state: 'parameterProfileParameters',
  },
  PARAMETERS: {
    single: 'parameter',
    plural: 'parameters',
    state: 'parameters',
  },
  SECTIONS: {
    single: 'section',
    plural: 'sections',
    state: 'sections',
  },
  SIMPLIFIED_REP_RULESET_RULE_CRITERION_VALUES: {
    single: 'simplifiedRepRulesetRuleCriterionValue',
    plural: 'simplifiedRepRulesetRuleCriterionValues',
    state: 'simplifiedRepRulesetRuleCriterionValues',
  },
  SIMPLIFIED_REP_RULESET_RULES: {
    single: 'simplifiedRepRulesetRule',
    plural: 'simplifiedRepRulesetRules',
    state: 'simplifiedRepRulesetRules',
  },
  SIMPLIFIED_REP_RULESETS: {
    single: 'simplifiedRepRuleset',
    plural: 'simplifiedRepRulesets',
    state: 'simplifiedRepRulesets',
  },
  SIMPLIFIED_REPS: {
    single: 'simplifiedRep',
    plural: 'simplifiedReps',
    state: 'simplifiedReps',
  },
  STYLE_STATES: {
    single: 'styleState',
    plural: 'styleStates',
    state: 'styleStates',
  },
  MODEL_TEMPLATES: {
    single: 'modelTemplate',
    plural: 'modelTemplates',
    state: 'modelTemplates',
  },
  // Automate
  AUTOMATE_ROUTING_PROFILES: {
    single: 'routingProfile',
    plural: 'routingProfiles',
    state: 'routingProfiles',
  },
  // Cabling
  CABLING_SPOOLS: {
    single: 'spool',
    plural: 'spools',
    state: 'spools',
  },
  // Piping
  BENDS: {
    single: 'bendFamily',
    plural: 'bendFamilies',
    state: 'bendFamilies',
  },
  BEND_INSTANCES: {
    single: 'bendInstance',
    plural: 'bendInstances',
    collection: 'bendInstancesAttributes',
    state: 'bendInstances',
  },
  BEND_MACHINES: {
    single: 'bendMachineFamily',
    plural: 'bendMachineFamilies',
    state: 'bendMachineFamilies',
  },
  BEND_MACHINE_INSTANCES: {
    single: 'bendMachineInstance',
    plural: 'bendMachineInstances',
    collection: 'bendMachineInstancesAttributes',
    state: 'bendMachineInstances',
  },
  BEND_SPRINGBACKS: {
    single: 'bendSpringbackFamily',
    plural: 'bendSpringbackFamilies',
    state: 'bendSpringbackFamilies',
  },
  BEND_SPRINGBACK_INSTANCES: {
    single: 'bendSpringbackInstance',
    plural: 'bendSpringbackInstances',
    collection: 'bendSpringbackInstancesAttributes',
    state: 'bendSpringbackInstances',
  },
  BOLT_NUTS: {
    single: 'boltNutFamily',
    plural: 'boltNutFamilies',
    state: 'boltNutFamilies',
  },
  BOLT_NUT_INSTANCES: {
    single: 'boltNutInstance',
    plural: 'boltNutInstances',
    collection: 'boltNutInstancesAttributes',
    state: 'boltNutInstances',
  },
  END_TYPE_COMPATIBILITIES: {
    single: 'endTypeCompatibility',
    plural: 'endTypeCompatibilities',
    state: 'endTypeCompatibilities',
  },
  END_TYPES: {
    single: 'endType',
    plural: 'endTypes',
    collection: 'endTypesAttributes',
    state: 'endTypes',
  },
  FITTING_FAMILIES: {
    single: 'fittingFamily',
    plural: 'fittingFamilies',
    state: 'fittingFamilies',
  },
  FITTING_CATEGORIES: {
    single: 'fittingCategory',
    plural: 'fittingCategories',
    collection: 'fittingCategoriesAttributes',
    state: 'fittingCategories',
  },
  FITTING_CODES: {
    single: 'fittingCode',
    plural: 'fittingCodes',
    state: 'fittingCodes',
  },
  FITTING_END_PORTS: {
    single: 'fittingEndPort',
    plural: 'fittingEndPorts',
    state: 'fittingEndPorts',
  },
  FITTINGS: {
    single: 'fittingFamily',
    plural: 'fittingFamilies',
    state: 'fittingFamilies',
  },
  FITTING_INSTANCES: {
    single: 'fittingInstance',
    plural: 'fittingInstances',
    state: 'fittingInstances',
    collection: 'fittingInstancesAttributes',
  },
  INSULATIONS: {
    single: 'insulationFamily',
    plural: 'insulationFamilies',
    state: 'insulationFamilies',
  },
  INSULATION_INSTANCES: {
    single: 'insulationInstance',
    plural: 'insulationInstances',
    state: 'insulationInstances',
    collection: 'insulationInstancesAttributes',
  },
  INSULATION_SPECS: {
    single: 'insulationSpec',
    plural: 'insulationSpecs',
    collection: 'insulationSpecsAttributes',
    state: 'insulationSpecs',
  },
  MATERIALS: {
    single: 'material',
    plural: 'materials',
    state: 'materials',
    collection: 'materialsAttributes',
  },
  MITERS: {
    single: 'miterFamily',
    plural: 'miterFamilies',
    state: 'miterFamilies',
  },
  MITER_INSTANCES: {
    single: 'miterInstance',
    plural: 'miterInstances',
    collection: 'miterInstancesAttributes',
    state: 'miterInstances',
  },
  PIPING_CATALOGS: {
    single: 'pipingCatalog',
    plural: 'pipingCatalogs',
    state: 'pipingCatalogs',
  },
  PIPE_FAMILIES: {
    single: 'pipeFamily',
    plural: 'pipeFamilies',
    state: 'pipeFamilies',
  },
  PIPE_INSTANCES: {
    single: 'pipeInstance',
    plural: 'pipeInstances',
    state: 'pipeInstances',
    collection: 'pipeInstancesAttributes',
  },
  SPEC_FITTING_INSTANCES: {
    single: 'specFittingInstance',
    plural: 'specFittingInstances',
    state: 'specFittingInstances',
    collection: 'specFittingInstancesAttributes',
  },
  SPEC_PIPE_INSTANCES: {
    single: 'specPipeInstance',
    plural: 'specPipeInstances',
    state: 'specPipeInstances',
    collection: 'specPipeInstancesAttributes',
  },
  SPECS: {
    single: 'spec',
    plural: 'specs',
    state: 'specs',
  },
  PROJECTS: {
    single: 'specProject',
    plural: 'specProjects',
    state: 'specProjects',
  },
  SCHEDULES: {
    single: 'schedule',
    plural: 'schedules',
    collection: 'schedulesAttributes',
    state: 'schedules',
  },
  SIZES: {
    single: 'size',
    plural: 'sizes',
    collection: 'sizesAttributes',
    state: 'sizes',
  },
  // ECAD
  ECAD_CATALOGS: {
    single: 'ecadCatalog',
    plural: 'ecadCatalogs',
    state: 'ecadCatalogs',
  },
  // Reporting
  REPORTING_TEMPLATES: {
    single: 'reportingTemplate',
    plural: 'reportingTemplates',
    state: 'reportingTemplates',
  },
  // Framework
  DIMENSIONS: {
    single: 'profileDimension',
    plural: 'profileDimensions',
    state: 'profileDimensions'
  },
  FRAMEWORK_CATALOGS: {
    single: 'frameworkCatalog',
    plural: 'frameworkCatalogs',
    state: 'frameworkCatalogs'
  },
  PROFILES: {
    single: 'profile',
    plural: 'profiles',
    state: 'profiles'
  },
  PROFILE_PARAMETERS: {
    single: 'profileParameter',
    plural: 'profileParameters',
    state: 'profileParameters'
  },
  PROFILE_LIBRARIES: {
    single: 'profileLibrary',
    plural: 'profileLibraries',
    state: 'profileLibraries'
  },
  PROFILE_TABLES: {
    single: 'profileTable',
    plural: 'profileTables',
    state: 'profileTables'
  },
  PROFILE_TABLE_ROWS: {
    single: 'profileTableRow',
    plural: 'profileTableRows',
    state: 'profileTableRows',
  },
  PROFILE_TABLE_COLUMNS: {
    single: 'profileTableColumn',
    plural: 'profileTableColumns',
    state: 'profileTableColumns',
  },
  PROFILE_TABLE_ITEMS: {
    single: 'profileTableItem',
    plural: 'profileTableItems',
    state: 'profileTableItems',
  },
};
