import styled from 'styled-components';

export const fillTransitionClass = 'cadactive-graphic-fill-transition';

const BaseGraphicSVG = styled.svg`
  .${fillTransitionClass} {
    transition: fill ${({ theme }) => theme.transitions.speed.fast};
  }
`;

export default BaseGraphicSVG;