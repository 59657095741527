import borders from './base/borders';
import fontSizes from './base/fontSizes';
import letterSpacings from './base/letterSpacings';
import lineHeights from './base/lineHeights';
import radii from './base/radii';
import shadows from './base/shadows';
import space from './base/space';
import transitions from './base/transitions';

// Dark theme colors
const colors = {
  // Primary colors remain similar but slightly adjusted for dark mode
  primary: {
    0: '#1a365d',  // Darker background
    1: '#2a4365',
    2: '#2c5282',
    3: '#2b6cb0',
    4: '#4299e1',  // Main primary color
    5: '#63b3ed',
    6: '#90cdf4',
    7: '#bee3f8',
    8: '#ebf8ff',
  },
  // Grays are inverted for dark mode
  gray: {
    0: '#1a1a1a',  // Darkest - Main background
    1: '#2d2d2d',
    2: '#404040',
    3: '#4d4d4d',
    4: '#666666',
    5: '#808080',
    6: '#cccccc',
    7: '#e6e6e6',
    8: '#f2f2f2',  // Lightest
  },
  // Error colors adjusted for dark mode
  error: {
    0: '#3b1818',
    1: '#5c2424',
    2: '#7d2f2f',
    3: '#9e3a3a',
    4: '#e53e3e',  // Main error color
    5: '#f56565',
    6: '#fc8181',
    7: '#feb2b2',
    8: '#fff5f5',
  },
  // Success colors adjusted for dark mode
  success: {
    0: '#1c3829',
    1: '#276749',
    2: '#2f855a',
    3: '#38a169',
    4: '#48bb78',  // Main success color
    5: '#68d391',
    6: '#9ae6b4',
    7: '#c6f6d5',
    8: '#f0fff4',
  },
  // Base colors
  white: '#ffffff',
  black: '#000000',
};

export default {
  borders,
  colors,
  fontSizes,
  letterSpacings,
  lineHeights,
  radii,
  shadows,
  space,
  transitions,
};