import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';
import styled from 'styled-components';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';
import Flex from '../../../../components/common/Flex';

import RDXCheckboxInput from '../../RDXCheckboxInput';
import RDXTextInput from '../../RDXTextInput';
import RDXUserRoleRadioInput from '../../RDXUserRoleRadioInput';

import ModuleLabel, { PRODUCT_MODULES } from '../../../../entities/Organizations/components/ModuleLabel';

const InviteFieldFormSectionContainer = styled(Flex)`
  align-items: center;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
`;

const Invite = styled(Flex)`
  flex-direction: row;
  align-items: center;

  padding-left: ${({ theme }) => theme.space[4]};
  padding-right: ${({ theme }) => theme.space[4]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[0]};

  &:last-child {
    border-bottom: none;
  }
`;

const FlexibleInputContainer = styled(Box)`
  flex-grow: 1;
  height: 100%;

  > div {
    justify-content: center;
  }
`;

const FlexibleInputField = styled(Field)`
  justify-content: center;
`;

const InviteFieldModulePipingToggle = ({ checked }) => (
  <ModuleLabel
    module={PRODUCT_MODULES.PIPING}
    disabled={!checked}
  />
);

const InviteFieldModuleCablingToggle = ({ checked }) => (
  <ModuleLabel
    module={PRODUCT_MODULES.CABLING}
    disabled={!checked}
  />
);

const InviteFieldModuleFrameworkToggle = ({ checked }) => (
  <ModuleLabel
    module={PRODUCT_MODULES.FRAMEWORK}
    disabled={!checked}
  />
);

const InviteFieldModuleEcadToggle = ({ checked }) => (
  <ModuleLabel
    module={PRODUCT_MODULES.ECAD}
    disabled={!checked}
  />
);

const InviteFieldModuleReportingToggle = ({ checked }) => (
  <ModuleLabel
    module={PRODUCT_MODULES.REPORTING}
    disabled={!checked}
  />
);

// can't use form section because of this STUPID WEBPACK
const InviteFieldEmailFormSection = ({
  invite,
  isPerforming,
  ...rest
}) => (
  <InviteFieldFormSectionContainer { ...rest }>
    <FlexibleInputContainer>
      <FlexibleInputField
        component={RDXTextInput}
        name={invite ? `${invite}.email` : 'email'}
        height='100%'
        placeholder='E-mail address'
        disabled={isPerforming}
      />
    </FlexibleInputContainer>
  </InviteFieldFormSectionContainer>
);

const InviteFieldContainer = styled(Flex)`
  flex-direction: row;
  width: 70rem;
`;

const InviteFieldRoleSection = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20rem;
`;

const InviteFieldModulesSection = styled(Flex)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50rem;
`;

const InviteFieldModuleItem = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InviteFieldPermissionsFormSection = ({
  invite,
  isPerforming,
  allowedRoles,
  ...rest
}) => {
  return (
    <InviteFieldContainer flexDirection='row' { ...rest }>

      <InviteFieldRoleSection>
        <Field
          component={RDXUserRoleRadioInput}
          name={invite ? `${invite}.role` : 'role'}
          allowed={allowedRoles}
          disabled={isPerforming}
        />
      </InviteFieldRoleSection>

      <InviteFieldModulesSection>
        <InviteFieldModuleItem>
          <Field
            component={RDXCheckboxInput}
            name={invite ? `${invite}.hasModulePiping` : 'hasModulePiping'}
            toggleComponent={InviteFieldModulePipingToggle}
            disabled={isPerforming}
          />
        </InviteFieldModuleItem>

        <InviteFieldModuleItem>
          <Field
            component={RDXCheckboxInput}
            name={invite ? `${invite}.hasModuleCabling` : 'hasModuleCabling'}
            toggleComponent={InviteFieldModuleCablingToggle}
            disabled={isPerforming}
          />
        </InviteFieldModuleItem>

        <InviteFieldModuleItem>
          <Field
            component={RDXCheckboxInput}
            name={invite ? `${invite}.hasModuleFramework` : 'hasModuleFramework'}
            toggleComponent={InviteFieldModuleFrameworkToggle}
            disabled={isPerforming}
          />
        </InviteFieldModuleItem>

        <InviteFieldModuleItem>
          <Field
            component={RDXCheckboxInput}
            name={invite ? `${invite}.hasModuleEcad` : 'hasModuleEcad'}
            toggleComponent={InviteFieldModuleEcadToggle}
            disabled={isPerforming}
          />
        </InviteFieldModuleItem>

        <InviteFieldModuleItem>
          <Field
            component={RDXCheckboxInput}
            name={invite ? `${invite}.hasModuleReporting` : 'hasModuleReporting'}
            toggleComponent={InviteFieldModuleReportingToggle}
            disabled={isPerforming}
          />
        </InviteFieldModuleItem>
      </InviteFieldModulesSection>
    </InviteFieldContainer>
  );
};

const OrganizationUserInviteForm = ({ handleSubmit, isPerforming, allowedRoles }) => (
  <Box as="form" onSubmit={handleSubmit} style={{ height: '100%', overflow: 'auto' }}>
    <Invite>
      <InviteFieldEmailFormSection isPerforming={isPerforming} allowedRoles={allowedRoles} />
    </Invite>
  </Box>
);

const enhance = compose(
  reduxForm({
    form: `${EK.ORGANIZATION_USER_INVITES.state}Edit`,
    onSubmit(model, dispatch, props) {
      props.onRDXSubmit(model.serialize());
    },
  })
);

export default enhance(OrganizationUserInviteForm);

export { InviteFieldEmailFormSection, InviteFieldPermissionsFormSection };
