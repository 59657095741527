import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styled from 'styled-components';

import Box from '../../../../components/common/Box';
import Text from '../../../../components/common/Text';

const Container = styled(Box)`
  flex: 1;
  min-width: 0;
`;

const TitleText = styled(Text)`
  color: ${({ theme }) => theme.colors.gray[7]};
  font-size: ${({ theme }) => theme.fontSizes[6]};
`;

const SubtitleText = styled(Text)`
  color: ${({ theme }) => theme.colors.gray[6]};
  font-size: ${({ theme }) => theme.fontSizes[2]};
`;

const Header = React.memo(({ isLoading, title, subtitle }) => (
  <Container>
    <TitleText $ellipsis>{ !isLoading ? title : <Skeleton /> }</TitleText>
    <SubtitleText $ellipsis>{ !isLoading ? subtitle : <Skeleton /> }</SubtitleText>
  </Container>
));

export default Header;