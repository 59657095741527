import React from 'react';
import styled from 'styled-components';
import Box from '../../common/Box';

const BaseFormSectionContainer = styled(Box)`
  border: ${({ theme }) => theme.borders[1]};
  background-color: ${({ theme }) => theme.colors.gray[0]};
  border-radius: ${({ theme }) => theme.radii[1]};
  margin-bottom: ${({ theme }) => theme.space[3]};
`;

const BaseFormSection = React.forwardRef((props, ref) => (
  <BaseFormSectionContainer
    ref={ref}
    {...props}
  />
));

export default BaseFormSection;