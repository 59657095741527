import React, { useMemo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Box from '../../components/common/Box';
import Alert from '../../components/common/Alert';
import Flex from '../../components/common/Flex';
import Text from '../../components/common/Text';
import Button from '../../components/common/Button';

import CadActiveTextLogoGraphic from '../../components/common/graphics/CadActiveTextLogoGraphic';
import Rocket from '../../components/common/graphics/Rocket';

import LoginForm from '../../containers/form/templates/LoginForm';
import ExternalFullPage from '../templates/ExternalFullPage';
import { UserIsNotAuthenticated } from '../../modules/auth/utils/routes';
import { selectIsAuthError } from '../../modules/auth/selectors';
import { selectIsPerforming } from '../../modules/utility/selectors';

import { selectIsFromPasswordReset } from './selectors';
import { processLogin, processSAMLLogin } from '../../modules/auth/actions';
import injectSaga from '../../utils/sagas/injectSaga';

import loginSaga from './sagas';
import samlLoginSaga from './SAMLSaga';

import { ForgotPasswordRequestPath, RegisterPath, LoginPath } from '../../paths';

const LoginContainer = styled(Flex)`
  flex-direction: row;
  height: 100%;
  position: relative;
  padding-bottom: ${props => props.theme.space[8]};

  @media (max-width: 768px) {
    flex-direction: column;
    padding-bottom: ${props => props.theme.space[4]};
  }
`;

const Column = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
    padding: ${props => props.theme.space[4]};
  }
`;

const LeftColumn = styled(Column)``;

const LeftColumnContents = styled(Box)`
  width: 20rem;
  text-align: center;

  @media (max-width: 768px) {
    width: 12rem;
  }
`;

const RightColumn = styled(Column)`
  padding-left: ${props => props.theme.space[11]};
  padding-right: ${props => props.theme.space[11]};
  margin-top: 10rem;

  @media (max-width: 768px) {
    margin-top: ${props => props.theme.space[4]};
    padding: ${props => props.theme.space[4]};
  }
`;

const Footer = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${props => props.theme.space[4]};
  text-align: center;

  @media (max-width: 768px) {
    position: relative;
    margin-top: ${props => props.theme.space[4]};
  }
`;

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const Login = ({ login, samlLogin, isPerforming, isError, isFromPasswordReset }) => {
  const query = useQuery();
  const token = query.get('token');
  if (token) {
    samlLogin(token);
  }

  return (
    <ExternalFullPage title={LoginPath.title}>
      <LoginContainer>
        {/* Left Column - Logo and Graphics */}
        <LeftColumn>
          <LeftColumnContents>
            <Box mb={6} width="100%">
              <CadActiveTextLogoGraphic />
            </Box>

            <Box mb={6} width="100%">
              <Rocket />
            </Box>

            <Text as="span" fontSize={5} color="gray.7">
              <strong>Welcome</strong> Back
            </Text>
          </LeftColumnContents>
        </LeftColumn>

        {/* Right Column - Login Form */}
        <RightColumn>
          {isFromPasswordReset && (
            <Alert primary mb={3}>
              <Text fontSize={1} as="span">
                Your password has been successfully reset.
              </Text>
            </Alert>
          )}

          <Box mb={6} style={{ textAlign: 'center' }}>
            <Text as="span" fontSize={5} color="gray.7">
              Please <strong>Sign In</strong> to access your account
            </Text>
          </Box>

          <Box mb={5} width="100%">
            <LoginForm
              isPerforming={isPerforming}
              onRDXSubmit={login}
            />
          </Box>

          <Box mb={5} width="100%">
            <Button
              width="100%"
              $primary
              $large
              $full
              as="a"
              href="/auth/sessions/web"
            >
              Login with SSO
            </Button>
          </Box>

          {isError && (
            <Alert error mb={3}>
              <Text fontSize={1} as="span">
                We were unable to log you in. Please double check your email and password.
              </Text>
            </Alert>
          )}

          <Flex flexDirection="row" justifyContent="center" mb={4}>
            <Text
              as={isPerforming ? 'span' : Link}
              to={ForgotPasswordRequestPath.url}
              $link
            >
              Forgot your password?
            </Text>
          </Flex>
        </RightColumn>
      </LoginContainer>

      {/* Footer - Copyright Notice */}
      <Footer>
        <Text as="p" color="gray.7" fontSize={2} $m={0}>
          CadActive Technologies &copy; {new Date().getFullYear()}
        </Text>
      </Footer>
    </ExternalFullPage>
  );
};

const mapStateToProps = createStructuredSelector({
  isPerforming: selectIsPerforming(),
  isError: selectIsAuthError(),
  isFromPasswordReset: selectIsFromPasswordReset(),
});

const mapDispatchToProps = (dispatch) => ({
  login(credentials) {
    dispatch(processLogin(credentials, true));
  },
  samlLogin(token) {
    dispatch(processSAMLLogin(token));
  },
});

const enhance = compose(
  withRouter,
  injectSaga({ key: 'login', saga: loginSaga }),
  injectSaga({ key: 'samlLogin', saga: samlLoginSaga }),
  connect(mapStateToProps, mapDispatchToProps),
  UserIsNotAuthenticated
);

export default enhance(Login);
