import React from 'react';

export default function FitFlangeIcon({ size = 28, style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="0 0 28 28"
      enableBackground="new 0 0 28 28"
      xmlSpace="preserve"
      style={style}
    >
      <path d="M18.02,22h-3.981V6h3.981V22z M15.038,21h1.981V7h-1.981V21z" />
      <path d="M18.938,21.02H14.42l-6.4-3.19V10.19L14.42,7h4.517V21.02z M14.656,20.02h3.281V8h-3.281L9.02,10.81v6.4L14.656,20.02z" />
    </svg>
  );
}
