import React, { useCallback, useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import EK from '../../../../../entities/keys';

import Box from '../../../../../components/common/Box';
import Button from '../../../../../components/common/Button';
import Flex from '../../../../../components/common/Flex';
import Icon from '../../../../../components/common/Icon';
import Text from '../../../../../components/common/Text';

import NoteBlockAddNoteForm from '../NoteBlockAddNoteForm';
import NoteBlockNote from '../NoteBlockNote';

import {
  processDeleteNoteBlock,
  processSortNoteBlockNote,
  processEditNoteBlock,
} from '../../../../../entities/Standardize/NoteBlocks/actions';

import { processCreateNoteBlockNote } from '../../../../../entities/Standardize/NoteBlockNotes/actions';

import { selectCurrentNoteBlockNotes } from '../../selectors';

import { showModal } from '../../../../../modules/modal/actions';

const NoteBlockContainer = styled(Box)`
  background-color: ${({ theme }) => theme.colors.gray[0]};
  border: ${({ theme }) => theme.borders[1]};
  border-radius: ${({ theme }) => theme.radii[1]};
  box-shadow: ${({ theme }) => theme.shadows[1]};
  margin-bottom: ${({ theme }) => theme.space[3]};
`;

const NoteBlockHeader = styled(Flex)`
  padding: ${({ theme }) => theme.space[3]};
  flex-direction: row;
  align-items: center;
`;

const NoteBlockHeaderContent = styled(Flex)`
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  margin-right: ${({ theme }) => theme.space[2]};
`;

const NoteBlockHeaderTitle = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes[1]};
  color: ${({ theme }) => theme.colors.gray[7]};
  margin-bottom: ${({ theme }) => theme.space[1]};
`;

const NoteBlockHeaderSubtitle = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes[0]};
  color: ${({ theme }) => theme.colors.gray[6]};
`;

const NoteBlockActionContainer = styled(Flex)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const NoteBlockNoteListContainer = styled(Flex)`
  flex-direction: column;
  justify-content: ${({ noteBlockNotes }) => noteBlockNotes && noteBlockNotes.size > 0 ? undefined : 'center'};
  align-items: center;
  min-height: 15rem;
  padding: ${({ theme }) => theme.space[2]};
  background-color: ${({ theme }) => theme.colors.gray[1]};
`;

const NoteBlockNoteList = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.gray[4]};
  border-radius: ${({ theme }) => theme.radii[1]};
  width: 100%;
`;

const NoteBlockAddNoteFormContainer = styled(Box)`
  padding: ${({ theme }) => theme.space[3]};
`;

const NoteBlock = ({
  profileId,
  noteBlock,
  noteBlockNotes,
  editable,
  onEdit,
  onToggleLock,
  onDelete,
  onCreateNoteBlockNote,
  onMoveNoteBlockNote,
}) => {
  const onMoveComplete = useCallback(
    (noteBlockNoteId, moveIndex) => {
      const existingIndex = noteBlockNotes.findIndex(
        (noteBlockNote) => noteBlockNote.id === noteBlockNoteId
      );
      if (existingIndex !== moveIndex) {
        onMoveNoteBlockNote(noteBlockNoteId, moveIndex + 1);
      }
    },
    [noteBlockNotes, onMoveNoteBlockNote]
  );

  const onHandleToggleLock = useCallback(
    () => onToggleLock(!noteBlock.isLocked),
    [noteBlock.isLocked]
  );

  return (
    <NoteBlockContainer>
      <NoteBlockHeader>
        <NoteBlockHeaderContent>
          <NoteBlockHeaderTitle>
            {noteBlock.name}
          </NoteBlockHeaderTitle>
          <NoteBlockHeaderSubtitle>
            {noteBlock.noteFeatureName}
          </NoteBlockHeaderSubtitle>
        </NoteBlockHeaderContent>

        {editable && (
          <NoteBlockActionContainer>
            <Button
              icon={noteBlock.isLocked ? 'lock' : 'unlock'}
              onClick={onHandleToggleLock}
              $transparent
              $error
              $subtle={!noteBlock.isLocked}
              $mr={2}
              iconFillColor={noteBlock.isLocked ? 'error.4' : 'gray.5'}
            />
            <Button
              icon='edit'
              onClick={onEdit}
              $transparent
              $subtle
              $primary
              $mr={2}
            />
            <Button
              icon='delete'
              onClick={onDelete}
              $transparent
              $subtle
              $error
            />
          </NoteBlockActionContainer>
        )}
      </NoteBlockHeader>

      <NoteBlockNoteListContainer>
        {(noteBlockNotes && noteBlockNotes.size > 0 && (
          <NoteBlockNoteList>
            {noteBlockNotes.map((noteBlockNote, idx) => (
              <NoteBlockNote
                key={idx}
                index={idx}
                profileId={profileId}
                noteBlockId={noteBlock.id}
                noteBlockNote={noteBlockNote}
                editable={editable}
                isLocked={noteBlock.isLocked}
                onMoveComplete={onMoveComplete}
              />
            ))}
          </NoteBlockNoteList>
        )) || (
          <React.Fragment>
            <Text fontSize={8} fontWeight={400} color='gray.4'>
              <Icon name='inbox' />
            </Text>
            <Text fontSize={4} fontWeight={400} color='gray.4'>
              Add Notes Below
            </Text>
          </React.Fragment>
        )}
      </NoteBlockNoteListContainer>

      <NoteBlockAddNoteFormContainer>
        <NoteBlockAddNoteForm
          form={`${EK.NOTE_BLOCK_NOTES.state}-${noteBlock.id}`}
          onRDXSubmit={onCreateNoteBlockNote}
          isLocked={noteBlock.isLocked || !editable}
          noteBlockNotes={noteBlockNotes}
        />
      </NoteBlockAddNoteFormContainer>
    </NoteBlockContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  noteBlockNotes: selectCurrentNoteBlockNotes(),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCreateNoteBlockNote(data) {
    dispatch(
      processCreateNoteBlockNote(
        ownProps.profileId,
        ownProps.noteBlock.id,
        data
      )
    );
  },
  onMoveNoteBlockNote(noteBlockNoteId, sortOrder) {
    dispatch(
      processSortNoteBlockNote(
        ownProps.profileId,
        ownProps.noteBlock.id,
        noteBlockNoteId,
        sortOrder
      )
    );
  },
  onEdit() {
    dispatch(
      showModal('NOTE_BLOCK_MODAL', {
        noteProfileId: ownProps.profileId,
        noteBlockId: ownProps.noteBlock.id,
      })
    );
  },
  onToggleLock(isLocked) {
    dispatch(
      processEditNoteBlock(
        ownProps.profileId,
        ownProps.noteBlock.id,
        { isLocked }
      )
    );
  },
  onDelete() {
    dispatch(
      processDeleteNoteBlock(
        ownProps.profileId,
        ownProps.noteBlock.id
      )
    );
  },
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(NoteBlock);
