import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import Icon from '../Icon';

const fluctuateLight = ({
  theme,
  $primary,
  $secondary,
  $error,
  $success
}) => keyframes`
  0% {
    transform: scale(.5);
    background-color: ${theme.colors.white};
  }

  50% {
    background-color: ${
  (
    ($primary && theme.colors.primary[1]) ||
      ($secondary && theme.colors.gray[1]) ||
      ($error && theme.colors.error[1]) ||
      ($success && theme.colors.success[1]) ||
      theme.colors.gray[1]
  )}

  100% {
    transform: scale(1);
    background-color: ${
  (
    ($primary && theme.colors.primary[2]) ||
      ($secondary && theme.colors.gray[2]) ||
      ($error && theme.colors.error[2]) ||
      ($success && theme.colors.success[2]) ||
      theme.colors.gray[2]
  )}
  }
`;

const performingActionAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: inherit;
  font-weight: 500;
  line-height: ${({ theme }) => theme.lineHeights[1]};
  white-space: nowrap;
  text-decoration: none;
  background: none;
  border: none;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  transition: all ${({ theme }) => theme.transitions.speed.fast} ${({ theme }) => theme.transitions.type.inOut};
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  box-shadow: ${({ theme }) => theme.shadows[0]};
  outline: none;
  height: 2.5rem;
  width: ${({ $icon }) => $icon ? '2.5rem' : '10rem'};

  &:focus,
  &:active,
  &:hover {
    border: none;
    outline: none;
  }

  ${({ $tiny }) => $tiny && css`
    height: 1.2rem;
    width: ${({ $icon }) => $icon ? '1.2rem' : '4rem'};
    font-size: ${({ theme }) => theme.fontSizes[1]};
    padding: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[2]};
  `}

  ${({ $small }) => $small && css`
    height: 2rem;
    width: ${({ $icon }) => $icon ? '2rem' : '8rem'};
    font-size: ${({ theme }) => theme.fontSizes[2]};
    padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]};
  `}

  ${({ $medium }) => $medium && css`
    font-size: ${({ theme }) => theme.fontSizes[2]};
    padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[4]};
  `}

  ${({ $large }) => $large && css`
    height: 3rem;
    width: ${({ $icon }) => $icon ? '3rem' : '12rem'};
    font-size: ${({ theme }) => theme.fontSizes[3]};
    padding: ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[4]};
  `}

  ${({ $fluid }) => $fluid && css`
    width: auto;
  `}

  ${({ $full }) => $full && css`
    width: 100%;
  `}

  ${({ $primary, theme }) => $primary && css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.primary[4]};
    border-radius: ${({ theme }) => theme.radii[1]};

    &:hover:not(:disabled) {
      background-color: ${theme.colors.primary[5]};
    }

    &:active:not(:disabled) {
      background-color: ${theme.colors.primary[6]};
    }

    &:disabled {
      cursor: default;
      background-color: ${theme.colors.gray[2]};
    }
  `}

  ${({ $secondary, theme }) => $secondary && css`
    color: ${theme.colors.gray[7]};
    background-color: ${theme.colors.gray[1]};
    border-radius: ${({ theme }) => theme.radii[1]};

    &:hover:not(:disabled) {
      background-color: ${theme.colors.gray[2]};
    }

    &:active:not(:disabled) {
      background-color: ${theme.colors.gray[3]};
    }

    &:disabled {
      cursor: default;
      color: ${theme.colors.gray[5]};
      background-color: ${theme.colors.gray[1]};
    }
  `}

  ${({ $error, theme }) => $error && css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.error[4]};
    border-radius: ${({ theme }) => theme.radii[1]};

    &:hover:not(:disabled) {
      background-color: ${theme.colors.error[5]};
    }

    &:active:not(:disabled) {
      background-color: ${theme.colors.error[6]};
    }

    &:disabled {
      cursor: default;
      background-color: ${theme.colors.gray[2]};
    }
  `}

  ${({ $success, theme }) => $success && css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.success[4]};
    border-radius: ${({ theme }) => theme.radii[1]};

    &:hover:not(:disabled) {
      background-color: ${theme.colors.success[5]};
    }

    &:active:not(:disabled) {
      background-color: ${theme.colors.success[6]};
    }

    &:disabled {
      cursor: default;
      background-color: ${theme.colors.gray[2]};
    }
  `}

  ${({ $loading, theme, $primary, $secondary, $error, $success }) => $loading && css`
    cursor: default;
    position: relative;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      animation: ${fluctuateLight({ theme, $primary, $secondary, $error, $success })} 1000ms linear infinite;
    }
  `}

  ${({ $isPerformingAction }) => $isPerformingAction && css`
    cursor: default;
    position: relative;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 30%;
      height: 100%;
      background: linear-gradient(
        90deg,
        transparent 0%,
        ${({ theme }) => theme.colors.gray[2]} 50%,
        transparent 100%
      );
      animation: ${performingActionAnimation} 1s ease-in-out infinite;
    }
  `}

  ${({ $subtle, theme }) => $subtle && css`
    color: ${theme.colors.gray[7]};
    background-color: transparent;
    border-radius: ${({ theme }) => theme.radii[1]};

    &:hover:not(:disabled) {
      background-color: ${theme.colors.gray[1]};
      border-color: ${theme.colors.gray[5]};
    }

    &:active:not(:disabled) {
      background-color: ${theme.colors.gray[2]};
    }

    &:disabled {
      cursor: default;
      color: ${theme.colors.gray[5]};
      border-color: ${theme.colors.gray[3]};
    }
  `}

  ${({ $transparent }) => $transparent && css`
    background-color: transparent;
    box-shadow: none;

    &:hover:not(:disabled) {
      background-color: rgba(0, 0, 0, 0.05);
    }

    &:active:not(:disabled) {
      background-color: rgba(0, 0, 0, 0.1);
    }
  `}

  margin: ${props => {
    if (typeof props.m === 'number') {
      return props.theme.space[props.m] || `${props.m}rem`;
    }
    return props.m;
  }};
  margin-top: ${props => {
    if (typeof props.mt === 'number') {
      return props.theme.space[props.mt] || `${props.mt}rem`;
    }
    return props.mt;
  }};
  margin-right: ${props => {
    if (typeof props.mr === 'number') {
      return props.theme.space[props.mr] || `${props.mr}rem`;
    }
    return props.mr;
  }};
  margin-bottom: ${props => {
    if (typeof props.mb === 'number') {
      return props.theme.space[props.mb] || `${props.mb}rem`;
    }
    return props.mb;
  }};
  margin-left: ${props => {
    if (typeof props.ml === 'number') {
      return props.theme.space[props.ml] || `${props.ml}rem`;
    }
    return props.ml;
  }};
`;

const StyledIcon = styled(Icon)`
  margin-right: ${({ $hasChildren, theme }) => $hasChildren ? theme.space[2] : 0};
  ${({ $customStyles }) => $customStyles};
`;

const Button = React.forwardRef(({
  primary,
  secondary,
  error,
  success,
  subtle,
  transparent,
  loading,
  isPerformingAction,
  icon,
  iconStyle,
  iconSize,
  iconFillColor,
  tiny,
  small,
  medium,
  large,
  fluid,
  full,
  children,
  ...props
}, ref) => {
  const iconStyles = Object.entries(iconStyle || {})
    .map(([key, value]) => `${key}: ${value};`)
    .join('\n');

  return (
    <StyledButton
      ref={ref}
      $primary={primary}
      $secondary={secondary}
      $error={error}
      $success={success}
      $subtle={subtle}
      $transparent={transparent}
      $loading={loading}
      $isPerformingAction={isPerformingAction}
      $icon={icon}
      $tiny={tiny}
      $small={small}
      $medium={medium}
      $large={large}
      $fluid={fluid}
      $full={full}
      disabled={loading || isPerformingAction || props.disabled}
      {...props}
    >
      {icon && (
        <StyledIcon
          name={icon}
          $hasChildren={!!children}
          $customStyles={iconStyles}
          size={iconSize}
          color={iconFillColor}
        />
      )}
      {children}
    </StyledButton>
  );
});

export default Button;
