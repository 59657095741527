import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { List } from 'immutable';

// Primereact imports
import { DataTable as JSDataTable } from 'primereact/datatable';
import { Column, ColumnEditorOptions, ColumnEvent } from 'primereact/column';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { ColorPicker, ColorPickerChangeEvent, ColorPickerRGBType, ColorPickerHSBType } from 'primereact/colorpicker';
import { InputText } from 'primereact/inputtext';
import DataTableContainer from '../../../components/primeGrid/DataTableContainer';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';
import './styles.css';

// selectors
import { selectCurrentOrganization } from '../../../entities/CurrentUser/selectors';
import { selectNormalizedAppearancesArray } from '../../../entities/Synchronize/Appearances/selectors';
import { selectSynchronizeModulePermissionsAndState } from '../../Dashboard/selectors';

//header imports
import JSHeader from '../../templates/Structures/Header';
import { SynchronizeModuleAppearancesPath } from '../../../paths';
import JSFlex from '../../../components/common/Flex';
import JSOptions from '../../templates/Structures/Options';

// page-specific components
import {
  ColorTypeOptions,
  DropdownItemTemplate,
  EntityTools
} from './components';

// dispatch processes
import {
  processFetchAllAppearances,
  processSaveAppearances
} from '../../../entities/Synchronize/Appearances/actions';

// general component imports
import Text from '../../../components/common/Text';

//types
import {
  RowProps,
  DeletedRowProps,
  ChangesProps,
  CollectionRowProps,
  CollectionDataProps
} from './types';

const Flex = JSFlex as any;
const Header = JSHeader as any;
const Options = JSOptions as any;
const DataTable = JSDataTable as any;

const mapStateToProps = createSelector(
  selectSynchronizeModulePermissionsAndState(),
  selectCurrentOrganization(),
  selectNormalizedAppearancesArray(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      canAdmin,
      hasValidLicense,
      ...rest
    }: any,
    currentOrganization,
    data,
  ): any => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      data: (!isLoading && data) || [],
      editable: hasValidLicense &&
        (canAdmin || canCollaborate),
      canAdmin,
      canCollaborate,
      currentOrganizationId: currentOrganization.id,
      hasValidLicense,
    };
  },
);


export default function (): JSX.Element {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const originalRows = useSelector(selectNormalizedAppearancesArray());
  const useableRows = List(originalRows);

  //used to store any changes made on the grid for api use
  const [changes, setChanges] = useState<ChangesProps>({
    edited: [],
    created: [],
    deleted: [],
  });
  //used to store changes for viewing/editing on the table
  const [rows, setRows] = useState<RowProps[]>([]);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editedAppearance, setEditedAppearance] = useState<RowProps>();

  const saveChanges = () => {
    const collectionsdata: CollectionDataProps[] = [];

    changes.created.forEach((item: RowProps) => {
      item.isCreo ?
        collectionsdata.push({ name: item.name, is_creo: item.isCreo }) :
        collectionsdata.push({ name: item.name, is_creo: item.isCreo, color: `[${item.color.r}, ${item.color.g}, ${item.color.b}]` });
    });

    changes.edited.forEach((item: RowProps) => collectionsdata.push({ ...item, color: `[${item.color.r}, ${item.color.g}, ${item.color.b}]` }));

    changes.deleted.forEach((item: DeletedRowProps) => collectionsdata.push({ id: item.id, _destroy: '1' }));

    dispatch(processSaveAppearances(collectionsdata));

    setRows([]);
    setChanges({
      edited: [],
      created: [],
      deleted: [],
    });
  };

  const rowStyles = (data: RowProps) => {
    const currentData: RowProps = rows && rows.find((row: RowProps) => row.id == data.id) || data;
    return {
      'bg-deleted': currentData.isDeleted,
      'bg-edited': (currentData.isCreated || currentData.isEdited),
    };
  };

  const handleRowAction = (item: RowProps, iconName: 'undo' | 'delete') => {
    const originalItem = originalRows.find((itm: RowProps) => item.id == itm.id);
    const _rows: RowProps[] = [...rows];
    const _changes: ChangesProps = { ...changes };
    switch (iconName) {
    case 'undo':
      removeItemFromRows(originalItem);
      removeItemFromChanges(originalItem, 'deleted');
      break;
    case 'delete':
      removeItemFromRows(originalItem);
      _rows.push({ ...originalItem, isDeleted: true });
      removeItemFromChanges(originalItem);
      _changes.deleted.push({ ...originalItem });
      setRows(_rows);
      break;
    }
  };

  const getRowTool = (rowdata: RowProps) => {
    const data: RowProps = rows && rows.find((row: RowProps) => row.id == rowdata.id) || originalRows.find((item: RowProps) => item.id == rowdata.id);
    return (
      <EntityTools rowdata={data} handleRowAction={handleRowAction} />
    );
  };

  const handleCellChange = (
    value: ColorPickerRGBType | ColorPickerHSBType | boolean | string | undefined | null,
    field: ('name' | 'color' | 'isCreo')
  ) => {
    setEditedAppearance((prevEditedAppearance) => (
      prevEditedAppearance
        ? { ...prevEditedAppearance, [field]: value }
        : undefined
    ));
  };

  // sets the row value when a user clicks a cell
  const onBeforeCellEditShow = (e: ColumnEvent) => {
    const curRow = { ...(rows && rows.find((item: RowProps) => item.id == originalRows[e.rowIndex].id) || originalRows[e.rowIndex]) };
    setEditedAppearance(curRow);
    setIsEditing(true);
  };

  const NameBody = (rowdata: RowProps) => {
    const data: RowProps = rows && rows.find((row: RowProps) => row.id == rowdata.id) || originalRows.find((item: RowProps) => item.id == rowdata.id);

    if (data.id.includes('NEW_ROW_') && data.name == '') {
      return (
        <Text style={{ color: 'rgba(0, 0, 0, .3)' }} >new row</Text>
      );
    }

    return (<Text>{data.name}</Text>);
  };

  const NameEditor = () => {
    return (
      <InputText
        value={editedAppearance && editedAppearance.name}
        onChange={(ent: React.ChangeEvent<HTMLInputElement>) => handleCellChange(ent.target.value, 'name')}
      />
    );
  };

  const ColorBody = (rowdata: RowProps) => {
    const data: RowProps = rows && rows.find((row: RowProps) => row.id == rowdata.id) || originalRows.find((item: RowProps) => item.id == rowdata.id);
    const color = !data.isCreo && `rgb(${data.color.r}, ${data.color.g}, ${data.color.b})` || 'rgb(255, 255, 255)';

    return (
      <div
        style={{
          backgroundColor: color,
          width: '90%',
          maxWidth: '70px',
          height: '20px',
          border: '1px solid lightgrey',
          borderRadius: '5px'
        }}
      ></div>
    );
  };

  const ColorEditor = (e: ColumnEditorOptions) => {
    if (!editedAppearance || (editedAppearance && editedAppearance.id != originalRows[e.rowIndex].id) || (editedAppearance && editedAppearance.isCreo)) {
      return <></>;
    }

    return (
      <ColorPicker
        format='rgb'
        value={editedAppearance.color}
        onChange={(ent: ColorPickerChangeEvent) => handleCellChange(ent.value, 'color')}
        inline
      />
    );
  };

  const ColorTypeBody = (rowdata: RowProps) => {
    const data: RowProps = rows && rows.find((item: RowProps) => item.id == rowdata.id) || originalRows.find((item: RowProps) => item.id == rowdata.id);

    return (
      <div style={{ color: 'rgb(77, 171, 247)' }}>
        {data.isCreo ? 'Creo Color' : 'Custom Color'}
      </div>
    );
  };

  const ColorTypeEditor = (e: ColumnEditorOptions) => {
    return (
      <Dropdown
        value={editedAppearance && editedAppearance.isCreo}
        options={ColorTypeOptions}
        optionLabel="name"
        itemTemplate={DropdownItemTemplate}
        onChange={(ent: DropdownChangeEvent) => onIsCreoChange(ent, e.rowIndex)}
      />
    );
  };

  //removes item from changes
  const removeItemFromChanges = (originalItem: RowProps, key?: string) => {
    const _changes = { ...changes };
    //the change will only ever be in one of the 'edited', 'created', or 'deleted' arrays
    let editChangeItem = _changes.edited.findIndex((item: RowProps) => item.id == originalItem.id);
    if (editChangeItem != -1) {
      _changes.edited.splice(editChangeItem, 1);
      setChanges(_changes);
      return;
    }

    editChangeItem = _changes.created.findIndex((item: RowProps) => item.id == originalItem.id);
    if (editChangeItem != -1) {
      _changes.created.splice(editChangeItem, 1);
      setChanges(_changes);
      return;
    }

    editChangeItem = _changes.deleted.findIndex((item: DeletedRowProps) => item.id == originalItem.id);
    if (editChangeItem != -1) {
      _changes.deleted.splice(editChangeItem, 1);
      setChanges(_changes);
      return;
    }
  };

  const removeItemFromRows = (originalRow: RowProps) => {
    const _rows: RowProps[] = [...rows];
    const rowsItemIndex: number = _rows && _rows.findIndex((item: RowProps) => item.id == originalRow.id);
    _rows.splice(rowsItemIndex, 1);
    setRows(_rows);
  };

  const onCellEditComplete = (e: ColumnEvent) => {
    if (editedAppearance) {
      const { field, rowIndex } = e;
      setIsEditing(false);
      const _editedAppearance: RowProps = { ...editedAppearance };
      setEditedAppearance(undefined);

      if (!reduxProps.editable) {
        // user does not have permission to edit ( 'user' level permissions or invalid license )
        return;
      }

      if (_editedAppearance.isDeleted || _editedAppearance.id != originalRows[rowIndex].id) {
        return;
      }

      const _rows: RowProps[] = [...rows];
      const _changes: ChangesProps = { ...changes };

      // if the item is the same as the original item, remove it from the rows
      if (JSON.stringify({ ..._editedAppearance, isEdited: false, isDeleted: false, isCreated: false }) === JSON.stringify({ ...originalRows[rowIndex] })) {
        const rowsItemIndex = _rows && _rows.findIndex((item: RowProps) => item.id == originalRows[rowIndex].id);
        if (rowsItemIndex != -1) {
          _rows.splice(rowsItemIndex, 1);
          removeItemFromChanges(originalRows[rowIndex]);
          setRows(_rows);
          return;
        }
        return;
      }

      // if it is a new item, add it to rows and changes
      if (originalRows[rowIndex].id.includes('NEW_ROW')) {
        const editedRowIndex: number = (_rows.findIndex((item: RowProps) => item.id == originalRows[rowIndex].id))!;
        const changeRowIndex: number = (_changes.created.findIndex((item: RowProps) => item.id == originalRows[rowIndex].id))!;
        if (editedRowIndex != -1 && changeRowIndex != -1) { // row has already been created/edited
          _rows[editedRowIndex] = { ...rows[editedRowIndex], [field]: _editedAppearance[field as keyof RowProps] };
          _changes.created[changeRowIndex] = { ...rows[editedRowIndex], [field]: _editedAppearance[field as keyof RowProps] };
        } else { // row needs to be added to the 'created rows' list
          _editedAppearance.isEdited = true;
          _editedAppearance.isCreated = true;
          _rows.push(_editedAppearance);
          _changes.created.push(_editedAppearance);
        }
        setRows(_rows);
        setChanges(_changes);
        return;
      }

      // if it is an existing item, add it to rows and changes
      const currentRowItemIndex: number = (_rows && _rows.findIndex((item: RowProps) => item.id == originalRows[rowIndex].id));
      const changesItemIndex: number = (_changes.edited && _changes.edited.findIndex((item: RowProps) => item.id == originalRows[rowIndex].id));
      if (currentRowItemIndex != -1) {
        //if the item is already in rows and changes, edit it
        _rows[currentRowItemIndex] = { ..._rows[currentRowItemIndex], [field]: _editedAppearance[field as keyof RowProps] };
        _changes.edited[changesItemIndex] = { ...changes.edited[changesItemIndex], [field]: _editedAppearance[field as keyof RowProps] };
      } else {
        //if it is not, then add it to them
        _editedAppearance.isEdited = true;
        _rows.push(_editedAppearance);
        _changes.edited.push(_editedAppearance);
      }
      setRows(_rows);
      setChanges(_changes);
    }
  };

  const onCellEditCompleteRef = useRef<(e: ColumnEvent) => void>(onCellEditComplete);

  const onIsCreoChange = (e: (DropdownChangeEvent), rowIndex: number) => {
    const { value }: { value: boolean } = e;
    //use rowIndex and e.value to get the value
    if (!reduxProps.editable) {
      // user does not have permission to edit ( 'user' level permissions or invalid license )
      return;
    }

    setIsEditing(false);
    const newAppearance: RowProps = { ...(rows && rows.find((item: RowProps) => item.id == originalRows[rowIndex].id) || originalRows[rowIndex]) };
    newAppearance.isCreo = value;

    if (newAppearance.isDeleted) {
      return;
    }

    const _rows: RowProps[] = [...rows];
    const _changes: ChangesProps = { ...changes };

    // if the item is the same as the original item
    if (JSON.stringify({ ...newAppearance, isEdited: false, isDeleted: false, isCreated: false }) === JSON.stringify({ ...originalRows[rowIndex] })) {
      const rowsItemIndex = _rows && _rows.findIndex((item: RowProps) => item.id == originalRows[rowIndex].id);
      if (rowsItemIndex != -1) {
        _rows.splice(rowsItemIndex, 1);
        removeItemFromChanges(originalRows[rowIndex]);
        setRows(_rows);
        return;
      }
      return;
    }

    // if it is a new item
    if (originalRows[rowIndex].id.includes('NEW_ROW')) {
      const editedRow: RowProps = (_rows.find((item: RowProps) => item.id == originalRows[rowIndex].id))!;
      const changeRow: RowProps = (_changes.created.find((item: RowProps) => item.id == originalRows[rowIndex].id))!;
      if (editedRow) { // row has already been created/edited
        editedRow['isCreo'] = value;
        changeRow['isCreo'] = value;
      } else { // row needs to be added to the 'created rows' list
        newAppearance.isEdited = true;
        _rows.push(newAppearance);
        _changes.created.push(newAppearance);
      }
      setRows(_rows);
      setChanges(_changes);
      return;
    }

    // if it is an existing item
    const currentRowItem: RowProps = (_rows && _rows.find((item: RowProps) => item.id == originalRows[rowIndex].id) as RowProps);
    const changesItem: RowProps = (_changes.edited && _changes.edited.find((item: RowProps) => item.id == originalRows[rowIndex].id) as RowProps);
    if (currentRowItem) {
      currentRowItem['isCreo'] = e.value;
      if (changesItem) {
        changesItem['isCreo'] = e.value;
      }
    } else {
      newAppearance.isEdited = true;
      _rows.push(newAppearance);
      _changes.edited.push(newAppearance);
    }
    setRows(_rows);
    setChanges(_changes);
  };

  useEffect(() => {
    //ref to solve value persistence (updates the function to use current values on state change)
    onCellEditCompleteRef.current = onCellEditComplete;
  }, [editedAppearance]);

  useEffect(() => {
    dispatch(processFetchAllAppearances());
  }, []);

  return (
    <>
      <Flex flexDirection="row" mb={4}>
        <Header
          isLoading={reduxProps.isLoading}
          title={SynchronizeModuleAppearancesPath.defaultTitle}
          subtitle={`${originalRows.length - 10 || 0} ${(!!originalRows.length && originalRows.length - 10 > 1) || !originalRows.length ? 'Appearances' : 'Appearance'} Total`}
        />
        <Options
          updateEntities={saveChanges}
          isEditingGrid={isEditing}
          isLoading={reduxProps.isLoading}
          pendingValidChanges={changes.created.length > 0 || changes.deleted.length > 0 || changes.edited.length > 0}
          canAdmin={reduxProps.canAdmin}

          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
        />
      </Flex>

      <DataTableContainer>
        <DataTable
          value={useableRows}
          tableStyle={{ minWidth: '50rem' }}
          rowClassName={rowStyles}
          size='normal'
          editMode='cell'

          scrollable
          scrollHeight='flex'
        >
          <Column
            header=''
            style={{ width: '2%' }}
            body={(rowdata: RowProps) => getRowTool(rowdata)}
          />
          <Column
            field='color'
            header='Color'
            body={ColorBody}
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={ColorEditor}
            onCellEditComplete={(e: ColumnEvent) => onCellEditCompleteRef.current(e)}
            style={{ width: '10%' }}
          />
          <Column
            field='name'
            header="Name"
            style={{ width: '40%' }}
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={NameEditor}
            body={NameBody}
            onCellEditComplete={(e: ColumnEvent) => onCellEditCompleteRef.current(e)}
          />
          <Column
            field='isCreo'
            header='Color Type'
            style={{ width: '40%' }}
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={ColorTypeEditor}
            body={ColorTypeBody}
            onCellEditComplete={() => setIsEditing(false)}
          />
        </DataTable>
      </DataTableContainer>
    </>
  );
}