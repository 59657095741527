import React from 'react';
import styled from 'styled-components';

import Flex from '../../common/Flex';

import BaseFormCell from '../BaseFormCell';

const Row = styled(Flex)`
  flex-direction: row;
  border-bottom: ${({ theme }) => theme.borders[1]};

  &:last-child {
    border-bottom: none;
  }

  & ${BaseFormCell} {
    border-right: ${({ theme }) => theme.borders[1]};
  }

  & ${BaseFormCell}:last-child {
    border-right: none;
  }

  &:first-child ${BaseFormCell}:first-child {
    border-top-left-radius: ${({ theme }) => theme.radii[1]};
  }
  &:first-child ${BaseFormCell}:last-child {
    border-top-right-radius: ${({ theme }) => theme.radii[1]};
  }
  &:last-child ${BaseFormCell}:first-child {
    border-bottom-left-radius: ${({ theme }) => theme.radii[1]};
  }
  &:last-child ${BaseFormCell}:last-child {
    border-bottom-right-radius: ${({ theme }) => theme.radii[1]};
  }
`;

const BaseFormRow = React.forwardRef((props, ref) => <Row ref={ref} { ...props } />);

export default BaseFormRow;