import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import Button from '../../components/common/Button';

import AccessRestrictedGraphic from '../../components/common/graphics/AccessRestricted';

import ExternalPage from '../templates/ExternalPage';
import ExternalPageHeader from '../templates/ExternalPage/components/ExternalPageHeader';
import ExternalPageGraphic from '../templates/ExternalPage/components/ExternalPageGraphic';
import ExternalPageText from '../templates/ExternalPage/components/ExternalPageText';
import ExternalPageAvatar from '../templates/ExternalPage/components/ExternalPageAvatar';
import ExternalPageButtons from '../templates/ExternalPage/components/ExternalPageButtons';

import {
  UserIsAuthenticated,
  UserEmailVerified,
  UserHasOrganization,
  UserIsInactive
} from '../../modules/auth/utils/routes';

import { selectIsLoggingIn } from '../../modules/auth/selectors';

import { selectCurrentUser } from '../../entities/CurrentUser/selectors';

import { handleLogout } from '../../modules/auth/actions';

import theme from '../../assets/themes/theme';

import { AccountDeactivatedPath } from '../../paths';

const AccountDeactivated = ({ isLoading, currentUser, logout }) => (
  <ExternalPage title={AccountDeactivatedPath.title}>
    <ExternalPageHeader>{ !isLoading ? 'Account Deactivated' : <Skeleton /> }</ExternalPageHeader>
    <ExternalPageText>
      {
        !isLoading ?
          <React.Fragment>Looks like your account has been <strong>deactivated</strong>. The easiest way to resolve this issue is to contact one of your organization administrators.</React.Fragment> :
          <Skeleton count={3} />
      }
    </ExternalPageText>
    <ExternalPageGraphic>
      <AccessRestrictedGraphic
        backgroundColor={theme.colors.gray[7]}
        iconColor={theme.colors.error[4]}
        iconAccentColor={theme.colors.error[6]}
        primaryColor={theme.colors.primary[4]}
        errorColor={theme.colors.error[4]}
      />
    </ExternalPageGraphic>
    <ExternalPageText>
      { !isLoading ? 'If you\'re not sure which organization members are administrators, you can always contact your current organization owner:' : <Skeleton count={3} /> }
    </ExternalPageText>
    <ExternalPageAvatar user={!isLoading && currentUser.getIn(['organization', 'owner'])} />
    <ExternalPageButtons>
      <Button
        $primary
        $large
        $full
        onClick={logout}
      >
        Log Out
      </Button>
    </ExternalPageButtons>
  </ExternalPage>
);

const mapStateToProps = createSelector(
  selectIsLoggingIn(),
  selectCurrentUser(),
  (isLoggingIn, currentUser) => ({ isLoading: isLoggingIn || !currentUser, isLoggedIn: !!currentUser, currentUser })
);

const enhance = compose(
  withRouter,
  connect(mapStateToProps, { logout: handleLogout }),
  UserIsAuthenticated,
  UserEmailVerified,
  UserHasOrganization,
  UserIsInactive,
);

export default enhance(AccountDeactivated);
