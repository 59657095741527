import styled, { css } from 'styled-components';

import Text from '../../common/Text';

const BaseCellDataItem = styled(Text).attrs(({ primary, secondary, variable, ...props }) => ({
  fontSize: secondary && 1 || undefined,
  $ellipsis: !variable,
  $secondary: secondary,
  $variable: variable,
  $isCapitalized: props.isCapitalized,
  $clickable: props.clickable,
  $disabled: props.disabled,
  ...props
}))`
  padding-bottom: ${({ theme }) => theme.space[1]};

  ${({ $variable }) => $variable && css`
    white-space: normal;
  `}

  ${({ $secondary }) => !$secondary && css`
    padding-top: ${({ theme }) => theme.space[1]};
  `}

  ${({ $isCapitalized }) => $isCapitalized && css`
    text-transform: capitalize;
  `}

  ${({ $clickable }) => $clickable && css`
    color: ${({ theme }) => theme.colors.primary[4]} !important;
  `}

  color: inherit;

  ${({ $disabled }) => $disabled && css`
    color: ${({ theme }) => theme.colors.gray[4]};
  `}
`;

export default BaseCellDataItem;