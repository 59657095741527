import React from 'react';
import styled from 'styled-components';

import ComplexCellRenderer from '../ComplexCellRenderer';
import Flex from '../../common/Flex';
import Icon from '../../common/Icon';

import { DEFAULT_SYMBOL_FILE_ICONS } from '../../../entities/Piping/FittingCategories/model';

const IconContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  height: 100%;
  float: left;
`;

export default function (option) {
  if (option.symbolFile) {
    if (DEFAULT_SYMBOL_FILE_ICONS.includes(option.symbolFile)) {
      return (
        <div style={{ maxWidth: '300px', overflow: 'ellipsis', height: '100%' }} >
          <IconContainer>
            <Icon
              style='ptc'
              name={option.symbolFile}
              $size='30px'
            />
          </IconContainer>
          <ComplexCellRenderer
            value={{ primary: option.categoryName, secondary: option.symbolFile || '--' }}
            placeholder={''}
            isCapitalized={false}
            paddingLeft='10px'
            style={{ float: 'right' }}
          />
        </div>
      );
    }
  }

  return (
    <div style={{ maxWidth: '300px', overflow: 'ellipsis', height: '100%' }} >
      <IconContainer/>
      <ComplexCellRenderer
        value={{ primary: option.categoryName, secondary: option.symbolFile || '--' }}
        placeholder={''}
        isCapitalized={false}
        paddingLeft='10px'
        style={{ float: 'right' }}
      />
    </div>
  );
}
