import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';

import BaseFormSection from '../../../../components/form/BaseFormSection';
import BaseFormRow from '../../../../components/form/BaseFormRow';

import RDXTextInput from '../../RDXTextInput';

import validateRules from '../../../../utils/validation/validateRules';
import isValidEmail from '../../../../utils/validation/isValidEmail';

const nameRegex = /^([a-z0-9\u00C0-\u02AB'´`-]{1,}\.?\s?)([a-z0-9\u00C0-\u02AB'´`-]?\.?\s?)+$/i;

const nameRules = {
  alphaSpaceCharacters: {
    message: 'Can only contain alphabetic characters and spaces',
    validator: value => value && nameRegex.test(value),
  },
};

const nameValidations = validateRules(nameRules);

const emailDescription = 'If you need to change your email, please contact our support team.';

const CurrentUserForm = ({ handleSubmit, isPerforming, valid }) => (
  <Box as='form' onSubmit={handleSubmit}>
    <BaseFormSection>
      <BaseFormRow>
        <Field
          component={RDXTextInput}
          name='email'
          label='Email'
          type='email'
          autoComplete='username'
          disabled
          description={emailDescription}
        />
      </BaseFormRow>
      <BaseFormRow>
        <Field
          component={RDXTextInput}
          name='firstName'
          label='First Name'
          required
          validate={nameValidations}
          width='50%'
        />
        <Field
          component={RDXTextInput}
          name='lastName'
          label='Last Name'
          required
          validate={nameValidations}
          width='50%'
        />
      </BaseFormRow>
      <BaseFormRow>
        <Field
          component={RDXTextInput}
          name='phoneNumber'
          label='Phone Number'
          type='tel'
        />
      </BaseFormRow>
    </BaseFormSection>

    <Button
      type="submit"
      $primary
      $large
      $full
      disabled={isPerforming || !valid}
      isPerformingAction={isPerforming}
    >
      Update
    </Button>
  </Box>
);

const enhance = compose(
  reduxForm({
    form: EK.CURRENT_USER.state,
    onSubmit(model, dispatch, props) {
      props.onRDXSubmit(model.serialize());
    },
  })
);

export default enhance(CurrentUserForm);
