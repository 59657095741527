import React from 'react';
import styled from 'styled-components';

import { USER_ROLE_TYPES } from '../../constants';

import Box from '../../../../components/common/Box';
import Flex from '../../../../components/common/Flex';

const Indicator = styled(Box)`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  margin-right: ${({ theme }) => theme.space[3]};

  transition: background-color ${({ theme }) => theme.transitions.speed.fast};
`;

const UserStatusIndicator = ({ role, pending, inactive }) => (
  <Flex flexDirection='row' alignItems='center'>
    <Indicator
      bg={
        pending ? 'success.2' : (
          inactive ? 'gray.3' : (
            // role does not matter here, so it will always be active
            'primary.4'
          )
        )
      }
    />
    <Indicator
      bg={
        pending ? 'success.2' : (
          inactive ? 'gray.3' : (
            (role === USER_ROLE_TYPES.COLLABORATOR || role === USER_ROLE_TYPES.ADMIN) ?
              'primary.4' : 'gray.3'
          )
        )
      }
    />
    <Indicator
      bg={
        pending ? 'success.2' : (
          inactive ? 'gray.3' : (
            role === USER_ROLE_TYPES.ADMIN ?
              'primary.4' : 'gray.3'
          )
        )
      }
    />

  </Flex>
);

export default UserStatusIndicator;