import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Field, reduxForm } from 'redux-form/immutable';
import { Map } from 'immutable';

import EK from '../../../../../entities/keys';

import Box from '../../../../../components/common/Box';
import Button from '../../../../../components/common/Button';

import BaseFormSection from '../../../../../components/form/BaseFormSection';
import BaseFormRow from '../../../../../components/form/BaseFormRow';

import RDXSearchSelect from '../../../RDXSearchSelect';

import UnitSystemSchema from '../../../../../entities/Synchronize/UnitSystems/schema';
import { UNIT_SYSTEM_FILTERS } from '../../../../../entities/Synchronize/UnitSystems/model';
import { selectPipingCatalogUnitSystemId } from '../../../../../entities/Piping/Catalogs/selectors';

const PipingCatalogUnitSystemForm = ({ handleSubmit, isPerforming, valid }) => (
  <Box as='form' onSubmit={handleSubmit}>
    <BaseFormSection>
      <BaseFormRow>
        <Field
          component={RDXSearchSelect}
          name='unitSystem'
          label='Unit System'
          ek={EK.UNIT_SYSTEMS}
          eFilter={UNIT_SYSTEM_FILTERS.DEFAULT}
          schema={UnitSystemSchema}
        />
      </BaseFormRow>
    </BaseFormSection>

    <Button
      type="submit"
      $primary
      $large
      $full
      disabled={isPerforming || !valid}
      isPerformingAction={isPerforming}
    >
      Save
    </Button>
  </Box>
);

const mapStateToProps = createSelector(
  selectPipingCatalogUnitSystemId(),
  defaultUnitSystemId => ({ initialValues: Map({ unitSystem: defaultUnitSystemId }) }),
);

const enhance = compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'pipingCatalogUnitSystem',
    onSubmit(model, dispatch, props) {
      props.onRDXSubmit(model.get('unitSystem'));
    },
  })
);

export default enhance(PipingCatalogUnitSystemForm);
