import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import useFloatingState from '../../../../hooks/useFloatingState';
import useIsHovering from '../../../../hooks/useIsHovering';

import Box from '../../../../components/common/Box';
import Icon from '../../../../components/common/Icon';

import Tooltip, { CONTAINERS } from '../../../../components/tooltip/Tooltip';
import TooltipBody from '../../../../components/tooltip/TooltipBody';
import TooltipSection from '../../../../components/tooltip/TooltipSection';

const CleanNavLink = styled(NavLink)`
  text-decoration: none;
`;

const BackLink = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 1.5rem;
  padding-top: 0.25rem;
  padding-left: 0.5rem;
  padding-bottom: 0.25rem;
  padding-right: 0.5rem;
  margin-right: ${({ theme }) => theme.space[3]};

  text-transform: uppercase;
  font-weight: 600;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.gray[6]};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  background-color: ${({ theme }) => theme.colors.gray[1]};
  border: 1px solid ${({ theme }) => theme.colors.gray[4]};
  border-radius: 3px;
`;

const BackLinkButton = ({ label }) => {
  const [parentRef, floating, floatingStyle] = useFloatingState({ placement: 'right' });
  const isHovering = useIsHovering(parentRef);

  return (
    <BackLink ref={parentRef}>
      <Icon name='chevron-left' color='gray.6' />
      <Box mr={1} />{ 'Back' }

      {
        label && isHovering && ReactDOM.createPortal(
          <Tooltip ref={floating} style={floatingStyle} size='fluid'>
            <TooltipBody>
              <TooltipSection small inverse>Return to <strong>{ label }</strong></TooltipSection>
            </TooltipBody>
          </Tooltip>,
          document.querySelector(CONTAINERS.TOOLTIP)
        )
      }
    </BackLink>
  );
};

const Back = React.memo(({ isLoading, to, label }) => {
  if (isLoading || !to) {
    return null;
  }

  return (
    <CleanNavLink to={to}>
      <BackLinkButton label={label} />
    </CleanNavLink>
  );
});

export default Back;