import React from 'react';
import styled from 'styled-components';
import Text from '../../common/Text';

const PlaceholderText = styled(Text)`
  color: ${({ theme }) => theme.colors.gray[5]};
`;

interface TextCellProps {
  value: string;
  placeholder: string;
}

const TextCell: React.FC<TextCellProps> = ({ value, placeholder }) => (
  value ? (
    <Text as="div">
      {value}
    </Text>
  ) : (
    <PlaceholderText as="div">
      {placeholder}
    </PlaceholderText>
  )
);

export default TextCell;