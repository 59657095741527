import { toast } from 'react-toastify';

import EK from '../../keys';

import projectClient from './client';

import { REQUEST_TYPES } from '../../../modules/api/constants';

import {
  fetchAllProjects,
  fetchIndividualProjectTypes,
  createProjectTypes,
  copyProjectTypes,
  editProjectTypes,
  archiveProjects,
  unarchiveProjects,
  saveIndividualProjectSpecs,
  deleteProjectTypes,
} from './constants';

export const processFetchAllProjects = () =>
  projectClient(
    REQUEST_TYPES.GET,
    'plural',
    fetchAllProjects,
    '/piping/spec_projects?all=true'
  );

export const processFetchIndividualProject = (projectId: string) =>
  projectClient(
    REQUEST_TYPES.GET,
    'single',
    fetchIndividualProjectTypes,
    `/piping/spec_projects/${projectId}`
  );

export const processCreateProject = (data: any) =>
  projectClient(
    REQUEST_TYPES.POST,
    'plural',
    createProjectTypes,
    '/piping/spec_projects',
    {
      data: {
        specProjects: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Project successfully created',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to create project',
      },
    }
  );

export const processCopyProject = (data: any) =>
  projectClient(
    REQUEST_TYPES.POST,
    'plural',
    copyProjectTypes,
    '/piping/spec_projects/copy',
    {
      data: {
        specProjects: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Project successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy project',
      },
    }
  );

export const processEditProject = (projectId: string, data: any) =>
  projectClient(
    REQUEST_TYPES.PATCH,
    'single',
    editProjectTypes,
    `/piping/spec_projects/${projectId}`,
    {
      data,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Changes saved',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to save changes',
      },
    }
  );

export const processArchiveProjects = (projectIds: string[]) =>
  projectClient(
    REQUEST_TYPES.ARCHIVE,
    'plural',
    archiveProjects,
    '/piping/spec_projects/archive',
    {
      data: { ids: projectIds },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Project archived',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to archive project',
      },
    }
  );

export const processUnarchiveProjects = (projectIds: string[]) =>
  projectClient(
    REQUEST_TYPES.UNARCHIVE,
    'plural',
    unarchiveProjects,
    '/piping/spec_projects/unarchive',
    {
      data: { ids: projectIds },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Project unarchived',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to unarchive project',
      },
    }
  );

export const processSaveProjectSpecs = (projectId: string, data: Array<any>) =>
  projectClient(
    REQUEST_TYPES.PATCH,
    'single',
    saveIndividualProjectSpecs,
    `/piping/spec_projects/${projectId}`,
    {
      data: { specIds: data },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Changes saved',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to save changes',
      },
    }
  );

export const processDeleteProject = (projectId: string) =>
  projectClient(
    REQUEST_TYPES.DELETE,
    'single',
    deleteProjectTypes,
    `/piping/spec_projects/${projectId}`,
    {
      deleteKey: EK.PROJECTS.state,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Project deleted',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to delete project',
      },
    }
  );