import React, { useCallback } from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
  // Typography
  font-size: ${({ $fontSize, theme }) => {
    if (typeof $fontSize === 'number') {
      return theme.fontSizes[$fontSize] || `${$fontSize}px`;
    }
    return $fontSize;
  }};
  font-weight: ${({ $fontWeight }) => $fontWeight};
  color: ${({ $color, theme }) => {
    if (!$color) return 'inherit';
    if (typeof $color === 'string' && $color.includes('.')) {
      const [colorName, shade] = $color.split('.');
      return theme.colors[colorName]?.[shade] || $color;
    }
    return theme.colors[$color] || $color;
  }};
  line-height: ${({ $lineHeight }) => $lineHeight};
  letter-spacing: ${({ $letterSpacing }) => $letterSpacing};
  text-align: ${({ $textAlign }) => $textAlign};

  // Base styles
  border: none;
  outline: none;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-family: 'proxima-soft', sans-serif;
  width: 100%;
  height: 100%;
  autofocus: false;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.primary[4]};
    color: ${({ theme }) => theme.colors.primary[4]};
    background-color: ${({ theme }) => theme.colors.white};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray[5]};
  }
`;

const BaseInput = React.forwardRef(({
  allow,
  onDisallow,
  onChange,
  customOnFocus,
  isOpen,
  clearable,
  defaultValue,
  fontSize = 2,
  color = 'gray.7',
  fontWeight,
  lineHeight,
  letterSpacing,
  textAlign,
  ...props
}, ref) => {
  const onShouldChange = useCallback(e => {
    if (allow) {
      if (allow instanceof RegExp) {
        allow.test(e.target.value) ? (onChange && onChange(e)) : (onDisallow && onDisallow(e));
      } else {
        allow(e) ? (onChange && onChange(e)) : (onDisallow && onDisallow(e));
      }
    } else {
      onChange && onChange(e);
    }
  }, [allow, onChange, onDisallow]);

  return (
    <StyledInput
      ref={ref}
      tabIndex='0'
      onChange={onShouldChange}
      onFocus={customOnFocus}
      $fontSize={fontSize}
      $color={color}
      $fontWeight={fontWeight}
      $lineHeight={lineHeight}
      $letterSpacing={letterSpacing}
      $textAlign={textAlign}
      {...props}
    />
  );
});

export default BaseInput;
