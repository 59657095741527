import React from 'react';
import styled from 'styled-components';
import { useTheme } from '../../../contexts/ThemeContext';
import Icon from '../Icon';

const ToggleButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: ${({ theme }) => theme.space[2]};
  color: ${({ theme }) => theme.colors.gray[6]};
  transition: color ${({ theme }) => theme.transitions.speed.fast} ${({ theme }) => theme.transitions.type.inOut};

  &:hover {
    color: ${({ theme }) => theme.colors.gray[7]};
  }

  &:focus {
    outline: none;
    color: ${({ theme }) => theme.colors.primary[4]};
  }
`;

const ThemeToggle = () => {
  const { isDarkMode, toggleTheme } = useTheme();

  const handleClick = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    toggleTheme();
  };

  return (
    <ToggleButton onClick={handleClick} aria-label="Toggle theme">
      <Icon name={isDarkMode ? 'sun' : 'moon'} />
    </ToggleButton>
  );
};

export default ThemeToggle;