/**
 * Sizes are based roughly on 1:sqroot(2) ratio (aug. fourth / dim. fifth) - refer to ModularScale.com
 * Assumes base font size of 16px
 * Values are given as rem, and when converted into px, should be the following values:
 * [ 12, 14, 16, 20, 24, 28, 32, 48, 64, 72, 96, 128 ]
 */

export default [
  '0.75rem',
  '0.875rem',
  '1rem',
  '1.25rem',
  '1.5rem',
  '1.75rem',
  '2rem',
  '3rem',
  '4.5rem',
  '4.5rem',
  '6rem',
  '8rem',
];
