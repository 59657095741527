import React from 'react';

import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';

// validation
export function areFieldsValid({ name, material, crossSectionShape, lineType }) {
  // takes the currentEditedRow, which is similar to the FittingFamily type
  const _VALIDATION_FIELDS = {
    name: !!name,
    material: !!material,
    crossSectionShape: !!crossSectionShape,
    lineType: !!lineType,
  };
  return _VALIDATION_FIELDS;
}

export function doRequiredFieldsExist({ name, material, crossSectionShape, lineType }) {
  // takes the VALIDATION_FIELDS type, so all values are booleans
  return !!name && !!material && !!crossSectionShape && !!lineType;
}

// Cell Bodies
export const nameBody = (rowdata, onClickAction) => {
  const transformedData = {
    primary: rowdata.name,
    to: true
  };

  return (
    <div
      onClick={() => onClickAction(rowdata.id)}
      style={{ cursor: 'pointer' }}
    >
      <ComplexCellRenderer
        value={transformedData}
        placeholder=""
        isCapitalized={true}
        paddingLeft="0px"
      />
    </div>
  );
};