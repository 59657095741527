import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';

import Button from '../../../../components/common/Button';

import { selectSidebarIsFiltering } from '../../../Dashboard/selectors';
import { toggleSidebarFiltering } from '../../../Dashboard/actions';

const StyledButton = styled(Button)`
  background-color: ${({ theme, $isFiltering }) => $isFiltering ? theme.colors.primary[4] : theme.colors.gray[1]};
  color: ${({ theme, $isFiltering }) => $isFiltering ? theme.colors.gray[6] : theme.colors.gray[6]};

  border-radius: ${({ theme }) => theme.radii[1]};
  margin-right: ${({ theme }) => theme.space[2]};

  transition: background-color 0.2s ease, color 0.2s ease, border-color 0.2s ease;

  &:hover {
    background-color: ${({ theme, $isFiltering }) => $isFiltering ? theme.colors.primary[5] : theme.colors.gray[2]};
    border-color: ${({ theme, $isFiltering }) => $isFiltering ? theme.colors.primary[5] : theme.colors.gray[4]};
  }
`;

const BaseFilterToggleButton = ({ isFiltering, toggleFiltering, ...rest }) => (
  <StyledButton
    $isFiltering={isFiltering}
    onClick={toggleFiltering}
    icon="filter"
    iconFillColor={isFiltering ? 'white' : 'gray.6'}
    {...rest}
  />
);

const mapStateToProps = createSelector(
  selectSidebarIsFiltering(),
  isFiltering => ({ isFiltering })
);

const enhance = compose(
  connect(mapStateToProps, { toggleFiltering: toggleSidebarFiltering })
);

export default enhance(BaseFilterToggleButton);