import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { formValueSelector } from 'redux-form/immutable';
import { List } from 'immutable';

import EK from '../../keys';
import selectEntities from '../../../modules/entities/selectors';
import SpecPipeInstanceSchema from './schema';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';
import { selectCurrentPipeInstances } from '../PipeInstances/selectors';
import { removeDuplicateFromObjectIdArray, selectCurrentSpec } from '../Specs/selectors';
import { convertToSpecPipeInstanceType } from '../../../routes/Piping/SpecsPipesEdit/actions';

export const selectNormalizedSpecPipeInstances = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.SPEC_PIPE_INSTANCES.state)
);

export const selectCurrentNormalizedSpecPipeInstances = (specId) =>
  createSelector(
    selectNormalizedSpecPipeInstances(),
    instances => instances.toList().filter(item => item.spec == specId)
  );

export const selectCurrentSpecPipeInstances = (specId) => createSelector(
  selectEntities(),
  selectCurrentNormalizedSpecPipeInstances(specId),
  (entities, instances) => denormalize(instances, [SpecPipeInstanceSchema], entities).toList()
);

const selectCurrentSpecPipeInstancesFromSpec = (specId) => createSelector(
  selectCurrentSpec(specId),
  (spec) => spec?.specPipeInstances || List()
);

const specFilterForm = formValueSelector(`filters.${EK.SPEC_PIPE_INSTANCES.state}`);

function removeDuplicateFromSpecPipeInstancesArray(arr) {
  let outputArray = arr.filter(function (v, i, self) {
    if (v.pipeInstance) {
      return v?.pipeInstance.id && i == self.findIndex((item) => item?.pipeInstance?.id ? item.pipeInstance.id == v.pipeInstance.id : item.id == v.pipeInstance.id);
    }
    return v?.id && i == self.findIndex((item) => item?.pipeInstance?.id ? item.pipeInstance.id == v.id : item.id == v.id);
  });

  return outputArray;
}

// Helper function to get values from either spec instance or direct instance
const getInstanceValues = (item) => {
  // If it's a spec instance
  if (item.pipeInstance) {
    const pipeInstance = item.pipeInstance;
    return {
      sizeProperty: pipeInstance.sizeProperty,
      schedule: pipeInstance.schedule,
      stockno: item.stockno || pipeInstance.stockno || '',
      mccsCode: item.mccsCode || pipeInstance.mccsCode || ''
    };
  }

  // If it's a direct instance
  return {
    sizeProperty: item.sizeProperty,
    schedule: item.schedule,
    stockno: item.stockno || '',
    mccsCode: item.mccsCode || ''
  };
};

// gets all of the instances for a pipe family, concatenates that list with the list of specpipeinstances, and then removes the duplicates
export const selectCurrentFilteredSpecPipeInstances = (specId, pipeId) => createSelector(
  selectSidebarIsFiltering(),
  selectCurrentSpecPipeInstancesFromSpec(specId),
  selectCurrentPipeInstances(pipeId),
  state => specFilterForm(state,
    'sizeProperty',
    'schedule',
    'stockno',
    'mccsCode'
  ),
  (isFiltering, instances, pipeInstances, query) => {
    const specPipeInstances = instances && instances.filter(item => typeof (item.pipeFamilyId) == 'string' ? item.pipeFamilyId == pipeId : item.pipeFamilyId?.id == pipeId);

    // merge the specpipeinstances with the pipeinstances
    const mergedInstances = specPipeInstances.concat(pipeInstances);

    // remove duplicates
    const uniqueInstances = removeDuplicateFromSpecPipeInstancesArray(mergedInstances);

    // If not filtering, return all instances
    if (!isFiltering) {
      return uniqueInstances.toArray().map(item => convertToSpecPipeInstanceType(item));
    }

    // Filter instances
    const filteredInstances = uniqueInstances.filter(item => {
      const values = getInstanceValues(item);

      // Check Size
      const querySizeProperty = query.sizeProperty;
      const sizeMatch = !querySizeProperty ||
        (values.sizeProperty && values.sizeProperty.id === (querySizeProperty.id || querySizeProperty));

      // Check Schedule
      const querySchedule = query.schedule;
      const scheduleMatch = !querySchedule ||
        (values.schedule && values.schedule.id === (querySchedule.id || querySchedule));

      // Check stockno
      const queryStockno = query.stockno || '';
      const stocknoMatch = !queryStockno || values.stockno.toLowerCase().includes(queryStockno.toLowerCase());

      // Check mccsCode
      const queryMccsCode = query.mccsCode || '';
      const mccsCodeMatch = !queryMccsCode || values.mccsCode.toLowerCase().includes(queryMccsCode.toLowerCase());

      return sizeMatch && scheduleMatch && stocknoMatch && mccsCodeMatch;
    });

    return filteredInstances.toArray().map(item => convertToSpecPipeInstanceType(item));
  }
);