import React, { useCallback, useMemo, useEffect } from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { List, Map } from 'immutable';
import styled, { css } from 'styled-components';

import {
  submit,
  isValid,
  isDirty,
} from 'redux-form/immutable';

import useStateWithCallback from '../../../../hooks/useStateWithCallback';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';
import Flex from '../../../../components/common/Flex';
import Label from '../../../../components/common/Label';
import Loading from '../../../../components/common/Loading';
import Tabs from '../../../../components/common/Tabs';
import Text from '../../../../components/common/Text';
import Typography from '../../../../components/common/Typography';

import OrganizationSettingsForm from '../../../form/templates/OrganizationSettingsForm';
import ModuleLabel, { PRODUCT_MODULES } from '../../../../entities/Organizations/components/ModuleLabel';

import UserStatusIndicator from '../../../../modules/permissions/components/UserStatusIndicator';

import {
  selectIsFetchingInModal,
  selectIsPerformingInModal,
} from '../../../../modules/utility/selectors';

import { showModal } from '../../../../modules/modal/actions';

import { USER_ROLE_TYPES } from '../../../../modules/permissions/constants';

import {
  SETTING_PRODUCTS,
  SETTING_PRODUCTS_LIST,
  SETTING_MODULES,
  SETTING_MODULES_LIST,
} from '../../../../entities/Settings/model';
import { selectNormalizedSettings } from '../../../../entities/Settings/selectors';
import { processSaveSettings } from '../../../../entities/Settings/actions';

import {
  selectCurrentUser,
  selectCurrentOrganization,
} from '../../../../entities/CurrentUser/selectors';

import {
  processDeleteOrganizationUserInvite,
  processResendOrganizationUserInvite,
} from '../../../../entities/OrganizationUserInvites/actions';

import {
  selectOrganizationDirectory,
  selectIsCurrentlyActiveModal,
} from './selectors';

import injectSaga from '../../../../utils/sagas/injectSaga';

import saga from './sagas';

const Modal = styled(Flex)`
  width: 95%;
  height: 100%;
  margin: auto;
  flex-direction: row;
  align-items: center;
  background: ${({ theme }) => theme.colors.gray[1]};
  border-radius: ${({ theme }) => theme.radii[2]};
  overflow: hidden;
`;

const ModalSidebar = styled(Flex)`
  flex-direction: column;
  width: 10%;
  height: 100%;
  padding: ${({ theme }) => theme.space[4]};
  overflow: hidden;
`;

const ModalContent = styled(Flex)`
  flex-direction: column;
  width: 90%;
  height: 100%;
  overflow: hidden;
`;

const OrganizationDetails = styled(Box)`
  flex-grow: 1;
`;

const Header = styled(Flex)`
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.space[6]};
  padding: ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[6]};
  align-items: center;
  background: ${({ theme }) => theme.colors.gray[1]};
`;

const BaseHeaderButtonGroup = styled(Flex)`
  display: inline-flex;
  flex-direction: row;
`;

const HeaderButton = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[6]};
  margin-left: ${({ theme }) => theme.space[4]};
  background: transparent;
  border: 1px solid transparent;
  color: ${({ theme }) => theme.colors.gray[7]};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  border-radius: ${({ theme }) => theme.radii[2]};
  cursor: pointer;
  font-weight: 600;

  transition: 0.2s background, 0.2s border-color, 0.2s color;

  ${({ $active }) => $active && css`
    background: ${({ theme }) => theme.colors.primary[0]};
    border-color: ${({ theme }) => theme.colors.primary[4]};
    color: ${({ theme }) => theme.colors.primary[4]};

    &:hover {
      background: ${({ theme }) => theme.colors.primary[0]};
      border-color: ${({ theme }) => theme.colors.primary[4]};
    }
  `}

  &:hover {
    color: ${({ theme }) => theme.colors.primary[4]};
  }
`;

const HeaderButtonGroup = ({ onClick, options, active, ...props }) => {
  return (
    <BaseHeaderButtonGroup {...props}>
      {options.map(option => (
        <HeaderButton
          key={option.value}
          onClick={() => onClick(option.value)}
          $active={active === option.value}
        >
          {option.label}
        </HeaderButton>
      ))}
    </BaseHeaderButtonGroup>
  );
};

const HeaderText = styled(Box)`
  flex-grow: 1;
`;

const Content = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  position: relative;
  overflow-y: hidden;

  border-top-left-radius: ${({ theme }) => theme.radii[2]};
  background: ${({ theme }) => theme.colors.gray[2]};
`;

const ContentHeader = styled(Flex)`
  flex-direction: row;
  align-items: center;

  padding: ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[6]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[0]};
`;

const ContentHeaderDescription = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

const ContentHeaderAction = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 30%;
`;

const ContentBodyContainer = styled(Flex)`
  flex-direction: column;
  flex: 1;
  min-height: 0;
  overflow: hidden;
`;

const TabsHeader = styled(Box)`
  padding: 0 ${({ theme }) => theme.space[4]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[0]};
  flex-shrink: 0;
`;

const SettingsFormContainer = styled(Box)`
  flex: 1;
  overflow-y: auto;
  padding: ${({ theme }) => theme.space[4]};
`;

const UserAccountHeader = styled(Flex)`
  flex-direction: row;
  align-items: center;
  background: ${({ theme }) => theme.colors.gray[0]};
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[6]};
  flex-shrink: 0;
`;

const UserAccountHeaderColumn = styled(Box)`
  ${({ $expand }) => $expand && css`
    flex: 1;
    min-width: 0;
  `}
  font-size: ${({ theme }) => theme.fontSizes[0]};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gray[6]};
  text-transform: uppercase;
  padding-right: ${({ theme }) => theme.space[4]};

  &:last-child {
    padding-right: 0;
  }
`;

const UserAccountBodyContainer = styled(Flex)`
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  min-height: 0;
`;

const UserAccountRowContainer = styled(Flex)`
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 4rem;
  padding: 0 ${({ theme }) => theme.space[6]};
  background: transparent;
  border-bottom: ${({ theme }) => theme.borders[1]};
  transition: 0.2s background;

  &:hover {
    background: ${({ theme }) => theme.colors.primary[0]};
  }

  &:last-child {
    border-bottom: none;
  }
`;

const AccountDetails = styled(Flex)`
  flex-direction: row;
  align-items: center;
  min-width: fit-content;

  ${({ onClick }) => onClick && css`
    cursor: pointer;
  `}
`;

const NameColumn = styled(Flex)`
  width: 15rem;
  flex-direction: column;
  justify-content: center;
  margin-right: ${({ theme }) => theme.space[4]};
`;

const EmailColumn = styled(Flex)`
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding-right: ${({ theme }) => theme.space[4]};
  min-width: 0;
`;

const RoleColumn = styled(Flex)`
  width: 10rem;
  flex-direction: row;
  align-items: center;
  padding-right: ${({ theme }) => theme.space[4]};
`;

const ModulesColumn = styled(Flex)`
  width: 30rem;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const ActionColumn = styled(Flex)`
  width: 5rem;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const UserAccount = ({ style, account, isOwner, onEdit, onResend, onDelete }) => {
  const isNamePresent = account.isNamePresent;
  const accountIsInactive = account.activeFlag === false;
  const accountIsPending = account.isPending === true;

  const onHandleEdit = useCallback(() => { onEdit && onEdit(account); }, [account, onEdit]);
  const onHandleResend = useCallback(() => { onResend && onResend(account); }, [account, onResend]);
  const onHandleDelete = useCallback(() => { onDelete && onDelete(account); }, [account, onDelete]);

  return (
    <UserAccountRowContainer style={style}>

      <NameColumn>
        <AccountDetails onClick={!account.isPending && !!onEdit && onHandleEdit || undefined}>
          <Text color="gray.7" fontSize={2} fontWeight="600">
            {isNamePresent ? account.fullName : account.email}
          </Text>
          {isOwner &&
            <Label $primary style={{ marginLeft: '0.5rem' }}>
              Owner
            </Label>
          }
        </AccountDetails>
      </NameColumn>

      <EmailColumn>
        <Text color="gray.6" fontWeight="400" fontSize={1}>
          {account.email}
        </Text>
      </EmailColumn>

      {
        accountIsInactive ? (
          <RoleColumn justifyContent='flex-end'>
            <React.Fragment>
              <Text color='gray.6' fontSize={1} fontWeight='500'>
                Disabled
              </Text>
            </React.Fragment>
          </RoleColumn>
        ) : (
          <RoleColumn justifyContent='flex-start'>
            {accountIsPending ? (
              <React.Fragment>
                <UserStatusIndicator pending />
                <Text color='success.4' fontSize={1} fontWeight='500'>
                  Sent
                </Text>
              </React.Fragment>
            ) : account.role === USER_ROLE_TYPES.ADMIN ? (
              <React.Fragment>
                <Text color='gray.7' fontSize={1} fontWeight='500'>
                  Admin
                </Text>
              </React.Fragment>
            ) : account.role === USER_ROLE_TYPES.COLLABORATOR ? (
              <React.Fragment>
                <Text color='gray.7' fontSize={1} fontWeight='500'>
                  Collaborator
                </Text>
              </React.Fragment>
            ) : account.role === USER_ROLE_TYPES.USER ? (
              <React.Fragment>
                <Text color='gray.7' fontSize={1} fontWeight='500'>
                  User
                </Text>
              </React.Fragment>
            ) : null}
          </RoleColumn>
        )
      }

      <ModulesColumn>
        <ModuleLabel
          module={PRODUCT_MODULES.PIPING}
          disabled={!account.hasModulePiping}
          style={{ marginRight: '0.5rem' }}
        />
        <ModuleLabel
          module={PRODUCT_MODULES.CABLING}
          disabled={!account.hasModuleCabling}
          style={{ marginRight: '0.5rem' }}
        />
        <ModuleLabel
          module={PRODUCT_MODULES.FRAMEWORK}
          disabled={!account.hasModuleFramework}
          style={{ marginRight: '0.5rem' }}
        />
        <ModuleLabel
          module={PRODUCT_MODULES.ECAD}
          disabled={!account.hasModuleEcad}
          style={{ marginRight: '0.5rem' }}
        />
        <ModuleLabel
          module={PRODUCT_MODULES.REPORTING}
          disabled={!account.hasModuleReporting}
          style={{ marginRight: '0' }}
        />
      </ModulesColumn>

      <ActionColumn>
        {!account.isPending && onEdit && (
          <Button
            icon='edit'
            onClick={onHandleEdit}
            $transparent
            $subtle
            $primary
          />
        )}
        {onResend && (
          <Button
            icon='mail-outline'
            onClick={onHandleResend}
            $transparent
            $subtle
          />
        )}
        {onDelete && (
          <Button
            icon='delete'
            onClick={onHandleDelete}
            $transparent
            $subtle
            $error
          />
        )}
      </ActionColumn>
    </UserAccountRowContainer>
  );
};

const createSelectInitialSettingsSelector = () => createSelector(
  selectNormalizedSettings(),
  (_, productTier) => productTier,
  (settings, productTier = null) => Map({
    settings: settings.filter(setting => {
      // For module tabs, filter by productModule
      if (Object.values(SETTING_MODULES).includes(productTier)) {
        return setting.productModule === productTier && !setting.productTier;
      }
      // For product tabs, filter by productTier
      if (Object.values(SETTING_PRODUCTS).includes(productTier)) {
        return setting.productTier === productTier && !setting.productModule;
      }
      return false;
    })
  }),
);

export const ORGANIZATION_SECTIONS = {
  DIRECTORY: 'directory',
  SETTINGS: 'settings',
};

const ORGANIZATION_SECTIONS_OPTIONS = List([
  {
    value: ORGANIZATION_SECTIONS.DIRECTORY,
    label: 'Directory',
  },
  {
    value: ORGANIZATION_SECTIONS.SETTINGS,
    label: 'Settings',
  },
]);

const SETTINGS_FORM_KEY = EK.SETTINGS.state;

const OrganizationModal = ({
  isFetching,
  isPerforming,
  currentUser,
  organization,
  directory = {},
  productTier: initialProductTier,
  section: initialSection,
  onCreateInvite,
  onEditUser,
  onDeleteInvite,
  onResendInvite,
  isCurrentlyActiveModal,
  submitSettings,
  onSubmitSettings,
  onEditPipingCatalogUnitSystem,
}) => {
  const isAdmin = currentUser.isAdmin;
  const orgOwner = organization.owner;

  const {
    accounts,
    activeUserCount,
    activeUserModulePipingCount,
    activeUserModuleCablingCount,
    activeUserModuleFrameworkCount,
    activeUserModuleReportingCount,
    activeUserModuleEcadCount,
  } = directory;

  const selectInitialSettings = useMemo(
    createSelectInitialSettingsSelector,
    []
  );

  const [section, setSection] = useStateWithCallback(initialSection || ORGANIZATION_SECTIONS.DIRECTORY);
  const [productTier, setProductTier] = useStateWithCallback(initialProductTier || SETTING_PRODUCTS.SYNCHRONIZE);

  const initialSettings = useSelector(state => selectInitialSettings(state, productTier));

  const onDelete = useCallback(invite => {
    onDeleteInvite(organization.id, invite.id);
  }, [organization, onDeleteInvite]);

  const onResend = useCallback(invite => {
    onResendInvite(organization.id, invite.id);
  }, [organization, onResendInvite]);

  return isFetching ? <Loading /> : (
    <Modal>
      <ModalSidebar>
        <OrganizationDetails>
          <Typography.F1 label="Organization">
            {organization.name}
          </Typography.F1>

          <Typography.F2
            label="Plan"
            sub={`Renewal: ${organization.productExpirationFormatted}`}
            capitalize
          >
            {organization.productTier}
          </Typography.F2>

          <Typography.F2 label='Active Users'>
            <Text
              as='span'
              color={organization.productTierUserCountErrorFlag ? 'error.4' : (activeUserCount >= organization.productTierUserCount ? 'gray.7' : 'primary.4')}
            >
              {activeUserCount}
            </Text>/{organization.productTierUserCount}
          </Typography.F2>

          {
            organization.modulePipingUserCount === 0 ? (
              <Typography.F3 label='Piping Module'>
                <Text as='span' color='gray.6'>Disabled</Text>
              </Typography.F3>
            ) : (
              <Typography.F2 label='Piping Module Users'>
                <Text
                  as='span'
                  color={organization.modulePipingUserCountErrorFlag ? 'error.4' : (activeUserModulePipingCount >= organization.modulePipingUserCount ? 'gray.7' : 'primary.4')}
                >
                  {activeUserModulePipingCount}
                </Text>/{organization.modulePipingUserCount}
              </Typography.F2>
            )
          }
          {
            organization.moduleCablingUserCount === 0 ? (
              <Typography.F3 label='Cabling Module'>
                <Text as='span' color='gray.6'>Disabled</Text>
              </Typography.F3>
            ) : (
              <Typography.F2 label='Cabling Module Users'>
                <Text
                  as='span'
                  color={organization.moduleCablingUserCountErrorFlag ? 'error.4' : (activeUserModuleCablingCount >= organization.moduleCablingUserCount ? 'gray.7' : 'primary.4')}
                >
                  {activeUserModuleCablingCount}
                </Text>/{organization.moduleCablingUserCount}
              </Typography.F2>
            )
          }
          {
            organization.moduleFrameworkUserCount === 0 ? (
              <Typography.F3 label='Framework Module'>
                <Text as='span' color='gray.6'>Disabled</Text>
              </Typography.F3>
            ) : (
              <Typography.F2 label='Framework Module Users'>
                <Text
                  as='span'
                  color={organization.moduleFrameworkUserCountErrorFlag ? 'error.4' : (activeUserModuleFrameworkCount >= organization.moduleFrameworkUserCount ? 'gray.7' : 'primary.4')}
                >
                  {activeUserModuleFrameworkCount}
                </Text>/{organization.moduleFrameworkUserCount}
              </Typography.F2>
            )
          }
          {
            organization.moduleEcadUserCount === 0 ? (
              <Typography.F3 label='ECAD Module'>
                <Text as='span' color='gray.6'>Disabled</Text>
              </Typography.F3>
            ) : (
              <Typography.F2 label='ECAD Module Users'>
                <Text
                  as='span'
                  color={organization.moduleEcadUserCountErrorFlag ? 'error.4' : (activeUserModuleEcadCount >= organization.moduleEcadUserCount ? 'gray.7' : 'primary.4')}
                >
                  {activeUserModuleEcadCount}
                </Text>/{organization.moduleEcadUserCount}
              </Typography.F2>
            )
          }
          {
            organization.moduleReportingUserCount === 0 ? (
              <Typography.F3 label='Reporting Module'>
                <Text as='span' color='gray.6'>Disabled</Text>
              </Typography.F3>
            ) : (
              <Typography.F2 label='Reporting Module Users'>
                <Text
                  as='span'
                  color={organization.moduleReportingUserCountErrorFlag ? 'error.4' : (activeUserModuleReportingCount >= organization.moduleReportingUserCount ? 'gray.7' : 'primary.4')}
                >
                  {activeUserModuleReportingCount}
                </Text>/{organization.moduleReportingUserCount}
              </Typography.F2>
            )
          }
        </OrganizationDetails>
        <Typography.F3 label='Owner' sub={orgOwner.email} mb={null}>{orgOwner.fullName}</Typography.F3>
      </ModalSidebar>

      <ModalContent>
        <Header>
          <HeaderText>
            <Text
              color='gray.7'
              fontSize={5}
            >
              {section === ORGANIZATION_SECTIONS.SETTINGS ? 'Organization Settings' : 'Accounts & Invites'}
            </Text>
          </HeaderText>
          <HeaderButtonGroup
            onClick={setSection}
            options={ORGANIZATION_SECTIONS_OPTIONS}
            active={section}
          />
        </Header>

        <Content>
          <ContentHeader>
            <ContentHeaderDescription>
              {
                section === ORGANIZATION_SECTIONS.SETTINGS && (
                  <Text color='gray.7' fontSize={1}>
                    Manage the various settings for your CadActive modules.&nbsp;
                    Please note that these settings here <strong>will affect your entire organization</strong>.</Text>
                ) || (
                  <Text color='gray.7' fontSize={1}>
                    View your organization&apos;s accounts, invite or create new users, and manage user permissions and product privileges.
                  </Text>
                )
              }
            </ContentHeaderDescription>
            <ContentHeaderAction>
              {
                section === ORGANIZATION_SECTIONS.SETTINGS && (
                  <Button
                    $primary
                    onClick={submitSettings}
                    disabled={isPerforming}
                  >
                    Save Changes
                  </Button>
                ) || (
                  <Button
                    $primary
                    onClick={onCreateInvite}
                  >
                    Invite Users
                  </Button>
                )
              }
            </ContentHeaderAction>
          </ContentHeader>

          <ContentBodyContainer>
            { // User Accounts
              section === ORGANIZATION_SECTIONS.DIRECTORY && (
                <>
                  <UserAccountHeader>
                    <UserAccountHeaderColumn width='15rem'>User</UserAccountHeaderColumn>
                    <UserAccountHeaderColumn $expand>Email</UserAccountHeaderColumn>
                    <UserAccountHeaderColumn width='10rem'>Role</UserAccountHeaderColumn>
                    <UserAccountHeaderColumn width='30rem'>Modules</UserAccountHeaderColumn>
                    <UserAccountHeaderColumn width='5rem'></UserAccountHeaderColumn>
                  </UserAccountHeader>

                  <UserAccountBodyContainer>
                    {
                      accounts.size && accounts.map((account, index) => (
                        <UserAccount
                          key={index}
                          index={index}
                          account={account}
                          isOwner={!account.isPending && account.id === orgOwner.id}
                          onEdit={isAdmin && !account.isPending && onEditUser || null}
                          onDelete={isAdmin && account.isPending && onDelete || null}
                          onResend={isAdmin && account.isPending && onResend || null}
                        />
                      ))
                    }
                  </UserAccountBodyContainer>
                </>
              )
            }
            { // Settings
              section === ORGANIZATION_SECTIONS.SETTINGS && (
                <>
                  <TabsHeader>
                    <Tabs
                      tabs={[...SETTING_PRODUCTS_LIST.toJS(), ...SETTING_MODULES_LIST.toJS()]}
                      activeTabId={productTier}
                      onChange={setProductTier}
                    />
                  </TabsHeader>

                  <SettingsFormContainer>
                    <OrganizationSettingsForm
                      form={SETTINGS_FORM_KEY}
                      enableReinitialize
                      initialValues={initialSettings}
                      active={productTier}
                      isPerforming={isPerforming}
                      onRDXSubmit={onSubmitSettings}
                    />
                  </SettingsFormContainer>
                </>
              )
            }
          </ContentBodyContainer>
        </Content>
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = createStructuredSelector({
  isFetching: selectIsFetchingInModal(),
  isPerforming: selectIsPerformingInModal(),
  currentUser: selectCurrentUser(),
  organization: selectCurrentOrganization(),
  directory: selectOrganizationDirectory(),
  isCurrentlyActiveModal: selectIsCurrentlyActiveModal(),
  isSettingsValid: isValid(SETTINGS_FORM_KEY),
  isSettingsDirty: isDirty(SETTINGS_FORM_KEY),
});

const mapDispatchToProps = dispatch => ({
  onCreateInvite() {
    dispatch(showModal('ORGANIZATION_USER_INVITES_MODAL'));
  },
  onDeleteInvite(organizationId, inviteId) {
    dispatch(processDeleteOrganizationUserInvite(organizationId, inviteId));
  },
  onResendInvite(organizationId, inviteId) {
    dispatch(processResendOrganizationUserInvite(organizationId, inviteId));
  },
  onCreateUser() {
    dispatch(showModal('CREATE_ORGANIZATION_USER_MODAL'));
  },
  onEditUser(user) {
    dispatch(showModal('ORGANIZATION_USER_MODAL', { userId: user.id }));
  },
  onEditPipingCatalogUnitSystem() {
    dispatch(showModal('PIPING_CATALOG_UNIT_SYSTEM_MODAL'));
  },
  submitSettings() {
    dispatch(submit(SETTINGS_FORM_KEY));
  },
  onSubmitSettings(settings) {
    dispatch(processSaveSettings(null, settings));
  },
});

const enhance = compose(
  injectSaga({ key: `${EK.ORGANIZATIONS.state}Modal`, saga }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(OrganizationModal);
