import { createSelector } from 'reselect';

import selectStatus from '../status/selectors';
import selectModal from '../modal/selectors';

/**
 * Computed Status selectors
 */
const selectIsFetchingOrConsolidating = () =>
  createSelector(
    selectStatus(),
    (status) =>
      !!(status.get('isFetching') || status.get('isConsolidatingFetch'))
  );
const selectIsPerformingOrConsolidating = () =>
  createSelector(
    selectStatus(),
    (status) =>
      !!(status.get('isPerforming') || status.get('isConsolidatingPerform'))
  );

export const selectIsFetching = () =>
  createSelector(
    selectIsFetchingOrConsolidating(),
    selectModal(),
    (isFetching, modals) => isFetching && modals.size === 0
  );

export const selectIsFetchingInModal = () =>
  createSelector(
    selectIsFetchingOrConsolidating(),
    selectModal(),
    (isFetching, modals) => isFetching && modals.size > 0
  );

export const selectIsPerforming = () =>
  createSelector(
    selectIsPerformingOrConsolidating(),
    selectModal(),
    (isPerforming, modals) => isPerforming && modals.size === 0
  );

export const selectIsPerformingInModal = () =>
  createSelector(
    selectIsPerformingOrConsolidating(),
    selectModal(),
    (isPerforming, modals) => isPerforming && modals.size > 0
  );
