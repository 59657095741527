import React from 'react';
import Avatar from 'react-avatar';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import Box from '../../../../../components/common/Box';
import Flex from '../../../../../components/common/Flex';
import Text from '../../../../../components/common/Text';

const ExternalPageAvatar = ({ user }) => (
  <Flex
    flexDirection='row'
    justifyContent='center'
    style={{
      backgroundColor: theme.colors.white,
      paddingTop: theme.space[3],
      paddingRight: theme.space[3],
      paddingBottom: theme.space[3],
      paddingLeft: theme.space[3],
      marginBottom: theme.space[6],
      border: theme.borders[2],
      borderRadius: theme.radii[2],
    }}
  >
    {
      !!user &&
      <Box mr={4}>
        <Avatar email={user.email} size={50} name={user.fullName} />
      </Box>
    }

    <Flex
      flexDirection="column"
      justifyContent="center"
      flex="1 1 auto"
    >
      <Text
        as="p"
        color="gray.8"
        fontSize={3}
        m={0}
        p={0}
      >
        {user ? user.fullName : <Skeleton />}
      </Text>
      <Text
        as="p"
        color="gray.6"
        fontSize={2}
        m={0}
        p={0}
      >
        {user ? user.email : <Skeleton />}
      </Text>
    </Flex>
  </Flex>
);

export default ExternalPageAvatar;
