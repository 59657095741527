import React from 'react';
import styled from 'styled-components';

import Flex from '../../../../components/common/Flex';

const Headers = styled(Flex)`
  flex-direction: column;
  flex: 1;
  min-width: 0;
  margin-right: ${({ theme }) => theme.space[3]};
`;

export default Headers;