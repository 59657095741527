import { useEffect } from 'react';

import useLatest from './useLatest';

const useEventListener = (ref, eventName, handler) => {
  // saving the latest ref of the handler here
  // which means we only ever run the latest one
  // after all renders are complete
  const handlerRef = useLatest(handler);

  useEffect(() => {
    if (!handler || !ref.current) return;

    const eventListener = (event) => {
      handlerRef.current(event);
    };

    const element = ref.current; // Store reference to the element
    element.addEventListener(eventName, eventListener);

    return () => {
      // Safely remove event listener using stored element reference
      element?.removeEventListener?.(eventName, eventListener);
    };

    // make the dependency for this handler a boolean
    // based on the presence of the handler - the handlerRef
    // inside the function will always be the correct one
  }, [ref, !handler]);
};

export default useEventListener;
