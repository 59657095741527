import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import selectEntities from '../../../modules/entities/selectors';
import PipeFamilySchema from './schema';

import { formValueSelector } from 'redux-form/immutable';
import { selectIsShowingArchived } from '../../../modules/query/selectors';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';

import EK from '../../keys';

export const selectNormalizedPipeFamilies = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.PIPE_FAMILIES.state)
);

export const selectNormalizedUnarchivedPipeFamilies = () => createSelector(
  selectNormalizedPipeFamilies(),
  (pipes) =>
    pipes.filter((pipe) => !pipe.archivedFlag)
);

export const selectPipeFamilies = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get(EK.PIPE_FAMILIES.state), [PipeFamilySchema], entities)
);

export const selectPipeFamiliesList = () => createSelector(
  selectPipeFamilies(),
  pipeFamilies => pipeFamilies.toList()
);

export const selectPipeFamiliesCounts = () => createSelector(
  selectNormalizedPipeFamilies(),
  pipes => pipes.reduce(({ unarchived, total }, pipe) => ({
    unarchived: !pipe.archivedFlag ? unarchived + 1 : unarchived,
    total: total + 1,
  }), {
    unarchived: 0,
    total: 0,
  })
);

const pipeFilterForm = formValueSelector(`filters.${EK.PIPE_FAMILIES.state}`);

export const selectCurrentFilteredPipes = () => createSelector(
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectPipeFamilies(),
  state => pipeFilterForm(state,
    'name',
    'crossSectionShape',
    'lineType',
    'material'
  ),
  (isFiltering, isShowingArchived, pipes, query) => {
    if (!isFiltering) {
      return pipes.filter(pipe => !pipe.archivedFlag);
    }

    return pipes.filter(pipe => {
      // First check if we should include based on archived status
      if (!isShowingArchived && pipe.archivedFlag) {
        return false;
      }

      // Name filter
      if (query.name && pipe.name &&
        pipe.name.toLowerCase().indexOf(query.name.toLowerCase()) === -1) {
        return false;
      }

      // Cross section shape filter
      if (query.crossSectionShape &&
        pipe.crossSectionShape !== query.crossSectionShape) {
        return false;
      }

      // Line type filter
      if (query.lineType &&
        pipe.lineType !== query.lineType) {
        return false;
      }

      // Material filter
      if (query.material && typeof query.material === 'object' && query.material.id) {
        // If material is an object with an id property
        if (!pipe.material || pipe.material.id !== query.material.id) {
          return false;
        }
      } else if (query.material) {
        // If material is just an id
        if (!pipe.material || pipe.material.id !== query.material) {
          return false;
        }
      }

      return true;
    });
  }
);