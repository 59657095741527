import React from 'react';
import styled from 'styled-components';

import Box from '../../../../components/common/Box';
import Flex from '../../../../components/common/Flex';
import Button from '../../../../components/common/Button';

const StyledButton = styled(Button)`
  min-width: 80px;
  transition: all 0.2s ease;
  opacity: ${({ disabled }) => disabled ? 0.5 : 1};

  color: ${({ theme }) => theme.colors.gray[7]};
  background-color: ${({ theme }) => theme.colors.primary[3]};

  border-radius: ${({ theme }) => theme.radii[1]};

  padding: 1rem;

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.primary[1]};
    border-color: ${({ theme }) => theme.colors.gray[5]};
  }

  &:active:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.primary[2]};
  }
`;

const ScrollableBox = styled(Box)`
  overflow-y: scroll;
  padding-left: ${({ theme }) => theme.space[3]};
  padding-right: ${({ theme }) => theme.space[3]};
`;

const FilterFormContainer = styled(Box)`
  margin-bottom: ${({ theme }) => theme.space[3]};

  border: ${({ theme }) => theme.borders[1]};
  border-radius: ${({ theme }) => theme.radii[1]};

  background-color: ${({ theme }) => theme.colors.gray[0]};
`;

const FilterButtonContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FilterFormTemplate = ({ pristine, reset, children }) => (
  <ScrollableBox>
    <FilterFormContainer as='form'>
      { children }
    </FilterFormContainer>

    <FilterButtonContainer>
      <StyledButton
        $tiny
        $primary
        disabled={pristine}
        onClick={reset}
      >
        Clear
      </StyledButton>
    </FilterButtonContainer>
  </ScrollableBox>
);

export default FilterFormTemplate;