import styled from 'styled-components';

import Box from '../../common/Box';

const BaseOptions = styled(Box).attrs(_ => ({
  tabIndex: '-1',
  bg: 'gray.1',
  border: 1,
  $borderRadii: 1,
}))`
  box-shadow: ${({ theme }) => theme.shadows[2]};
  overflow: hidden;
  border-color: ${({ theme }) => theme.colors.gray[4]};

  & > :first-child {
    border-top-left-radius: ${({ theme }) => theme.radii[1]};
    border-top-right-radius: ${({ theme }) => theme.radii[1]};
  }

  & > :last-child {
    border-bottom-left-radius: ${({ theme }) => theme.radii[1]};
    border-bottom-right-radius: ${({ theme }) => theme.radii[1]};
  }

  &:focus,
  &:active {
    outline: none;
    border-color: ${({ theme }) => theme.colors.gray[4]};
  }
`;

export default BaseOptions;