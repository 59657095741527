import { take, takeLatest, put, fork, all, select } from 'redux-saga/effects';
import get from 'lodash/get';

import { push } from 'connected-react-router';

import EK from '../../../entities/keys';

import { PipingModulePipesEditPath } from '../../../paths';

import {
  createPipeFamilyTypes,
  copyPipeFamilyTypes,
} from '../../../entities/Piping/PipeFamilies/constants';
import {
  selectNormalizedPipeFamilies
} from '../../../entities/Piping/PipeFamilies/selectors';

export function* handleCreatePipeFamilySuccess({ payload = {} }) {
  const id = `${get(payload, `${EK.PIPE_FAMILIES.plural}[0].id`)}`;
  if (id) {
    let pipes = yield select(selectNormalizedPipeFamilies());
    let copiedPipe = pipes.get(id);

    while (!copiedPipe) {
      yield take();
      pipes = yield select(selectNormalizedPipeFamilies());
      copiedPipe = pipes.get(id);
    }
    yield put(push(PipingModulePipesEditPath.generate({ id: id })));
  }
}

export function* watchCreatePipeSaga() {
  yield takeLatest([
    createPipeFamilyTypes.SUCCESS,
    copyPipeFamilyTypes.SUCCESS,
  ], handleCreatePipeFamilySuccess
  );
}

// final output saga
export default function* main() {
  yield all([
    fork(watchCreatePipeSaga),
  ]);
}
