import React from 'react';
import styled, { css } from 'styled-components';

import Box from '../Box';

const StyledAlert = styled(Box)`
  padding: ${({ theme }) => theme.space[3]};
  border-radius: ${({ theme }) => theme.radii[2]};

  ${
  ({ $primary }) => $primary && css`
      background-color: ${({ theme }) => theme.colors.primary[0]};
      color: ${({ theme }) => theme.colors.primary[4]};
    `
}

  ${
  ({ $secondary }) => $secondary && css`
      background-color: ${({ theme }) => theme.colors.gray[0]};
    `
}

  ${
  ({ $error }) => $error && css`
      background-color: ${({ theme }) => theme.colors.error[0]};
      color: ${({ theme }) => theme.colors.error[4]};
    `
}
`;

const Alert = React.forwardRef((props, ref) => {
  const { primary, secondary, error, ...rest } = props;
  return (
    <StyledAlert
      ref={ref}
      $primary={primary}
      $secondary={secondary}
      $error={error}
      {...rest}
    />
  );
});

export default Alert;