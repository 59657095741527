import React from 'react';

import Icon from '../../Icon';
import Box from '../../Box';

/**
 * Component that displays either 'undo', 'delete', or 'lock' based on the isEdited, isCreated, isDeleted properties of the rowdata
 * and handles clicks based on the current icon displayed.
 */
const EntityRowTools = React.forwardRef(({ rowdata, handleRowAction, ...props }, ref) => {
  let [iconName, iconColor] = rowdata && (rowdata.isDeleted || rowdata.isCreated || rowdata.isEdited)
    ? ['undo', 'gray.6']
    : ['delete', 'error.4'];

  let $cursor = 'pointer';

  if (rowdata && !rowdata.canSafelyDelete && !rowdata.isEdited) {
    [iconName, iconColor] = ['lock', 'gray.6'];
    $cursor = 'inherit';
  }

  if (rowdata && rowdata.id.includes('NEW_ROW_') && !rowdata.isCreated && !rowdata.isEdited) {
    iconColor = 'gray.2';
  }

  return (
    <Box
      ref={ref}
      $cursor={$cursor}
      onClick={() => handleRowAction(rowdata, iconName)}
      {...props}
    >
      <Icon name={iconName} color={iconColor} />
    </Box>
  );
});

export default EntityRowTools;