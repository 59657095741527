import React from 'react';
import styled, { css } from 'styled-components';

/**
 * @typedef {Object} TextProps
 * @property {React.ReactNode} [children]
 * @property {string | React.ElementType} [as]
 * @property {Object} [style]
 * @property {string | number} [fontSize]
 * @property {string | number} [fontWeight]
 * @property {string} [color]
 * @property {string | number} [lineHeight]
 * @property {string | number} [letterSpacing]
 * @property {string} [textAlign]
 * @property {string} [href]
 * @property {boolean} [link]
 * @property {boolean} [primary]
 * @property {boolean} [ellipsis]
 */

// Base styled component that handles all styling
const StyledText = styled.div`
  font-size: ${props => {
    if (typeof props.$fontSize === 'number') {
      return props.theme.fontSizes[props.$fontSize] || `${props.$fontSize}px`;
    }
    return props.$fontSize;
  }};

  font-weight: ${props => props.$fontWeight};

  color: ${props => {
    if (props.$color) {
      // Handle dot notation for theme colors (e.g., 'gray.6')
      if (typeof props.$color === 'string' && props.$color.includes('.')) {
        const [colorName, shade] = props.$color.split('.');
        return props.theme.colors[colorName]?.[shade] || props.$color;
      }
      // Handle direct theme color reference or custom color
      return props.theme.colors[props.$color] || props.$color;
    }
    return 'inherit';
  }};

  line-height: ${props => props.$lineHeight};
  letter-spacing: ${props => props.$letterSpacing};
  text-align: ${props => props.$textAlign};

  ${props => props.$link && css`
    cursor: pointer;
    text-decoration: none;
    color: ${props.theme.colors.primary[4]};

    transition: color ${({ theme }) => theme.transitions.speed.fast} ${({ theme }) => theme.transitions.type.inOut};

    &:hover {
      color: ${props.theme.colors.primary[5]};
    }
  `}

  ${props => props.$primary && css`
    color: ${props.theme.colors.primary[4]};
  `}

  ${props => props.$ellipsis && css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

const Text = React.forwardRef((props, ref) => {
  const {
    fontSize,
    fontWeight,
    color,
    lineHeight,
    letterSpacing,
    textAlign,
    link,
    primary,
    ellipsis,
    style, // We'll ignore this prop as we're handling all styling through styled-components
    ...rest
  } = props;

  return (
    <StyledText
      ref={ref}
      $fontSize={fontSize}
      $fontWeight={fontWeight}
      $color={color}
      $lineHeight={lineHeight}
      $letterSpacing={letterSpacing}
      $textAlign={textAlign}
      $link={link}
      $primary={primary}
      $ellipsis={ellipsis}
      {...rest}
    />
  );
});

export const PlaceholderText = styled(Text)`
  color: ${({ theme }) => theme.colors.gray[5]};
`;

Text.defaultProps = {
  as: 'div',
};

PlaceholderText.defaultProps = {
  as: 'div',
};

export default Text;
