import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import Box from '../../components/common/Box';
import Flex from '../../components/common/Flex';
import Text from '../../components/common/Text';

import OrganizationForm from '../../containers/form/templates/OrganizationForm';

import ExternalPage from '../templates/ExternalPage';

import {
  UserIsAuthenticated,
  UserEmailVerified,
  UserHasNoOrganization,
} from '../../modules/auth/utils/routes';

import {
  selectIsFetching,
  selectIsPerforming,
} from '../../modules/utility/selectors';

// import {
//   processAcceptInvite,
// } from '../../modules/auth/actions';

import { selectCurrentUser } from '../../entities/CurrentUser/selectors';

import { processCreateOrganization } from '../../entities/Organizations/actions';

import { handleLogout } from '../../modules/auth/actions';

import injectSaga from '../../utils/sagas/injectSaga';

import accountSetupSaga from './sagas';

// import theme from '../../assets/themes/theme';

import { AccountSetupPath } from '../../paths';

const AccountSetup = ({ isFetching, isPerforming, invites, isInvitePresent, createOrganization, logout }) => (
  <ExternalPage title={AccountSetupPath.title}>
    <Flex $flexDirection='row' $justifyContent='center' $ml='auto' $mr='auto' $mb={7}>
      <Text as='span' $fontSize={5} $color='gray.7'>
        {
          isFetching ?
            <Skeleton /> :
            (
              isInvitePresent ?
                <React.Fragment><strong>Invite</strong> Pending</React.Fragment> :
                <React.Fragment><strong>Create</strong> Organization</React.Fragment>
            )
        }
      </Text>
    </Flex>

    <Box $ml='auto' $mr='auto' $mb={5}>
      {
        isFetching ?
          <Skeleton count={2} /> :
          <OrganizationForm isPerforming={isPerforming} onRDXSubmit={createOrganization} />
      }
    </Box>

    <Box $ml='auto' $mr='auto'>
      <Text as='p' $color='gray.7' $fontSize={2} $m={0} $p={0}>
        {
          isFetching ?
            <Skeleton /> :
            <Text $link href="/auth/sessions/sign_out">Log Out</Text>
        }
      </Text>
    </Box>
  </ExternalPage>
);

const mapStateToProps = createSelector(
  selectIsFetching(),
  selectIsPerforming(),
  selectCurrentUser(),
  (isFetching, isPerforming, currentUser) => {
    const invites = !!currentUser && !!currentUser.organizationInvites && currentUser.organizationInvites.filter(invite => invite.isPending);
    return {
      isFetching,
      isPerforming,
      currentUser,
      invites,
      isInvitePresent: !!invites && invites.size > 0,
    };
  }
);

const enhance = compose(
  withRouter,
  injectSaga({ key: 'accountSetup', saga: accountSetupSaga }),
  connect(
    mapStateToProps,
    {
      createOrganization: processCreateOrganization,
      logout: handleLogout,
    }
  ),
  UserIsAuthenticated,
  UserEmailVerified,
  UserHasNoOrganization,
);

export default enhance(AccountSetup);
