/**
 * Pretty straightforward for radii, caps out radii at 50% (which makes circles)
 */

export default [
  0,
  '3px',
  '5px',
  '50%',
];
