/**
 * Pretty straightforward for borders, shouldn't be using anything over a border thickness of 2px anyway
 * Importing colors here because just using border without color defaults it to black
 */

import colors from './colors';

export default [
  0,
  `1px solid ${colors.gray[3]}`,
  `1px solid ${colors.gray[4]}`,
  `1px solid ${colors.gray[6]}`,
];
