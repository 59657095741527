import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Field, reduxForm } from 'redux-form/immutable';
import times from 'lodash/times';
import { withRouter } from 'react-router-dom';

import EK from '../../../entities/keys';

import BaseFormRow from '../../../components/form/BaseFormRow';

import FilterFormTemplate from '../../templates/Structures/FilterFormTemplate';

import RDXTextInput from '../../../containers/form/RDXTextInput';
import RDXSearchSelect from '../../../containers/form/RDXSearchSelect';

import {
  selectPortNames,
} from '../../../entities/Piping/FittingInstances/selectors';

import {
  selectCurrentFittingFamily,
} from '../../../entities/Piping/FittingFamilies/selectors';


const FilterForm = ({ portNames, endPortCount, ...props }) => (
  <FilterFormTemplate {...props}>
    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name='cadModelName'
        label='CAD Model Name'
        type='text'
      />
    </BaseFormRow>
    <BaseFormRow>
      <Field
        component={RDXSearchSelect}
        name='schedule'
        label='Schedule'
        ek={EK.SCHEDULES}
        clearable
      />
    </BaseFormRow>
    {
      endPortCount > 0 && times(endPortCount, idx => (
        <React.Fragment key={idx}>
          <BaseFormRow>
            <Field
              component={RDXSearchSelect}
              name={`port${idx}Size`}
              label={`${portNames && portNames.get ? portNames.get(idx) : `Port ${idx}`} Size`}
              ek={EK.SIZES}
              clearable
            />
          </BaseFormRow>
          <BaseFormRow>
            <Field
              component={RDXSearchSelect}
              name={`port${idx}EndType`}
              label={`${portNames && portNames.get ? portNames.get(idx) : `Port ${idx}`} End Type`}
              ek={EK.END_TYPES}
              clearable
            />
          </BaseFormRow>
        </React.Fragment>
      ))
    }
    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name='selectionName'
        label='Selection Name'
        type='text'
      />
    </BaseFormRow>
    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name='stockno'
        label='Stock No'
        type='text'
      />
    </BaseFormRow>
    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name='mccsCode'
        label='MCCS Code'
        type='text'
      />
    </BaseFormRow>
  </FilterFormTemplate>
);

const mapStateToProps = createSelector(
  (state, props) => props.match.params.id,
  selectPortNames(),
  (state, props) => selectCurrentFittingFamily(props.match.params.id)(state),
  (fittingId, portNames, fitting) => {
    const endPortCount = fitting?.fittingCode?.endCount || 0;
    return {
      portNames,
      endPortCount,
    };
  }
);

const enhance = compose(
  withRouter,
  connect(mapStateToProps),
  reduxForm({ form: `filters.${EK.FITTING_INSTANCES.state}` }),
);

export default enhance(FilterForm);
