import React from 'react';

import styled, { css } from 'styled-components';

import Flex from '../../common/Flex';

const StyledCell = styled(Flex)`
  min-height: 56px;
  height: 100%;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  padding-left: ${({ $spaceLeft, theme }) => $spaceLeft || theme.space[3]};
  padding-right: ${({ $spaceRight, theme }) => $spaceRight || theme.space[3]};

  position: relative;

  flex-grow: 1;
  flex-shrink: 0;

  position: relative;

  ${
  ({ onClick }) => onClick && css`
      cursor: pointer;
      text-decoration: none;
    `
}
`;

const BaseCell = React.forwardRef((props, ref) => <StyledCell ref={ref} { ...props } />);

export default BaseCell;