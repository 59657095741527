import React from 'react';
import styled from 'styled-components';
import Flex from '../../common/Flex';

const Container = styled(Flex)`
  border: ${({ theme }) => theme.borders[1]};
  border-radius: ${({ theme }) => theme.radii[2]};
  height: 100%;

  min-height: 0;
  flex: 1;
  flex-direction: column;
  overflow: hidden;

  .p-datatable {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .p-datatable-wrapper {
    flex: 1;
    overflow-y: auto;
  }
`;

const DataTableContainer = ({ children, ...props }) => (
  <Container {...props}>
    {children}
  </Container>
);

export default DataTableContainer;