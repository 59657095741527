import React from 'react';
import styled, { css } from 'styled-components';

import Box from '../../common/Box';

const BaseTooltipSection = styled(Box)`
  background-color: ${({ theme }) => theme.colors.gray[0]};
  padding: ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[4]};
  color: ${({ theme }) => theme.colors.gray[7]};

  ${
  ({ small }) => small && css`
      padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]};
      font-size: ${({ theme }) => theme.fontSizes[1]};
    `
}

  ${
  ({ large }) => large && css`
      padding: ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[4]};
    `
}

  ${
  ({ primary }) => primary && css`
      background-color: ${({ theme }) => theme.colors.primary[4]};
      color: ${({ theme }) => theme.colors.white};
    `
}

  ${
  ({ main }) => main && css`
      background-color: ${({ theme }) => theme.colors.white};
    `
}

  ${
  ({ secondary }) => secondary && css`
      background-color: ${({ theme }) => theme.colors.gray[0]};
    `
}

  ${
  ({ error }) => error && css`
      background-color: ${({ theme }) => theme.colors.error[4]};
      color: ${({ theme }) => theme.colors.white};
    `
}

  ${
  ({ inverse }) => inverse && css`
      background-color: ${({ theme }) => theme.colors.gray[7]};
      color: ${({ theme }) => theme.colors.gray[0]};
    `
}
`;

const TooltipSection = React.forwardRef((props, ref) => <BaseTooltipSection ref={ref} { ...props } />);

export default TooltipSection;