import React from 'react';

export default function FitNobreakIcon({ size = '1em', style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="0 0 28 28"
      enableBackground="new 0 0 28 28"
      xmlSpace="preserve"
      style={style}
    >
      <path d="M11,7.5h-1V6.48c0-0.162-0.38-0.46-1-0.46S8,6.318,8,6.48V7.5H7V6.48c0-0.819,0.878-1.46,2-1.46s2,0.641,2,1.46V7.5z" />
      <path d="M20.96,7.5h-1V6.48c0-0.163-0.38-0.461-1-0.461s-1,0.298-1,0.461V7.5h-1V6.48c0-0.819,0.879-1.461,2-1.461s2,0.642,2,1.461V7.5z" />
      <path d="M14.04,23C10.158,23,7,20.541,7,17.52V9.98H6.333c-0.724,0-1.313-0.655-1.313-1.46C5.02,7.682,5.609,7,6.333,7h5.187c0.805,0,1.46,0.682,1.46,1.52c0,0.805-0.655,1.46-1.46,1.46H11v6.52c0,1.379,1.346,2.5,3,2.5c1.665,0,3.021-1.121,3.021-2.5V9.98h-0.52c-0.806,0-1.461-0.655-1.461-1.46c0-0.838,0.655-1.52,1.461-1.52h5.186C22.411,7,23,7.682,23,8.52c0,0.805-0.589,1.46-1.313,1.46h-0.666v7.54C21.021,20.541,17.889,23,14.04,23z M6.333,8C6.186,8,6.02,8.222,6.02,8.52c0,0.271,0.165,0.46,0.313,0.46H8v8.54C8,19.99,10.709,22,14.04,22c3.298,0,5.98-2.01,5.98-4.48V8.98h1.666C21.835,8.98,22,8.791,22,8.52C22,8.222,21.835,8,21.687,8h-5.186c-0.254,0-0.461,0.233-0.461,0.52c0,0.253,0.207,0.46,0.461,0.46h1.52v7.52c0,1.93-1.804,3.5-4.021,3.5c-2.206,0-4-1.57-4-3.5V8.98h1.52c0.253,0,0.46-0.207,0.46-0.46c0-0.287-0.207-0.52-0.46-0.52H6.333z" />
      <rect x="7.5" y="8.98" width="3" height="1" />
      <rect x="17.521" y="8.98" width="3" height="1" />
    </svg>
  );
}
