import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import Button from '../../../../../../../components/common/Button';
import Flex from '../../../../../../../components/common/Flex';
import Icon from '../../../../../../../components/common/Icon';
import Text from '../../../../../../../components/common/Text';

const ListItem = styled(Flex)`
  padding-right: ${({ theme }) => theme.space[3]};
  border-bottom: ${({ theme }) => theme.borders[2]};

  &:first-of-type {
    border-top-left-radius: ${({ theme }) => theme.radii[2]};
    border-top-right-radius: ${({ theme }) => theme.radii[2]};
  }

  &:last-of-type {
    border-bottom-left-radius: ${({ theme }) => theme.radii[2]};
    border-bottom-right-radius: ${({ theme }) => theme.radii[2]};
    border-bottom: none;
  }

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      &:last-of-type {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    `}
`;

const Handle = styled(Flex)`
  cursor: move;
  flex-direction: column;
  padding-left: ${({ theme }) => theme.space[2]};
  padding-right: ${({ theme }) => theme.space[2]};
`;

const ToggleContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  padding-left: ${({ theme }) => theme.space[3]};
  padding-right: ${({ theme }) => theme.space[3]};
`;

const ContentContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  flex-grow: 1;
  padding-left: ${({ theme }) => theme.space[2]};
  padding-top: ${({ theme }) => theme.space[3]};
  padding-bottom: ${({ theme }) => theme.space[3]};
`;

const ButtonContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MinimizableListItem = ({
  item,
  isSelected,
  isHidden,
  sortable,
  onToggleSelect,
  onEdit,
  onDelete,
}) => {
  const handleToggleSelect = useCallback(() => onToggleSelect(item), [
    item,
    onToggleSelect,
  ]);
  const handleEdit = useCallback(() => onEdit && onEdit(item), [item, onEdit]);
  const handleDelete = useCallback(() => onDelete && onDelete(item), [
    item,
    onDelete,
  ]);

  if (isHidden) {
    return null;
  }

  return (
    <ListItem $isSelected={isSelected} flexDirection="row">
      {sortable && !isSelected && (
        <Handle bg="gray.1">
          <Icon color="gray.6" name="move" />
        </Handle>
      )}
      <ToggleContainer onClick={handleToggleSelect}>
        <Icon
          fontSize={6}
          color="primary.4"
          name={isSelected ? 'chevron-left' : 'chevron-right'}
        />
      </ToggleContainer>
      <ContentContainer onClick={handleToggleSelect}>
        <Text
          fontSize={2}
          color={isSelected ? 'primary.4' : 'gray.7'}
          fontWeight={isSelected ? '600' : '400'}
        >
          {item.primary || item.primaryPlaceholder}
        </Text>
        <Text
          fontSize={1}
          color={isSelected ? 'primary.3' : 'gray.6'}
          fontWeight={isSelected ? '600' : '400'}
        >
          {item.secondary || item.secondaryPlaceholder}
        </Text>
      </ContentContainer>
      {onEdit && (
        <ButtonContainer>
          <Button
            icon="edit"
            onClick={handleEdit}
            $transparent
            $subtle
            $primary
          />
        </ButtonContainer>
      )}
      {onDelete && (
        <ButtonContainer>
          <Button
            icon="delete"
            onClick={handleDelete}
            $transparent
            $subtle
            $error
          />
        </ButtonContainer>
      )}
    </ListItem>
  );
};

export default MinimizableListItem;
