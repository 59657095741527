/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

// Grid Imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import DataTableContainer from '../../../components/primeGrid/DataTableContainer';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

// cell bodies/editors
import Text, { PlaceholderText } from '../../../components/common/Text';
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import EntityRowTools from '../../../components/common/EntityTools/RowStartTools';

// Header
import Flex from '../../../components/common/Flex';
import { PipingModuleSpecsPipeInstancesEditPath, PipingModuleSpecsPipesEditPath } from '../../../paths';
import Header from '../../templates/Structures/Header';
import Options from '../SpecsEditSwitch/Options';
import Skeleton from 'react-loading-skeleton';
import { saveCurrentPipingSpecPipeFamiliesChanges } from './actions';
import { pluralize } from '../../../components/common/Header';
import { push } from 'connected-react-router';
import SpecsDialog from '../../../containers/dialog/templates/Piping/SpecsDialog';

// validation
import useRowStyles from '../../../components/primeGrid/RowStyles';
import { VALIDATION_FIELDS, } from '../../../entities/Piping/Specs/model';
import { areFieldsValid, doRequiredFieldsExist } from '../Specs/components';

// actions
import { processSaveSpecPipeInstances } from '../../../entities/Piping/SpecPipeInstances/actions';
import { processFetchPipeFamiliesForSpec } from '../../../entities/Piping/PipeFamilies/actions';
import { processFetchAllMaterials } from '../../../entities/Piping/Materials/actions';
import { processEditSpec, processFetchAllSpecs, processFetchIndividualSpec } from '../../../entities/Piping/Specs/actions';

// selectors
import { selectPipingModulePermissionsAndState, selectSidebarIsFiltering } from '../../Dashboard/selectors';
import { removeDuplicateFromObjectIdArray, selectCurrentSpec, selectOrderedFilteredUnarchivedPipeFamilies } from '../../../entities/Piping/Specs/selectors';
import Button from '../../../components/common/Button';
import { selectisPrioritizedFamilySortingEnabled } from '../SpecsEditSwitch/selectors';
import { selectDenormalizedPipeInstances } from '../../../entities/Piping/PipeInstances/selectors';
import { selectPipeFamiliesCounts } from '../../../entities/Piping/PipeFamilies/selectors';
import { PIPE_SHAPES, LINE_TYPES } from '../../../entities/Piping/PipeFamilies/model';

const mapStateToProps = (specId) => createSelector(
  selectPipingModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectOrderedFilteredUnarchivedPipeFamilies(specId),
  selectCurrentSpec(specId),
  selectisPrioritizedFamilySortingEnabled(),
  selectDenormalizedPipeInstances(),
  selectPipeFamiliesCounts(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    data,
    spec,
    isPrioritizedFamilySortingEnabled,
    instances,
    { unarchived, total},
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.length == 0));
    return {
      ...rest,
      isFiltering,
      isLoading,
      canCollaborate,
      hasValidLicense,
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense,
      count: total,
      spec,
      isPrioritizedFamilySortingEnabled,
      instances,
    };
  },
);

function SpecPipeFamiliesGrid({ match, location }) {
  const reduxProps = useSelector(mapStateToProps(match.params.id));
  const dispatch = useDispatch();

  // editing state
  const [editedRows, setEditedRows] = useState({});
  const [editedOrder, setEditedOrder] = useState([]);
  const [orderedRows, setOrderedRows] = useState(reduxProps.data);
  const rowStyles = useRowStyles(true);

  // dialog state
  const [currentEditingSpec, setCurrentEditingSpec] = useState(reduxProps.spec);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [validation, setValidation] = useState(VALIDATION_FIELDS);

  const handleEditedSpecChange = (newValue, field) => {
    // called on every edit
    setCurrentEditingSpec((prev) => {
      let _editedRow = { ...prev };
      _editedRow[`${field}`] = newValue;
      return _editedRow;
    });
  };

  const openDialog = () => {
    const currentNonEntityRow = {
      id: reduxProps.spec.id,
      specName: reduxProps.spec.specName,
      mnemonic: reduxProps.spec.mnemonic,
      layerName: reduxProps.spec.layerName,
      appearance: reduxProps.spec.appearance,
      defaultInsulationSpec: reduxProps.spec.defaultInsulationSpec
    };
    setCurrentEditingSpec(currentNonEntityRow);
    setIsDialogOpen(true);
  };

  const cancelDialogs = () => {
    setIsDialogOpen(false);
  };

  const saveSpecAction = () => {
    // translated here to a format that can be sent to the api, replacing all object fields with their Id.
    setValidation(areFieldsValid(currentEditingSpec));
    if (!doRequiredFieldsExist(currentEditingSpec)) {
      return;
    }

    const editedRow = {
      id: currentEditingSpec.id,
      specName: currentEditingSpec.specName,
      mnemonic: currentEditingSpec?.mnemonic,
      layerName: currentEditingSpec?.layerName,
      appearanceId: currentEditingSpec.appearance.id,
      defaultInsulationSpecId: currentEditingSpec?.defaultInsulationSpec?.id
    };

    dispatch(processEditSpec(editedRow.id, editedRow));
    cancelDialogs();
  };

  // reorder funcs
  const handleOnRowReorder = (e) => {
    const sorted = e.value.filter((fam) => {
      if (editedRows[fam.id]) {
        return editedRows[fam.id].isPrioritized;
      }
      return fam.isPrioritized;
    }).concat(e.value.filter((fam) => {
      if (editedRows[fam.id]) {
        return !editedRows[fam.id].isPrioritized;
      }
      return !fam.isPrioritized;
    }));

    if(editedRows[e.value[e.dropIndex].id]) {
      setEditedRows((prev) => {
        let _editedRows = { ...prev };
        _editedRows[e.value[e.dropIndex].id] = {
          ...editedRows[e.value[e.dropIndex].id],
          isEdited: true,
        };
        return _editedRows;
      });
    } else {
      setEditedRows((prev) => {
        let _editedRows = { ...prev };
        _editedRows[e.value[e.dropIndex].id] = {
          ...e.value[e.dropIndex],
          isEdited: true,
        };
        return _editedRows;
      });
    }
    setEditedOrder(sorted);
    setOrderedRows(sorted);
  };

  const rowCanReorder = (rowdata) => {
    const curRow = rowdata && (editedRows && editedRows[rowdata.id] || rowdata);
    return !curRow.isPrioritized && 'hide-row-reorder-button';
  };
  const crossSectionShapeBody = (rowdata) => {
    const curRow = rowdata && (editedRows && editedRows[rowdata.id] || rowdata);
    const shape = curRow?.crossSectionShape || curRow?.pipeFamily?.crossSectionShape;
    return shape ? (
      <Text>{PIPE_SHAPES[shape].label}</Text>
    ) : (
      <PlaceholderText>No Shape</PlaceholderText>
    );
  };

  const lineTypeBody = (rowdata) => {
    const curRow = rowdata && (editedRows && editedRows[rowdata.id] || rowdata);
    const lineType = curRow?.lineType || curRow?.pipeFamily?.lineType;
    return lineType ? (
      <Text>{LINE_TYPES[lineType].label}</Text>
    ) : (
      <PlaceholderText>No Line Type</PlaceholderText>
    );
  };

  const materialBody = (rowdata) => {
    const curRow = rowdata && (editedRows && editedRows[rowdata.id] || rowdata);
    return curRow?.material ? (
      <ComplexCellRenderer
        value={curRow.material}
        isCapitalized={true}
        paddingLeft='0px'
      />
    ) : (
      <PlaceholderText>Material</PlaceholderText>
    );
  };

  const textValueBody = (rowdata, field, placeholder = '') => {
    const curRow = rowdata && (editedRows && editedRows[rowdata.id] || rowdata);

    if (curRow.id && curRow.id.includes('NEW_ROW_') && curRow[field] == '') {
      return <PlaceholderText>new row</PlaceholderText>;
    }

    return curRow && curRow[`${field}`] ?
      <Text>{curRow[`${field}`]}</Text> :
      <PlaceholderText>{placeholder}</PlaceholderText>;
  };

  const instanceBody = (rowdata) => {
    const curRow = rowdata && (editedRows && editedRows[rowdata.id] || rowdata);
    return curRow.isSelectedForSpec ? (
      <Text>
        {`${curRow.selectedPipeInstancesForSpec} selected / ${curRow.pipeInstances.size} ${pluralize('instance', curRow.pipeInstances.size)}`}
      </Text>
    ) : (
      <Text>
        {`${curRow.pipeInstances.size} ${pluralize('instance', curRow.pipeInstances.size)}`}
      </Text>
    );
  };

  // cell actions
  // this method assumes that the item is selected FOr the spec (filtering happens in a useEffect to ensure this is the case).
  //cases where the item is not selected for the spec are handled inside of the 'toggleForSpec' method
  const handlePrioritizedRowsChange = (pipeFamily, prevRows) => {
    let _orderedRows = [...prevRows];
    const curItem = _orderedRows.findIndex(item => item.id == pipeFamily.id);
    const _curRow = _orderedRows[curItem];
    switch (pipeFamily.isPrioritized) {
    case true:
      curItem == -1 && _orderedRows.push(pipeFamily.originalData || pipeFamily);
      break;
    case false:
      curItem != -1 && _orderedRows.splice(curItem, 1);
      _orderedRows.push(_curRow);
      break;
    }
    return _orderedRows;
  };

  const toggleForPriority = (pipeFamily) => {
    const newFamily = {
      id: pipeFamily.id,
      pipeInstances: pipeFamily.pipeInstances,
      isSelectedForSpec: pipeFamily.isSelectedForSpec,
      isPrioritized: !pipeFamily.isPrioritized,
      selectedPipeInstancesForSpec: pipeFamily.selectedPipeInstancesForSpec,
      isEdited: true,
      name: pipeFamily.name,
      material: pipeFamily.material,
      originalData: pipeFamily.isEdited && pipeFamily.originalData || pipeFamily,
    };

    setEditedRows((prev) => {
      let _editedRows = { ...prev };
      _editedRows[pipeFamily.id] = newFamily;
      if (pipeFamily.isEdited) {
        if (!pipeFamily.isPrioritized == pipeFamily.originalData.isPrioritized && pipeFamily.isSelectedForSpec == pipeFamily.originalData.isSelectedForSpec) {
          delete _editedRows[pipeFamily.id];
          return _editedRows;
        }
      }
      return _editedRows;
    });

    setOrderedRows((prevRows) => handlePrioritizedRowsChange(newFamily, prevRows));
  };

  const toggleForSpec = (pipeFamily) => {
    if (pipeFamily.pipeInstances.size <= 0) {
      return;
    }
    const originalData = pipeFamily.originalData || pipeFamily;
    const newFamily = {
      id: pipeFamily.id,
      pipeInstances: pipeFamily.pipeInstances,
      isSelectedForSpec: !pipeFamily.isSelectedForSpec,
      selectedPipeInstancesForSpec: !pipeFamily.isSelectedForSpec && pipeFamily.originalData.pipeInstances?.size || 0,
      isPrioritized: !pipeFamily.isSelectedForSpec ? originalData.isPrioritized : false,
      isEdited: true,
      name: pipeFamily.name,
      material: pipeFamily.material,
      originalData: originalData,
    };
    setEditedRows((prev) => {
      let _editedRows = { ...prev };

      if (pipeFamily.isEdited) {
        // if item is not selected for the spec and was not originally selected for spec, remove from changes
        // if item is slected for the spec and was originally selected for the spec, and the prioritization has not changed, remove from changes
        if (!newFamily.isSelectedForSpec && !newFamily.originalData.isSelectedForSpec) {
          delete _editedRows[newFamily.id];
        } else if (newFamily.isSelectedForSpec && newFamily.originalData.isSelectedForSpec && newFamily.isPrioritized === newFamily.originalData.isPrioritized) {
          delete _editedRows[newFamily.id];
        } else {
          _editedRows[pipeFamily.id] = newFamily;
        }
      } else {
        _editedRows[pipeFamily.id] = newFamily;
      }
      return _editedRows;
    });

    setOrderedRows((prevRows) => handlePrioritizedRowsChange(newFamily, prevRows));
  };

  // Cell Editors
  const SelectItemForPriorityCellBody = ({ pipeFamily }) => (
    <Button
      icon='star'
      iconFillColor={pipeFamily.isPrioritized ? 'primary.4' : 'gray.4'}
      onClick={() => toggleForPriority(pipeFamily)}
    />
  );

  const SelectItemForSpecCellBody = ({ pipeFamily }) => (
    <Button
      icon={pipeFamily.isSelectedForSpec ? 'checkbox' : 'checkbox-outline'}
      iconFillColor={pipeFamily.isSelectedForSpec ? 'primary.4' : 'gray.6'}
      onClick={() => toggleForSpec(pipeFamily)}
    />
  );

  const PipingSpecsRowTool = (rowdata) => {
    const curRow = rowdata && (editedRows && editedRows[rowdata.id] || rowdata);

    if (curRow) {
      return reduxProps.isPrioritizedFamilySortingEnabled ?
        // eslint-disable-next-line react/no-unknown-property
        <SelectItemForPriorityCellBody pipeFamily={curRow} /> :
        <SelectItemForSpecCellBody pipeFamily={curRow} />;
    }
  };

  const handleRowAction = (item, iconName) => {
    setEditedRows((prevRows) => {
      let _rows = { ...prevRows };
      switch (iconName) {
      case 'undo':
        delete _rows[item.id];
        break;
      }
      return _rows;
    });
  };

  const getRowTool = (rowdata) => {
    const data = rowdata && (editedRows && editedRows[rowdata.id] || rowdata);
    return (
      <EntityRowTools rowdata={data} handleRowAction={handleRowAction} />
    );
  };

  // save action

  const saveAction = (collectionsData) => {
    let callData = {
      id: match.params.id
    };

    const meditedOrder = orderedRows.filter(item => {
      if (editedRows[item.id]) {
        return editedRows[item.id].isPrioritized && editedRows[item.id].isSelectedForSpec;
      }
      return !!item.isPrioritized ?? item.isSelectedForSpec;
    });

    if (`[${meditedOrder.map(fam => fam.id)}]` != `[${reduxProps.spec?.prioritizedPipeFamilies?.map(fam => fam.id)}]`) {
      callData.prioritized_pipe_families = meditedOrder && `[${meditedOrder.map(fam => fam.id)}]` || reduxProps.spec?.prioritizedPipeFamilies?.map(fam => fam.id);
      dispatch(processEditSpec(match.params.id, callData));
    }

    let allinst = [];
    collectionsData.length > 0 && collectionsData.forEach(family => {
      allinst = allinst.concat(family.data);
    });

    collectionsData.length > 0 && dispatch(processEditSpec(match.params.id, { specPipeInstancesAttributes: allinst }));
    setEditedRows({});
  };

  useEffect(() => {
    dispatch(processFetchAllMaterials());
    dispatch(processFetchPipeFamiliesForSpec());
    dispatch(processFetchAllSpecs());
    dispatch(processFetchIndividualSpec(match.params.id));
  }, []);

  useEffect(() => {
    let _data = removeDuplicateFromObjectIdArray(editedOrder.concat(reduxProps.data));
    if (editedRows && Object.keys(editedRows).length > 0 && reduxProps.isPrioritizedFamilySortingEnabled) {
      for (const [key, value] of Object.entries(editedRows)) {
        const dataItemIndex = _data.findIndex(item => item.id == key);
        if (dataItemIndex != -1) {
          if (value.isSelectedForSpec) { // place selected items in their original location
            _data[dataItemIndex] = value.originalData || value;
          } else { // remove unselected items
            _data.splice(dataItemIndex, 1);
          }
        } else { // push new items to the end of the list
          _data.push(value.originalData || value);
        }
      }
      setOrderedRows(_data);
    } else {
      setOrderedRows(reduxProps.data);
    }
  }, [reduxProps.isLoading, reduxProps.isPrioritizedFamilySortingEnabled]);

  useEffect(() => {
    if (reduxProps.isPrioritizedFamilySortingEnabled) {
      const filteredRows = orderedRows.filter(item => {
        if (editedRows[item.id]) {
          return editedRows[item.id].isPrioritized && editedRows[item.id].isSelectedForSpec;
        }
        return !!item.isPrioritized ?? item.isSelectedForSpec;
      });
      setEditedOrder(filteredRows);
    }
  }, [orderedRows]);

  return (
    <>
      {!reduxProps.isLoading && <Flex flexDirection="row" mb={4} >
        <Header
          isLoading={reduxProps.isLoading}
          title={
            (reduxProps.spec?.specName &&
              `${reduxProps.spec.specName} // ${PipingModuleSpecsPipesEditPath.defaultTitle}`
            ) || 'Loading...'
          }
          subtitle={
            reduxProps.isFiltering
              ? `Showing ${reduxProps.data.length}/${reduxProps.count} ${pluralize('filtered Pipe Family', reduxProps.count)}`
              : `${reduxProps.data.length} ${pluralize('Pipe Family', reduxProps.data.length)}`
          }
        />
        <Options
          location={location}
          specId={(match.params.id)}
          editSpec={openDialog}
          updateEntities={() => saveCurrentPipingSpecPipeFamiliesChanges(editedRows, reduxProps.spec?.specPipeInstances?.toArray() || [], saveAction)}
          pendingValidChanges={Object.keys(editedRows)?.length > 0}
        />
      </Flex> || <Skeleton style={{ height: '2rem', marginBottom: '1rem' }} />
      }
      <DataTableContainer>
        <DataTable
          reorderableRows={reduxProps.isPrioritizedFamilySortingEnabled}
          onRowReorder={handleOnRowReorder}
          value={reduxProps.isPrioritizedFamilySortingEnabled ? orderedRows : reduxProps.data}
          tableStyle={{ minWidth: '50rem' }}
          size='normal'
          rowClassName={(data) => data && rowStyles(data, editedRows)}

          scrollable
          scrollHeight='flex'

          removableSort
        >
          <Column
            header=""
            style={{ width: '2%' }}
            field="id"
            body={getRowTool}
          />
          {reduxProps.isPrioritizedFamilySortingEnabled && (
            <Column
              rowReorder
              bodyClassName={rowCanReorder}
              style={{ width: '2%' }}
            />
          )}
          <Column
            header=""
            style={{ width: '2%' }}
            body={PipingSpecsRowTool}
          />
          <Column
            field='name'
            style={{ width: '20%' }}
            header='Name'
            body={(rowdata) => textValueBody(rowdata, 'name', '')}
            sortable
          />
          <Column
            field='instances'
            header={'Instances'}
            body={(rowdata) => instanceBody(rowdata)}
            style={{ width: '10%' }}
            bodyStyle={{ fontSize: '.875rem' }}
            sortable
          />
          <Column
            field='crossSectionShape'
            header='Cross Section Shape'
            body={crossSectionShapeBody}
            style={{ width: '10%' }}
            sortable
          />
          <Column
            field='lineType'
            header='Line Type'
            body={lineTypeBody}
            style={{ width: '10%' }}
            sortable
          />
          <Column
            field='material'
            header={'Material'}
            body={materialBody}
            style={{ width: '15%' }}
            sortable
          />
          <Column
            header=''
            style={{ textAlign: 'right' }}
            body={(rowdata) =>
              <Button
                icon='list'
                onClick={() => dispatch(push(PipingModuleSpecsPipeInstancesEditPath.generate({ id: match.params.id, familyId: rowdata.id })))}
              />
            }
          />
        </DataTable>
      </DataTableContainer>
      <SpecsDialog
        currentEditingRow={currentEditingSpec}
        handleEditedRowChange={handleEditedSpecChange}
        isDialogOpen={isDialogOpen}
        saveAction={saveSpecAction}
        cancelDialogs={cancelDialogs}
        editable={reduxProps.editable}
        editSpec={openDialog}
      />
    </>
  );
}

export default withRouter(SpecPipeFamiliesGrid);