import React, { useState, useEffect, useMemo } from 'react';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// custom hooks
import { useOnCellEditComplete } from '../../../hooks/useOnCellEditComplete';

//Grid
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { rowStyles } from '../../../components/primeGrid/RowStyles';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';
import DataTableContainer from '../../../components/primeGrid/DataTableContainer';

// cell editors/bodies
import EntityRowTools from '../../../components/common/EntityTools/RowStartTools';
import { Dropdown } from 'primereact/dropdown';
import Text, { PlaceholderText } from '../../../components/common/Text';
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import UnitValueCellRenderer from '../../../components/grid/UnitValueCellRenderer';

// Header imports
import Options from '../../templates/Structures/Options';
import Header from '../../templates/Structures/Header';
import { pluralize } from '../../../components/common/Header';
import Skeleton from 'react-loading-skeleton';
import Flex from '../../../components/common/Flex';
import { PipingModulePipesBendMachinesEditPath } from '../../../paths';
import { DeleteBendMachineDialog, BendMachineDialog } from '../../../containers/dialog/templates/Piping/BendMachineDialog';

//actions
import {
  processFetchIndividualBendMachineFamilyForPipeFamily,
  processDeleteBendMachineFamilyForPipeFamily,
  processArchiveBendMachineFamiliesForPipeFamily,
  processUnarchiveBendMachineFamiliesForPipeFamily,
  processEditBendMachineFamilyForPipeFamily,
} from '../../../entities/Piping/BendMachineFamilies/actions';
import { processFetchIndividualPipeFamily } from '../../../entities/Piping/PipeFamilies/actions';
import { processFetchAllSizes } from '../../../entities/Piping/Sizes/actions';
import { processSaveBendMachineInstances } from '../../../entities/Piping/BendMachineInstances/actions';

// Bend Family Dialog
import { doRequiredFieldsExist, areFieldsValid } from '../PipesBends/components';
import { VALIDATION_FIELDS } from '../PipesBends/Body';
import { BendDialog, DeleteBendDialog } from '../../../containers/dialog/templates/Piping/BendDialog';

// misc.
import { getUnitLength } from '../../../entities/Synchronize/UnitSystems/model';
import { convertToBendMachineInstanceType, saveCurrentBendMachineInstanceChanges } from './actions';

// Selectors
import {
  selectCurrentBendMachineInstancesForPage,
  selectBendMachineInstanceCount,
} from '../../../entities/Piping/BendMachineInstances/selectors';
import { selectCurrentBendMachine } from '../../../entities/Piping/BendMachineFamilies/selectors';
import { selectCurrentPipeUnitSystem } from '../PipesEditSwitch/selectors';
import { selectPipingModulePermissionsAndState } from '../../Dashboard/selectors';
import { selectNormalizedSizes } from '../../../entities/Piping/Sizes/selectors';
import { selectSidebarIsFiltering } from '../../Dashboard/selectors';

const mapStateToProps = (bendFamilyId, pipeFamilyId) => createSelector(
  selectPipingModulePermissionsAndState(),
  selectCurrentBendMachine(bendFamilyId),
  selectCurrentBendMachineInstancesForPage(bendFamilyId),
  selectNormalizedSizes(),
  selectCurrentPipeUnitSystem(pipeFamilyId),
  selectBendMachineInstanceCount(bendFamilyId),
  selectSidebarIsFiltering(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    bendMachine,
    data,
    sizes,
    defaultUnitSystem,
    instanceCount,
    isFiltering,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || instanceCount == 0));
    return {
      ...rest,
      isLoading,
      data: !isLoading && data || [],
      editable: canCollaborate && hasValidLicense && !bendMachine?.archivedFlag,
      bendMachine,
      bendMachineId: bendMachine?.id,
      defaultUnitSystem,
      instanceCount,
      canCollaborate,
      hasValidLicense,
      sizes: sizes && sizes.toList().toArray() || [],
      isFiltering,
    };
  },
);

function BendsEditGrid({ match }) {
  const reduxProps = useSelector(mapStateToProps(match.params.familyId, match.params.id));
  const dispatch = useDispatch();

  // dialog state
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [validation, setValidation] = useState(VALIDATION_FIELDS);
  const [editedBendMachineFamily, setEditedBendMachineFamily] = useState(reduxProps.bendMachine);

  // table state
  const [editedRows, resetEditedRows, currentEditingRow, isEditing, onBeforeCellEditShow, onCellChange, onCellEditComplete, handleRowAction, onDropdownComplete] = useOnCellEditComplete(reduxProps.data, convertToBendMachineInstanceType, reduxProps.editable, true);
  const sizeOptions = useMemo(() => {
    const dataSizes = reduxProps.data.map(item => item.sizeProperty?.id);
    return reduxProps.sizes.filter(size => !dataSizes.includes(size.id));
  }, [reduxProps.data, reduxProps.sizes]);

  //dialog methods
  const handleEditedDialogChange = (newValue, field) => {
    // called on every edit, needs to be made into a custom hook of some kind
    let _editedRow = { ...editedBendMachineFamily };
    _editedRow[`${field}`] = newValue;
    setEditedBendMachineFamily(_editedRow);
  };

  const saveBendMachineAction = () => {
    // translated here to a format that can be sent to the api, replacing all object fields with their Id.
    setValidation(areFieldsValid(editedBendMachineFamily));
    if (!doRequiredFieldsExist(editedBendMachineFamily)) {
      return;
    }

    const editedRow = {
      id: editedBendMachineFamily.id,
      name: editedBendMachineFamily.name,
    };

    dispatch(processEditBendMachineFamilyForPipeFamily(match.params.id, editedRow.id, editedRow));
    setIsDialogOpen(false);
  };

  //Cell Bodies

  const unitValueBody = (rowdata, field) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToBendMachineInstanceType(rowdata);

    return (
      <UnitValueCellRenderer data={curRow[field] || ''} unitSystem={reduxProps.defaultUnitSystem} />
    );
  };

  const sBody = (rowdata, field, placeholder) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToBendMachineInstanceType(rowdata);

    return curRow && curRow[field] ?
      <ComplexCellRenderer value={curRow[field]} isCapitalized={true} paddingLeft='0px' /> :
      <PlaceholderText>{placeholder}</PlaceholderText>;
  };

  // Cell Editors
  const unitNumberCellEditor = (field) => (
    <span className='p-input-icon-right'>
      <Text as='i'>
        {reduxProps.defaultUnitSystem.unitLength.abbreviation || ''}
      </Text>
      <InputNumber
        value={currentEditingRow && getUnitLength(currentEditingRow[field], reduxProps.defaultUnitSystem)}
        onChange={(e) => onCellChange(e.value, field)}
        minFractionDigits={1}
        maxFractionDigits={8}
      />
    </span>
  );

  const sizeDropdown = () => {
    return <Dropdown
      value={currentEditingRow && currentEditingRow.sizeProperty}
      options={sizeOptions}
      optionLabel='name'
      filter={true}

      itemTemplate={(item) => ComplexCellRenderer({ value: item, isCapitalized: true })}
      valueTemplate={(item) => ComplexCellRenderer({ value: item, isCapitalized: true })}

      onChange={(e) => onDropdownComplete.current(e.value, 'sizeProperty', currentEditingRow)}
      style={{ width: '100%' }}
    />;
  };

  const getRowTool = (rowdata) => {
    const data = editedRows && editedRows.find(row => row.id == rowdata.id) || reduxProps.data.find((item) => item.id == rowdata.id);
    return (
      <EntityRowTools rowdata={data} handleRowAction={handleRowAction} />
    );
  };

  useEffect(() => {
    dispatch(processFetchIndividualBendMachineFamilyForPipeFamily(match.params.id, match.params.familyId));
    dispatch(processFetchIndividualPipeFamily(match.params.id));
    dispatch(processFetchAllSizes());
  }, []);

  return (
    <>
      {!reduxProps.isLoading && <Flex flexDirection="row" mb={4} >
        <Header
          isLoading={reduxProps.isLoading}
          title={(reduxProps.bendMachine && reduxProps.bendMachine.name) || PipingModulePipesBendMachinesEditPath.defaultTitle}
          subtitle={reduxProps.isFiltering ?
            `Showing ${reduxProps.data.length}/${reduxProps.instanceCount} ${pluralize('filtered Bend Machine Instance', reduxProps.instanceCount)}` :
            `Showing ${reduxProps.instanceCount} ${pluralize('Bend Machine Instance', reduxProps.instanceCount)} Total`}
        />
        <Options
          updateEntities={() => saveCurrentBendMachineInstanceChanges(
            editedRows,
            (data) => { dispatch(processSaveBendMachineInstances(reduxProps.bendMachineId, data)); resetEditedRows(); },
            reduxProps.defaultUnitSystem
          )}
          isEditingGrid={isEditing}
          isLoading={reduxProps.isLoading}
          pendingValidChanges={editedRows.length > 0}

          editEntity={() => {setEditedBendMachineFamily({id: reduxProps.bendMachine.id, name: reduxProps.bendMachine.name}); setIsDialogOpen(true);}}
          isArchived={reduxProps.bendMachine?.archivedFlag}
          archiveEntity={() => dispatch(processArchiveBendMachineFamiliesForPipeFamily(match.params.id, [reduxProps.bendMachineId]))}
          unarchiveEntity={() => dispatch(processUnarchiveBendMachineFamiliesForPipeFamily(match.params.id, [reduxProps.bendMachineId]))}

          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
          canFilter={true}
        />
      </Flex> || <Skeleton style={{ height: '2rem', marginBottom: '1rem' }} />
      }
      <DataTableContainer>
        <DataTable
          reorderableColumns
          value={reduxProps.data}
          tableStyle={{ minWidth: '55rem' }}
          size='normal'
          editMode='cell'
          rowClassName={(data) => data && rowStyles(data, editedRows)}

          scrollable
          scrollHeight='flex'

          removableSort
        >
          <Column
            header=''
            style={{ width: '2%' }}
            body={(rowdata) => getRowTool(rowdata)}
          />
          <Column
            field='sizeProperty'
            header='Size'
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={() => sizeDropdown()}
            body={(rowdata) => sBody(rowdata, 'sizeProperty', 'Select Size')}
            style={{ width: '24%' }}
          />
          <Column
            field='startClampLength'
            header='Start Clamp Length'
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={() => unitNumberCellEditor('startClampLength')}
            onCellEditComplete={(e) => onCellEditComplete.current(e)}
            body={(rowdata) => unitValueBody(rowdata, 'startClampLength', '')}
            style={{ width: '24%' }}
          />
          <Column
            field='midClampLength'
            style={{ width: '24%' }}
            header='Mid Clamp Length'
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={() => unitNumberCellEditor('midClampLength')}
            onCellEditComplete={(e) => onCellEditComplete.current(e)}
            body={(rowdata) => unitValueBody(rowdata, 'midClampLength', '')}
          />
          <Column
            field='endClampLength'
            style={{ width: '24%' }}
            header='End Clamp Length'
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={() => unitNumberCellEditor('endClampLength')}
            onCellEditComplete={(e) => onCellEditComplete.current(e)}
            body={(rowdata) => unitValueBody(rowdata, 'endClampLength', '')}
          />
        </DataTable>
      </DataTableContainer>
      <BendMachineDialog
        currentEditingRow={editedBendMachineFamily || reduxProps.bendMachine}
        editable={reduxProps.editable}
        handleEditedRowChange={handleEditedDialogChange}
        isDialogOpen={isDialogOpen}
        saveAction={saveBendMachineAction}
        cancelDialogs={() => { setIsDialogOpen(false); setEditedBendFamily(reduxProps.bend); }}
        validation={validation}
      />
    </>);
}

export default withRouter(BendsEditGrid);