import React from 'react';

import Text, { PlaceholderText } from '../../../components/common/Text';
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import Button from '../../../components/common/Button';

export function areFieldsValid({ name }) {
  // takes the currentEditedRow, which is similar to the FittingFamily type
  const _VALIDATION_FIELDS = {
    name: !!name,
  };
  return _VALIDATION_FIELDS;
}

export function doRequiredFieldsExist({ name }) {
  // takes the VALIDATION_FIELDS type, so all values are booleans
  return !!name;
}

// Cell Bodies
export const nameBody = (rowdata, onClickAction) => {
  return (
    <div
      onClick={() => onClickAction(rowdata.id)}
      style={{ cursor: 'pointer' }}
    >
      <ComplexCellRenderer
        showSecondary={false}
        value={{ ...rowdata.summary, to: true }}
        placeholder=""
        isCapitalized={true}
        paddingLeft="0px"
      />
    </div>
  );
};

export const descriptionBody = (rowdata) => {
  if (rowdata.description) {
    return (
      <Text
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '300px'
        }}
      >
        {rowdata.description}
      </Text>
    );
  }

  return <PlaceholderText>No Description</PlaceholderText>;
};

// Misc. Dialog Components
export const dialogFooter = (
  isCreateDialog,
  isCopyDialog,
  deleteAction,
  saveAction,
  cancelAction
) => (
  <>
    {!isCreateDialog && !isCopyDialog && (
      <Button
        error={true}
        onClick={deleteAction}
        style={{ float: 'left' }}
      >
        Delete
      </Button>
    )}
    <Button
      secondary={true}
      onClick={cancelAction}
    >
      Cancel
    </Button>
    {!isCopyDialog ? (
      <Button
        primary={true}
        onClick={saveAction}
      >
        Save
      </Button>
    ) : (
      <Button
        primary={true}
        onClick={saveAction}
      >
        Copy
      </Button>
    )}
  </>
);

export const deleteDialogFooter = (cancelAction, deleteAction) => (
  <>
    <Button
      secondary={true}
      onClick={cancelAction}
    >
      Cancel
    </Button>
    <Button
      error={true}
      onClick={deleteAction}
    >
      Delete
    </Button>
  </>
);