import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import Downshift from 'downshift';

import Flex from '../../common/Flex';
import FixedItemSizeList from '../../common/FixedItemSizeList';

import useFloatingState from '../../../hooks/useFloatingState';

import Tooltip, { LAYER_PRIORITY, CONTAINERS } from '../../tooltip/Tooltip';

import BaseOption from '../BaseOption';
import BaseOptions from '../BaseOptions';
import BaseOptionsFilterCount from '../BaseOptionsFilterCount';
import BaseCreateOption from '../BaseCreateOption';

import CurrentEditableOptionValue from './CurrentEditableOptionValue';

const findSelectedItem = (value, options) => {
  if (value === true || value === false) {
    return options.find(option => option.value === value) || null;
  }
  return options.find(option => option.value == value) || null;
};

const itemToString = option => {
  if (!option) return '';
  if (typeof option.value === 'boolean') {
    return option.value ? 'Yes' : 'No';
  }
  return option.primary || option.value || '';
};

const filterItems = (inputValue, items) => {
  if (inputValue === '' || inputValue === null || inputValue === undefined) {
    return items;
  }

  return items.filter(item => (
    (item.primary && item.primary.toLowerCase().indexOf(inputValue.toLowerCase()) > -1) ||
    (item.secondary && item.secondary.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
  ));
};

const BaseSelect = ({
  forwardRef,
  isLoading,
  name,
  placeholder,
  value,
  options: unfilteredOptions,
  onCreateOption,
  defaultIsOpen,
  onOuterClick: onOuterClickHandler,
  onChange: onChangeHandler,
  currentItemHeight,
  itemHeight = 56,
  width,
  renderValue,
  renderOption,
  clearable
}) => {
  const currentSelectedItem = useMemo(
    () => findSelectedItem(value, unfilteredOptions),
    [value, unfilteredOptions]
  );

  const initialInputValue = useMemo(
    () => itemToString(currentSelectedItem),
    []
  );

  const [selectState, setSelectState] = useState({
    isOpen: !!defaultIsOpen,
    inputValue: initialInputValue,
    initialInputValue
  });

  const options = useMemo(
    () => filterItems(selectState.inputValue, unfilteredOptions),
    [selectState.inputValue, unfilteredOptions]
  );

  const onChange = useCallback(
    item => onChangeHandler && onChangeHandler(!!item && item.value || '') || null,
    [onChangeHandler],
  );

  const onOuterClick = useCallback(
    _ => {
      setSelectState(prev => ({ ...prev, isOpen: false }));
      onOuterClickHandler && onOuterClickHandler();
    },
    [onOuterClickHandler, setSelectState],
  );

  const onStateChange = useCallback(
    ({ type, inputValue, isOpen, selectedItem }) => {
      if (selectedItem) {
        // there is a selected item, so we set it as the new value
        const v = itemToString(selectedItem);
        setSelectState(prev => ({
          ...prev,
          isOpen: false,
          inputValue: v,
          initialInputValue: v,
        }));
        return;
      } else {
        // there is no selected value
        if (type === Downshift.stateChangeTypes.unknown) {
          // specifically using this for the "clearSelection" method which doesn't appear to be working
          // as expected from the docs - WE NEED TO MAKE SURE THERE ARE NO OTHER WEIRD UNKNOWN CLICKS HAPPENING
          setSelectState(prev => ({
            ...prev,
            isOpen: true,
            inputValue: '',
            initialInputValue: clearable ? '' : prev.initialInputValue,
          }));

          if (clearable) {
            onChange();
          }
          return;
        } else if (
          type === Downshift.stateChangeTypes.clickItem ||
          type === Downshift.stateChangeTypes.keyDownEnter ||
          isOpen === false
        ) {
          // this case only triggers if the user basically enters the same matching value
          // as the current value, which is why selectedItem comes back empty
          // this triggers onChange on its own
          // this ALSO triggers whenever the dropdown is closed
          setSelectState(prev => {
            return {
              ...prev,
              isOpen: false,
              inputValue: prev.initialInputValue,
            };});
          return;
        }
      }

      // when the input is just being typed in so the value is changing as normal
      setSelectState(prev => ({
        ...prev,
        isOpen: type !== Downshift.stateChangeTypes.blurInput,
        inputValue: inputValue === undefined ? prev.inputValue : inputValue,
      }));
    },
    [options, onChange],
  );

  const listHeight = options.size >= 5 ? (itemHeight * 5) : (itemHeight * options.size);
  const menuWidth = width <= 240 ? 240 : width;

  const [reference, floating, floatingStyle] = useFloatingState({ placement: 'bottom' });

  // if the value coming into this component is ever set to null or '',
  // we are going to reset the value
  useEffect(() => {
    if ((value === '' || value === null) && clearable) {
      // effectively, we are calling a pseudo "clearSelection", except we are going
      // to close the dropdown here instead of keeping it open because it is an
      // external "clearSelection"
      setSelectState(prev => ({
        ...prev,
        isOpen: false,
        inputValue: '',
        initialInputValue: clearable ? '' : prev.initialInputValue,
      }));
    }
  }, [value]);

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      height="100%"
    >
      <Downshift
        isOpen={selectState.isOpen}
        inputValue={selectState.inputValue}
        initialInputValue={selectState.initialInputValue}
        defaultIsOpen={defaultIsOpen}
        itemToString={itemToString}
        onChange={onChange}
        onOuterClick={onOuterClick}
        defaultHighlightedIndex={0}
        itemCount={options.size}
        onStateChange={onStateChange}
      >
        {
          ({ isOpen, highlightedIndex, inputValue, clearSelection, getRootProps, getInputProps, getMenuProps, getItemProps }) => (
            <Flex
              flexDirection='column'
              justifyContent='center'
              height='100%'
              {...getRootProps({ ref: reference })}
            >
              <CurrentEditableOptionValue
                ref={forwardRef}
                getInputProps={getInputProps}
                isLoading={isLoading}
                inputValue={inputValue}
                name={name}
                placeholder={placeholder}
                currentHighlightedItem={!isOpen ? currentSelectedItem : highlightedIndex > -1 && options.get(highlightedIndex)}
                renderValue={renderValue}
                isOpen={isOpen}
                setSelectState={setSelectState}
                clearSelection={clearSelection}
                clearable={clearable}
                itemHeight={currentItemHeight || itemHeight}
              />
              {
                !isLoading && isOpen && ReactDOM.createPortal(
                  <Tooltip {...getMenuProps({
                    ref: floating,
                    style: floatingStyle,
                    size: 'fluid',
                    priority: LAYER_PRIORITY.MODAL_DROPDOWN
                  })}>
                    <BaseOptions style={{
                      width: menuWidth,
                      height: listHeight + 32 + (onCreateOption ? itemHeight : 0)
                    }}>
                      {
                        onCreateOption && (
                          <BaseCreateOption
                            width={menuWidth}
                            height={itemHeight}
                            currentValue={inputValue}
                            onClick={onCreateOption}
                          />
                        )
                      }

                      <FixedItemSizeList
                        width={menuWidth}
                        height={listHeight}
                        itemCount={options.size}
                        itemSize={itemHeight}
                        scrollToIndex={highlightedIndex || 0}
                      >
                        {
                          ({ index, style }) => (
                            <BaseOption
                              key={index}
                              item={options.get(index)}
                              index={index}
                              style={style}
                              searchWords={[inputValue]}
                              isHighlighted={index === highlightedIndex}
                              isSelected={options.get(index).value === inputValue}
                              getItemProps={getItemProps}
                              renderItem={renderOption}
                            />
                          )
                        }
                      </FixedItemSizeList>

                      <BaseOptionsFilterCount
                        width={menuWidth}
                        filteredCount={options.size}
                        totalCount={unfilteredOptions.size}
                      />
                    </BaseOptions>
                  </Tooltip>,
                  document.querySelector(CONTAINERS.DROPDOWN)
                )
              }
            </Flex>
          )
        }
      </Downshift>
    </Flex>
  );
};

const Select = React.forwardRef((props, ref) => <BaseSelect forwardRef={ref} { ...props } />);

export default Select;
