import React from 'react';
import { NavLink } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styled, { css } from 'styled-components';

import Box from '../../../../components/common/Box';
import Flex from '../../../../components/common/Flex';

const BreadcrumbList = styled(Flex).attrs(props => ({
  $flexDirection: 'row',
  $alignItems: 'center',
}))`
  height: 2rem;
  flex-grow: 1;
  flex-shrink: 0;
`;

const BreadcrumbToken = styled(Flex)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  font-size: ${({ $large, theme }) => $large ? theme.fontSizes[2] : theme.fontSizes[1]};
  font-weight: 500;
  height: 2rem;
  max-width: 10rem;
  margin-right: ${({ theme }) => theme.space[2]};
  text-transform: none;

  transition: color ${({ theme }) => theme.transitions.speed.fast} ${({ theme }) => theme.transitions.type.inOut};

  color: ${({ theme }) => theme.colors.gray[5]};

  &:not(:last-child) {
    padding-right: ${({ theme }) => theme.space[1]};
    padding-left: ${({ theme }) => theme.space[1]};
  }

  &:last-child {
    color: ${({ theme }) => theme.colors.gray[7]};
  }

  ${({ to, theme }) => to && css`
    &:hover,
    &:focus,
    &:active {
      color: ${theme.colors.gray[6]};
    }
  `}
`;

const Breadcrumbs = ({ breadcrumbs, isLoading, theme }) => (
  <BreadcrumbList>
    {breadcrumbs.map(({ breadcrumb, match }, idx) => {
      const isLastItem = breadcrumbs.length === idx + 1;
      return (
        <React.Fragment key={idx}>
          {isLoading ? (
            <Box $width={idx === 0 ? '1.5rem' : '5rem'} $mr={2}>
              <Skeleton />
            </Box>
          ) : (
            <BreadcrumbToken
              as={!isLastItem ? NavLink : undefined}
              to={!isLastItem ? match.url : undefined}
            >
              {breadcrumb}
            </BreadcrumbToken>
          )}
          {!isLastItem && (
            <BreadcrumbToken $large>
              {'//'}
            </BreadcrumbToken>
          )}
        </React.Fragment>
      );
    })}
  </BreadcrumbList>
);

export default Breadcrumbs;
