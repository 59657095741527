import React, { useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import useFloatingState from '../../../../../hooks/useFloatingState';
import useIsHovering from '../../../../../hooks/useIsHovering';

import Flex from '../../../../../components/common/Flex';
import Label from '../../../../../components/common/Label';
import Text from '../../../../../components/common/Text';

import Tooltip, { LAYER_PRIORITY, CONTAINERS } from '../../../../../components/tooltip/Tooltip';
import TooltipBody from '../../../../../components/tooltip/TooltipBody';
import TooltipSection from '../../../../../components/tooltip/TooltipSection';

const BaseNavigationListItem = styled(Flex)`
  flex-direction: row;
  align-items: center;
  height: 3.625rem;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[1]};
  padding: ${({ theme }) => theme.space[4]};
  background-color: transparent;

  transition: background-color ${({ theme }) => theme.transitions.speed.fast};

  ${({ onClick }) => onClick && css`
    cursor: pointer;
    &:hover {
      background-color: ${({ theme }) => theme.colors.gray[0]};
    }
  `}

  ${({ active }) => active && css`
    background-color: ${({ theme }) => theme.colors.primary[0]};

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary[0]};
    }
  `}
`;

const NavigationText = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  margin-right: ${({ theme }) => theme.space[4]};
  flex-grow: 1;
`;

const NavigationListItem = ({ category, active, onSelect, isLoading }) => {
  const [reference, floating, floatingStyle] = useFloatingState({ placement: 'right' });
  const isHovering = useIsHovering(reference);
  const onSelectTab = useCallback(() => {
    onSelect && onSelect(category.id);
  }, [category, onSelect]);

  return (
    <BaseNavigationListItem ref={reference} onClick={!isLoading && onSelectTab || undefined} active={active}>
      <NavigationText>
        <Text color='gray.7' fontWeight='600' fontSize={1} $ellipsis>{ isLoading ? <Skeleton /> : category.name }</Text>
      </NavigationText>
      {
        !isLoading && ((category.valuesCount || category.editedValuesCount) &&
          (<Label primary={!category.editedValuesCount} success={category.editedValuesCount}>{ category.valuesCount + category.editedValuesCount }</Label>)
        ) || null
      }
      {
        !isLoading && (category.valuesCount || category.editedValuesCount) && isHovering && ReactDOM.createPortal(
          <Tooltip ref={floating} style={floatingStyle} size='fluid' priority={LAYER_PRIORITY.MODAL_DROPDOWN}>
            <TooltipBody>
              <TooltipSection small inverse>
                { category.valuesCount && (
                  <Text color='gray.0'><Text as='span' color='primary.4' fontWeight='600' mr={2}>{ category.valuesCount }</Text> saved settings</Text>
                ) || null
                }
                { category.editedValuesCount && (
                  <Text color='gray.0'><Text as='span' color='success.4' fontWeight='600' mr={2}>{ category.editedValuesCount }</Text> unsaved settings</Text>
                ) || null
                }
              </TooltipSection>
            </TooltipBody>
          </Tooltip>,
          document.querySelector(CONTAINERS.TOOLTIP)
        ) || null
      }
    </BaseNavigationListItem>
  );
};

export default NavigationListItem;