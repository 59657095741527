import React from 'react';
import styled from 'styled-components';

import Flex from '../../common/Flex';

export const Cell = styled(Flex)`
  flex-direction: column;
  padding: ${({ theme }) => theme.space[3]};
  position: relative;
  overflow: hidden;

  height: ${({ $height }) => $height || 'auto'};
  min-height: ${({ $minHeight }) => $minHeight || '6rem'};
  width: ${({ $width }) => $width};
`;

const BaseFormCell = React.forwardRef(({ bg, ...props }, ref) => (
  <Cell
    ref={ref}
    $bg={bg || 'gray.1'}
    {...props}
  />
));

export default BaseFormCell;