import React, { useCallback, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import useFloatingState from '../../../../hooks/useFloatingState';
import useIsHovering from '../../../../hooks/useIsHovering';

import Flex from '../../../../components/common/Flex';
import Icon from '../../../../components/common/Icon';
import Text from '../../../../components/common/Text';

import Tooltip, { CONTAINERS } from '../../../../components/tooltip/Tooltip';
import TooltipBody from '../../../../components/tooltip/TooltipBody';
import TooltipSection from '../../../../components/tooltip/TooltipSection';

import { toggleShowArchived } from '../../../../modules/query/actions';
import { selectIsShowingArchived } from '../../../../modules/query/selectors';

const ShowArchivedContainer = styled(Flex)`
  margin-bottom: ${({ theme }) => theme.space[5]};
  padding-right: ${({ theme }) => theme.space[3]};
  padding-left: ${({ theme }) => theme.space[3]};

  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Widget = styled(Flex)`
  overflow: hidden;
  background: ${({ theme }) => theme.colors.gray[1]};
  flex-grow: 1;
  flex-shrink: 0;
  cursor: pointer;

  flex-direction: row;
  align-items: center;
  justify-content: center;

  border: ${({ theme }) => theme.borders[1]};
  border-radius: ${({ theme }) => theme.radii[1]};
`;

const WidgetText = styled(Text)`
  flex-grow: 1;
  flex-shrink: 0;
  padding-left: ${({ theme }) => theme.space[2]};
  padding-right: ${({ theme }) => theme.space[2]};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gray[7]};
`;

const WidgetToggle = styled(Flex)`
  padding: ${({ theme }) => theme.space[3]};
  font-size: ${({ theme }) => theme.fontSizes[2]};

  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: ${({ theme }) => theme.radii[1]};
`;

const ShowArchivedTooltipContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.space[3]};
  cursor: help;

  &:hover {
    opacity: 0.8;
  }
`;

const ShowArchivedWidget = () => {
  const dispatch = useDispatch();
  const toggle = useCallback(() => dispatch(toggleShowArchived()), []);
  const memoizedSelectIsShowingArchived = useMemo(selectIsShowingArchived, []);
  const showingArchived = useSelector(state => memoizedSelectIsShowingArchived(state));

  const [reference, floating, floatingStyle] = useFloatingState({ placement: 'right' });
  const isHovering = useIsHovering(reference);

  return (
    <ShowArchivedContainer>
      <Widget onClick={toggle} >
        <WidgetToggle>
          {showingArchived ? (
            <Icon name='checkbox' color='primary.4' />
          ) : (
            <Icon name='checkbox-outline' color='gray.6' />
          )}
        </WidgetToggle>

        <WidgetText>
          Show Archived
        </WidgetText>
      </Widget>

      <ShowArchivedTooltipContainer ref={reference}>
        <Icon name='help' color='gray.7' />
      </ShowArchivedTooltipContainer>

      {
        isHovering && ReactDOM.createPortal(
          <Tooltip ref={floating} style={floatingStyle} size='small'>
            <TooltipBody>
              <TooltipSection small inverse>
                Archived entities are hidden from view by default. If this toggle is on, then all entities will be visible.
              </TooltipSection>
            </TooltipBody>
          </Tooltip>,
          document.querySelector(CONTAINERS.TOOLTIP)
        )
      }
    </ShowArchivedContainer>
  );
};

export default ShowArchivedWidget;

